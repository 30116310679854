import { Box, Chip, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Fragment, useContext, useEffect } from 'react'
import { GlobalContext } from '../../providers/globalStore'
import '@styles/common.module.scss'
import '@styles/header/index.scss'
import { useNavigate } from 'react-router-dom'
import GetContractTextColor from '@utils/getContractTextColor'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const HeaderTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 15,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 8,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

interface HeaderProps {
  title: string
  subtitle?: string
  formStatus?: string
  bgColor: string
}

const Header = (props: HeaderProps) => {
  const { dispatch } = useContext(GlobalContext)
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    dispatch({ type: 'toggleLeftDrawer', opened: !matchDownMd })
  }, [matchDownMd])
  const { t } = useTranslation()

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          zIndex: 9999,
        }}>
        {process.env.REACT_APP_ENV !== 'PROD' && (
          <Box>
            {' '}
            <Chip color="warning" label={t(process.env.REACT_APP_ENV as string)} />
          </Box>
        )}
        <Box
          sx={{
            paddingX: 1,
          }}>
          {!matchDownMd && props.subtitle && <HeaderSubtitle>{t(props.subtitle)}</HeaderSubtitle>}
          <HeaderTitle>
            {matchDownMd || !props.formStatus
              ? t(props.title)
              : `${t(props.title)} - ${t(props.formStatus)}`}
          </HeaderTitle>
          {matchDownMd && props.formStatus && (
            <HeaderSubtitle>{t(props.formStatus)}</HeaderSubtitle>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {matchDownMd && (
          <Box>
            <IconButton
              sx={{ p: 0, position: 'absolute', right: '10%', top: '30%' }}
              onClick={() => {
                dispatch({ type: 'toggleLeftDrawer' })
              }}>
              <img src="/img/nav.svg" alt="menu" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default Header
