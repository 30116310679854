import { useState, MouseEvent, Fragment, useEffect } from 'react'
import { Box, Divider, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useAPIFetch from '@hooks/useAPIFetch'
import { FormCountByUser } from '@services/model/form/form.model'
import { UserInfo } from '@services/model/user.model'
import useLocalStorage from '@hooks/useLocalStorage'
import { getFormListOrder } from '@utils/getFormListOrder'
import SelectContract from '@components/sidebar/selectContract'
import { useOktaAuth } from '@okta/okta-react'
import { useNavigate } from 'react-router-dom'
import { NavigateTo } from '@utils/navigate'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import UserService from '@services/user.service'

const HeaderTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  marginTop: '0px',
}))

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 27,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
    margin: 8,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '0px',
  fontWeight: 'bold',
}))
const LandingPage = () => {
  const { t } = useTranslation()
  const [formCountItem, setFormCountItem] = useState<FormCountByUser[]>([])
  const { setRequest, isLoading } = useAPIFetch()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const formListOrder = getFormListOrder()
  const { authState, oktaAuth } = useOktaAuth()
  const navigate = useNavigate()

  const reload = async () => {
    setRequest({
      callback: async () => {
        // let list = await GeneralOptionsService.GetUserFormCount()
        // const sortList = formListOrder.map((x) => x.formCode)
        // list.sort((a, b) => {
        //   return sortList.indexOf(a.formCode) - sortList.indexOf(b.formCode)
        // })
        let list = [
          { formCode: 'DRS', totalCount: 0 },
          { formCode: 'MWF', totalCount: 0 },
          { formCode: 'WFT', totalCount: 0 },
          { formCode: 'WMP', totalCount: 0 },
          { formCode: 'WMPT', totalCount: 0 },
        ]
        setFormCountItem(list)
      },
    })
  }

  useEffect(() => {
    console.debug('[authState]', authState)
    if (!authState?.isAuthenticated) {
      NavigateTo(navigate, '/login')
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          const email = info.email
          if (info.email) {
            UserService.GetUserInfo(info.email).then((userInformation: UserInfo) => {
              // window.alert(JSON.stringify(userInformation))
              userInformation.email = email ?? ''
              setLoginUser(userInformation)
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [authState])

  useEffect(() => {
    if (loginUser !== undefined && loginUser?.uuid !== '') {
      reload()
    }
  }, [loginUser?.uuid])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        padding: matchDownMd ? '10px 10px' : '57px 100px',
      }}>
      <Box>
        <HeaderSubtitle hidden={matchDownMd}>{t('common:landing.title')}</HeaderSubtitle>
        <HeaderTitle hidden={matchDownMd}>{loginUser?.firstName}</HeaderTitle>
        <Divider sx={{ mb: '20px' }} />
        <HeaderSubtitle>{t('common:landing.outstandingWorks')}</HeaderSubtitle>
        <Box>
          <SelectContract />
        </Box>
      </Box>
      {/* <Grid container spacing={2}>
        {formCountItem.map((item) => (
          <Grid item xs={12} md={12} lg={12} key={`landing-form-item-${item.formCode}`}>
            <LandingFormItem model={{ ...item }} />
          </Grid>
        ))}
      </Grid> */}
    </Box>
  )
}

export default LandingPage
