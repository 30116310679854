const replaceNullValues = (arr, value) => {
  if (!Array.isArray(arr)) {
    throw new Error('Parameter must be an array')
  }

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (Array.isArray(item)) {
      replaceNullValues(item, value)
    } else {
      if (item === null) {
        arr[i] = value
      }
    }
  }

  return arr
}

export default replaceNullValues
