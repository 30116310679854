import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormFreeText from '@components/form/freeText'
import { FormDrsItemModel } from '@services/model/form/form.DRS.item.model'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { KeyValPair } from '@models/Common'
import FormTypography from '@components/form/typography'
import cleanString from '@utils/cleanString'
import { DisposalGroundEnum } from '@services/model/form/form.DRS.model'
import TimeField from '@components/TimeField'
const DailyRecordSystemItemSinglePartA = ({
  uneditable,
  control,
  drsGeneralOptions,
  formId,
}: {
  uneditable: any
  control: Control<FormDrsItemModel>
  drsGeneralOptions: any
  formId: any
}) => {
  let index = 0
  const designatedDisposalGroundOptions = drsGeneralOptions?.disposalGround?.filter(
    (option) => cleanString(option.value) !== cleanString(DisposalGroundEnum.other),
  )
  const { t } = useTranslation(['forms'])

  return (
    <>
      <FormContainer fkey={`drs-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractName',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.chitNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'chitNo',
              control,
            }}>
            <FormFreeText textfieldProps={{ type: 'string' }} uneditable={uneditable} />
          </FormController>
        </FormField>
        <FormField
          fieldName={t('forms:dailyRecordSummary.fields.vehicleRegistrationMark')}
          index={index++}>
          <FormController
            controllerProps={{
              name: 'vehicleRegistrationMark',
              control,
            }}>
            <FormFreeText textfieldProps={{ type: 'string' }} uneditable={uneditable} />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.dateOfDisposal')} index={index++}>
          <FormController
            controllerProps={{
              name: 'dateOfDisposal',
              control,
            }}>
            <FormDateTimePicker
              type="date"
              maxDate={new Date()}
              uneditable={uneditable}
              sx={{ width: '100%' }}
              withCurrent={true}
            />
          </FormController>
        </FormField>
        <FormField
          fieldName={t('forms:dailyRecordSummary.fields.departureTimeFromSite')}
          index={index++}>
          <FormController
            controllerProps={{
              name: 'departureTimeFromSite',
              control,
            }}>
            {/* <FormDateTimePicker
              type="time"
              maxDate={new Date()}
              // defaultValue={new Date()}
              uneditable={uneditable}
              sx={{ width: '100%' }}
              withCurrent={true}
            /> */}
            <TimeField uneditable={uneditable} sx={{ width: '100%' }} withCurrent={true} />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.approxVol')} index={index++}>
          <FormController
            controllerProps={{
              name: 'approxVol',
              control,
            }}>
            <FormSelect
              options={drsGeneralOptions?.approximateValue ?? []}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.cDMaterialType')} index={index++}>
          <FormController
            controllerProps={{
              name: 'cDMaterialType',
              control,
            }}>
            <FormSelect
              options={drsGeneralOptions?.cDMaterialType ?? []}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.disposalGround')} index={index++}>
          <FormController
            controllerProps={{
              name: 'disposalGround',
              control,
            }}>
            <FormSelect
              options={designatedDisposalGroundOptions}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.zoneWithinSite')} index={index++}>
          <FormController
            controllerProps={{
              name: 'zoneWithinSite',
              control,
            }}>
            <FormFreeText uneditable={uneditable} />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.remarks.label')} index={index++}>
          <FormController
            controllerProps={{
              name: 'remarks',
              control,
            }}>
            <FormFreeText uneditable={uneditable} />
          </FormController>
        </FormField>
      </FormContainer>
    </>
  )
}

export default DailyRecordSystemItemSinglePartA
