import { Fragment, useContext, useEffect, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { FormWftModel, InitWftForm } from '@services/model/form/form.WFT.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import WftPartA from './PartA'
import WftPartB from './PartB'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormWftGeneralOptionsModel } from '@services/model/form/form.WFT.model'
import useLocalStorage from '@hooks/useLocalStorage'
import FormWftService from '@services/formService/form.WFT.service'
import getErrMsg from '@utils/getErrMsg'

export default function WFTForm() {
  const UUID = uuid()
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  Yup.addMethod(Yup.mixed, 'validateOnlyOne', function (fields: string[]) {
    return this.test({
      name: 'validateOnlyOne',
      message: t('forms:validate.messages.onlyOne'),
      test: function (value) {
        let hasOne = false
        fields.forEach((field) => {
          if (this.parent[field] !== undefined) {
            if (hasOne) return false
            hasOne = true
          }
        })
        return hasOne
      },
    })
  })
  const defaultMsg = t('forms:validate.messages.required')
  const formSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
        constructionStage: Yup.number().nullable().required(defaultMsg),
        // otherConstructionStage: Yup.string().nullable().required(defaultMsg),
        constructionStartDate: Yup.date().nullable().required(defaultMsg),
        constructionEndDate: Yup.date().nullable().required(defaultMsg),
        material: Yup.number().nullable().required(defaultMsg),
        wasteManagementMethod: Yup.number().nullable().required(defaultMsg),
      }),
    ),
  })
  const { control, setValue, getValues, reset, trigger, setError, clearErrors } =
    useForm<FormWftModel>({
      defaultValues: { ...InitWftForm },
      resolver: yupResolver(formSchema, { abortEarly: false }),
      mode: 'all',
      reValidateMode: 'onBlur',
      criteriaMode: 'all',
    })

  const [contractNoId, contractNo, contractName, formStatus, formPermission] = useWatch({
    name: [
      'baseForm.contractNoId',
      'baseForm.contractNo',
      'baseForm.contractName',
      'baseForm.formStatus',
      'baseForm.formPermission',
    ],
    control: control,
  })

  const isMounted = useRef(false)

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const wftGeneralOptionsRef = useRef<FormWftGeneralOptionsModel>()

  const triggerValidate = async (event) => {
    if (!(await trigger())) {
      enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
      event.preventDefault()
    }
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) {
      enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
      return
    }
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    setValue('submissionComment', submissionComment)
    const values = getValues()
    setRequest({
      callback: async () => {
        await FormWftService.SaveWftForm(values)
          .then((resp) => {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/waste-forecast-table/:id', {
              id: resp.formId,
            })
            formId = resp.formId
            isMounted.current = false
            enqueueSnackbar(t('common:messages.recordSubmitted'), { variant: 'success' })
            NavigateTo(navigate, '/waste-forecast-table/all-records')
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const handleOnSave = async () => {
    let request = getValues()

    setRequest({
      callback: async () => {
        await FormWftService.SaveWftForm(request)
          .then((resp) => {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/waste-forecast-table/:id', {
              id: resp.formId,
            })
            formId = resp.formId
            isMounted.current = false
            enqueueSnackbar(t('common:messages.recordSubmitted'), { variant: 'success' })
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormWftService.DeleteWftForm(getValues())
          .then(() => {
            enqueueSnackbar(t('common:messages.recordArchived'), { variant: 'success' })
            NavigateTo(navigate, '/waste-forecast-table/all-records')
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formStatus])

  useEffect(() => {
    if (!isMounted.current) {
      reloadWFTGeneralOptions()
    }
    if (formStateContext.form && formStateContext.formType === 'WFT') {
      formStateAction({ type: 'pop', targetFormType: 'WFT' })
      // previous editing form (new / existed form)
      reset({ ...formStateContext.form })
    } else if (currentContract?.contractId && !formId) {
      FormWftService.GetWftList(
        {
          pagination: null,
          sorting: null,
          contractNo: {
            Operator: '=',
            Value: [currentContract.contractId],
          },
        },
        {},
        false,
      ).then((res) => {
        if (res.totalCount > 0) {
          if (res.list.find((x) => x.status !== false).formId) {
            NavigateTo(navigate, '/waste-forecast-table/:id', { id: res.list?.[0]?.formId })
          }
        }
      })
      reset({
        ...InitWftForm,
        baseForm: {
          ...InitWftForm.baseForm,
          contractNoId: currentContract?.contractId,
          contractNo: currentContract?.contractNo,
          contractName: currentContract?.contractName,
          projectId: currentProject?.projectId,
          projectType: currentContract?.projectType,
        },
      })
    } else if (globalState.contractList && formId && !isMounted.current) {
      reload(formId)
      isMounted.current = true
    }
    return () => {
      isMounted.current = false
    }
  }, [formId, currentContract, globalState.contractList])

  const reloadWFTGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormWftService.GetWftGeneralOptions().then(async (res) => {
          wftGeneralOptionsRef.current = res
        })
      },
    })
  }

  const handleReloadTips = (res: FormWftModel) => {
    let rows = res.rows
    let data = res
    if (rows) {
      const modifiedRows = rows.map((item) => {
        if (item.material && item.wasteManagementMethod) {
          return {
            ...item,
            shortTips:
              wftGeneralOptionsRef.current?.wasteManagementTipShort?.[item.material]?.[
                item.wasteManagementMethod
              ],
            tips: wftGeneralOptionsRef.current?.wasteManagementTip?.[item.material]?.[
              item.wasteManagementMethod
            ],
          }
        }
        return item
      })

      data.rows = modifiedRows
    }
    return data
  }

  const reload = async (formId: string) => {
    setRequest({
      callback: async () => {
        await FormWftService.GetWftForm(formId, formStatus)
          .then(async (res) => {
            if (res) {
              const resContractNo = globalState?.contractList?.find(
                (x) => x.id === res.baseForm.contractNoId,
              )?.contractNo
              const resContractName = globalState?.contractList?.find(
                (x) => x.id === res.baseForm.contractNoId,
              )?.contractName
              let newRes = {
                ...handleReloadTips(res),
                baseForm: {
                  ...res.baseForm,
                  contractNo: resContractNo,
                  contractName: resContractName,
                },
              }
              reset(newRes)
              if (res.baseForm.formStatus !== formStatus) {
                globalAction({
                  type: 'changeFormStatus',
                  formStatus: res.baseForm.formStatusName,
                })
              }
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const partA = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteForecastTable.sections.general.title'),
      component: <WftPartA control={control} formId={formId} />,
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteForecastTable.title'),
      component: (
        <WftPartB
          globalState={globalState}
          getValues={getValues}
          useWatch={useWatch}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          control={control}
          formPermission={formPermission}
          wftGeneralOptions={wftGeneralOptionsRef.current}
          editable={true}
        />
      ),
      onNext: async (event) => {
        if (!(await trigger())) {
          enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
          return 1
        }
      },
    }
  }

  const partC = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteForecastTable.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }
  const onLoadNotifyList = async (isReject: boolean) => {
    let defaultNotifyList = await FormService.GetNotifyList(
      'WFT',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    let defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:wasteForecastTable.title'),
    components: [partA(), partB(), partC()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      if (getValues('baseForm.formId')) {
        await FormService.SubmitComment(getValues('baseForm.formId'), comment)
      }
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableSaveBtn: true,
    disableComment: getValues('baseForm.formId') ? false : true,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate, // TODO: change back to !formPermission?.canUpdate
    disableDelete: !formPermission?.canDelete || false, // TODO: remove this when month is working?
    approveRequired: formPermission?.approveRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}
