import { KeyValPair } from '@models/Common'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
interface formRadioProps {
  value?: number
  onChange?: (...event: any[]) => void
  labels: KeyValPair[]
  additionalOnChangeAction?: (...event: any[]) => any

  disabled?: boolean
  uneditable?: boolean
}

/**
 * form radio group with custom labels, which should be type `string[]`
 * @param props formRadioProps
 * @example
 * ```
 * <FormField fieldName="Type of Works" index={6}>
 *     <FormController
 *         controllerProps={{
 *             name: 'typeOfWorks',
 *             control: control,
 *         }}>
 *             <FormRadio labels={masterData.typeOfWorks}/>
 *     </FormController>
 * </FormField>
 * ```
 */
const FormRadio = (props: formRadioProps) => {
  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any) => {
    props.onChange!(parseInt(event.target.value))
    if (props.additionalOnChangeAction) {
      props.additionalOnChangeAction!(event.target.value)
    }
  }

  const { t } = useTranslation()
  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{t(GetValueWithKey(props.value, props.labels))}</Typography>
      ) : (
        <RadioGroup value={props.value ?? -1} onChange={handleChange}>
          <FormControlLabel key={`form-radio-undefined`} label="" value={-1} control={<></>} />
          {props.labels.map((label, index) => (
            <FormControlLabel
              label={t(label.value)}
              value={label.key}
              disabled={(label.status !== undefined && !label.status) || props.disabled}
              key={`form-radio-${index}`}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      )}
    </Fragment>
  )
}

export default FormRadio
