import FormContainer from '@components/form/container'
import FormField from '@components/form/field'
import { FormWmpModel } from '@services/model/form/form.WMP.model'
import FormTypography from '@components/form/typography'
import { Control } from 'react-hook-form'
import { Grid } from '@mui/material'
import FormController from '@components/form/controller'
import { useTranslation } from 'react-i18next'

const WasteManagementPlanTemplatePartA = ({ control }: { control: Control<FormWmpModel> }) => {
  let index = 0
  const { t } = useTranslation()
  return (
    <Grid sx={{ minHeight: 620 }}>
      <FormContainer fkey={`$wmp-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractName',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
      </FormContainer>
    </Grid>
  )
}

export default WasteManagementPlanTemplatePartA
