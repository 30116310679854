import {
  Typography,
  Collapse,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { FormStatus, FormStatusHistory } from '@services/model/form/form.model'
import { Fragment, useEffect, useState } from 'react'
import FormDate from './date'
import FormField from './field'
import { useTranslation } from 'react-i18next'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Base64StringToImageBase64 } from '@utils/image'
import FormController from './controller'
import FormSignatureHolder from './signatureHolder'
import { KeyValPair } from '@models/Common'

type uploadProps = {
  items: {
    key: string
    value: any
  }[]
  // handleOnOpen?: any
  control?: any
  errors?: any
  uneditable?: boolean
  disabled?: boolean
  openList?: Array<boolean>
  handleOnOpen?: (open: Array<boolean>) => Array<boolean>
  tabsx?: any
  sx?: any
}

/**
 * form signature items showcasing signatures and related fields from action items
 * @param props uploadProps
 * @example
 * ```
 * <AccordionGroup items={[name:'', value:{<Element />},]} />
 * ```
 */

const AccordionGroup = (props: uploadProps) => {
  const { t, i18n: i18next } = useTranslation()
  const { language } = i18next
  const [open, setOpen] = useState<boolean[]>(props?.openList ?? [])

  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  useEffect(() => {
    
  }, [language])

  return (
    <Grid xs={12}>
      <List>
        {props.items?.map((item: any, index: number) => (
          <List key={`form-item-${index}`}>
            <ListItemButton
              sx={{ ...props.tabsx }}
              onClick={(_: any) => {
                handleOpen(index)
              }}>
              <ListItemText primary={t(item.key)} />
              {open[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[index]} sx={{ ...props.sx }}>
              {item.value}
            </Collapse>
          </List>
        ))}
      </List>
    </Grid>
  )
}

export default AccordionGroup
