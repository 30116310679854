import MultiTypeDatasetChart, {
  generalChartDatasetProps,
} from '@components/chart/MultiTypeDatasetChart'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const DashboardProjectCumulatiiveActualVsForecast = ({
  getValues,
  settings,
  StyledCard,
  StyledBox,
}: {
  getValues: any
  settings: any
  StyledCard: any
  StyledBox: any
}) => {
  const { t } = useTranslation()

  const mockData = {
    title: 'Cumulative Actual vs Forecast',
    datasets: [
      {
        groupName: 'Total Forecast',
        data: [
          {
            itemName: '2024-02',
            value: 0,
            itemNo: 1,
          },
          {
            itemName: '2024-03',
            value: 3,
            itemNo: 12,
          },
        ],
        totalItemNo: 0,
      },
      {
        groupName: 'Total Actual',
        data: [
          {
            itemName: '2024-02',
            value: 2,
            itemNo: 7,
          },
          {
            itemName: '2024-03',
            value: 1,
            itemNo: 24,
          },
        ],
        totalItemNo: 0,
      },
    ],
    showChart: false,
    message: 'No data was found to match your filter criteria. Try broadening your filtered range',
  }

  const values = getValues()
  const resKey = 'cumulativeActualVsForecast'

  const hideChart = values?.[resKey]?.showChart === false
  const message = values?.[resKey]?.message ?? ''

  const byMonthWasteCategorySettings = settings?.cumulativeActualVsForecast
  const byMonthWasteCategoryData = values?.[resKey]?.datasets

  const timeFormattedData = byMonthWasteCategoryData?.map((item) => {
    const formattedValue: { [key: string]: any } = {}
    item.data?.map((item: any) => {
      const formattedDate = moment(item.itemName).format('MMM YYYY')
      formattedValue[formattedDate] = item.itemNo // actual value
    })
    return { groupName: item.groupName, value: formattedValue }
  })

  let wasteData: generalChartDatasetProps[] = []
  const processWasteData = (wasteData: any[]) => {
    console.debug('timeFormattedData', timeFormattedData)
    timeFormattedData?.map((item: any, index: number) => {
      let obj: generalChartDatasetProps = {
        type: 'line',
        label: t(item.groupName),
        data: item.value,
        yAxisID: 'y',

        borderColor: byMonthWasteCategorySettings?.[item.groupName]?.borderColor,
        backgroundColor: byMonthWasteCategorySettings?.[item.groupName]?.backgroundColor,
      }
      wasteData.push(obj)
    })
  }

  processWasteData(wasteData)

  let multiTypeDatasetChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        reverse: true,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 10,
        },
        fullSize: false, // set fullSize to false
      },
      //TODO:  Add a custom plugin to display the total value on top of each bar
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
      },
    },
  }

  return (
    <Grid container md={12} xs={12}>
      <Grid item md={12} xs={12}>
        <StyledBox>
          {hideChart ? (
            <>{t(message)}</>
          ) : (
            <MultiTypeDatasetChart
              eleBefore={
                <>
                  <span>{t('dashboard:wasteInTonnes')}</span>
                </>
              }
              chartWrapperSX={{
                width: '100%',
                minWidth: 200,
                minHeight: 450,
                margin: 'auto',
              }}
              datasets={wasteData}
              options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
          )}
        </StyledBox>
      </Grid>
    </Grid>
  )
}
export default DashboardProjectCumulatiiveActualVsForecast
