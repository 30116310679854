import { useTranslation } from 'react-i18next'
import VerticalTabs from '@components/dashboard/verticalTabs'
import { ReactElement, useEffect, useRef } from 'react'
// import ByType from './byType'
import RegisteredUsersAndVisitors from './RegisteredUsersAndVisitors'
import OngoingProjects from './OngoingProjects'
import Enquiries from './Enquiries'

const DashboardCicQuantityStatistics = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  type tabProps = () => {
    menuTitle: string
    title: string
    content: ReactElement
  }

  const { t } = useTranslation('dashboard')

  const getRegisteredUsersAndVisitors: tabProps = () => {
    return {
      menuTitle: t('dashboard:registeredUsersAndVisitors'),
      title: '',
      content: (
        <RegisteredUsersAndVisitors
          getValues={getValues}
          settings={settings}
          StyledCard={StyledCard}
        />
      ),
    }
  }
  const getOngoingProjects: tabProps = () => {
    return {
      menuTitle: t('dashboard:ongoingProjects'),
      title: '',
      content: <OngoingProjects getValues={getValues} settings={settings} />,
    }
  }
  const getEnquiriesChart: tabProps = () => {
    return {
      menuTitle: t('dashboard:enquiries'),
      title: '',
      content: <Enquiries getValues={getValues} settings={settings} StyledCard={StyledCard} />,
    }
  }
  const tabs = [getRegisteredUsersAndVisitors(), getOngoingProjects(), getEnquiriesChart()]
  return <VerticalTabs data={tabs} sx={{ padding: 1 }} />
}
export default DashboardCicQuantityStatistics
