import { styled } from '@mui/material/styles'
import { lighten } from 'polished'
import { Box } from '@mui/material'

const LinkTableAddButton = styled(Box)<{
  state: {
    showHeaderAddButton?: boolean
    disableAddButton?: boolean
    uneditable?: boolean
    color?: string
  }
}>(({ state }) => ({
  width: 60,
  height: 60,
  display: state.uneditable || !state.showHeaderAddButton ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  position: 'relative',
  zIndex: 1000,
  backgroundColor: state.disableAddButton ? '#909090' : state.color ?? '#aaaaaa',
  ...(!state.disableAddButton && {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: state.color ? lighten(0.1, state.color) : '#cccccc',
      '#add-button-hover-text': {
        opacity: '80% !important',
        width: 200,
        transition: 'opacity 500ms !important',
      },
    },
  }),
}))

export default LinkTableAddButton
