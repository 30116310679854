import { KeyValPair } from '@models/Common'
import { Typography } from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import moment from 'moment'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface BinaryOptions {
  yes: string
  no: string
  na?: string
}
interface formTypographyProps {
  value?: any
  onChange?: (...event: any[]) => void
  name?: string
  type?: 'string' | 'binary' | 'select' | 'numbers' | 'date' | 'time' | 'number' | 'custom'
  omitNaN?: boolean
  dateFormat?: string
  timeFormat?: string
  numberDemical?: number
  options?: KeyValPair[]
  binaryOptions?: BinaryOptions
  sx?: CSSProperties
  error?: any
}

export default function FormTypography(props: formTypographyProps) {
  const { t } = useTranslation()

  switch (props.type) {
    case 'binary': {
      let displayValue = ''
      switch (props.value) {
        case true: {
          displayValue = props.binaryOptions
            ? t(props.binaryOptions.yes)
            : t('common:components.binarySelect.yes')
          break
        }
        case false: {
          displayValue = props.binaryOptions
            ? t(props.binaryOptions.no)
            : t('common:components.binarySelect.no')
          break
        }
        default: {
          displayValue =
            props.binaryOptions && props.binaryOptions.na
              ? t(props.binaryOptions.na)
              : t('common:components.binarySelect.na')
          break
        }
      }
      return <Typography sx={props.sx}>{displayValue}</Typography>
    }
    case 'select': {
      if (props.options) {
        return (
          <Typography sx={props.sx}>{t(GetValueWithKey(props.value, props.options) ?? '')}</Typography>
        )
      } else {
        console.warn(`${props.name} missing options for select type typography`)
        return <></>
      }
    }
    case 'numbers': {
      const str =
        props.value?.reduce((acc: string, cur: string) => (acc += cur + ', '), '')?.slice(0, -2) ??
        ''
      return <Typography sx={props.sx}>{str}</Typography>
    }
    case 'number': {
      let num = props.value as number
      //if num is NaN, num = 0
      if (isNaN(num)) {
        return <Typography sx={props.sx}></Typography>
      }
      if (props.numberDemical) {
        return <Typography sx={props.sx}>{num?.toFixed(props.numberDemical)}</Typography>
      } else {
        return <Typography sx={props.sx}>{num}</Typography>
      }
    }
    case 'date': {
      const dateStr = props.value
        ? moment(props.value).format(props.dateFormat ?? 'D MMMM YYYY')
        : ''
      return <Typography sx={props.sx}>{dateStr}</Typography>
    }
    case 'time': {
      const timeStr = props.value
        ? moment(props.value).format(props.timeFormat ?? moment.defaultFormat)
        : ''
      return <Typography sx={props.sx}>{timeStr}</Typography>
    }

    case 'custom': {
      return <Typography sx={props.sx}>{props.value ?? ''}</Typography>
    }
    case 'string':
    default: {
      return <Typography sx={props.sx}>{t(props.value) ?? ''}</Typography>
    }
  }
}
