import apiGateway from '@utils/axiosInterceptor'
import { FormStatus } from './model/form/form.model'
import { GeneralConfig, GeneralOptionGroup } from './model/general.model'

const GetFormActionList = async () => {
    return await apiGateway.get<FormStatus[]>('/api/v1/forms/form-status-list').then((rep) => {
        return rep.data
    })
}

const GetGeneralOptionGroupList = async () => {
    return await apiGateway.get<GeneralOptionGroup[]>('/api/v1/general-options').then((rep) => {
        return rep.data
    })
}

const GetGeneralConfigList = async () => {
    return await apiGateway.get<GeneralConfig[]>('/api/v1/general-config').then((rep) => {
        return rep.data
    })
}

const GeneralOptionsService = {
    GetGeneralOptionGroupList,
    GetFormActionList,
    GetGeneralConfigList,
}

export default GeneralOptionsService
