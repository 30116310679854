import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import ns, { i18nCommonNS as defaultNS } from './namespaces'

export enum LanguageEnum {
  ENGLISH = 'en-US',
  TRADITIONAL_CHINESE = 'zh-Hant',
  SIMPLIFIED_CHINESE = 'zh-Hans',
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns,
    defaultNS,
    fallbackLng: LanguageEnum.ENGLISH,
    // debug: process.env.REACT_APP_ENV !== 'PROD',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
