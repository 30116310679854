import { KeyValPair } from '@models/Common'
import {
  Autocomplete,
  FilterOptionsState,
  TextField,
  Tooltip,
  Typography,
  TextFieldProps,
  FormHelperText,
  ComponentsProps,
  Popper,
  SxProps,
} from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type additionalChangeActionPromiseType = (event: any, newValue: KeyValPair | null) => Promise<void>
type additionalChangeActionType = (event: any, newValue: KeyValPair | null) => void

interface formSelectProps {
  tooltipFieldName?: string
  value?: any
  onChange?: (...event: any[]) => void
  onBlur?: (...event: any[]) => void
  error?: any
  options: KeyValPair[]
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  additionalChangeAction?: additionalChangeActionPromiseType | additionalChangeActionType
  multiple?: boolean
  disabled?: boolean
  uneditable?: boolean
  disableClearable?: boolean
  textfieldProps?: TextFieldProps
  componentsProps?: any
  sx?: SxProps
}

export default function FormSelect(props: formSelectProps) {
  const { t } = useTranslation()

  const [optionList, setOptionList] = useState<KeyValPair[]>([])
  useEffect(() => {
    setOptionList(props.options || [])
  }, [props.options])

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = async (event: any, newValue: KeyValPair | null) => {
    props.onChange!(newValue ? newValue.key : -1)
    props.onBlur!(newValue ? newValue.key : -1)
    if (props.additionalChangeAction) {
      if (props.additionalChangeAction.constructor.name === 'AsyncFunction') {
        await props.additionalChangeAction(event, newValue)
      } else {
        props.additionalChangeAction(event, newValue)
      }
    }
  }

  const textfieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t('Please select an option'),
    disabled: props.disabled,
    ...props.textfieldProps,
  }
  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        placement="bottom"
        sx={{ height: '300px', maxHeight: '100px', width: props.anchorEl.clientWidth }}
      />
    )
  }
  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{t(GetValueWithKey(props.value, optionList) ?? '')}</Typography>
      ) : props.multiple ? null : (
        <>
          <Autocomplete
            sx={props.sx}
            slotProps={{ popper: { sx: { '& .MuiAutocomplete-listbox': { maxHeight: '200px' } } } }}
            componentsProps={props.componentsProps}
            disablePortal
            blurOnSelect
            value={{
              key: props.value || -1,
              value: optionList.find((option) => option.key === props.value)?.value || '',
            }}
            getOptionLabel={(option: KeyValPair) => {
              return t(option.value)
            }}
            isOptionEqualToValue={(option: KeyValPair, value: KeyValPair) =>
              option.key === value.key
            }
            options={[{ key: -1, value: '' }, ...optionList]}
            onChange={handleChange}
            disableClearable={props.disableClearable ?? false}
            disabled={props.disabled ?? false}
            fullWidth={props.fullWidth ?? true}
            renderOption={(renderprops, option) => {
              let tooltipTitle =
                option?.[props.tooltipFieldName ?? 'title'] &&
                option?.[props.tooltipFieldName ?? 'title'] !== ''
                  ? option?.[props.tooltipFieldName ?? 'title']
                  : null
              return tooltipTitle ? (
                <Tooltip title={t(tooltipTitle)}>
                  <li {...renderprops} key={`${option.key}`}>
                    <Typography
                      sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                      {t(option.value)}
                    </Typography>
                  </li>
                </Tooltip>
              ) : (
                <li {...renderprops} key={`${option.key}`}>
                  <Typography
                    sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                    {t(option.value)}
                  </Typography>
                </li>
              )
            }}
            renderInput={(params) => {
              const { inputProps, ...restParams } = params
              return (
                <TextField
                  error={!!props.error}
                  {...restParams}
                  {...textfieldProps}
                  inputProps={{
                    ...inputProps,
                    value: inputProps?.value
                      ? typeof inputProps?.value === 'string'
                        ? t(inputProps?.value)
                        : inputProps?.value
                      : '',
                  }}
                  helperText={props.error ? props.error.message : null}
                />
              )
            }}
            filterOptions={(options: KeyValPair[], state: FilterOptionsState<KeyValPair>) =>
              options.filter(
                (opt: KeyValPair) =>
                  opt.value !== '' &&
                  opt.value?.toLowerCase()?.includes(state.inputValue.toLowerCase()),
              )
            }
          />
        </>
      )}
    </Fragment>
  )
}
