export const enum UserRoles {
  superAdmin = 'SA',
  envOfficer = 'EO',
  clerkOfWork = 'CoW',
  projBEAMP = 'Pj-BEAMP',
  projAdmin = 'Pj-Admin',
  foreman = 'FM',
  guest = 'Guest',
  cicUserAdmin = 'CIC-UA',
  cicProjAdmin = 'CIC-PA',
  orgAdmin = 'Org-Admin',
  envManager = 'EM',
}
