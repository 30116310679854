import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { MediaModel } from '../media.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'

export interface FormDrsItemsModel {
  editable?: boolean
  baseForm: FormModel
  approvedDate?: Date
  signatureBase64?: string
  dateOfDisposal?: Date

  chitNo?: string
  vehicleRegistrationMark?: string
  cDMaterialType?: number
  designatedDisposalGround?: KeyValPair[]
  disposalGround?: number
  approvedAlternativeDisposalGround?: KeyValPair[]
  otherApprovedAlternativeDisposalGround?: string
  drsItems: part1Part2Model[]
  notifyList?: string[]
  assignToList?: string[]
  drsLock?: boolean
  submissionComment?: string

  mobileRecords?: mobileRecordModel[]
  cEasyDisplayData?: any
  cEasySubmitData?: any
  cEasyFile?: any
}

export interface FormDrsItemModel {
  baseForm: FormModel
  signatureBase64?: string
  notifyList?: string[]
  assignToList?: string[]
  chitNo?: string
  vehicleRegistrationMark?: string
  dateOfDisposal?: Date
  approxVol?: number
  cDMaterialType?: number
  departureTimeFromSite?: Date
  disposalGround?: number
  remarks?: string
  submissionComment?: string
  zoneWithinSite?: string
  drsLock?: boolean
}

export interface mobileRecordModel {
  type?: 'edit' | 'add' | 'display' | undefined | null
}
export interface drsChitUploadModel {
  baseForm: FormModel
  chitNo: string
  chitReceipts: MediaModel[]
  truckPhotos: MediaModel[]
}
export interface part1Part2Model {
  status?: boolean
  chitNo?: string
  vehicleRegistrationMark?: string
  approxVol?: number
  cDMaterialType?: any
  disposalGround?: number
  drsChitUpload?: drsChitUploadModel
  drsFormId?: string
  selectedTruckPhotos?: MediaModel[]
  selectedChitReceipts?: MediaModel[]
  contractorSignatureBase64?: string
  reRepresentativeSignatureBase64?: string
  actualDisposalGround?: number
  departureTimeFromSite?: Date
  otherActualDisposalGround?: number
  remarks?: string
  chitReceipts?: MediaModel[]
  truckPhotos?: MediaModel[]
  arrivedTime?: Date

  mobileStatus?: 'edit' | 'add' | 'display' | 'displayDetail' | undefined | null
}

export const InitDrsItemsForm: FormDrsItemsModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.DailyRecordSummaryItemDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  drsItems: [],
}

export const InitDrsItemForm: FormDrsItemModel = {
  dateOfDisposal: new Date(),
  departureTimeFromSite: new Date(),

  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.DailyRecordSummaryDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormDrsItemsListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo: number
  chitNo: number
  contractNoId: number
  archivedDate?: Date
  submittedBy?: string
  approvedBy?: string
  updatedAt?: Date
  createdAt?: Date
  dateOfDisposal?: Date
  dateOfArchived?: Date
  vehicleRegistrationMark?: string
}

export type FormDrsGeneralOptionsModel = {
  disposalGround?: { key: number; value: string; title?: string }[]
  materialType?: KeyValPair[]
  approximateValue?: KeyValPair[]
  cDMaterialType?: KeyValPair[]
}
