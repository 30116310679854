import { useTranslation } from 'react-i18next'
import VerticalTabs from '@components/dashboard/verticalTabs'
import { ReactElement, useEffect, useRef } from 'react'
import Type from './Type'
import WasteFlow from './WasteFlow'
import MonthWasteCategory from './MonthWasteCategory'
import MonthWasteFlow from './MonthWasteFlow'
const TotalCDWasteGeneration = ({
  getValues,
  settings,
  StyledCard,
  StyledBox,
}: {
  getValues: any
  settings: any
  StyledCard: any
  StyledBox: any
}) => {
  type tabProps = () => {
    menuTitle: string
    title: string
    content: ReactElement
  }

  const { t } = useTranslation('dashboard')

  const getType: tabProps = () => {
    return {
      menuTitle: t('dashboard:type'),
      title: '',
      content: (
        <Type
          getValues={getValues}
          settings={settings}
          StyledCard={StyledCard}
          StyledBox={StyledBox}
        />
      ),
    }
  }

  const getWasteFlow: tabProps = () => {
    return {
      menuTitle: t('dashboard:wasteFlow'),
      title: '',
      content: <WasteFlow getValues={getValues} settings={settings} StyledCard={StyledCard} />,
    }
  }
  const getMonthWasteCategory: tabProps = () => {
    return {
      menuTitle: t('dashboard:monthAndWasteCategory'),
      title: '',
      content: (
        <MonthWasteCategory getValues={getValues} settings={settings} StyledCard={StyledCard} />
      ),
    }
  }
  const getMonthWasteFlow: tabProps = () => {
    return {
      menuTitle: t('dashboard:monthAndWasteFlow'),
      title: '',
      content: <MonthWasteFlow getValues={getValues} settings={settings} StyledCard={StyledCard} />,
    }
  }
  const tabs = [getType(), getWasteFlow(), getMonthWasteCategory(), getMonthWasteFlow()]
  return <VerticalTabs data={tabs} />
}
export default TotalCDWasteGeneration
