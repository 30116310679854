import { KeyValPair } from '@models/Common'

const GetValueWithKey = (
  key: number | undefined | string,
  pairs: KeyValPair[] | any,
): any | undefined => {
  if (key === undefined) return undefined
  for (let i = 0; i < pairs?.length; i++) {
    if (pairs[i].key === key) {
      return pairs[i].value
    }
  }
  return undefined
}

export default GetValueWithKey
