import { t } from 'i18next'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

interface BaseSaveResponse {
  id: number
}

export default BaseSaveResponse

export interface BaseModel {
  status: boolean
  createdBy: string
  createdAt: Date
  updatedBy?: string
  updatedAt?: Date
  deletedBy?: string
  deletedAt?: Date
}

export type BaseFilterDTO = {
  page: number
  pageSize: number
}

export enum StatusEnum {
  INACTIVE = 0,
  ACTIVE,
}

export const StatusEnumFilterList = [
  {
    label: 'Active',
    value: StatusEnum.ACTIVE,
  },
  {
    label: 'Inactive',
    value: StatusEnum.INACTIVE,
  },
]

export enum WeekDay {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thurday,
  Friday,
  Saturday,
}

export enum ApiStatusEnum {
  SUCCESS = 204,
}
