import { styled, useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'

const DialogButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
}))

export default DialogButton
