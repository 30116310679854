import useLocalStorage from '@hooks/useLocalStorage'
import React, { createContext, useState, ReactNode, useContext } from 'react'
import { GlobalContext } from './globalStore'
import i18n from 'i18next'
import { LanguageEnum } from '@i18n/index'

type Language =
  | LanguageEnum.ENGLISH
  | LanguageEnum.TRADITIONAL_CHINESE
  | LanguageEnum.SIMPLIFIED_CHINESE // Define the possible language values

type LanguageContextValue = {
  language: Language
  changeLanguage: (language: Language) => void
}

const defaultValue: LanguageContextValue = {
  language: LanguageEnum.ENGLISH,
  changeLanguage: () => {},
}

export const LanguageContext = createContext<LanguageContextValue>(defaultValue)

type LanguageProviderProps = {
  children: ReactNode
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  //get language from globalContext
  const { userInfo } = useContext(GlobalContext)

  //set default language by the value in globalContext
  const [currentLanguage, setCurrentLanguage] = useState<Language>(LanguageEnum.ENGLISH)

  const changeLanguage = (language: Language) => {
    setCurrentLanguage(language)
    i18n.changeLanguage(language)
    // Additional logic for changing the language
  }

  const value: LanguageContextValue = {
    language: currentLanguage,
    changeLanguage,
  }

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}
