import { menuItems, SideMenuChildren } from '@components/sidebar/menuList'

export function GetMenuColorCode(path: string) {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    for (let j = 0; j < menuItem.children.length; j++) {
      const child = menuItem.children[j]
      if (child.urlPattern && path.includes(child.urlPattern)) {
        return child.colorCode
      }
    }
  }
}

export function GetMenuTitle(path: string) {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    for (let j = 0; j < menuItem.children.length; j++) {
      const child = menuItem.children[j]
      if (child.urlPattern && path.includes(child.urlPattern)) {
        return child.title
      }
    }
  }
}

export function GetMenuSubtitle(path: string) {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    for (let j = 0; j < menuItem.children.length; j++) {
      const child = menuItem.children[j]
      if (child.urlPattern && path.includes(child.urlPattern)) {
        return menuItem.caption
      }
    }
  }
}

export function GetMenuMetaData(path: string) {
  let match: any
  let matchLength = 0

  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    for (let j = 0; j < menuItem.children.length; j++) {
      const child = menuItem.children[j]
      if (child.urlPattern && path.includes(child.urlPattern)) {
        const length = child.urlPattern.length
        if (length > matchLength) {
          match = {
            title: child.title,
            subtitle: menuItem.title,
            colorCode: child.colorCode,
          }
          matchLength = length
        }
      }
    }
  }

  return match
}

export function GetMenuChildParentTitle(child: SideMenuChildren) {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    for (let j = 0; j < menuItem.children.length; j++) {
      const menuChild = menuItem.children[j]
      if (menuChild.children) {
        for (let k = 0; k < menuChild.children.length; k++) {
          const menuSubChild = menuChild.children[k]
          if (menuSubChild.id === child.id) {
            return menuChild.title
          }
        }
      }
    }
  }
}
