import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import { FormPartComponents, KeyValPair } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import FormTable from '@components/form/table/Table'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import moment from 'moment'
import {
  FormMwfUploadModel,
  InitFormMwfUploadModel,
  MWFDocRequestModel,
} from '@services/model/form/form.MWF.upload.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormMwfService from '@services/formService/form.MWF.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import AccordionGroup from '@components/form/accordionGroup'
import useLocalStorage from '@hooks/useLocalStorage'
import { useWatch } from 'react-hook-form'
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material'
import DialogButton from '@components/button/DialogButton'
import FormContainer from '@components/form/container'
import testIsMobile from '@utils/testIsMobile'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import { FormStatusEnum } from '@services/model/form/form.model'
import MwfUpPartB from './PartB'
import { WasteCategoryListEnum } from '@services/model/form/form.MWF.model'
import cleanString from '@utils/cleanString'
import getErrMsg from '@utils/getErrMsg'

export default function UploadForm() {
  const {
    state: globalState,
    userInfo,
    hasRightByCatAndCode,
    dispatch: globalAction,
  } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const backStep = query.get('backstep')

  let { id: formId } = useParams<string>()
  const cat = QueryStepParser(query.get('cat'))
  const month = query.get('month')
  const locked = query.get('locked')
  const { setRequest, isLoading } = useAPIFetch()
  const isMounted = useRef(false)
  const [dataList, setDataList] = useState<any>([])
  const [openListState, setOpenListState] = useState<boolean[]>()

  const displayWasteCategoryList = [
    WasteCategoryListEnum.hardRockRecycled,
    WasteCategoryListEnum.reusedInOthers,
    WasteCategoryListEnum.metals,
    WasteCategoryListEnum.paperPackaging,
    WasteCategoryListEnum.timber,
    WasteCategoryListEnum.plastic,
    WasteCategoryListEnum.otherRecycled,
  ]

  const isMobile = testIsMobile()
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormMwfUploadModel>({
    defaultValues: { ...InitFormMwfUploadModel },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const UUID = uuid()

  const formPermission = getValues('baseForm.formPermission')
  const formStatus = getValues('baseForm.formStatus')
  // const [acknowledgement] = watch(['acknowledgement'])
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [reloadCount, setReloadCount] = useState(0)
  const acknowledgement = t(
    'I confirm that the uploaded documents and inputted information are correct, and I acknowledge that I will not be able to delete documents or change document details after I confirm changes.',
  )
  const [documentCategoryList, setDocumentCategoryList] = useState<any>([])
  const [selectableMonths, setSelectableMonths] = useState<any>()

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  const reloadGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormMwfService.GetGeneralOptions()
          .then(async (res) => {
            if (res) {
              setDocumentCategoryList(res.documentCategoryList!)
              setSelectableMonths(res.selectableMonths!)
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    reloadGeneralOptions()
    const intervalId = setInterval(() => {
      if (dataList.length === 0) {
        setReloadCount((count) => count + 1)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [dataList])

  useEffect(() => {
    reloadGeneralOptions()
    if (
      currentContract?.contractId! &&
      globalState.userMetaList?.length! > 0 &&
      documentCategoryList.length! > 0 &&
      selectableMonths &&
      !isMounted.current
    ) {
      reload()
    }
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [formId, globalState, globalAction, month, reloadCount, currentContract])

  const reload = async () => {
    let reqData: MWFDocRequestModel = {}
    if (month) {
      reqData.reportPeriod = {
        Operator: '=',
        Value: { Min: month ?? null },
      }
    }
    if (currentContract?.contractId) {
      reqData.contractNo = {
        Operator: '=',
        Value: [currentContract?.contractId ?? null],
      }
    }

    // Get list of uploaded documents
    if (reqData.contractNo) {
      setRequest({
        callback: async () => {
          const lists: Array<any> = []
          await FormMwfService.GetMwfUploadedDocumentList(reqData)
            .then(async (res) => {
              if (res) {
                displayWasteCategoryList.forEach((categoryName) => {
                  const categoryId = documentCategoryList.find(
                    (category: KeyValPair) =>
                      cleanString(category.value as string) === cleanString(categoryName),
                  )?.key
                  lists[categoryId] = res?.list?.filter((x) => x.mwfCategory === categoryId)
                  lists[categoryId].forEach((x) => {
                    if (globalState.userMetaList) {
                      x.uploadedBy =
                        globalState.userMetaList?.find((y) => y.key === x.baseForm.createdBy)
                          ?.value || ''
                    }
                  })
                })

                const _resetVal = { ...getValues(), lists: lists }
                reset(_resetVal)
                assignTablesToDisplayItems(
                  documentCategoryList!.filter((item: KeyValPair) =>
                    displayWasteCategoryList.some(
                      (value) => cleanString(value) === cleanString(item.value),
                    ),
                  ),
                )
                isMounted.current = true
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        },
      })
    }
  }

  const handleOnDelete = async () => {
    return
    // This Form do not have a delete function
  }
  const handleOnBack = async () => {
    if (formId) {
      if (formState.form && formState.formType === 'MWF') {
        formStateAction({ type: 'push', form: formState.form, formType: 'MWF' })
      }
      NavigateTo(
        navigate,
        '/monthly-waste-flow-table/:id',
        { id: formId },
        { step: backStep ?? '1' },
      )
    } else {
      NavigateTo(navigate, '/monthly-waste-flow-table/all-records')
    }
  }

  const handleOnSave = async () => {
    const _values = getValues()
    const { list } = _values
    let count = 0
    const _list = list?.map((x) => {
      if (x.mwfCategory === undefined || x.mwfMonth === undefined) {
        count += 1
      }

      const baseFormWithProjectId: any = x.baseForm || {}
      baseFormWithProjectId.projectId = currentProject?.id ?? 0
      return {
        baseForm: baseFormWithProjectId,
        mwfCategory: x.mwfCategory,
        mwfMonth: x.mwfMonth,
        bytes: x.bytes,
        bytesBase64: x.bytesBase64,
        fileName: x.fileName,
        size: x.size,
        ext: x.ext,
      }
    })

    // _list.baseForm.projectId = currentProject?.id
    if (count === 0) {
      setRequest({
        callback: async () => {
          await FormMwfService.SaveMWFDocument({ list: _list }).then((resp) => {
            if (formId) {
              if (formState.form && formState.formType === 'MWF') {
                formStateAction({ type: 'push', form: formState.form, formType: 'MWF' })
              }
              NavigateTo(
                navigate,
                '/monthly-waste-flow-table/:id',
                { id: formId },
                { step: backStep ?? '1' },
              )
            } else {
              reset(InitFormMwfUploadModel)
              handleBack(undefined)
              reload()
            }
            enqueueSnackbar(t('common:messages.recordSaved'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
          })
        },
      })
    } else {
      enqueueSnackbar(t('Please fill in all the fields'), {
        variant: 'error',
        action: (key) => {
          return <SnackbarCloseButton id={key} />
        },
      })
    }
  }

  const handleDownloadFile = async (index: number, mwfCategory: number) => {
    let targetData = getValues().lists?.[mwfCategory]?.[index]
    let _formId = targetData?.fileId
    let _fileName = targetData?.fileName
    if (!_formId || !_fileName) return
    let downloadURL = targetData?.fileUrl || ''
    fetch(downloadURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${_fileName}`)
        document.body.appendChild(link)
        link.click()
        link?.parentNode?.removeChild(link)
      })
  }

  const handleDialogConfirmDelete = (event: any, index: number, mwfCategory: number) => {
    const _values = getValues()
    let req: any = {}
    let _formId: string = ''
    let _documentId: string = ''
    if (_values?.lists !== undefined && index !== undefined && mwfCategory !== undefined) {
      req = _values.lists?.[mwfCategory]?.[index]
      _formId = req?.baseForm?.formId
      _documentId = req?.fileId
    }
    if (_formId === undefined || _documentId === undefined) return
    setRequest({
      callback: async () => {
        await FormMwfService.DeleteMwfUploadedDocument(_formId, _documentId).then((res) => {
          reload()
          enqueueSnackbar(t('Record Deleted'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
        })
      },
    })
  }

  // confirm update the record
  const handleDialogConfirm = (event: any, index: number, mwfCategory: number) => {
    let needUpdate = false
    if (currentCategoryRef.current! > -1 && mwfCategory !== currentCategoryRef.current!) {
      needUpdate = true
      setValue(`lists.${mwfCategory}.${index}.mwfCategory`, currentCategoryRef.current)
    }
    const _values = getValues()
    let req = {}
    if (_values?.lists !== undefined && index !== undefined && mwfCategory !== undefined) {
      req = _values.lists?.[mwfCategory]?.[index]
    }
    // only send request if current field is modified
    if (needUpdate) {
      setRequest({
        callback: async () => {
          await FormMwfService.UpdateMWFUploadedDocument(req).then((res) => {
            enqueueSnackbar(t('common:messages.recordSaved'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            reload()
          })
        },
      })
    }
  }

  const filteredList = documentCategoryList!.filter((item: KeyValPair) =>
    displayWasteCategoryList.some((value) => cleanString(value) === cleanString(item.value)),
  )

  const currentCategoryRef = useRef(-1)
  const handleChangeCategory = (event) => {
    currentCategoryRef.current = event.target.value
  }

  const handleDialogDeleteClick = (
    event: any,
    index: number,
    action: string,
    extraInfo: any,
    setDialogContext: any,
    dialogContext: any,
    editField: any,
  ) => {
    setDialogContext({
      ...dialogContext,
      title: <Typography variant="h6">{t('common:messages.confirmDeletion')}</Typography>,
      showConfirmDeleteBtn: true,
      isOpen: true,
      buttons: (
        <>
          <DialogButton
            onClick={(event) => handleDialogConfirmDelete(event, index, extraInfo?.mwfCategory)}
            sx={{ color: 'red' }}>
            {t('common:buttons.confirm')}
          </DialogButton>
          <DialogButton onClick={(event) => editField(event, index, action, extraInfo)}>
            {t('common:buttons.cancel')}
          </DialogButton>
        </>
      ),
    })
  }

  const assignTablesToDisplayItems = (documentCategoryList?: KeyValPair[]) => {
    const tableList: any = []
    const items: any = []
    console.log("documentCategoryList", documentCategoryList)
    if (documentCategoryList) {
      documentCategoryList?.map((documentCategory, index) => {
        tableList.push(
          <FormTable
            isMobile={isMobile}
            removeOverflow
            disableDeleteRowButton={true}
            useWatch={useWatch}
            key={`${documentCategory.key}${index}`}
            columns={[
              {
                field: 'mwfMonth',
                displayName: t('forms:monthlyWasteFlowTable.fields.mwfMonth'),
                type: 'date',
                dateFormat: 'MMMM yyyy',
                uneditable: true,
              },
              {
                field: 'mwfCategory',
                displayName: t('forms:monthlyWasteFlowTable.fields.mwfCategory'),
                type: 'select',
                options: documentCategoryList,
                uneditable: true,
              },
              {
                field: 'fileName',
                displayName: t('forms:monthlyWasteFlowTable.fields.fileName'),
                type: 'string',
                uneditable: true,
              },
              {
                field: 'uploadedBy',
                displayName: t('forms:monthlyWasteFlowTable.fields.uploadedBy'),
                type: 'string',
                uneditable: true,
              },
            ]}
            uneditable={
              !hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableUploadPlatformDraft, [
                'U',
                'D',
              ])
            }
            hiddenItemNo
            removeAddButton
            showEditDownloadButtons
            height={50}
            fieldArrayProps={{
              name: `lists.${documentCategory.key}`,
              control: control,
            }}
            extraInfo={{
              mwfCategory: documentCategory.key,
              createdBy: userInfo.firstName,
              documentCategoryList: filteredList,
            }}
            onDownloadFile={(index: number, extraInfo: any) =>
              handleDownloadFile(index, extraInfo?.mwfCategory)
            }
            onClickEditField={(
              event,
              index,
              action,
              extraInfo,
              fields,
              setDialogContext,
              dialogContext,
              handleDialogClose,
              editField,
            ) => {
              setDialogContext({
                ...dialogContext,
                title: (
                  <Typography variant="h6">
                    {t('forms:monthlyWasteFlowTable.fields.fileDetails')}
                  </Typography>
                ),
                buttons: (
                  <Fragment>
                    <DialogButton
                      onClick={(event) => {
                        handleDialogConfirm(event, index, extraInfo?.mwfCategory)
                        handleDialogClose()
                      }}>
                      {t('common:buttons.confirm')}
                    </DialogButton>
                    <DialogButton
                      onClick={(event) =>
                        handleDialogDeleteClick(
                          event,
                          index,
                          action,
                          extraInfo,
                          setDialogContext,
                          dialogContext,
                          editField,
                        )
                      }
                      sx={{ color: 'red' }}>
                      {t('common:buttons.delete')}
                    </DialogButton>
                    <DialogButton onClick={handleDialogClose}>
                      {t('common:buttons.cancel')}
                    </DialogButton>
                  </Fragment>
                ),
                children: (
                  <>
                    <FormField fieldName={t('forms:monthlyWasteFlowTable.fields.mwfMonth')}>
                      {moment(fields?.[index]?.['mwfMonth']).format('MMMM YYYY')}
                    </FormField>
                    <FormField fieldName={t('forms:monthlyWasteFlowTable.fields.mwfCategory')}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* {column.icon} */}
                        <Select
                          fullWidth
                          sx={{
                            minWidth: '300px',
                            fontSize: 12,
                            backgroundColor: '#ffffff',
                          }}
                          // {...field}
                          onChange={handleChangeCategory}
                          defaultValue={fields?.[index]?.['mwfCategory']}>
                          {extraInfo?.documentCategoryList!.map((option: KeyValPair) => (
                            <MenuItem
                              sx={{
                                fontSize: 12,
                              }}
                              value={option.key}
                              key={`form-table-select-option-${option.key}`}>
                              {t(option.value)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </FormField>
                    <FormField fieldName={t('forms:monthlyWasteFlowTable.fields.fileName')}>
                      {fields?.[index]?.['fileName']}
                    </FormField>
                  </>
                ) ?? <></>,
                isOpen: true,
              })
            }}
          />,
        )
      })
    }
    if (tableList.length > 0 && documentCategoryList) {
      documentCategoryList.map((documentCategory, index) => {
        items.push({
          key: t(documentCategory.value),
          value: tableList[index],
          categoryId: documentCategory.key,
        })
      })
    }
    if (items) {
      setDataList(items)
    }
  }

  const handleOpenAccordion = (open: Array<boolean>) => {
    return [true]
  }
  let openListFirstLoad: Array<boolean> = []

  //open accordian base on cat in quary param in url first load

  if (dataList?.length > 0) {
    if (cat) {
      dataList.map((item, index) => {
        if (item.categoryId === cat) {
          openListFirstLoad[index] = true
        }
      })
    } else {
      openListFirstLoad = [true]
    }
  }

  const MWFPartA = (): FormPartComponents => {
    return {
      partTitle: t('Uploaded Documents'),
      component: (
        <FormContainer fkey={`$mwf-a`} removeOverflow={true}>
          <AccordionGroup
            items={dataList}
            handleOnOpen={handleOpenAccordion}
            openList={openListState ?? openListFirstLoad}
            tabsx={{ marginRight: '30px', marginLeft: '30px' }}
          />
        </FormContainer>
      ),
      onNextDisabled:
        !hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableUploadPlatformDraft, [
          'U',
          'D',
        ]) || locked === 'true', // TODO: remove this
    }
  }

  const MWFPartB = (): FormPartComponents => {
    return {
      partTitle: t('Upload Documents'),
      component: (
        <MwfUpPartB
          globalState={globalState}
          month={month}
          control={control}
          isMobile={isMobile}
          userInfo={userInfo}
          filteredList={filteredList}
          selectableMonths={selectableMonths}
        />
      ),
      disabled:
        !hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableUploadPlatformDraft, [
          'U',
          'D',
        ]) || locked === 'true',
    }
  }

  const { FormSwitcher, handleBack } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('Upload Platform for Monthly Waste Flow Table'),
    components: [MWFPartA(), MWFPartB()],
    formOnSubmit: handleOnSave,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    navigateBack: handleOnBack,
    customNextLabel: 'Upload a Document',
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate,
    disableSaveBtn: true,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: false,
    endOfFlow: formPermission?.endOfFlow,
    customEndDialog: {
      control: control,
      getValues: getValues,
      handleConfirm: handleOnSave,
      content: (
        <Grid>
          <FormField fieldName="Submitted By">{userInfo.firstName}</FormField>
          <FormField fieldName="Acknowledgement">{acknowledgement}</FormField>
        </Grid>
      ),
    },
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}
