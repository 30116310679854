import '@styles/table.module.scss'
import { useState, useRef } from 'react'
import { FieldValues } from 'react-hook-form'
import useImageModal from '@hooks/useImageModal'
import { DialogContextProps, InitDialogContent } from '@components/form/table/model'
import { FormTableCardProps } from '@components/form/table/model'
import DesktopTable from './DesktopTable'
import MobileTable from './MobileTable'

function FormTable<F extends FieldValues>(props: FormTableCardProps<F>) {
  const signatureBase64Ref = useRef<string | undefined>()
  const [signatureBase64, setSignatureBase64] = useState<Array<string>>([''])
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const [warningText, setWarningText] = useState<string | undefined>()

  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }
  const handleDialogConfirm = (index: string) => {
    setDialogContext(InitDialogContent)
    let _signature: Array<any> = [...signatureBase64]
    _signature[index] = signatureBase64Ref[index]
    if (_signature) {
      setSignatureBase64(_signature)
    }
  }

  const desktopChild = (
    <>
      <DesktopTable
        {...props}
        handleImageModalOpen={handleImageModalOpen}
        setWarningText={setWarningText}
        setDialogContext={setDialogContext}
        handleDialogClose={handleDialogClose}
        dialogContext={dialogContext}
        ImageModal={ImageModal}
        modalImage={modalImage}
        warningText={warningText}
        signatureBase64Ref={signatureBase64Ref}
        setSignatureBase64={setSignatureBase64}
        signatureBase64={signatureBase64}
        handleDialogConfirm={handleDialogConfirm}
      />
    </>
  )

  const mobileChild = (
    <>
      <MobileTable
        {...props}
        handleImageModalOpen={handleImageModalOpen}
        setWarningText={setWarningText}
        setDialogContext={setDialogContext}
        handleDialogClose={handleDialogClose}
        dialogContext={dialogContext}
        ImageModal={ImageModal}
        modalImage={modalImage}
        warningText={warningText}
        signatureBase64Ref={signatureBase64Ref}
        setSignatureBase64={setSignatureBase64}
        signatureBase64={signatureBase64}
        handleDialogConfirm={handleDialogConfirm}
      />
    </>
  )

  return props.isMobile ? <>{mobileChild}</> : <>{desktopChild}</>
}

export default FormTable
