import { isNil } from 'lodash'

import { Nil } from '@models/Common'

export interface Fraction {
	numerator: number
	denominator: number
}

export const numberToLetter = (i = 0) => {
	/* Start from zero */
	return (i + 10).toString(36).toUpperCase()
}

export const stringToNumber = (val: Nil<string>, fallback?: any, min?: number, max?: number) => {
	if (val === null || val === undefined || val === '') return fallback

	const num = Number(val)
	const value = !isNaN(num) ? num : fallback

	if (value !== undefined) {
		if (!isNil(min) && value < min) return min
		if (!isNil(max) && value > max) return max
	}

	return value
}
