import MultiTypeDatasetChart, {
  generalChartDatasetProps,
} from '@components/chart/MultiTypeDatasetChart'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const RegisteredUsersAndVisitors = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  const { t } = useTranslation()
  const chartData: generalChartDatasetProps[] = []
  const values = getValues()
  const resKey = 'userVisitors'
  const hideChart = values?.[resKey]?.showChart === false
  const message = values?.[resKey]?.message ?? ''
  const resData = values?.[resKey]?.datasets
  const chartSettings = settings?.[resKey]
  const timeFormattedData = resData?.map((item) => {
    const formattedValue: { [key: string]: any } = {}
    item.data?.map((item: any) => {
      const formattedDate = moment(item.itemName).format('MMM YYYY')
      formattedValue[formattedDate] = item.itemNo
    })

    return { groupName: item.groupName, value: formattedValue }
  })

  const processChartData = (chartData: any[]) => {
    timeFormattedData?.map((item: any) => {
      let obj: generalChartDatasetProps = {
        type: 'bar',
        label: t(item.groupName),
        data: item.value,
        yAxisID: 'y',
        borderColor: chartSettings?.[item.groupName]?.borderColor,
        backgroundColor: chartSettings?.[item.groupName]?.backgroundColor,
      }
      chartData.push(obj)
    })
  }

  processChartData(chartData)

  const multiTypeDatasetChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        reverse: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 10,
        },
        fullSize: false, // set fullSize to false
      },
      //TODO:  Add a custom plugin to display the total value on top of each bar
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
      },
    },
  }

  return (
    <Grid container md={12} xs={12}>
      <Grid item md={12} xs={12}>
        {hideChart ? (
          <>{t(message)}</>
        ) : (
          <MultiTypeDatasetChart
            eleBefore={
              <>
                <span>{t('common:units.noOfUsers')}</span>
              </>
            }
            chartWrapperSX={{
              width: '100%',
              minWidth: 200,
              minHeight: 350,
              margin: 'auto',
            }}
            datasets={chartData}
            options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
        )}
      </Grid>
    </Grid>
  )
}
export default RegisteredUsersAndVisitors
