import { SxProps, Theme } from '@mui/material/styles'
import { Dispatch, SetStateAction } from 'react'
import { KeyValPair, KeyValPairSelect } from '@models/Common'
import '@styles/table.module.scss'
import {
  ArrayPath,
  Controller,
  ControllerRenderProps,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayAppend,
} from 'react-hook-form'

export type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

export type DrawerContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

export const InitDialogContent: DialogContextProps = {
  isOpen: false,
  title: <></>,
}

export const InitDrawerContent: DialogContextProps = {
  isOpen: false,
  title: <></>,
}

export interface TableColumnProps<F extends FieldValues> {
  icon?: any
  fieldProps?: any
  mobileFieldProps?: any
  dateFormat?: string
  disableCallback?: (datumIndex: number) => any
  isDebounce?: boolean
  additionalChangeAction?: (...event: any[]) => void
  validationSchema?: any
  getValues?: any
  control?: any
  callback?: any
  gallerySource?: any
  gallerySelected?: any
  sx?: any
  rows?: number
  onClick?: (...event: any[]) => void
  additionalOnChangeAction?: (...event: any[]) => void
  onRefreshClick?: (...event: any[]) => void
  onUploadClick?: (...event: any[]) => void
  getTooltip?: (...event: any[]) => any
  field: string
  subfield?: string
  displayName: any
  defaultDate?: Date | string
  defaultValue?: Date | string
  setDefaultValue?: (...event: any[]) => any
  type?:
    | 'select'
    | 'selectCreatable'
    | 'string'
    | 'number'
    | 'times'
    | 'dates'
    | 'months'
    | 'checkbox'
    | 'images'
    | 'image'
    | 'date'
    | 'time'
    | 'month'
    | 'fileUpload'
    | 'thumbnail'
    | 'imageUploadGallery'
    | 'imageGallery'
    | 'binarySelect'
    | 'displayDate'
    | 'displaySelected'
    | 'displayImages'
    | 'controlledCustom'
    | 'custom'
    | 'selectWithSearch'
    | 'signature'
  options?: KeyValPair[] | KeyValPairSelect[]
  optionsCreatable?: string[]
  binaryOptions?: { yes: string; no: string; na?: string }
  render?: (
    row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    rowIndex: number,
    setWarningText: Dispatch<SetStateAction<string | undefined>>,
  ) => JSX.Element
  controlledRender?: (
    // row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    field: ControllerRenderProps<F, Path<F>>,
    rowIndex: number,
    setWarningText: Dispatch<SetStateAction<string | undefined>>,
  ) => JSX.Element
  hidden?: boolean
  disable?: boolean
  uneditable?: boolean
  mobileHidden?: boolean
  tooltip?: string
  width?: number | string
  minWidth?: number | string
  minHeight?: number | string
  minNumber?: number
  cellStyle?: any
  withPortal?: boolean
  minDate?: Date | string
  maxDate?: Date | string
}

export interface SingleRowTableColumnProps<F extends FieldValues> {
  icon?: any
  fieldProps?: any
  mobileFieldProps?: any
  dateFormat?: string
  disableCallback?: (datumIndex: number) => any
  isDebounce?: boolean
  validationSchema?: any
  getValues?: any
  control?: any
  callback?: any
  gallerySource?: any
  gallerySelected?: any
  sx?: any
  rows?: number
  onClick?: (...event: any[]) => void
  additionalOnChangeAction?: (...event: any[]) => void
  onRefreshClick?: (...event: any[]) => void
  onUploadClick?: (...event: any[]) => void
  getTooltip?: (...event: any[]) => any
  field: any
  subfield?: string
  displayName: any
  defaultDate?: Date | string
  type?:
    | 'select'
    | 'selectCreatable'
    | 'string'
    | 'number'
    | 'times'
    | 'checkbox'
    | 'images'
    | 'image'
    | 'date'
    | 'time'
    | 'fileUpload'
    | 'thumbnail'
    | 'imageUploadGallery'
    | 'imageGallery'
    | 'binarySelect'
    | 'displayDate'
    | 'displaySelected'
    | 'displayImages'
    | 'controlledCustom'
    | 'custom'
    | 'selectWithSearch'
    | 'signature'
  options?: KeyValPair[] | KeyValPairSelect[]
  optionsCreatable?: string[]
  binaryOptions?: { yes: string; no: string; na?: string }
  render?: (
    row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    rowIndex: number,
    setWarningText: Dispatch<SetStateAction<string | undefined>>,
  ) => JSX.Element
  controlledRender?: (
    row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    field: ControllerRenderProps<F, Path<F>>,
    rowIndex: number,
    setWarningText: Dispatch<SetStateAction<string | undefined>>,
  ) => JSX.Element
  hidden?: boolean
  disable?: boolean
  uneditable?: boolean
  mobileHidden?: boolean
  tooltip?: string
  width?: number | string
  minWidth?: number | string
  minHeight?: number | string
  minNumber?: number
  cellStyle?: any
  withPortal?: boolean
}

export interface FormTableCardProps<F extends FieldValues> {
  acceptFileTypes?: string
  title?: string
  color?: string
  useWatch?: any
  getValues?: any
  setValue?: any
  model?: any
  removeOverflow?: boolean
  columns: TableColumnProps<F>[]
  fieldArrayProps: UseFieldArrayProps<F>
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  disableDeleteRowButton?: boolean
  disableAddButton?: boolean
  additionalAppendAction?: (event: any) => void
  additionalRemoveAction?: (event: any, index: number) => void
  additionalRow?: (rows: FieldArrayWithId<F, ArrayPath<F>, '_id'>[]) => JSX.Element
  rowFilter?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => boolean
  statusMapper?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>) => boolean
  disabled?: boolean
  uneditable?: boolean
  onOpenAddForm?: (...event: any) => void
  onOpenEditForm?: (...event: any) => void
  isMobile?: boolean
  showAddFilesButton?: boolean
  onClickAddFiles?: (...event: any) => void
  extraInfo?: any
  showEditDownloadButtons?: boolean
  onDownloadFile?: (index: number, extraInfo: any) => void
  onClickEditField?: (...event: any) => void
  showDeleteFileRecordButton?: boolean
  onFooterButtonClick?: (event: any) => void
  onHeaderAddButtonClick?: (append: UseFieldArrayAppend<F, ArrayPath<F>>) => void
  sx?: any
  onClickSaveRowBtn?: any
  appendWithComponent?: (append: any, component: any) => void
  maxDate?: Date | string
  minDate?: Date | string
  desktopDatePickerType?: 'form' | 'mobile-table' | 'desktop-table'
  mobileDatePickerType?: 'form' | 'mobile-table' | 'desktop-table'
}

export interface FormSingleRowTableProps<F extends FieldValues> {
  title?: string
  color?: string
  useWatch?: any
  getValues?: any
  setValue?: any
  model?: any
  removeOverflow?: boolean
  columns: SingleRowTableColumnProps<F>[]
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  disableDeleteRowButton?: boolean
  disableAddButton?: boolean
  additionalAppendAction?: (event: any) => void
  additionalRemoveAction?: (event: any, index: number) => void
  additionalRow?: (rows: FieldArrayWithId<F, ArrayPath<F>, '_id'>[]) => JSX.Element
  rowFilter?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => boolean
  statusMapper?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>) => boolean
  disabled?: boolean
  uneditable?: boolean
  onOpenAddForm?: (...event: any) => void
  onOpenEditForm?: (...event: any) => void
  isMobile?: boolean
  showAddFilesButton?: boolean
  onClickAddFiles?: (...event: any) => void
  extraInfo?: any
  showEditDownloadButtons?: boolean
  onDownloadFile?: (index: number, extraInfo: any) => void
  onClickEditField?: (...event: any) => void
  showDeleteFileRecordButton?: boolean
  onFooterButtonClick?: (event: any) => void
  onHeaderAddButtonClick?: (append: UseFieldArrayAppend<F, ArrayPath<F>>) => void
  sx?: any
  maxDate?: Date | string
  minDate?: Date | string
}

export interface FormSingleRowTableChildProps<F extends FieldValues> {
  title?: string
  useWatch?: any
  getValues?: any
  setValue?: any
  model?: any
  removeOverflow?: boolean
  columns: SingleRowTableColumnProps<F>[]
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  disableDeleteRowButton?: boolean
  disableAddButton?: boolean
  disabled?: boolean
  uneditable?: boolean
  isMobile?: boolean
  handleImageModalOpen: (base64Url: string) => void
  setWarningText: Dispatch<SetStateAction<string | undefined>>
  setDialogContext: Dispatch<SetStateAction<any | undefined>>
  handleDialogClose: () => void
  dialogContext: DialogContextProps
  ImageModal: any
  modalImage: any
  warningText: string | undefined
  signatureBase64Ref: any
  setSignatureBase64: any
  signatureBase64: any
  handleDialogConfirm: (event: any) => void
  showAddFilesButton?: boolean
  extraInfo?: any
  cellStyle?: any
  showDeleteFileRecordButton?: boolean
  showHeaderAddButton?: boolean
  color?: string
  addButtonText?: string
  sx?: any
  maxDate?: Date | string
  minDate?: Date | string
}

export interface FormSingleRowTableProps<F extends FieldValues> {
  title?: string
  color?: string
  useWatch?: any
  getValues?: any
  setValue?: any
  model?: any
  removeOverflow?: boolean
  columns: SingleRowTableColumnProps<F>[]
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  disableAddButton?: boolean
  disabled?: boolean
  uneditable?: boolean
  isMobile?: boolean
  extraInfo?: any
  sx?: any
  maxDate?: Date | string
  minDate?: Date | string
}

export interface FormTableChildProps<F extends FieldValues> {
  selectable?: boolean
  acceptFileTypes?: string
  desktopDatePickerType?: 'form' | 'mobile-table' | 'desktop-table'
  mobileDatePickerType?: 'form' | 'mobile-table' | 'desktop-table'
  title?: string
  useWatch?: any
  getValues?: any
  setValue?: any
  model?: any
  removeOverflow?: boolean
  columns: TableColumnProps<F>[]
  fieldArrayProps: UseFieldArrayProps<F>
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  disableDeleteRowButton?: boolean
  disableAddButton?: boolean
  additionalAppendAction?: (event: any) => void
  additionalRemoveAction?: (event: any, index: number) => void
  additionalRow?: (rows: FieldArrayWithId<F, ArrayPath<F>, '_id'>[]) => JSX.Element
  rowFilter?: (
    row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
    rowIndex?: number,
    mobileRowFilter?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => boolean,
  ) => boolean
  statusMapper?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>) => boolean
  disabled?: boolean
  uneditable?: boolean
  onOpenAddForm?: (...event: any) => void
  onOpenEditForm?: (...event: any) => void
  isMobile?: boolean
  // fields: any
  handleImageModalOpen: (base64Url: string) => void
  setWarningText: Dispatch<SetStateAction<string | undefined>>
  setDialogContext: Dispatch<SetStateAction<any | undefined>>
  handleDialogClose: () => void
  dialogContext: DialogContextProps
  ImageModal: any
  modalImage: any
  warningText: string | undefined
  // removeField: (event: any, datumIndex: number) => void
  signatureBase64Ref: any
  setSignatureBase64: any
  signatureBase64: any
  handleDialogConfirm: (event: any) => void
  // appendField: (event: any) => void
  showAddFilesButton?: boolean
  onClickAddFiles?: (...event: any) => void
  onDownloadFile?: (index: number, extraInfo: any) => void
  extraInfo?: any
  showEditDownloadButtons?: boolean
  cellStyle?: any
  showDeleteFileRecordButton?: boolean
  onClickEditField?: (...event: any) => void
  onHeaderAddButtonClick?: (append: UseFieldArrayAppend<F, ArrayPath<F>>) => void
  addWithPopupComponent?: (isOpen: boolean, handleClose: () => void) => JSX.Element
  onFooterButtonClick?: (event: any) => void
  showHeaderAddButton?: boolean
  color?: string
  addButtonText?: string
  sx?: any
  onClickSaveRowBtn?: any
  withPortal?: boolean
  maxDate?: Date | string
  minDate?: Date | string
}
export interface FormTableCardCellProps<F extends FieldValues> {}
