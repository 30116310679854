import { SelectChangeEvent } from '@mui/material/Select'
import { Box, Typography, Grid, TableCell } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { UserListModel } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import SearchPanel from '@components/searchPanel'
import { RiFileTextFill } from 'react-icons/ri'

const AdminUserAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const { state: globalState } = useContext(GlobalContext)

  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [status, setStatus] = useState('')

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value)
  }
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [TableView, reload] = useTableView<UserListModel>({
    headers: [
      {
        key: 'displayName',
        desc: t('Display Name'),
        renderCell: (record: UserListModel) => <Fragment>{record.displayName}</Fragment>,
      },
      {
        key: 'email',
        desc: t('Email'),
        renderCell: (record: UserListModel) => <Fragment>{record.email}</Fragment>,
      },
      {
        key: 'position',
        desc: t('Position'),
        renderCell: (record: UserListModel) => <Fragment>{record.position}</Fragment>,
      },
      {
        key: 'organizationName',
        desc: t('Organization'),
        renderCell: (record: UserListModel) => (
          <Fragment>
            {
              globalState.organizationMetaList?.find((o) => o.identifier === record.organizationId)
                ?.companyName
            }{' '}
            {
              globalState.organizationMetaList?.find((o) => o.identifier === record.organizationId)
                ?.subsidiaryName
            }
          </Fragment>
        ),
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowGen: function (navigate: NavigateFunction, record: UserListModel): JSX.Element {
      return (
        <Fragment>
          <TableCell>{record.status}</TableCell>
          <TableCell>{record.displayName}</TableCell>
          <TableCell>{record.email}</TableCell>
          <TableCell>{record.position}</TableCell>
          <TableCell>{record.organizationName}</TableCell>
          {/* <TableCell>
            <EditIcon /> <EditIcon />
          </TableCell> */}
        </Fragment>
      )
    },
    onRowClick: function (navigate: NavigateFunction, record: UserListModel) {
      NavigateTo(navigate, '/admin/user/:id', { id: record.userId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      let list = await AdminService.GetUserList(
        {
          ...state.filterResult,
          status:
            state.filterResult?.status?.value === 0
              ? { operator: '=', value: false }
              : state.filterResult?.status?.value === 1
              ? { operator: '!=', value: false }
              : null,
          sorting: sorting,
          pagination: pagination,
        },
        cancelToken,
        props.showMyRecord || false,
      )
      return list
    },
  })

  useEffect(() => {
    document.title = t('Admin', { ns: common.i18nFormNS })

    reload()
  }, [])

  const onClickSideBtnHandler: Function = () => {
    NavigateTo(navigate, '/admin/user')
  }

  const LinkTableAddButton = styled(Box)<{
    state: {
      disableAddButton?: boolean
      uneditable?: boolean
      color?: string
      hoverBackgroundColor?: string
      backgroundColor?: string
    }
  }>(({ state }) => ({
    width: 50,
    height: 50,
    display: state.uneditable ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    zIndex: 1000,
    backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
    ...(!state.disableAddButton && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
        '#add-button-hover-text': {
          opacity: '100% !important',
          width: '200px',
          transition: 'opacity 500ms !important',
        },
      },
    }),
  }))
  const handleAddUser = (event) => {
    NavigateTo(navigate, '/admin/user')
  }
  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('Users') : t('Users')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            '/admin/user'
            // hasRightByCatAndCode(FormStatusEnum.DailyRecordSummary, ['C']) ? '/daily-record-summary' : undefined
          }
          // target="_self"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={false}
          criteria={[
            {
              key: 'displayName',
              desc: t('Display Name'),
              type: 'StringFilter',
              disableOperator: true,
            },
            {
              key: 'email',
              desc: t('Email'),
              type: 'StringFilter',
              disableOperator: true,
            },
            {
              key: 'roleId',
              desc: t('Role'),
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState?.roleMetaList, //todo : add options from globalState
              disableOperator: true,
            },
            {
              key: 'position',
              desc: t('Position'),
              type: 'StringFilter',
              disableOperator: true,
            },
            {
              key: 'organizationId',
              desc: t('Organization'),
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState?.organizationMetaList?.map(({ id, companyName }) => ({ key: id, value: companyName})),
              disableOperator: true,
            },            
            // {
            //   key: 'assignedOrganizations',
            //   desc: 'Assigned Organizations',
            //   type: 'StringFilter',
            //   disableOperator: true,
            // },
            // {
            //   key: 'assignedProjects',
            //   desc: 'Assigned Project                                                   mnmmmmmm          nmnv',
            //   type: 'StringFilter',
            //   disableOperator: true,
            // },
            // {
            //   key: 'assignedContracts',
            //   desc: 'Assigned Contracts                                                   mnmmmmmm          nmnv',
            //   type: 'StringFilter',
            //   disableOperator: true,
            // },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AdminUserAllRecordsPage
