import {
  Box,
  Select,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  useMediaQuery,
  Grid,
  Tooltip,
  Autocomplete,
  createFilterOptions,
  FormHelperText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import '@styles/table.module.scss'
import { useTranslation } from 'react-i18next'
import { Dispatch, Fragment, SetStateAction, useState, useRef, useEffect } from 'react'
import {
  ArrayPath,
  Controller,
  ControllerRenderProps,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayProps,
} from 'react-hook-form'
import moment from 'moment'
import GetValueWithKey from '@utils/getValueWithKey'
import { ImageModel } from '@services/model/image.model'
import { ImageModelToUrl, Base64StringToImageBase64 } from '@utils/image'
import FormTypography from '@components/form/typography'
import TimePicker from '@components/TimePicker'
import DateTimePicker from '@components/form/dateTimePicker'
import FormSignatureHolderForTable from '@components/form/signatureHolderForTable'
import { Stack } from '@mui/material'
import FormSignaturePad from '@components/form/signaturePad'
import DialogButton from '@components/form/table/presentation/dialogButton'
import TableImageHolder from '@components/form/table/presentation/tableImageHolder'
import TableFileUpload from '@components/form/table/control/tableFileUpload'
import TableImageUploadGallery from '@components/form/table/control/tableImageUploadGallery'
import TableSelectWithSearch from '@components/form/table/control/tableSelectWithSearch'
import TableTextField from '@components/form/table/control/tableTextField'
import TableSelect from '@components/form/table/control/tableSelect'
import { TableColumnProps } from '@components/form/table/model'
// import { TimeField } from '@mui/x-date-pickers'
import TimeField from '@components/TimeField'
export interface FormWidgetProps<F extends FieldValues> {
  column: TableColumnProps<F>
  datePickerType?: 'desktop-table' | 'mobile-table' | 'form'
  disabled?: boolean
  uneditable?: boolean
  fieldArrayProps: UseFieldArrayProps<F>
  datumIndex: number
  datum: FieldArrayWithId<F, ArrayPath<F>, '_id'>
  handleImageModalOpen: (base64Url: string) => void
  tooltip?: string
  useWatch: any
  setValue: any
  getValues: any
  setWarningText: any
  setDialogContext: any
  handleDialogClose: any
  handleDialogConfirm: any
  signatureBase64Ref: any
  signatureBase64: string[]
  setSignatureBase64: Dispatch<SetStateAction<string[]>>
}
function FormWidget<F extends FieldValues>(props: FormWidgetProps<F>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const { datumIndex } = props
  const rowName = props.fieldArrayProps.name

  const rowData =
    props.useWatch &&
    props.useWatch!({
      control: props.fieldArrayProps.control,
      name: `${rowName}.${props.datumIndex}`,
    })

  let children: JSX.Element = <Fragment></Fragment>
  // if (props.column.hidden || (downMd && props.column.mobileHidden)) {
  //   return children
  // } // handled in the parent component depending on the Desktop version or mobile version

  switch (props.column.type) {
    case 'select': {
      if (props.column.options) {
        children = (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Fragment>
                {props.uneditable || props.column.uneditable ? (
                  <>
                    <FormTypography
                      type="select"
                      options={props.column.options}
                      value={field.value}
                      sx={error ? { fontSize: 12, color: 'red' } : { fontSize: 12 }}
                      onChange={(event) => {
                        field.onChange(event)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }}
                    />
                    <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                  </>
                ) : (
                  <>
                    <TableSelect
                      error={error}
                      uneditable={props.uneditable || props.column.uneditable}
                      options={props.column.options}
                      value={field.value}
                      field={field}
                      additionalOnChangeAction={props.column.additionalOnChangeAction}
                      datumIndex={props.datumIndex}
                      minHeight={props.column.minHeight}
                      fieldProps={props.column.fieldProps}
                    />
                  </>
                )}
              </Fragment>
            )}
            name={
              `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
            }
            control={props.fieldArrayProps.control}
            defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
          />
        )
      }
      break
    }
    case 'selectCreatable': {
      if (props.column.optionsCreatable) {
        const filter = createFilterOptions<string[]>()

        children = (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Fragment>
                {props.uneditable || props.column.uneditable ? (
                  <></>
                ) : (
                  <Autocomplete
                    freeSolo={true}
                    value={field.value}
                    getOptionLabel={(option: string) => {
                      return t(option)
                    }}
                    options={[...props.column.optionsCreatable!]}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        field.onChange(newValue)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }
                    }}
                    onInputChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        field.onChange(newValue)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }
                    }}
                    disabled={props.disabled ?? false}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={`${option}`}>
                          <Tooltip title={option}>
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                              {t(option)}
                            </Typography>
                          </Tooltip>
                        </li>
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!!error}
                        helperText={error ? error.message : ''}
                        variant={'standard'}
                        {...params}
                      />
                    )}
                    filterOptions={(options, params) => {
                      const filtered = createFilterOptions<string>({
                        ignoreCase: true,
                      })(options, params)

                      const { inputValue } = params
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option)
                      if (inputValue !== '' && !isExisting) {
                        filtered.push(inputValue)
                      }

                      return filtered
                    }}
                  />
                )}
              </Fragment>
            )}
            name={
              `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
            }
            control={props.fieldArrayProps.control}
            defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
          />
        )
      }
      break
    }
    case 'selectWithSearch': {
      if (props.column.options) {
        children = (
          <Controller
            render={({ field }) => (
              <Fragment>
                {props.uneditable ? (
                  <FormTypography
                    type="select"
                    options={props.column.options}
                    value={field.value}
                    sx={{ fontSize: 12 }}
                  />
                ) : (
                  <TableSelectWithSearch
                    uneditable={props.uneditable || props.column.uneditable}
                    disabled={props.disabled}
                    value={field?.value}
                    options={props.column.options}
                    onChange={(event) => {
                      field.onChange(event)
                      if (props.column.additionalOnChangeAction) {
                        props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                      }
                    }}
                    fieldProps={props.column.fieldProps}
                  />
                )}
              </Fragment>
            )}
            name={
              `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
            }
            control={props.fieldArrayProps.control}
            defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
          />
        )
      }
      break
    }
    case 'binarySelect': {
      children = (
        <Controller
          render={({ field }) => (
            <Fragment>
              {props.uneditable || props.column.uneditable ? (
                <FormTypography type="binary" value={field.value} sx={{ fontSize: 12 }} />
              ) : (
                <Select
                  fullWidth
                  sx={{
                    fontSize: 12,
                    backgroundColor: '#ffffff',
                  }}
                  disabled={props.column.disable || props.disabled}
                  value={typeof field.value !== 'boolean' ? 'NA' : field.value ? 'Yes' : 'No'}
                  onChange={(event: any) => {
                    switch (event.target.value) {
                      case 'Yes': {
                        field.onChange(true)
                        return
                      }
                      case 'No': {
                        field.onChange(false)
                        return
                      }
                      default: {
                        field.onChange(undefined)
                        return
                      }
                    }
                  }}>
                  <MenuItem
                    sx={{
                      fontSize: 12,
                    }}
                    value="Yes"
                    key={`form-table-select-option-true`}>
                    {props.column.binaryOptions
                      ? t(props.column.binaryOptions.yes)
                      : t('common:components.binarySelect')}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: 12,
                    }}
                    value="No"
                    key={`form-table-select-option-false`}>
                    {props.column.binaryOptions
                      ? t(props.column.binaryOptions.no)
                      : t('common:components.binarySelect.no')}
                  </MenuItem>
                  <MenuItem
                    value="NA"
                    key={`form-table-select-option-empty`}
                    style={{
                      display:
                        props.column.binaryOptions && props.column.binaryOptions.na
                          ? 'inherit'
                          : 'none',
                      fontSize: 12,
                    }}>
                    {props.column.binaryOptions &&
                      props.column.binaryOptions.na &&
                      t(props.column.binaryOptions.na)}
                  </MenuItem>
                </Select>
              )}
            </Fragment>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'string': {
      let disableByCallBack = false
      if (props.column.disableCallback) {
        disableByCallBack = props.column.disableCallback(props.datumIndex)
      }
      let tooltipData = props.column?.getTooltip
        ? props.column?.getTooltip({ rowData: rowData })
        : ''

      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Fragment>
              {props.uneditable || props.column.uneditable ? (
                <Tooltip title={tooltipData} placement="bottom">
                  <div>
                    <FormTypography
                      type="string"
                      value={field.value}
                      sx={error ? { fontSize: 12, color: 'red' } : { fontSize: 12 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <>
                  <TableTextField
                    error={error}
                    rows={props.column.rows}
                    value={field.value}
                    onBlur={field.onBlur}
                    datumIndex={props.datumIndex}
                    // additionalOnChangeAction={props.column.additionalOnChangeAction}
                    onChange={(event) => {
                      field.onChange(event)
                      if (props.column.additionalOnChangeAction) {
                        props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                      }
                    }}
                    displayName={props.column.displayName}
                    minHeight={props.column.minHeight}
                    disabled={props.column.disable || props.disabled || disableByCallBack}
                    isDebounce={props.column.isDebounce}
                    fieldProps={props.column.fieldProps}
                  />
                </>
              )}
            </Fragment>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'number': {
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Fragment>
              {props.uneditable || props.column.uneditable ? (
                <FormTypography type="string" value={field.value} sx={{ fontSize: 12 }} />
              ) : (
                <TableTextField
                  type="number"
                  error={error}
                  rows={props.column.rows}
                  value={field.value}
                  onBlur={field.onBlur}
                  datumIndex={props.datumIndex}
                  additionalOnChangeAction={props.column.additionalOnChangeAction}
                  onChange={(event) => {
                    field.onChange(event)
                    if (props.column.additionalOnChangeAction) {
                      props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                    }
                  }}
                  displayName={props.column.displayName}
                  minHeight={props.column.minHeight}
                  disabled={props.column.disable || props.disabled}
                  isDebounce={props.column.isDebounce}
                  fieldProps={props.column.fieldProps}
                />
              )}
            </Fragment>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'times': {
      if (props.column.subfield) {
        let dateFormat = 'HH:mm'
        if (props.column.dateFormat) {
          dateFormat = props.column.dateFormat
        }
        children = (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {t('common:components.table.from')}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  {props.uneditable || props.column.uneditable ? (
                    <>
                      <FormTypography
                        type="date"
                        dateFormat={dateFormat}
                        value={field.value}
                        sx={error ? { color: 'red', fontSize: 12 } : { fontSize: 12 }}
                      />
                      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                    </>
                  ) : (
                    <DateTimePicker
                      type="time"
                      error={error}
                      onChange={(event) => {
                        field.onChange(event)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }}
                      minDate={props.column.minDate}
                      maxDate={props.column.maxDate}
                      value={field.value}
                      sx={{ fontSize: 12, justifyContent: 'center' }}
                      disabled={props.column.disable || props.disabled}
                    />
                  )}
                </Fragment>
              )}
              name={
                `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
              }
              control={props.fieldArrayProps.control}
              defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
            />
            {t('common:components.table.to')}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  {props.uneditable || props.column.uneditable ? (
                    <FormTypography
                      type="date"
                      dateFormat={dateFormat}
                      value={field.value}
                      sx={error ? { color: 'red', fontSize: 12 } : { fontSize: 12 }}
                    />
                  ) : (
                    <DateTimePicker
                      onChange={(event) => {
                        field.onChange(event)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }}
                      minDate={props.column.minDate}
                      maxDate={props.column.maxDate}
                      value={field.value}
                      sx={{ fontSize: 12, justifyContent: 'center' }}
                      disabled={props.column.disable || props.disabled}
                    />
                  )}
                </Fragment>
              )}
              name={
                `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.subfield}` as Path<F>
              }
              control={props.fieldArrayProps.control}
              defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
            />
          </Box>
        )
      }
      break
    }
    case 'months':
    case 'dates': {
      if (props.column.subfield) {
        let dateFormat = 'D/MM/YYYY'
        if (props.column.dateFormat) {
          dateFormat = props.column.dateFormat
        } else if (props.column.type === 'months') {
          dateFormat = 'MM/YYYY'
        }
        children = (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {t('common:components.table.from')}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  {props.uneditable || props.column.uneditable ? (
                    <FormTypography
                      type="date"
                      dateFormat={dateFormat}
                      value={field.value}
                      sx={error ? { color: 'red', fontSize: 12 } : { fontSize: 12 }}
                    />
                  ) : (
                    <DateTimePicker
                      error={error}
                      type={
                        props.column.type === 'times'
                          ? 'time'
                          : props.column.type === 'months'
                          ? 'month'
                          : 'date'
                      }
                      onChange={(event) => {
                        field.onChange(event)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }}
                      minDate={props.column.minDate}
                      maxDate={props.column.maxDate}
                      value={field.value}
                      sx={{ fontSize: 12, justifyContent: 'center' }}
                      disabled={props.column.disable || props.disabled}
                      withPortal={true}
                    />
                  )}
                </Fragment>
              )}
              name={
                `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
              }
              control={props.fieldArrayProps.control}
              defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
            />
            {t('common:components.table.to')}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  {props.uneditable || props.column.uneditable ? (
                    <FormTypography
                      type="date"
                      dateFormat={dateFormat}
                      value={field.value}
                      sx={error ? { color: 'red', fontSize: 12 } : { fontSize: 12 }}
                    />
                  ) : (
                    <DateTimePicker
                      type={
                        props.column.type === 'times'
                          ? 'time'
                          : props.column.type === 'months'
                          ? 'month'
                          : 'date'
                      }
                      onChange={(event) => {
                        field.onChange(event)
                        if (props.column.additionalOnChangeAction) {
                          props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                        }
                      }}
                      minDate={props.column.minDate}
                      maxDate={props.column.maxDate}
                      value={field.value}
                      sx={{ fontSize: 12, justifyContent: 'center' }}
                      disabled={props.column.disable || props.disabled}
                      withPortal={true}
                    />
                  )}
                </Fragment>
              )}
              name={
                `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.subfield}` as Path<F>
              }
              control={props.fieldArrayProps.control}
              defaultValue={props.datum[props.column.field] ?? ''}
            />
          </Box>
        )
      }
      break
    }
    case 'checkbox': {
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Box sx={error ? { color: 'red' } : {}}>
              {props.uneditable || props.column.uneditable ? (
                <FormTypography type="binary" value={field.value} sx={{ fontSize: 12 }} />
              ) : (
                <Checkbox
                  checked={!!field.value}
                  onChange={(event: any) => {
                    field.onChange(event.target.checked)
                    if (props.column.additionalOnChangeAction) {
                      props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                    }
                  }}
                />
              )}
            </Box>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'images': {
      // display first image of the image model array with status = true
      const renderedDatum = (props.datum[props.column.field] as ImageModel[] | undefined)?.find(
        (image) => image.status,
      )
      children = (
        <Fragment>
          {renderedDatum ? (
            <TableImageHolder
              onClick={() => {
                props.handleImageModalOpen(
                  Base64StringToImageBase64(
                    ImageModelToUrl(renderedDatum, 'original'),
                    renderedDatum.ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                  ),
                )
              }}>
              <img
                style={{
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                src={ImageModelToUrl(renderedDatum, 'regular')}
                alt={`table-img-${props.datumIndex + 1}`}
              />
            </TableImageHolder>
          ) : (
            <Typography fontSize={12}>{t('No image')}</Typography>
          )}
        </Fragment>
      )
      break
    }
    case 'imageGallery': {
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Box sx={error ? { color: 'red' } : {}}>
              <TableImageUploadGallery
                error={error}
                useWatch={props.useWatch}
                minHeight={props.column.minHeight}
                width={props.column.width}
                callback={props.column.callback}
                onClick={(
                  setRequest,
                  openImageGallery,
                  setImageGalleryImages,
                  currentGallerySource,
                  setImageGallerySelected,
                ) => {
                  setRequest({
                    callback: props.column.callback!({
                      field,
                      openImageGallery,
                      datumIndex,
                      setImageGalleryImages,
                      currentGallerySource,
                      setImageGallerySelected,
                    }),
                  })
                }}
                disabled={props.disabled}
                uneditable={props.uneditable || props.column.uneditable}
                setValue={props.setValue}
                getValues={props.getValues}
                gallerySource={`${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.gallerySource}`}
                gallerySelected={`${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.gallerySelected}`}
                galleryName={`${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}`}
                control={props.fieldArrayProps.control}
                onRefreshClick={(
                  setRequest,
                  openImageGallery,
                  setImageGalleryImages,
                  currentGallerySource,
                  setImageGallerySelected,
                  closeImageGallery,
                ) => {
                  setRequest({
                    callback: props.column.onRefreshClick!({
                      field,
                      openImageGallery,
                      datumIndex,
                      setImageGalleryImages,
                      currentGallerySource,
                      setImageGallerySelected,
                      closeImageGallery,
                    }),
                  })
                }}
                onUploadClick={() => props.column.onUploadClick!(props.datumIndex)}
              />
            </Box>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'time': {
      let dateFormat = 'HH:mm'
      if (props.column.dateFormat) {
        dateFormat = props.column.dateFormat
      }
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              {props.uneditable || props.column.uneditable ? (
                <FormTypography
                  type="date"
                  dateFormat={dateFormat}
                  value={field.value}
                  sx={error ? { fontSize: 12, color: 'red' } : { fontSize: 12 }}
                />
              ) : (
                <TimeField
                  // type="time"
                  onChange={(event) => {
                    field.onChange!(event)
                    if (props.column.additionalOnChangeAction) {
                      props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                    }
                  }}
                  // timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                  value={field.value}
                  sx={{ ...props.column.sx, justifyContent: 'center', width: '100%' }}
                  {...props.column.fieldProps}
                  disabled={props.column.disable || props.disabled}
                  // datePickerType={props.datePickerType}
                  error={error}
                />
              )}
            </Box>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }

    case 'month':
    case 'date': {
      let dateFormat = 'D/MM/YYYY'
      if (props.column.dateFormat) {
        dateFormat = props.column.dateFormat
      } else if (props.column.type === 'month') {
        dateFormat = 'MM/YYYY'
      }
      if (!props.column.defaultDate) {
        props.column.defaultDate = new Date()
      }
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
              }}>
              {props.uneditable || props.column.uneditable ? (
                <FormTypography
                  type="date"
                  dateFormat={dateFormat}
                  value={field.value}
                  sx={
                    error
                      ? { fontSize: 12, color: 'red', width: '100%' }
                      : { fontSize: 12, width: '100%' }
                  }
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}>
                  <DateTimePicker
                    type={props.column.type === 'month' ? 'month' : 'date'}
                    onChange={(event) => {
                      field.onChange!(event)
                      if (props.column.additionalOnChangeAction) {
                        props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                      }
                    }}
                    value={field.value}
                    sx={{ ...props.column.sx, justifyContent: 'center', width: '100%' }}
                    {...props.column.fieldProps}
                    minDate={props.column.minDate}
                    maxDate={props.column.maxDate}
                    disabled={props.column.disable || props.disabled}
                    withPortal={true}
                    datePickerType={props.datePickerType}
                    error={error}
                  />
                </Box>
              )}
            </Box>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }

    case 'fileUpload': {
      children = (
        <Controller
          render={({ field }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              <TableFileUpload
                minHeight={props.column.minHeight}
                width={props.column.width}
                value={field.value}
                onChange={(event) => {
                  field.onChange!(event)
                  if (props.column.additionalOnChangeAction) {
                    props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                  }
                }}
                disabled={props.disabled}
                uneditable={props.uneditable || props.column.uneditable}
              />
            </Box>
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'imageUploadGallery': {
      children = (
        <Controller
          render={({ field }) => (
            <TableImageUploadGallery
              minHeight={props.column.minHeight}
              width={props.column.width}
              value={field.value}
              onChange={(event) => {
                field.onChange!(event)
                if (props.column.additionalOnChangeAction) {
                  props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                }
              }}
              onClick={(event) => {
                props.column.onClick!(props.datumIndex, field.name)
              }}
              disabled={props.disabled}
              uneditable={props.uneditable || props.column.uneditable}
            />
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'displayDate': {
      children = (
        <Typography textAlign="center" fontSize={12}>
          {props.datum[props.column.field]
            ? moment(props.datum[props.column.field]).format('DD/MM/YYYY')
            : ''}
        </Typography>
      )
      break
    }
    case 'displaySelected': {
      if (props.column.options) {
        children = (
          <Typography textAlign="center" fontSize={12}>
            {t(GetValueWithKey(props.datum[props.column.field], props.column.options) ?? '')}
          </Typography>
        )
      }
      break
    }
    case 'displayImages': {
      // display first image of the image model array with status = true
      const renderedDatum = (props.datum[props.column.field] as ImageModel[] | undefined)?.find(
        (image) => image.status,
      )
      children = (
        <Fragment>
          {renderedDatum ? (
            <TableImageHolder
              onClick={() => {
                props.handleImageModalOpen(
                  Base64StringToImageBase64(
                    ImageModelToUrl(renderedDatum, 'original'),
                    renderedDatum.ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                  ),
                )
              }}>
              <img
                style={{
                  margin: 'auto',
                  border: '0.1px solid rgb(0 0 0 / 20%)',
                  width: 300,
                  height: 200,
                  objectFit: 'contain',
                }}
                src={ImageModelToUrl(renderedDatum, 'regular')}
                alt={`table-img-${props.datumIndex + 1}`}
              />
            </TableImageHolder>
          ) : (
            <Typography fontSize={12}>{t('No image')}</Typography>
          )}
        </Fragment>
      )
      break
    }
    case 'image': {
      // display image buffer into thumbnail
      const renderedDatum = props.datum[props.column.field]
      children = (
        <Fragment>
          {renderedDatum ? (
            <Box
              sx={{
                margin: 'auto',
                border: '0.1px solid rgb(0 0 0 / 20%)',
                width: 300,
                height: 200,
                objectFit: 'contain',
                overflow: 'hidden',
              }}>
              <img
                style={{
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                src={URL.createObjectURL(renderedDatum)}
                alt={`table-img-${datumIndex + 1}`}
              />
            </Box>
          ) : (
            <Typography fontSize={12}>{t('No image')}</Typography>
          )}
        </Fragment>
      )
      break
    }
    case 'thumbnail': {
      // display image buffer into thumbnail
      const renderedDatum = props.datum[props.column.field]
      children = (
        <Fragment>
          {renderedDatum ? (
            <Box
              sx={{
                margin: 'auto',
                border: '0.1px solid rgb(0 0 0 / 20%)',
                width: 300,
                height: 200,
                objectFit: 'contain',
                overflow: 'hidden',
              }}>
              <img
                style={{
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                src={renderedDatum}
                alt={`table-img-${datumIndex + 1}`}
              />
            </Box>
          ) : (
            <Typography fontSize={12}>{t('No image')}</Typography>
          )}
        </Fragment>
      )
      break
    }
    case 'signature': {
      children = (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormSignatureHolderForTable
              error={error}
              newValue={
                props.signatureBase64 ? props.signatureBase64[field.name.toString()] : undefined
              }
              control={props.fieldArrayProps.control}
              value={field.value}
              onChange={(event) => {
                field.onChange!(event)
                if (props.column.additionalOnChangeAction) {
                  props.column.additionalOnChangeAction!(field.value, props.datumIndex)
                }
              }}
              onClick={(event) => {
                props.setDialogContext({
                  isOpen: true,
                  title: <Typography variant="body1">{t('Signature')}</Typography>,
                  children: (
                    <Grid item xs={9} sx={{ p: 1 }}>
                      <FormSignaturePad
                        onChange={(newValue: string) => {
                          props.signatureBase64Ref[field.name.toString()] = newValue
                        }}
                      />
                    </Grid>
                  ),
                  buttons: (
                    <>
                      <Stack direction="row" spacing={2}>
                        <DialogButton
                          onClick={() => {
                            props.handleDialogConfirm(field.name.toString())
                          }}>
                          {t('common:buttons.confirm')}
                        </DialogButton>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <DialogButton onClick={props.handleDialogClose}>{t('Cancel')}</DialogButton>
                      </Stack>
                    </>
                  ),
                })
              }}
              disabled={props.disabled || props.column.disable}
              uneditable={props.uneditable || props.column.uneditable}
            />
          )}
          name={
            `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
          }
          control={props.fieldArrayProps.control}
          defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
        />
      )
      break
    }
    case 'controlledCustom': {
      if (props.column.controlledRender) {
        children = (
          <Controller
            render={({ field }) =>
              props.column.controlledRender!(props.datum, field, datumIndex, props.setWarningText)
            }
            name={
              `${props.fieldArrayProps.name}.${props.datumIndex}.${props.column.field}` as Path<F>
            }
            control={props.fieldArrayProps.control}
            defaultValue={(props.column.defaultValue as any) || props.datum[props.column.field]}
          />
        )
      }
      break
    }
    case 'custom': {
      if (props.column.render) {
        children = (
          <Fragment>{props.column.render(props.datum, datumIndex, props.setWarningText)}</Fragment>
        )
      }
      break
    }
  }
  return children
}

export default FormWidget
