import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import { Card, Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import SwitchTabs from '@components/dashboard/switchTabs'
import WasteFlow from './WasteFlow'
import MonthWasteCategory from './MonthWasteCategory'
const DashboardCicTotalCdWasteGeneration = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  const { t } = useTranslation()

  let data = [
    {
      title: '',
      menuTitle: t('dashboard:wasteFlow'),
      content: <WasteFlow getValues={getValues} settings={settings} StyledCard={StyledCard} />,
    },
    {
      title: '',
      menuTitle: t('dashboard:monthAndWasteCategory'),
      content: (
        <MonthWasteCategory getValues={getValues} settings={settings} StyledCard={StyledCard} />
      ),
    },
  ]
  return <SwitchTabs data={data} />
}

export default DashboardCicTotalCdWasteGeneration
