import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { pieChartProps } from './BasicPieChart'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = (props: pieChartProps) => {
  const { t } = useTranslation()
  const translatedLabels = props.labels?.map((label) => t(label))

  let datasets = props.datasets.map((dataset) => {
    return {
      label: t(dataset.name as string),
      data: dataset.data,
      ...dataset.dataConfig,
    }
  })
  return (
    <Pie
      options={props.options}
      data={{
        labels: translatedLabels,
        datasets: [...datasets],
      }}
    />
  )
}
export default PieChart
