import { forwardRef, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'

import { Grid, ListItemButton, ListItemText, Typography, useMediaQuery, Box } from '@mui/material'

// assets

import { GlobalContext } from '@providers/globalStore'
import { SideMenuChildren } from '@components/sidebar/menuList'
import styles from '@styles/sidebar.module.scss'
import { useTranslation } from 'react-i18next'
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = (item: SideMenuChildren) => {
  const theme = useTheme()
  const { state, dispatch } = useContext(GlobalContext)
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

  let listItemProps = {
    component: forwardRef<HTMLAnchorElement>((props, ref) => (
      <Link ref={ref} {...props} to={`${item.url}`} />
    )),
  }

  const itemHandler = () => {
    dispatch({ type: 'openMenu', menuId: item.id })
    if (matchesSM) dispatch({ type: 'toggleLeftDrawer', opened: false })
  }

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id)
    if (currentIndex > -1) {
      dispatch({ type: 'openMenu', menuId: item.id })
    }
  }, [])
  const { t } = useTranslation()

  return (
    <ListItemButton
      {...listItemProps}
      sx={{
        minHeight: '42px',
        alignItems: 'flex-end',
        backgroundColor: 'inherit',
        p: 0,
        position: 'relative',
        border: 'none',
      }}
      className={styles.navItem}
      selected={state.openedMenuId === item.id}
      onClick={() => itemHandler()}>
      <Grid
        sx={{ width: '72px', height: '45px', backgroundColor: item.colorCode, zIndex: 10 }}
        className={styles.navSubItemHeader}></Grid>

      <Grid
        item
        sx={{
          width: '100%',
          alignSelf: 'center',
          pr: '12px',
        }}>
        <ListItemText
          sx={{
            textAlignLast: 'end',
          }}
          primary={
            <Typography variant={'body1'} color="inherit" sx={{ fontWeight: '900' }}>
              {t(item.title)}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant="caption" className="subMenuCaption" display="block" gutterBottom>
                {t(item.caption)}
              </Typography>
            )
          }
        />
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          backgroundColor: '#ccc',
          height: 2,
        }}
        className={styles.borderBottom}
      />
    </ListItemButton>
  )
}

export default NavItem
