import SearchPanel from '@components/searchPanel'
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { FormMwfListModel } from '@services/model/form/form.MWF.model'
import XLSX from 'xlsx-js-style'
import FormMwfService from '@services/formService/form.MWF.service'
import { NavigateTo } from '@utils/navigate'
import DescriptionIcon from '@mui/icons-material/Description'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import { BsFiletypePdf } from 'react-icons/bs'
import { SiMicrosoftexcel } from 'react-icons/si'
import useAPIFetch from '@hooks/useAPIFetch'
import { saveDataToFile } from '@utils/documentExporter'
import replaceNullValues from '@utils/replaceNullValues'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { RiEdit2Fill } from 'react-icons/ri'
import { KeyValPair } from '@models/Common'

const MonthlyWasteFlowTableAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const { state: globalState, userInfo, hasRightByCatAndCode } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const [extraListInfo, setExtraListInfo] = useState<{
    submittedByList: string[]
    approvedByList: string[]
    formIdList: KeyValPair[]
    parentFormIdList: KeyValPair[]
  }>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
  })

  const { t } = useTranslation()
  const [contractId, setContractId] = useState<number | undefined>(undefined)
  useEffect(() => {
    setContractId(globalState.currentContract?.contractId)
  }, [globalState.currentContract])
  const { setRequest, isLoading } = useAPIFetch()
  const allRecordsList = useRef<FormMwfListModel[]>([])
  const newOrder = [
    'reportPeriod',
    'total',
    'hardRockRecycled',
    'reusedInContract',
    'reusedInOthers',
    'disposedOfToPublicFill',
    'disposedOfToSortingFacility',
    'importedFill',
    'metals',
    'paperPackaging',
    'timber',
    'plastic',
    'chemicalWaste',
    'otherRecycled',
  ]
  const exportToExcel = async (dataList: any, type?: 'xlsx' | 'pdf') => {
    console.debug('dataList', dataList)
    const dataWithReportPeriod = dataList?.map((d) => {
      const {
        constructionStageId,
        acknowledgement,
        documentLock,
        reportNo,
        wasteQuantityReportingUnit,
        ...rest
      } = d
      const reportPeriod = moment(d.reportPeriod).format('YYYY-MM')

      const isInWeight = wasteQuantityReportingUnit === 5

      const mapValue = (key) => {
        const value = d[key]
        return isInWeight ? value / 2 : value
      }

      return {
        reportPeriod: reportPeriod,
        total: Object.values(rest).reduce((acc: number, val) => {
          if (typeof val === 'number' && !isNaN(val)) {
            let newVal = val
            if (isInWeight) {
              newVal = val / 2
            }
            return acc + newVal
          }
          return acc
        }, 0),
        hardRockRecycled: mapValue('hardRockRecycled'),
        reusedInContract: mapValue('reusedInContract'),
        reusedInOthers: mapValue('reusedInOthers'),
        disposedOfToPublicFill: mapValue('disposedOfToPublicFill'),
        disposedOfToSortingFacility: mapValue('disposedOfToSortingFacility'),
        importedFill: mapValue('importedFill'),
        metals: mapValue('metals'),
        paperPackaging: mapValue('paperPackaging'),
        timber: mapValue('timber'),
        plastic: mapValue('plastic'),
        chemicalWaste: mapValue('chemicalWaste'),
        otherRecycled: mapValue('otherRecycled'),
      }
    })

    const reorderedData = dataWithReportPeriod?.map((item) => {
      const reorderedItem = {}
      newOrder.forEach((prop) => (reorderedItem[prop] = item[prop]))

      console.debug(
        'reorderedItem',
        Object.values(reorderedItem)?.map((prop) => prop),
      )
      return Object.values(reorderedItem)?.map((prop) => prop)
    })

    const formattedData = replaceNullValues(reorderedData, '')
    console.debug('formattedData', formattedData)
    let merge1 = XLSX.utils.decode_range('A1:N1')
    let merge2 = XLSX.utils.decode_range('A2:N2')
    let merge3 = XLSX.utils.decode_range('A3:A5')
    let merge4 = XLSX.utils.decode_range('B3:B4')
    let merge5 = XLSX.utils.decode_range('C3:H3')
    let merge6 = XLSX.utils.decode_range('I3:N3')
    let unit = `(in '000 \m³\)`
    const dataLength = formattedData?.length
    const wb = XLSX.utils.book_new()
    console.debug('formattedData', formattedData)

    const contractName = globalState?.currentContract?.contractName

    const excelData = [
      [contractName, '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        `Monthly Waste Flow Table for ${formattedData[0][0]} to ${
          formattedData[dataLength - 1][0]
        }`,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      [
        'Month',
        'Total Waste Quantity',
        'Actual Quantities of Inert C & D Materials Generated Monthly',
        '',
        '',
        '',
        '',
        '',
        'Actual Quantities of Non-Inert C & D Materials Generated Monthly',
        '',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        'Hard Rock and Large Broken Concrete Recycled',
        'Reused in the Contract',
        'Reused in Other Projects',
        'Disposed of to Public Fill',
        'Disposed of to Sorting Facility',
        'Imported Fill',
        'Metals Recycled',
        'Paper/ Cardboard Packaging Recycled',
        'Timber/ Wood Pallet Recycled',
        'Plastics Recycled',
        'Chemical Waste Collected',
        'Other, e.g. General Refuse Disposed of to Landfill',
      ],
      ['', unit, unit, unit, unit, unit, unit, unit, unit, unit, unit, unit, unit, unit],

      ...formattedData,
      [
        'Total',
        { f: 'SUM(B6:B' + (dataLength + 5) + ')' },
        { f: 'SUM(C6:C' + (dataLength + 5) + ')' },
        { f: 'SUM(D6:D' + (dataLength + 5) + ')' },
        { f: 'SUM(E6:E' + (dataLength + 5) + ')' },
        { f: 'SUM(F6:F' + (dataLength + 5) + ')' },
        { f: 'SUM(G6:G' + (dataLength + 5) + ')' },
        { f: 'SUM(H6:H' + (dataLength + 5) + ')' },
        { f: 'SUM(I6:I' + (dataLength + 5) + ')' },
        { f: 'SUM(J6:J' + (dataLength + 5) + ')' },
        { f: 'SUM(K6:K' + (dataLength + 5) + ')' },
        { f: 'SUM(L6:L' + (dataLength + 5) + ')' },
        { f: 'SUM(M6:M' + (dataLength + 5) + ')' },
        { f: 'SUM(N6:N' + (dataLength + 5) + ')' },
      ],
    ]

    const sheet = XLSX.utils.aoa_to_sheet(excelData)
    if (!sheet['!merges']) {
      sheet['!merges'] = []
    }
    sheet['!merges'].push(merge1, merge2, merge3, merge4, merge5, merge6)
    const colWidth = 12 // Set the width for all columns
    const numCols = 14 // Set the number of columns
    const cols = Array.from({ length: numCols }, () => ({ width: colWidth }))
    sheet['!cols'] = cols
    sheet['!rows'] = [
      { hpt: 24 }, // height for row 1
      { hpt: 24 }, //height for row 2
      {},
      { hpt: 80 },
      {},
    ]

    let range = XLSX.utils.decode_range(sheet['!ref'] || '')
    for (let r = range.s.r; r <= range.e.r; ++r) {
      for (let c = range.s.c; c <= range.e.c; ++c) {
        let font: any = {}
        if (r <= 1) {
          font.bold = true
          font.italic = true
          font.sz = 16
        }
        let border: any = {}
        border = {
          top: {},
          bottom: {},
          left: {},
          right: {},
        }
        for (const [key, value] of Object.entries(border)) {
          border[key] = {
            color: { rgb: '000000' },
            style: 'thin',
          }
        }
        if (r === range.s.r) {
          border.top.style = 'medium'
        }
        if (r === range.e.r) {
          border.bottom.style = 'medium'
        }
        if (c === range.s.c) {
          border.left.style = 'medium'
        }
        if (c === range.e.c) {
          border.right.style = 'medium'
        }
        if ((c === 2 || c === 8) && r >= 2) {
          border.left.style = 'medium'
        }
        if (c >= 2 && r === 2) {
          border.top.style = 'medium'
        }
        let numFmt = '0.000'
        if (c === 0 && r >= 4 && r < range.e.r) {
          numFmt = ''
        }
        let fill: any = {}
        fill = {
          patternType: 'none',
          fgColor: { rgb: 'b2b2b2' },
          bgColor: { rgb: 'b2b2b2' },
        }
        if (r === range.e.r) {
          fill.patternType = 'solid'
        }
        let cellRef = XLSX.utils.encode_cell({ c: c, r: r })
        sheet[cellRef].s = {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true,
          },
          font: font,
          border: border,
          numFmt: numFmt,
          fill: fill,
        }
      }
    }

    const isUnitVolume = true //TODO: get from form
    if (isUnitVolume) {
      const commentRef = XLSX.utils.encode_cell({ c: 0, r: range.e.r + 2 })
      sheet[commentRef] = {
        v: `Note: A density of 2 tonnes per cubic metre has been used to derive default volumes from weights extracted from Environmental Protection Department transaction records for the fields "Disposed of to Public Fill" and "Disposed of to Sorting Facility"
`,
        t: 's',
        s: {
          alignment: {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true,
          },
        },
      }

      sheet['!rows'][range.e.r + 2] = { hpt: 36 }
      //update the range when comment is added
      let commentRange = XLSX.utils.encode_range({
        s: { c: 0, r: 0 },
        e: { c: range.e.c, r: range.e.r + 2 },
      })
      sheet['!ref'] = commentRange

      let mergeComment = XLSX.utils.decode_range(`A${range.e.r + 3}:N${range.e.r + 3}`)
      sheet['!merges'].push(mergeComment)
    }
    XLSX.utils.book_append_sheet(wb, sheet, 'MWFT')
    // Export the workbook to a BlobPart
    const wbBlob = XLSX.write(wb, { type: 'array', bookType: 'xlsx' })

    if (type === 'pdf') {
      saveDataToFile(
        wbBlob,
        'xlsx-js-style-demo.pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'pdf-download',
      )
    } else {
      XLSX.writeFile(wb, 'Monthly Waste Flow Table.xlsx')
    }
  }

  const handleClickDownload = async (
    formId: string,
    formStatus: string,
    reportNo: string,
    type?: 'xlsx' | 'pdf',
  ) => {
    let dataList: any = []
    let res = await FormMwfService.GetMwfForm(formId, formStatus)
    if (res) {
      dataList.push(res)
    }
    if (type === 'pdf') {
      await exportToExcel(dataList, 'pdf')
    } else {
      await exportToExcel(dataList, 'xlsx')
    }
  }

  const handleMultiDownload = async (type: 'xlsx' | 'pdf') => {
    let records: any = []
    let idList: any = []

    selected?.map((selectedRowNo: number) => {
      idList.push(allRecordsList.current?.[selectedRowNo]?.formId)
    })

    let res = await FormMwfService.GetMwfExportList({ idList: idList })
    if (res) {
      records = res.list
    }

    if (type === 'pdf') {
      await exportToExcel(records, 'pdf')
    } else {
      await exportToExcel(records, 'xlsx')
    }
  }

  const [TableView, reload, selected] = useTableView<FormMwfListModel>({
    selectable: true,
    headers: [
      {
        key: 'short_name',
        desc: t('common:baseForm.generalStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'report_no',
        desc: t('common:baseForm.formId', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'report_period',
        desc: t('forms:monthlyWasteFlowTable.fields.reportPeriod', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {record.reportPeriod ? moment(record.reportPeriod).format('MMM yyyy') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.contractNo', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {globalState?.contractList?.find((x) => x.id === record.contractNoId)?.contractNo}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.submittedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },

      {
        key: 'updated_at',
        desc: t('common:baseForm.approvedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {GetValueWithKey(record.approvedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.dateApproved', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {record.dateOfArchived ? moment(record.dateOfArchived).format('DD-MM-yyyy') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.updatedAt', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        width: 200,
        desc: t('common:buttons.actions', { ns: common.i18nFormNS }),
        renderCell: (record: FormMwfListModel) => (
          <Fragment>
            <IconButton
              className="edit-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
                // display: handleClickDownload ? 'inherit' : 'none',
              }}
              onClick={() => {
                // handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
              }}>
              <RiEdit2Fill />
            </IconButton>
            <IconButton
              className="download-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
              }}
              onClick={() => {
                // handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'xlsx')
              }}>
              <SiMicrosoftexcel />
            </IconButton>
            <IconButton
              className="download-pdf-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
              }}
              onClick={() => {
                handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
              }}>
              <BsFiletypePdf />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormMwfListModel, event: any) {
      if (event.target.matches('.download-button *')) {
        handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'xlsx')
      } else if (event.target.matches('.download-pdf-button *')) {
        handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
      } else if (event.target.matches('.edit-button *')) {
        NavigateTo(navigate, '/monthly-waste-flow-table/:id', { id: record.formId })
      }
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      const res = await FormMwfService.GetMwfList(
        {
          ...state.filterResult,
          pagination: pagination,
          sorting: sorting,
          contractNo: {
            Operator: '=',
            Value: contractId ? [contractId] : [globalState.currentContract?.contractId],
          },
        },

        cancelToken,
        props.showMyRecord || false,
      )
      setExtraListInfo({
        submittedByList: res.submittedByList,
        approvedByList: res.approvedByList,
        formIdList: res.formIdList,
        parentFormIdList: res.parentFormIdList,
      })

      allRecordsList.current = res?.list!
      return res
    },
  })

  useEffect(() => {
    document.title = t('forms:monthlyWasteFlowTable.title', { ns: common.i18nFormNS })
    // eslint-disable-next-line
  }, [])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleExportSelected = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <Grid component="main" container padding={3}>
      <DescriptionIcon sx={{ fontSize: 40, color: '#707070' }} />
      <Typography sx={{ color: '#707070', fontSize: '30px', fontWeight: 'medium' }}>
        {props.showMyRecord ? t('My Actions') : t('All Records')}
      </Typography>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTable, ['C'])
              ? '/monthly-waste-flow-table'
              : undefined
          }
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'reportPeriod',
              desc: t('forms:drsItems.fields.dateOfDisposal'),
              type: 'DateFilter',
              subType: 'month',
              disableOperator: true,
              defaultOperator: 'in',
              defaultFrom: '',
              defaultTo: '',
            },
            {
              key: 'formStatus',
              desc: t('common:baseForm.formStatus'),
              type: 'StringSelectionFilter',
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.MonthlyWasteFlowTable)) //
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
            },
            {
              key: 'submittedBy',
              desc: t('common:baseForm.submittedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.submittedByList?.includes(x.key),
              ),
            },
            {
              key: 'approvedBy',
              desc: t('common:baseForm.approvedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.approvedByList?.includes(x.key),
              ),
            },
          ]}
          extraButtons={
            <>
              <IconButton
                onClick={handleExportSelected}
                sx={{
                  margin: '0 5px 0 5px',
                  backgroundColor: globalState.headerColor,
                  color: 'white',
                  borderRadius: '5px',
                  '&:hover': {
                    backgroundColor: globalState.headerColor,
                  },
                  '&:disabled': {
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    backgroundColor: globalState.headerColor,
                  },
                }}
                disabled={selected?.length <= 0}>
                <AddBoxIcon sx={{ color: 'white' }} />
                <Box
                  sx={{
                    fontSize: '16px',
                    paddingLeft: '4px',
                    color: 'white',
                  }}>
                  {t('common:buttons.exportSelected')}
                </Box>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <Typography sx={{ p: 2 }}>
                  <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical button group"
                    variant="text">
                    <Tooltip title={t('forms:drsItems.batch.button').toString()} placement="left">
                      <Button
                        onClick={() => {
                          handleMultiDownload('xlsx')
                          // navigate('/daily-record-summary-item/batch')
                        }}>
                        {t('common:buttons.exportExcel')}
                      </Button>
                    </Tooltip>
                    <Tooltip title={t('forms:drsItems.single.button').toString()} placement="left">
                      <Button
                        onClick={() => {
                          handleMultiDownload('pdf')
                          // navigate('/daily-record-summary-item/single')
                        }}>
                        {t('common:buttons.exportPdf')}
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </Typography>
              </Popover>
            </>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default MonthlyWasteFlowTableAllRecordsPage
