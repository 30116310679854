import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export interface BasicTableProps {
  columns: { title: string; key: string; width?: string | number }[]
  rows?: Record<string, any>[]
}

/*
Usage:
<BasicTable
  columns = {[
    { title: 'A', key: 'columnA' },
    { title: 'B', key: 'columnB' },
    { title: 'C', key: 'columnC' },
  ]}
  rows = {[
    { columnA: 'Row 1, Column A', columnB: 'Row 1, Column B', columnC: 'Row 1, Column C' },
    { columnA: 'Row 2, Column A', columnB: 'Row 2, Column B', columnC: 'Row 2, Column C' },
    { columnA: 'Row 3, Column A', columnB: 'Row 3, Column B', columnC: 'Row 3, Column C' },
  ] }
  />

Result:

  |    A    |    B    |    C    |
|---------|---------|---------|
| Row 1,  | Row 1,  | Row 1,  |
| Column  | Column  | Column  |
|    A    |    B    |    C    |
|---------|---------|---------|
| Row 2,  | Row 2,  | Row 2,  |
| Column  | Column  | Column  |
|    A    |    B    |    C    |
|---------|---------|---------|
| Row 3,  | Row 3,  | Row 3,  |
| Column  | Column  | Column  |
|    A    |    B    |    C    |
-------------------------------

*/

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  padding: theme.spacing(1),
  color: '#707070',
}))

export const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: theme.spacing(1),
  color: '#707070',
}))

export default function BasicTable(props: BasicTableProps) {
  return !props.columns ? (
    <></>
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#c5c5c5',
              color: '#707070',
              width: '100%',
            }}>
            {props.columns.map((column, index) => (
              <HeaderTableCell key={index} width={column.width ?? undefined}>
                {column.title}
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((row) => (
            <TableRow
              key={row[props.columns[0].key]}
              sx={{
                // height: props.height ?? 200,
                ':hover': {
                  '#table-row-actions': {
                    display: 'inherit',
                  },
                },
                backgroundColor: '#edeef0',
                width: '100%',
              }}>
              {props.columns.map((column: any, index: number) =>
                index === 0 ? (
                  <BodyTableCell key={index} component="th" scope="row">
                    {row[column.key]}
                  </BodyTableCell>
                ) : (
                  <BodyTableCell key={index}>{row[column.key]}</BodyTableCell>
                ),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
