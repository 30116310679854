import { FileToFileModel, GetFileFromUrl } from '@utils/file'
import { FileModel } from '@services/model/file.model'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Paper, CircularProgress, IconButton, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import ClearIcon from '@mui/icons-material/Clear'
import FileIcon from '@components/icon/file'
import UploadFileIcon from '@mui/icons-material/UploadFile'

const TableFileUpload = (props: {
  value: FileModel[] | undefined
  onChange: (...event: any[]) => void
  minHeight?: string | number
  width?: string | number
  disabled?: boolean
  uneditable?: boolean
}) => {
  const [files, setFiles] = useState<FileModel[]>([])
  const uploadRef = useRef<HTMLInputElement | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const { t } = useTranslation()

  // mounting
  const mounted = useRef<boolean>(false)
  useEffect(() => {
    if (!mounted.current && props.value) {
      setFiles(props.value)
      setUploaded(true)
      mounted.current = true
    }
  }, [props.value])

  const handleUploadFileChange = async (event: any) => {
    setIsLoading(true)

    if (event.target.files) {
      const _files: File[] = [...event.target.files]
      const _file = await FileToFileModel(_files[0])
      if (_file) {
        setFiles([...files, _file])
        props.onChange!([...files, _file])
      }
      event.target.value = ''
      setUploaded(true)
    }

    setIsLoading(false)
  }

  const handleAddFileClick = () => {
    if (uploadRef && uploadRef.current && !uploaded) {
      uploadRef.current.click()
    }
  }

  const handleDownloadFile = async () => {
    setIsLoading(true)

    const file = files.find((f) => f.status)
    if (file) {
      const fileBytes = file.bytes
      if (fileBytes) {
        const download = document.createElement('a')
        download.href = window.URL.createObjectURL(new Blob([fileBytes]))
        download.setAttribute('download', `${file.fileName}.${file.ext}`)
        document.body.appendChild(download)
        download.click()
        document.body.removeChild(download)
      } else if (file.fileUrl && file.fileUrl !== '') {
        const blob = await GetFileFromUrl(file)
        if (blob) {
          const download = document.createElement('a')
          download.href = URL.createObjectURL(blob)
          download.setAttribute('download', `${file.fileName}.${file.ext}`)
          document.body.appendChild(download)
          download.click()
          document.body.removeChild(download)
        }
      }
    }

    setIsLoading(false)
  }

  const handleRemoveFile = () => {
    setIsLoading(true)

    const _files = [...files]
    const foundIndex = _files.findIndex((f) => f.status)
    if (foundIndex !== -1) {
      // fileId => status = false
      // otherize => remove record
      if (_files[foundIndex].fileId) {
        _files[foundIndex].status = false
      } else {
        _files.splice(foundIndex, 1)
      }
      setFiles(_files)
      props.onChange!(_files)
      setUploaded(false)
    }

    setIsLoading(false)
  }

  const CurrentFile = () => {
    const file = files.find((f) => f.status)
    if (file) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
          <FileIcon text={file ? file.ext : ''} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 12,
                whiteSpace: 'nowrap',
              }}>
              {file && `${file.fileName}.${file.ext}`}
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 9,
                whiteSpace: 'nowrap',
              }}>
              {Math.floor(file.size / 1024) + t('Kb')}
            </Typography>
          </Box>
          <IconButton onClick={handleDownloadFile}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handleRemoveFile}>
            <ClearIcon />
          </IconButton>
        </Box>
      )
    } else {
      return <></>
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
      <input
        type="file"
        ref={uploadRef}
        style={{ display: 'none' }}
        onChange={handleUploadFileChange}
      />
      <Paper
        sx={{
          width: props.width ?? '95%',
          minWidth: '150',
          minHeight: props.minHeight ?? '50',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!uploaded && {
            cursor: 'pointer',
          }),
        }}
        {...(!uploaded && {
          onClick: handleAddFileClick,
        })}>
        {isLoading ? <CircularProgress /> : uploaded ? <CurrentFile /> : <UploadFileIcon />}
      </Paper>
    </Box>
  )
}

export default TableFileUpload
