import { useContext, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
  Box,
} from '@mui/material'

// project imports
import NavItem from './navItem'

import { SideMenuChildren } from '@components/sidebar/menuList'
import { ReactComponent as FormSvg } from '@images/presentation-alt.svg'
import styles from '@styles/sidebar.module.scss'
import { useNavigate } from 'react-router-dom'
import UIAdd from '../../../assets/img/ui-add.svg'
import { GlobalContext } from '@providers/globalStore'
import FormIcon from '@components/FormIcon'
import { property } from 'lodash'
import { CiFileOn } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = (menu: SideMenuChildren) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string | null>(null)

  const handleClick = (event) => {
    if (menu.disabled) {
      event.preventDefault()
      return
    }

    setOpen(!open)
    let redirect = !open
    if (redirect && menu.url !== undefined) {
      navigate(menu.url)
    }
    setSelected(!selected ? menu.id : null)
  }

  // menu collapse & item
  const menus = menu.children
    ?.filter((x) => x.hidden !== true)
    ?.map((item) => {
      switch (item.type) {
        case 'collapse':
          return <NavCollapse key={item.id} {...item} />
        case 'item':
        case 'sub-item':
          return <NavItem key={item.id} {...item} />
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          )
      }
    })

  if (menus === undefined || menus?.length <= 0) return <></>
  const { t } = useTranslation()
  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `5px 5px 5px ${selected !== menu.id ? '5px' : '0px'}`,
          mt: 1,
          alignItems: 'center',
          boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
          overflow: 'hidden',
          transitionDelay: '.2s',
          padding: '0!important',
          height: '60px',
          justifyContent: 'center',
          border: 'none',
        }}
        className={styles.navItem}
        data-selected={selected !== menu.id}
        selected={selected === menu.id}
        onClick={handleClick}>
        <Grid sx={{ my: 'auto', backgroundColor: menu.colorCode }} className={styles.navItemHeader}>
          <ListItemIcon className={styles.afa} data-code={menu.acronym}>
            <SvgIcon sx={{ fontSize: '28px', width: 46, height: 50 }}>
              {/* <FormSvg /> */}
              <CiFileOn />
            </SvgIcon>
          </ListItemIcon>
        </Grid>

        <ListItemText
          className={styles.navDescription}
          sx={{
            // textAlignLast: 'end',
            textAlign: 'right',
          }}
          primary={
            <Typography variant="body2" color="inherit" sx={{ my: 'auto' }}>
              {t(menu.title)}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography className="subMenuCaption" display="block" gutterBottom>
                {t(menu.caption)}
              </Typography>
            )
          }
        />

        <Box className={styles.navItemButton} sx={{ backgroundColor: menu.colorCode }}>
          <img src={UIAdd} alt={'add'} />
        </Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            position: 'relative',
            padding: '0px',
          }}>
          {menus}
        </List>
      </Collapse>
    </>
  )
}

export default NavCollapse
