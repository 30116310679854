import { ImageModel } from '../image.model'
import { FormModel, InitFormModel } from './form.model'
import { FileModel } from '../file.model'
import { KeyValPair } from '@models/Common'
export interface FormMwfCreateDocumentModel {
  baseForm?: FormModel
  mwfMonth?: string
  mwfCategory?: number
  bytes?: string
  bytesBase64?: string
  fileName?: string
  fileId?: string
  fileUrl?: string
  size?: number
  ext?: string
  status?: boolean
}

export interface FormMwfUploadModel {
  baseForm?: FormModel
  mwfCategory?: number
  mwfMonth?: string
  hardRockRecycledList?: FormMwfCreateDocumentModel[]
  reusedInContractList?: FormMwfCreateDocumentModel[]
  reusedInOtherList?: FormMwfCreateDocumentModel[]
  disposedOfToPublicFillList?: FormMwfCreateDocumentModel[]
  disposedOfToSortingFacilityList?: FormMwfCreateDocumentModel[]
  importedFillList?: FormMwfCreateDocumentModel[]
  metalsList?: FormMwfCreateDocumentModel[]
  paperPackagingList?: FormMwfCreateDocumentModel[]
  timberList?: FormMwfCreateDocumentModel[]
  plasticList?: FormMwfCreateDocumentModel[]
  chemicalWasteList?: FormMwfCreateDocumentModel[]
  otherRecycledList?: FormMwfCreateDocumentModel[]
  list?: FormMwfCreateDocumentModel[]
  lists?: FormMwfCreateDocumentModel[][]
  signatureBase64?: string
  acknowledgement?: boolean
}

export const InitFormMwfUploadModel: FormMwfUploadModel = {
  baseForm: { ...InitFormModel, formStatus: 'Draft' },
  // list: [{ baseForm: { ...InitFormModel, formStatus: 'Draft' } }],
}

export type FormMwfUploadOptions = {
  constructionStageList: KeyValPair[]
  wasteQuantityReportingUnitList: KeyValPair[]
  documentsCategoryList: KeyValPair[]
  selectableMonths?: object
  contractNo: {
    key: number
    contractNo: string
    contractName: string
  }[]
}

export interface MWFDocRequestModel {
  contractNo?: any
  reportPeriod?: any
  category?: any
}

export interface MWFDocResponseModel {
  list: {
    baseForm?: {
      formId?: string
      parentFormId?: string
      contractNoId?: number
      formStatusId?: number
      formStatus?: string
      editable?: boolean
      formPermission?: {
        canUpdate: boolean
        canDelete: boolean
        approveRequired: boolean
        endOfFlow: boolean
      }
      status?: true
      createdAt?: string
      createdBy?: string
    }
    createdBy?: string
    id?: number
    mwfCategory?: 7
    mwfMonth?: string
    fileId?: string
    fileName?: string
  }[]
  totalCount?: number
}
