import { KeyValPair } from '@models/Common'
import { Checkbox, FormControlLabel, FormGroup, Typography, Box, Stack } from '@mui/material'
import { Fragment, useState } from 'react'
import FormTypography from './typography'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
interface FormCheckbox {
  value?: boolean
  onChange?: (...event: any[]) => void
  onBlur?: (...event: any[]) => void
  additionalChangeAction?: (event: any) => void
  label?: string
  disabled?: boolean
  uneditable?: boolean
  sx?: CSSProperties
}

const FormCheckbox = (props: FormCheckbox) => {
  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any, checked: boolean) => {
    props.onChange!(checked)
    props.onBlur!(checked)

    if (props.additionalChangeAction) {
      props.additionalChangeAction(event)
    }
  }
  const handleBlur = (event: any) => {
    props.onBlur!(event.target.checked)
  }
  const { t } = useTranslation()
  return (
    <Fragment>
      {props.uneditable ? (
        <>{props.value && props.label ? <Typography>{t(props.label)}</Typography> : <></>}</>
      ) : props.label ? (
        <FormControlLabel
          sx={{ alignSelf: 'start', ...props.sx }}
          checked={!!props.value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={props.disabled}
          control={<Checkbox sx={{ m: 1 }} />}
          label={t(props.label)}
        />
      ) : (
        <Checkbox
          sx={{ alignSelf: 'start', ...props.sx }}
          checked={!!props.value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={props.disabled}
        />
      )}
    </Fragment>
  )
}

export default FormCheckbox
