import { useOktaAuth } from '@okta/okta-react'
import { NavigateTo } from '@utils/navigate'
import { Navigate, useNavigate } from 'react-router-dom'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'

type ProtectedRouteProps = {
  permission?: boolean
  children: JSX.Element
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { authState, oktaAuth } = useOktaAuth()
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)

  const navigate = useNavigate()

  const isCorsError = (err) =>
    err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch'

  const logout = async () => {
    const basename = window.location.origin
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename })
      NavigateTo(navigate, '/login')
    } catch (err) {
      if (isCorsError(err)) {
        // setCorsErrorModalOpen(true);
      } else {
        throw err
      }
    }
  }

  if (!authState || !authState.isAuthenticated) {
    logout()
  }

  if (props.permission === undefined || props.permission === true) return props.children
  else {
    if (globalState?.roleMetaList && userInfo && authState) {
      return <Navigate to="/401" />
    } else return props.children
  }
}
