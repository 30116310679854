import { menuItems } from '@components/sidebar/menuList'

export function getFormDetails(formShortName: string, req: string) {
  let formDetailsMap = {}
  menuItems.map((module) => {
    let formName = {}
    module.children.map((form) => {
      let reqItem = form.acronym ? form.acronym : ''
      switch (req) {
        case 'title':
          formName[reqItem] = form.title
          break
        case 'urlPattern':
          formName[reqItem] = form.urlPattern
          break
        case 'colorCode':
          formName[reqItem] = form.colorCode
          break
        default:
          break
      }

      // if (req === 'title') {
      //   formName[reqItem] = form.title
      // } else if (req === 'urlPattern') {
      //   formName[reqItem] = form.urlPattern
      // }
      return form
    })
    formDetailsMap[module.id] = formName
    return module
  })

  for (const module in formDetailsMap) {
    for (const form in formDetailsMap[module]) {
      if (form === formShortName) {
        return formDetailsMap[module][formShortName]
      }
    }
  }
  return ''
}
