import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { MediaModel } from '../media.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'
import { Construction } from '@mui/icons-material'
export interface FormWftModel {
  baseForm: FormModel
  editable?: boolean
  reportPeriod?: Date
  list?: FormWftListModel[]
  rows?: FormWftListModel[]
  signatureBase64?: string
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
  formRemarks?: any
}

export interface FormWftListModel {
  constructionStage?: string
  material?: string
  estimatedQuantityVolume?: number
  estimatedQuantityWeight?: number
  wasteManagementMethod?: string
  remarks?: string
  wasteManagementTips?: string
}

export const InitWftForm: FormWftModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.WasteForecastTableDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormWftListModel {
  formId: string
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo?: number
  chitNo?: number
  contractNoId: number
  // archivedDate: Date
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  createdAt: Date
  reportPeriod?: Date
  dateOfArchived?: Date
}

export type FormWftGeneralOptionsModel = {
  constructionStage?: KeyValPair[]
  material?: { key: number; value: string; isGroup: boolean }[]
  wasteManagementMethod?: KeyValPair[]
  wasteManagementTip?: any
  wasteManagementTipShort?: any
}
