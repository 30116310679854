import { Fragment, useContext, useEffect, useInsertionEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { FormDrsItemModel, InitDrsItemForm } from '@services/model/form/form.DRS.item.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormDrsItemService from '@services/formService/form.DRS.item.service'
import FormDrsService from '@services/formService/form.DRS.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import DailyRecordSummaryUploadPartA from './PartA'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormStatusEnum } from '@services/model/form/form.model'
import useLocalStorage from '@hooks/useLocalStorage'
import FormContainer from '@components/form/container'
import FormSignatureHistories from '@components/form/signatureHistories'
import FormService from '@services/form.service'
import { FormContext } from '@providers/formStateProvider'
import _ from 'lodash'
import getErrMsg from '@utils/getErrMsg'

const DailyRecordSummaryItemSinglePage = () => {
  const UUID = uuid()
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: globalState,
    userInfo,
    hasRightByCatAndCode,
    dispatch: globalAction,
  } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  let { id: formId } = useParams<string>()
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestOptions } = useAPIFetch()
  const defaultMsg = t('forms:validate.messages.required')

  const validationSchema = Yup.object().shape({
    chitNo: Yup.string().typeError(defaultMsg).required(defaultMsg),
    vehicleRegistrationMark: Yup.string().typeError(defaultMsg).required(defaultMsg),
    dateOfDisposal: Yup.string().typeError(defaultMsg).required(defaultMsg),
    departureTimeFromSite: Yup.string().typeError(defaultMsg).required(defaultMsg),
    approxVol: Yup.number().typeError(defaultMsg).required(defaultMsg),
    disposalGround: Yup.number().typeError(defaultMsg).required(defaultMsg),
    cDMaterialType: Yup.number().typeError(defaultMsg).required(defaultMsg),
  })

  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const [currentContract] = useLocalStorage<any | undefined>('currentContract', undefined)
  const [currentProject] = useLocalStorage<any | undefined>('currentProject', undefined)
  const { control, setValue, getValues, reset, trigger, setError } = useForm<FormDrsItemModel>({
    defaultValues: { ...InitDrsItemForm },
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })
  const drsGeneralOptions = useRef<FormDrsGeneralOptionsModel>()
  const isMounted = useRef(false)
  const [drsLock, contractNoId, contractNo, contractName, formStatus, formPermission] = useWatch({
    name: [
      'drsLock',
      'baseForm.contractNoId',
      'baseForm.contractNo',
      'baseForm.contractName',
      'baseForm.formStatus',
      'baseForm.formPermission',
    ],
    control: control,
  })

  const uneditable =
    formPermission?.canUpdate === false ||
    drsLock === true ||
    formStatus === FormStatusEnum.DailyRecordSummaryItemCompleted

  useEffect(() => {
    if (currentContract?.contractId) {
      setValue('baseForm.contractNoId', currentContract?.contractId)
    }
  }, [currentContract])

  useEffect(() => {
    if (currentProject?.projectId) {
      setValue('baseForm.projectId', currentProject?.projectId)
    }
  }, [currentProject])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    setValue('submissionComment', submissionComment)
    const values = getValues()

    if (!(await trigger())) {
      enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
      return
    }
    setRequest({
      callback: async () => {
        await FormDrsItemService.ApproveSingleDrsItemForm(values).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSubmitted'), { variant: 'success' })
          NavigateTo(navigate, '/daily-record-summary-item/all-records')
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormDrsItemService.DeleteSingleDrsItemForm(getValues()).then((resp) => {
          enqueueSnackbar(t('common:messages.recordArchived'), { variant: 'success' })
          NavigateTo(navigate, '/daily-record-summary-item/all-records')
        })
      },
    })
  }

  const handleOnSave = async () => {
    // if (!(await trigger())) {
    //   return
    // }
    const values = getValues()
    setRequest({
      callback: async () => {
        await FormDrsItemService.SaveSingleDrsItemForm(values)
          .then((resp) => {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/daily-record-summary-item/:id', {
              id: resp.formId,
            })
            formId = resp.formId
            enqueueSnackbar(t('common:messages.recordSubmitted'), { variant: 'success' })
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      reloadDrsGeneralOptions()
    }
    if (formStateContext.form && formStateContext.formType === 'DRS_ITEM') {
      formStateAction({ type: 'pop', targetFormType: 'DRS_ITEM' })
      // previous editing form (new / existed form)
      let prevform = { ...formStateContext.form }
      isMounted.current = false // if back from another form, reevaluate onNext disable by reset form
      reset(prevform)
      isMounted.current = true
    } else if (currentContract && currentProject && !formId) {
      setValue('baseForm', {
        ...getValues('baseForm'),
        contractNoId: currentContract.contractId,
        contractNo: currentContract.contractNo,
        contractName: currentContract.contractName,
        projectId: currentProject.projectId,
      })
    } else if (globalState?.contractList && formId) {
      reload(formId)
    }
    return () => {}
  }, [formId, currentContract, currentProject, globalState?.contractList])

  const reloadDrsGeneralOptions = async () => {
    setRequestOptions({
      callback: async () => {
        await FormDrsService.GetDrsGeneralOptions().then(async (res) => {
          drsGeneralOptions.current = res
        })
      },
    })
  }

  const reload = async (formId: string) => {
    setRequest({
      callback: async () => {
        await FormDrsItemService.GetSingleDrsItemForm(formId).then(async (res) => {
          if (res) {
            const resContractNo = _.get(
              _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
              'contractNo',
            )
            const resContractName = _.get(
              _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
              'contractName',
            )

            let newRes = {
              ...res,
              baseForm: {
                ...res.baseForm,
                contractNo: resContractNo,
                contractName: resContractName,
              },
            }
            reset(newRes)
            if (res.baseForm.formStatus !== formStatus) {
              globalAction({
                type: 'changeFormStatus',
                formStatus: res.baseForm.formStatusName,
              })
            }
          }
        })
      },
    })
  }

  const partA = (): FormPartComponents => {
    return {
      partTitle: t('forms:drsItems.single.sections.create.title'),
      component: (
        <DailyRecordSummaryUploadPartA
          uneditable={uneditable}
          control={control}
          drsGeneralOptions={drsGeneralOptions.current}
          formId={formId}
        />
      ),
      onNextDisabled: uneditable,
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('forms:drsItems.single.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
      disabled: false,
    }
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    let defaultNotifyList = await FormService.GetNotifyList(
      'DRS_ITEM',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    let defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:drsItems.single.title'),
    components: [partA(), partB()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableComment: getValues('baseForm.formId') ? false : true,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate || drsLock === true,
    disableDelete: !formPermission?.canDelete || drsLock === true,
    approveRequired: formPermission?.approveRequired,
    returnRequired: formPermission?.returnRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })
  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}

export default DailyRecordSummaryItemSinglePage
