import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { MediaModel } from '../media.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'
import { Construction } from '@mui/icons-material'
export interface FormWmpModel {
  baseForm: FormModel
  fileId?: number | string
  fileName?: string
  ext?: string
  status?: any
  reportPeriod?: Date
  bytesBase64?: string
  uploadWasteManagementPlan?: any
  signatureBase64?: string
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
}

export const InitWmpForm: FormWmpModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.WasteManagementPlanUploadPlatformDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormWmpListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo?: number
  chitNo?: number
  contractNoId: number
  archivedDate: Date
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  createdAt: Date
  reportPeriod?: Date
}

export type FormWmpGeneralOptionsModel = {}
