import { TextField, TextFieldProps, Typography } from '@mui/material'
import { CSSProperties, Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from '@utils/debounce'
import { useState } from 'react'
interface formFreeTextProps {
  value?: any
  onChange?: (...event: any[]) => void
  onBlur?: (...event: any[]) => void
  sx?: CSSProperties
  textfieldProps?: TextFieldProps
  additionalOnChangeAction?: (...event: any[]) => any
  disabled?: boolean
  uneditable?: boolean
  isDebounce?: boolean
  error?: any
  allowNegative?: boolean
  inputType?: 'string' | 'number' | 'float' | 'integer'
  ref?: any
}

export default function FormFreeText(props: formFreeTextProps) {
  const { t } = useTranslation()

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const [hasBeenFocused, setHasBeenFocused] = useState(false)

  const handleFocus = (event) => {
    setHasBeenFocused(true)
    handleChange(event)
  }

  const textfieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t('common:messages.placeholder.default'),
    disabled: props.disabled,
    ...props.textfieldProps,
  }

  const isNum =
    props.textfieldProps?.type === 'number' ||
    props.textfieldProps?.type === 'float' ||
    props.inputType === 'float' ||
    props.inputType === 'number' ||
    props.inputType === 'integer'
  const isInteger = props.inputType === 'integer'
  const handleChange = (event: any) => {
    event.stopPropagation()
    let value = isNum ? parseFloat(event.target.value) : event.target.value
    if (isNum && isNaN(value)) {
      value = null
    }
    props.onChange!(value)
    if (props.additionalOnChangeAction) {
      props.additionalOnChangeAction!(value)
    }
  }

  const handleBlur = (event: any) => {
    event.stopPropagation()
    if (hasBeenFocused) {
      // Trigger the error if the input has been focused and there's no value
      props.onBlur!()
    }
    setHasBeenFocused(false)
  }

  const handleOnKeyDown = (event) => {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'End' ||
      event.key === 'Home'
    ) {
      return
    }

    if (isNum) {
      const value = event.key
      if (isInteger) {
        if (event.key === '.') {
          event.preventDefault()
        }
      }
      if (event.key === '.' && event.target.value.includes('.')) {
        event.preventDefault()
      }
      if (!props.allowNegative) {
        if (!/^[0-9.]*$/.test(value)) {
          event.preventDefault()
        }
      } else {
        if (!/^-?[0-9.]*$/.test(value)) {
          event.preventDefault()
        }
      }
    }
  }
  return (
    <Fragment>
      {props.uneditable ? (
        <Typography sx={{ ...props.sx }}>{props.value ?? ''}</Typography>
      ) : (
        <TextField
          inputRef={props.ref}
          onKeyDown={handleOnKeyDown}
          onFocus={handleFocus}
          helperText={props.error ? props.error.message : null}
          error={!!props.error}
          defaultValue={props.value ?? ''}
          onBlur={(event: any) => {
            if (props.isDebounce) {
              debounce(handleBlur, 1000)(event)
            } else {
              handleBlur(event)
            }
          }}
          onChange={(event: any) => {
            if (props.isDebounce) {
              debounce(handleChange, 800)(event)
            } else {
              handleChange(event)
            }
          }}
          sx={{ backgroundColor: 'transparent', ...props.sx }}
          {...textfieldProps}
          // autoFocus={true}
        />
      )}
    </Fragment>
  )
}
