import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormFreeText from '@components/form/freeText'
import FormTypography from '@components/form/typography'
import {
  DisposalGroundEnum,
  FormDrsGeneralOptionsModel,
  FormDrsModel,
} from '@services/model/form/form.DRS.model'
import FormMultipleSelect from '@components/form/multipleSelect'
import { Control } from 'react-hook-form'
import { Grid } from '@mui/material'
import { BaseFormPermission, FormStatusEnum } from '@services/model/form/form.model'
import { useTranslation } from 'react-i18next'
import cleanString from '@utils/cleanString'
const DailyRecordSummaryPartA = ({
  formId,
  useWatch,
  onNextDisabled,
  control,
  formPermission,
  drsGeneralOptions,
  formStatus,
  handleOnSave,
}: {
  formId?: string
  useWatch: any
  onNextDisabled: (disabled: boolean) => void
  control: Control<FormDrsModel>
  formPermission: BaseFormPermission
  drsGeneralOptions?: FormDrsGeneralOptionsModel
  formStatus: string
  handleOnSave: () => Promise<void>
}) => {
  let index = 0
  const { t } = useTranslation(['forms'])
  const [dateOfDisposal, designatedDisposalGround, approvedAlternativeDisposalGround] = useWatch({
    name: ['dateOfDisposal', 'designatedDisposalGround', 'approvedAlternativeDisposalGround'],
    control: control,
  })

  if (dateOfDisposal && designatedDisposalGround?.length > 0) {
    onNextDisabled(false)
  } else {
    onNextDisabled(true)
  }

  const designatedDisposalGroundOptions = drsGeneralOptions?.disposalGround?.filter(
    (option) => cleanString(option.value) !== cleanString(DisposalGroundEnum.other),
  )

  const uneditable =
    !formPermission?.canUpdate ||
    formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
    formStatus === FormStatusEnum.DailyRecordSummaryFirstApproved ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

  const uneditableEO =
    !formPermission?.canUpdate ||
    formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

  return (
    <Grid sx={{ minHeight: 620 }}>
      <FormContainer fkey={`$drs-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractName',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('CHIT Account No.')} index={index++}>
          <FormController
            controllerProps={{
              name: 'chitAccountNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.dateOfDisposal')} index={index++}>
          <FormController
            controllerProps={{
              name: 'dateOfDisposal',
              control,
            }}>
            <FormDateTimePicker
              type="date"
              maxDate={new Date()}
              uneditable={uneditable || !!formId}
              sx={{ width: '100%' }}
              withCurrent={true}
            />
          </FormController>
        </FormField>
        <FormField
          fieldName={t('forms:dailyRecordSummary.fields.designatedDisposalGround.label')}
          index={index++}
          fieldTooltip={t('forms:dailyRecordSummary.fields.designatedDisposalGround.tooltip')}>
          <FormController
            controllerProps={{
              name: 'designatedDisposalGround',
              control,
            }}>
            <FormMultipleSelect
              uneditable={uneditable}
              options={designatedDisposalGroundOptions ?? []}
            />
          </FormController>
        </FormField>
        <FormField
          fieldName={t('forms:dailyRecordSummary.fields.approvedAlternativeDisposalGround')}
          index={index++}>
          <FormController
            controllerProps={{
              name: 'approvedAlternativeDisposalGround',
              control,
            }}>
            <FormMultipleSelect
              uneditable={uneditable}
              options={drsGeneralOptions?.disposalGround ?? []}
            />
          </FormController>
        </FormField>
        <FormField
          fieldName={t('forms:dailyRecordSummary.fields.otherApprovedAlternativeDisposalGround')}
          index={index++}
          alignItems={'center'}
          hidden={
            // if Other is not selected, hide the field
            !approvedAlternativeDisposalGround?.includes(
              drsGeneralOptions?.disposalGround?.find(
                (option) => cleanString(option.value) === cleanString(DisposalGroundEnum.other),
              )?.key,
            )
          }>
          <FormController
            controllerProps={{
              name: 'otherApprovedAlternativeDisposalGround',
              control,
            }}>
            <FormFreeText uneditable={uneditable} />
          </FormController>
        </FormField>

        <FormField fieldName={t('forms:dailyRecordSummary.fields.meansOfAccess')} index={index++}>
          <FormController
            controllerProps={{
              name: 'meansOfAccess',
              control,
            }}>
            <FormFreeText uneditable={uneditableEO} textfieldProps={{ multiline: true, rows: 3 }} />
          </FormController>
        </FormField>
      </FormContainer>
    </Grid>
  )
}

export default DailyRecordSummaryPartA
