import { useMediaQuery, useTheme, Select, MenuItem, Box } from '@mui/material'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '@providers/globalStore'
import { useEffect } from 'react'
import ContractService from '@services/contract.service'
import ProjectService from '@services/project.service'
export const DrawerWidth = 300
import { useTranslation } from 'react-i18next'
import useLocalStorage from '@hooks/useLocalStorage'
import useAPIFetch from '@hooks/useAPIFetch'

const SelectContract = () => {
  const navigate = useNavigate()
  const { state: globalState, dispatch, userInfo } = useContext(GlobalContext)
  const [currentContract, setCurrentContract] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  const { setRequest: setContractListRequest } = useAPIFetch()
  const { setRequest: setProjectListRequest } = useAPIFetch()

  const [contractList, setContractList] = useState<any[]>([])
  const [projectList, setProjectList] = useState<any[]>([])

  const reloadContractList = async () => {
    setContractListRequest({
      callback: async (cancelToken: any) => {
        if (!userInfo?.uuid) return
        const res = await ContractService.GetContractListByUser(userInfo?.uuid)
        if (res) {
          setContractList(res)
        }
      },
    })
  }
  const reloadProjectList = async () => {
    setProjectListRequest({
      callback: async (cancelToken: any) => {
        if (!userInfo?.uuid) return
        const res = await ProjectService.GetProjectListByUser(userInfo?.uuid)
        if (res) {
          setProjectList(res)
        }
      },
    })
  }

  useEffect(() => {
    if (!userInfo?.uuid || userInfo?.uuid === '') return
    reloadContractList()
    reloadProjectList()
  }, [userInfo])
  useEffect(() => {
    if (currentContract) {
      dispatch({
        type: 'changeContract',
        contract: currentContract,
      })
    }
  }, [currentContract, dispatch])
  useEffect(() => {
    if (currentProject) {
      dispatch({
        type: 'changeProject',
        project: currentProject,
      })
    }
  }, [currentProject, dispatch])
  const { t } = useTranslation()
  return (
    <>
      <Box>{t('Select Contract')}</Box>
      <Select
        onChange={(event: any) => {
          let cId = Number(event.target.value)
          let contract = contractList?.find((x) => x.id === cId)
          if (contract) {
            setCurrentContract({ ...contract, contractId: contract.id })
            setCurrentProject({ projectId: null })
          }
          navigate('/') // redirect user back to homepage if contract changed
        }}
        value={currentContract?.contractId}
        sx={{
          fontSize: 12,
          backgroundColor: '#ffffff',
          width: '80%',
          margin: '10px',
          ml: 1,
        }}>
        {(contractList || []).map((c, index) => {
          return (
            <MenuItem
              sx={{
                fontSize: 12,
              }}
              value={c.id}
              key={`form-body-contract-option-${index}`}>
              {`${c.id} - ${c.contractNo}`}
            </MenuItem>
          )
        })}
      </Select>
      <Box>{t('Select Project')}</Box>

      <Select
        onChange={(event: any) => {
          let cId = Number(event.target.value)
          let project = projectList?.find((x) => x.id === cId)
          if (project) {
            setCurrentProject({ ...project, projectId: project.id })
          }
          navigate('/') // redirect user back to homepage if project changed
        }}
        value={currentProject?.id}
        sx={{
          fontSize: 12,
          backgroundColor: '#ffffff',
          width: '80%',
          margin: '10px',
          ml: 1,
        }}>
        {(projectList?.filter((x) => x.contractId === currentContract?.contractId) || []).map(
          (c, index) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                value={c.id}
                key={`form-project-option-${index}`}>
                {`${c.id} - ${c.projectName}`}
              </MenuItem>
            )
          },
        )}
      </Select>
    </>
  )
}

export default SelectContract
