import { forwardRef } from 'react'
import {
  Breakpoint,
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@mui/material'
import { ellipsis as ellipsisFunc } from 'polished'
import { get } from 'lodash'

import { Styles } from '@models/Common'
import { cx } from '@utils/cx'

export type VariantName = Exclude<MUITypographyProps['variant'], undefined>

export type ResponsiveVariant =
  | MUITypographyProps['variant']
  | Partial<{
      [key in Breakpoint]: VariantName | string
    }>
export interface EllipsisConfig {
  width?: null | void | string | null | void | number
  lines?: number
}

export type Transform = 'lowercase' | 'uppercase' | 'capitalize'

export type Decoration = 'overline' | 'line-through' | 'underline'

export type Weight = 'thin' | 'light' | 'regular' | 'medium' | 'bold'

export type WhiteSpace = 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces'

export interface Props extends Omit<MUITypographyProps, 'variant'> {
  variant?: ResponsiveVariant
  transform?: Transform
  italic?: boolean
  ellipsis?: EllipsisConfig | number
  decoration?: Decoration
  whiteSpace?: WhiteSpace
  weight?: Weight
  smallCaps?: boolean
}

const Typography = forwardRef<Props, any>(
  (
    {
      variant = 'body1',
      className,
      sx,
      whiteSpace,
      smallCaps,
      transform,
      decoration,
      ellipsis,
      weight,
      italic,
      ...props
    },
    ref,
  ) => {
    const isResponsiveVariant = variant && typeof variant === 'object'

    const getStylesByBreakpoints = ({ typography, breakpoints }) => {
      const styles: Styles = {}

      if (isResponsiveVariant) {
        const keys = breakpoints.keys.filter((key: string) => Object.keys(variant).includes(key))

        keys.forEach((bp: Breakpoint) => {
          const _variant = variant[bp] as VariantName
          const variantStyles = typography?.[_variant as never]
          Object.keys(variantStyles || {}).forEach((key) => {
            if (styles?.[key]) {
              styles[key][bp] = variantStyles[key]
            } else {
              styles[key] = { [bp]: variantStyles[key] }
            }
          })
        })
      }

      return styles
    }

    const getEllipsisStyles = () => {
      let styles: Styles = {}
      const width = get(ellipsis, 'width', null)
      const lines = get(ellipsis, 'lines', ellipsis || 0)

      if (lines) {
        styles.whiteSpace = ellipsis > 1 ? 'nowrap' : 'pre-line'
        styles = { ...styles, ...ellipsisFunc(width, lines) }
      }

      return styles
    }

    return (
      <MUITypography
        ref={ref}
        variant={isResponsiveVariant ? undefined : variant}
        className={cx(transform, decoration, weight, className, italic && 'italic')}
        sx={[
          getStylesByBreakpoints,
          getEllipsisStyles(),
          { whiteSpace, fontVariant: smallCaps && 'small-caps' },
          ...(sx && Array.isArray(sx) ? sx : []),
          sx && !Array.isArray(sx) ? sx : {},
        ]}
        {...props}
      />
    )
  },
)

Typography.displayName = 'Typography'

export default Typography
