import { SelectChangeEvent } from '@mui/material/Select'
import { Box, Typography, Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { OrganizationListModel } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import SearchPanel from '@components/searchPanel'
import { RiFileTextFill } from 'react-icons/ri'
import { RightsCategory } from '@services/model/form/form.model'

const AdminOrganizationAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  // const [status, setStatus] = useState('true')
  const { hasRightByCatAndCode, userInfo } = useContext(GlobalContext)

  // const handleChangeStatus = (event: SelectChangeEvent) => {
  //   setStatus(event.target.value)
  // }
  const { t } = useTranslation()
  // const navigate = useNavigate()

  const [TableView, reload] = useTableView<OrganizationListModel>({
    headers: [
      {
        key: 'status',
        desc: t('Status'),
        renderCell: (record: OrganizationListModel) => (
          <Fragment>{record.status === true ? t('Active') : t('Disabled')}</Fragment>
        ),
      },
      {
        key: 'company_name',
        desc: t('Company Name'),
        renderCell: (record: OrganizationListModel) => <Fragment>{record.companyName}</Fragment>,
      },
      {
        key: 'subsidiary_name',
        desc: t('Subsidiary Name'),
        renderCell: (record: OrganizationListModel) => <Fragment>{record.subsidiaryName}</Fragment>,
      },
      //todo: confirm if necessary in web-1090
      {
        key: 'business_registration_no',
        desc: t('Business Registration No.'),
        renderCell: (record: OrganizationListModel) => (
          <Fragment>{record.businessRegistrationNo}</Fragment>
        ),
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: OrganizationListModel) {
      NavigateTo(navigate, '/admin/organization/:id', { id: record.uuid })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      let list = await AdminService.GetOrganizationList(
        {
          ...state.filterResult,
          status:
            state.filterResult?.status?.value === 0
              ? { operator: '=', value: false }
              : state.filterResult?.status?.value === 1
              ? { operator: '!=', value: false }
              : null,
          sorting: sorting,
          pagination: pagination,
        },
        cancelToken,
        props.showMyRecord || false,
      )

      return list
    },
  })

  useEffect(() => {
    document.title = t('Admin', { ns: common.i18nFormNS })

    reload()
  }, [])

  // const onClickSideBtnHandler: Function = () => {
  //   NavigateTo(navigate, '/admin/organization')
  // }

  // const LinkTableAddButton = styled(Box)<{
  //   state: {
  //     disableAddButton?: boolean
  //     uneditable?: boolean
  //     color?: string
  //     hoverBackgroundColor?: string
  //     backgroundColor?: string
  //   }
  // }>(({ state }) => ({
  //   width: 50,
  //   height: 50,
  //   display: state.uneditable ? 'none' : 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   borderTopLeftRadius: 10,
  //   borderTopRightRadius: 10,
  //   borderBottomRightRadius: 10,
  //   position: 'relative',
  //   zIndex: 1000,
  //   backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
  //   ...(!state.disableAddButton && {
  //     '&:hover': {
  //       cursor: 'pointer',
  //       backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
  //       '#add-button-hover-text': {
  //         opacity: '100% !important',
  //         width: '200px',
  //         transition: 'opacity 500ms !important',
  //       },
  //     },
  //   }),
  // }))

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('Organizations') : t('Organizations')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            hasRightByCatAndCode(RightsCategory.Organization, ['C']) || userInfo?.canCreateOrgCnt
              ? '/admin/organization'
              : undefined
          }
          // target="_self"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'status',
              desc: 'Status',
              type: 'NumberSelectionFilter',
              disableOperator: true,
              valueList: [
                { key: 1, value: 'Active' },
                { key: 0, value: 'Disabled' },
              ],
            },
            {
              key: 'companyName',
              desc: 'Company Name',
              type: 'StringFilter',
              disableOperator: true,
            },
            {
              key: 'businessRegistrationNo',
              desc: 'Business Registration No.',
              type: 'StringFilter',
              disableOperator: true,
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AdminOrganizationAllRecordsPage
