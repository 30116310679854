import { useState, useEffect, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Button, TextFieldProps, FormHelperText, Box } from '@mui/material'
import { TimePicker as TP, TimePickerProps } from '@mui/x-date-pickers/TimePicker'
import { DesktopTimePicker as DesktopTP } from '@mui/x-date-pickers/DesktopTimePicker'
import { Nil } from '@models/Common'
import { getDateFormatByType } from '@utils/dateTime'
import Typography from '@components/Typography'
import moment from 'moment'
import { TimeFieldProps } from '@mui/x-date-pickers'
import { TimeField as MuiTimeField } from '@mui/x-date-pickers/TimeField'
interface Props extends Omit<TimeFieldProps<Nil<Date>>, 'value' | 'onChange'> {
  variant?: TextFieldProps['variant']
  placeholder?: TextFieldProps['placeholder']
  id?: string
  name?: string
  value?: Date | null | string
  onChange?: (val: Date | null, ...args: any[]) => void

  error?: any
  disabled?: boolean
  uneditable?: boolean
  withCurrent?: boolean
  defaultValue?: any
  disableMobilePicker?: boolean
}

const TimeField = forwardRef(
  (
    {
      variant,
      placeholder,
      value,
      onChange,

      error,
      disabled,
      uneditable,
      withCurrent,
      defaultValue,
      disableMobilePicker,
      sx,
      ...rest
    }: Props,
    ref: any,
  ) => {
    const { t } = useTranslation(['common'])
    const [date, setDate] = useState<Date | null>(
      value ? new Date(value) : defaultValue ? new Date(defaultValue) : null,
    )

    const handleChange = (val: Date, ...args: any[]) => {
      setDate(val || null)
      onChange?.(val, ...args)
    }

    const defaultProps: any = {
      ampm: false,
      value: date,
      disabled,
      uneditable,
      format: getDateFormatByType('time'),
      // views: ['hours', 'minutes'],

      // timeSteps,
      onChange: handleChange,
      inputRef: ref,
      error,
      variant: variant ?? 'outlined',

      slotProps: {
        textField: {
          fullWidth: true,
          variant: variant ?? 'outlined',
          placeholder,
          error,
          sx: {
            // backgroundColor: 'white',

            '.MuiInput-root': {
              flexDirection: 'row-reverse',
            },
          },
          inputProps: {
            sx: { px: 1.5 },
          },
        },
      },
    }

    const getTypographyDisplay = () => {
      if (!value) {
        return ''
      }
      return moment(value).format('HH:mm')
    }

    return (
      <>
        {uneditable ? (
          <Typography sx={{ display: 'flex', alignItems: 'flex-start', ...sx }}>
            {getTypographyDisplay()}
          </Typography>
        ) : (
          <>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="left" spacing={3} sx={sx}>
              <Stack
                direction={{ xs: 'column', md: 'column' }}
                alignItems="left"
                spacing={0}
                sx={sx}>
                <MuiTimeField {...defaultProps} {...rest} />
                <FormHelperText sx={{ textJustify: 'left' }} error={!!error}>
                  {error?.message}
                </FormHelperText>
              </Stack>
              {withCurrent && (
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    color: '#1976d2 !important',
                    borderColor: 'rgba(25, 118, 210, 0.5) !important',
                  }}
                  size="small"
                  variant="outlined"
                  disabled={disabled}
                  onClick={() => handleChange(new Date())}>
                  <Typography component="span" variant="remark" whiteSpace="nowrap" sx={{ px: 1 }}>
                    {t('common:buttons.currentTime')}
                  </Typography>
                </Button>
              )}
            </Stack>
          </>
        )}
      </>
    )
  },
)

TimeField.displayName = 'TimeField'

export default TimeField
