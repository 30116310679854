import FormContainer from '@components/form/container'
import FormField from '@components/form/field'
import { Grid } from '@mui/material'
import BaseButton from '@components/button/BaseButton'
import styles from '@styles/baseButton.module.scss'
import useAPIFetch from '@hooks/useAPIFetch'
import { useEffect, useState } from 'react'
import FormWftService from '@services/formService/form.WFT.service'
import { FormWftGeneralOptionsModel } from '@services/model/form/form.WFT.model'
import { useSnackbar } from 'notistack'
import * as common from '@common/common'
import { useTranslation } from 'react-i18next'
import FormTypography from '@components/form/typography'
import moment from 'moment'
import { DocumentExporter } from '@utils/documentExporter'

const WasteManagementPlanTemplatePartD = ({
  getValues,
  currentContract,
}: {
  getValues: any
  currentContract?: any
}) => {
  let index = 0
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [wftGeneralOptions, setWftGeneralOptions] = useState<FormWftGeneralOptionsModel>()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const reloadWFTGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormWftService.GetWftGeneralOptions().then(async (res) => {
          setWftGeneralOptions(res)
        })
      },
    })
  }
  useEffect(() => {
    reloadWFTGeneralOptions()
    document.title = t('forms:wasteForecastTable.title', { ns: common.i18nFormNS })
  }, [])

  const formatData = (rows, constructionStageList, materialList, wasteManagementMethodList) => {
    const data = rows.map((row) => {
      const constructionStage = constructionStageList.find(
        (item) => item.key === row.constructionStage,
      )
      const material = materialList.find((item) => item.key === row.material)
      const wasteManagementMethod = wasteManagementMethodList.find(
        (item) => item.key === row.wasteManagementMethod,
      )
      row.constructionStartDate = moment(row.constructionStartDate).format('MM/YYYY')
      row.constructionEndDate = moment(row.constructionEndDate).format('MM/YYYY')
      return {
        ...row,
        constructionStage: constructionStage?.value,
        material: material?.value,
        wasteManagementMethod: wasteManagementMethod?.value,
      }
    })
    return data
  }

  const getWftFromIdFromlist = (exportFn: any, data) => {
    setRequest({
      callback: async () => {
        FormWftService.GetWftList(
          {
            pagination: null,
            sorting: null,
            contractNo: {
              Operator: '=',
              Value: [currentContract?.contractId],
            },
          },
          null,
          false,
        ).then((result) => {
          if (result) {
            exportFn!(data, result?.list?.[0]?.formId, result?.list?.[0]?.formStatus)
          } else {
            exportFn!(data, null, null)
          }
        })
      },
    })
  }

  let exportDocx = (data, formId, formStatus) => {
    data.isDemolition = currentContract?.isDemolition ?? false
    setRequest({
      callback: async () => {
        FormWftService.GetWftForm(formId, formStatus)
          .then((result) => {
            data.list = []
            data.list =
              formatData(
                result?.rows,
                wftGeneralOptions?.constructionStage,
                wftGeneralOptions?.material,
                wftGeneralOptions?.wasteManagementMethod,
              ) || []

            data.list = data.list?.map((item) => {
              const newItem = { ...item }
              Object.keys(newItem).forEach((key) => {
                if (newItem[key] === undefined || newItem[key] === null) {
                  newItem[key] = ''
                }
              })
              return newItem
            })
            DocumentExporter({
              templatePath: '/templates/Waste_Management_Plan_Template_v3_docxexporter.docx',
              data: data,
              reportName: 'Waste Management Plan Template',
              returnFormat: 'blob-download',
            })
          })
          .catch((error) => {
            DocumentExporter({
              templatePath: '/templates/Waste_Management_Plan_Template_v3_docxexporter.docx',
              data: data,
              reportName: 'Waste Management Plan Template',
              returnFormat: 'blob-download',
            })
          })
      },
    })
  }

  const handleClickDocx = () => {
    let data = getValues()
    let newData = setNullValuesToFalse(data)

    getWftFromIdFromlist(exportDocx, newData)
  }

  const setNullValuesToFalse = (obj) => {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (obj[property] === null || obj[property] === undefined) {
          obj[property] = false
        } else if (typeof obj[property] === 'object') {
          setNullValuesToFalse(obj[property])
        }
      }
    }
    return obj
  }

  return (
    <Grid sx={{ minHeight: 620 }}>
      <FormContainer fkey={`$wmpt-e`}>
        <FormField fieldName={t('forms:wasteManagementPlan.template.fields.note')} index={index++}>
          <FormTypography
            value={t(
              'forms:wasteManagementPlan.template.fields.templateReferenceMsg',
            )}></FormTypography>
        </FormField>
        <FormField
          fieldName={t('forms:wasteManagementPlan.template.fields.exportAsMSWordDocument')}
          index={index++}
          alignItems={'center'}>
          <BaseButton
            displayText={t('common:buttons.export')}
            disabled={false}
            className={styles.formButton}
            sx={{ width: '200px' }}
            onClickHandler={handleClickDocx}
          />
        </FormField>
      </FormContainer>
    </Grid>
  )
}

export default WasteManagementPlanTemplatePartD
