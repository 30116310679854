import apiGateway from '@utils/axiosInterceptor'
import { BaseFormResponse } from '@services/model/form/form.model'
import { FormDrsItemsModel, FormDrsItemModel } from '@services/model/form/form.DRS.item.model'
import { FormStatusEnum } from '@services/model/form/form.model'

const GetSingleDrsItemForm = async (formId: string) => {
  if (formId === undefined) {
    return
  }
  return await apiGateway.get<FormDrsItemModel>(`api/v1/forms/DRS_ITEM/${formId}`).then((resp) => {
    return resp.data
  })
}

const SaveSingleDrsItemForm = async (req: FormDrsItemModel) => {
  let formStatus = req.baseForm.formStatus
  let formId = req?.baseForm?.formId
  //update existing from
  if (formId) {
    if (!formStatus || formStatus === '') {
      formStatus = FormStatusEnum.DailyRecordSummaryItemDraft
    }
    return await apiGateway
      .put(`api/v1/forms/DRS_ITEM/${req.baseForm.formId}/${formStatus}`, req)
      .then((resp) => {
        return resp.data
      })
  }
  //create new form
  return await apiGateway.post('api/v1/forms/DRS_ITEM', req).then((resp) => {
    return resp.data
  })
}

const DeleteSingleDrsItemForm = async (req: FormDrsItemModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryItemDraft
  }
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/DRS_ITEM/${formStatus}/${req.baseForm.formId}`,
  )
}

const GetDrsItemsList = async (req: any, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post(`/api/v1/forms/DRS_ITEM/list`, req).then((resp) => {
    return resp.data
  })
}

const SaveBatchDrsItems = async (req: FormDrsItemsModel) => {
  let formStatus = req.baseForm.formStatus
  let formId = req?.baseForm?.formId
  if (formId) {
    if (!formStatus || formStatus === '') {
      formStatus = FormStatusEnum.DailyRecordSummaryItemDraft
    }

    return await apiGateway
      .put(`api/v1/forms/DRS_ITEM/${formStatus}/${req.baseForm.formId}`, req)
      .then((resp) => {
        return resp.data
      })
  }

  return await apiGateway.post('api/v1/forms/DRS_ITEM', req).then((resp) => {
    return resp.data
  })
}

const ApproveSingleDrsItemForm = async (req: FormDrsItemModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryItemDraft
  }
  let formId = req?.baseForm?.formId
  if (!formId) {
    formId === ''
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DRS_ITEM/${formId}/approve`, req)
    .then((resp) => {
      return resp.data
    })
}

const BatchApproveDrsItems = async (req: FormDrsItemsModel) => {
  let formStatus = req.baseForm?.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryItemDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/DRS_ITEM/${formStatus}/${req.baseForm.formId}/approve`,
      req,
    )
    .then((resp) => {
      return resp.data
    })
}

const BatchSaveDrsItems = async (req: FormDrsItemsModel) => {
  return await apiGateway.post('api/v1/forms/DRS_ITEM/batch', req).then((resp) => {
    return resp.data
  })
}
const FormDrsItemService = {
  GetSingleDrsItemForm,
  SaveSingleDrsItemForm,
  ApproveSingleDrsItemForm,
  DeleteSingleDrsItemForm,
  GetDrsItemsList,
  SaveBatchDrsItems,
  BatchApproveDrsItems,
  BatchSaveDrsItems,
}

export default FormDrsItemService
