import {
  Typography,
  Collapse,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { FormStatus, FormStatusHistory } from '@services/model/form/form.model'
import { Fragment, useState } from 'react'
import FormDate from './date'
import FormField from './field'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Base64StringToImageBase64 } from '@utils/image'
import FormController from './controller'
import FormSignatureHolder from './signatureHolder'
import { KeyValPair } from '@models/Common'
import { useTranslation } from 'react-i18next'
type formSignatureHistoriesProps = {
  histories: FormStatusHistory[]
  handleOnComplete?: () => void
  control?: any
  endOfFlow?: boolean
  formStatusList?: FormStatus[]
  userMetaList?: KeyValPair[]

  disabled?: boolean
}

/**
 * form signature histories showcasing signatures and related fields from action histories
 * @param props formSignatureHistoriesProps
 * @example
 * ```
 * <FormSignatureHistories histories={form.formStatusHistories} />
 * ```
 */

const regexFromActionCode = (actionCode: string): string => {
  const output = actionCode?.replace(/^FORM_|_/g, (match: string, index: number): string => {
    if (index === 0) {
      return '' // Remove "FORM_"
    } else if (match === '_') {
      return ' ' // Replace "_" with space
    }
    return match // Return the match as-is if none of the conditions match
  })

  const transformedOutput = output?.replace(/\w\S*/g, (match: string) => {
    return match.charAt(0)?.toUpperCase() + match.slice(1)?.toLowerCase()
  })

  return transformedOutput
}

const FormSignatureHistories = (props: formSignatureHistoriesProps) => {
  const [open, setOpen] = useState<boolean[]>(
    props.histories
      ?.sort((a, b) => a.id - b.id)
      ?.map((_: FormStatusHistory, index: number) =>
        index === props.histories.length - 1 ? true : false,
      ) || [],
  )
  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }
  const { t } = useTranslation()
  return (
    <Grid xs={12}>
      <List>
        {props.histories
          ?.sort((a, b) => a.id - b.id)
          ?.map((history: FormStatusHistory, index: number) => (
            <List key={`form-history-${index}-${history.id}`}>
              {index === props.histories.length - 1 && props.endOfFlow ? (
                <Box
                  sx={{
                    color: 'inherit',
                    display: 'flex',
                    width: '100%',
                    padding: '8px 16px',
                    border: '1px solid #c9c9c9',
                    alignItems: 'center',
                  }}>
                  <ListItemIcon>
                    <BookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      history.actionCode
                        ? t(history.actionCode)
                        : t(
                            props.formStatusList?.find((x) => x.id === history.formStatusId)
                              ?.actionCode || '',
                          )
                    }
                  />
                </Box>
              ) : (
                <Fragment>
                  <ListItemButton
                    onClick={(_: any) => {
                      handleOpen(index)
                    }}>
                    <ListItemIcon>
                      <BookIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        t(history.actionCode)
                          ? history.actionCode
                          : t(
                              props.formStatusList?.find((x) => x.id === history.formStatusId)
                                ?.actionCode || '',
                            )
                      }
                    />
                    {open[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open[index]}>
                    {index === props.histories.sort((a, b) => a.id - b.id)?.length - 1 ? (
                      props.endOfFlow ? (
                        <></>
                      ) : (
                        <Fragment>
                          <FormField fieldName={t('common:baseForm.submittedBy')}>
                            <Typography>
                              {props.userMetaList?.find((x) => x.key === history.actionBy)?.value}
                            </Typography>
                          </FormField>

                          <FormField fieldName={t('forms:components.formSwitcher.signature')}>
                            <FormController
                              controllerProps={{
                                name: 'signatureBase64',
                                control: props.control,
                              }}>
                              <FormSignatureHolder
                                onClick={() => {
                                  if (props.handleOnComplete && !props.disabled) {
                                    props.handleOnComplete()
                                  }
                                }}
                                disabled={props.disabled}
                              />
                            </FormController>
                          </FormField>

                          <FormField fieldName={t('forms:components.formSwitcher.dateSignOff')}>
                            <FormDate value={history.actionAt} visable={false} />
                          </FormField>
                        </Fragment>
                      )
                    ) : (
                      <Fragment>
                        <FormField fieldName={t('common:baseForm.submittedBy')}>
                          <Typography>
                            {props.userMetaList?.find((x) => x.key === history.actionBy)?.value}
                          </Typography>
                        </FormField>
                        {history.actionSignatureBase64 && (
                          <FormField fieldName={t('forms:components.formSwitcher.signature')}>
                            <Box
                              sx={{
                                width: 300,
                                height: 150,
                              }}>
                              <Box
                                component="img"
                                alt={`action-history-${index}-signature`}
                                src={Base64StringToImageBase64(
                                  history.actionSignatureBase64,
                                  'jpeg',
                                )}
                              />
                            </Box>
                          </FormField>
                        )}

                        <FormField fieldName={t('forms:components.formSwitcher.dateSignOff')}>
                          <FormDate value={history.actionAt} visable />
                        </FormField>
                      </Fragment>
                    )}
                  </Collapse>
                </Fragment>
              )}
            </List>
          ))}
      </List>
    </Grid>
  )
}

export default FormSignatureHistories
