import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

const ButtonBase = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  boxShadow: '0px 3px 6px #00000029',
  padding: '10px 15px',
  borderRadius: '5px',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#f2f2f2',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

declare type ButtonBaseOnClickPromise = (event: any) => Promise<void>
declare type ButtonBaseOnClick = (event: any) => void

export type BaseButtonProps = {
  id?: string
  onClick?: ButtonBaseOnClickPromise | ButtonBaseOnClick
  sx?: Record<string, any>
  children?: ReactNode | string

  disabled?: boolean
}

export default function DialogButton(props: BaseButtonProps) {
  const { t } = useTranslation()

  return (
    <ButtonBase
      id={props.id}
      sx={{ ...props.sx }}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </ButtonBase>
  )
}
