import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import MrPartA from './PartA'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import useLocalStorage from '@hooks/useLocalStorage'
import FormMrService from '@services/formService/form.MR.service'
import useAPIFetch from '@hooks/useAPIFetch'
import _ from 'lodash'
import getErrMsg from '@utils/getErrMsg'
import { FormMrModel, InitMrForm } from '@services/model/form/form.MR.upload.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const WasteManagementPlanFormPage = () => {
  const UUID = uuid()
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const cat = QueryStepParser(query.get('cat'))

  const { control, setValue, getValues, reset, register, trigger } = useForm<FormMrModel>({
    defaultValues: { ...InitMrForm },
  })
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )
  const [formStatus, formPermission] = useWatch({
    name: ['baseForm.formStatus', 'baseForm.formPermission'],
    control: control,
  })
  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    setValue('submissionComment', submissionComment)
    const values = getValues()

    setRequest({
      callback: async () => {
        await FormMrService.SaveMrForm(values).then((resp) => {
          if (resp) {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/monthly-report/all-records')
            formId = resp.formId
            if (formId) reload(formId)
          }
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
        })
      },
    })
  }

  const handleOnSave = async () => {
    let values = getValues()

    setRequest({
      callback: async () => {
        await FormMrService.SaveMrForm(values).then((resp) => {
          if (resp) {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/monthly-report/:id', {
              id: resp.formId,
            })
            formId = resp.formId
            if (formId) reload(formId)
          }
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
        })
      },
    })
  }

  // handleOnDelete Todo: already set up, issue: log not working yet due to canDelete Flag is always set to false
  const handleOnDelete = async () => {}

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formStatus])

  useEffect(() => {
    if (formStateContext.form && formStateContext.formType === 'MR') {
      formStateAction({ type: 'pop', targetFormType: 'MR' })
      reset({ ...formStateContext.form })
    } else if (formId && !isMounted.current) {
      reload(formId)
    } else if (currentContract?.contractId! && currentProject?.projectId! && !formId) {
      reset({
        ...InitMrForm,
        baseForm: {
          ...InitMrForm.baseForm,
          contractNoId: currentContract?.contractId,
          contractNo: currentContract?.contractNo,
          contractName: currentContract?.contractName,
          projectId: currentProject?.projectId,
          projectType: currentContract?.projectType,
        },
      })
    }
    return () => {
      isMounted.current = false
    }
  }, [formId, currentContract])

  const reload = async (formId: string) => {
    if (formId) {
      setRequest({
        callback: async () => {
          const list: Array<any> = []
          await FormMrService.GetMrForm(formId, formStatus)
            .then(async (res) => {
              if (res) {
                let newRes = {
                  ...res,
                  baseForm: {
                    ...res.baseForm,
                  },
                }
                reset(newRes)
                globalAction({
                  type: 'changeFormStatus',
                  formStatus: res.baseForm.formStatusName,
                })
                isMounted.current = true
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        },
      })
    }
  }

  const partA = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteManagementPlan.sections.general.title'),
      component: (
        <MrPartA
          currentContract={currentContract}
          control={control}
          formPermission={formPermission}
          formId={formId}
        />
      ),
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('forms:monthlyReport.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }
  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'MR',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:monthlyReport.title'),
    components: [partA(), partB()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableComment: getValues('baseForm.formId') ? false : true,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate,
    disableSaveBtn: true,
    disableDelete: true,
    approveRequired: formPermission?.approveRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}
export default WasteManagementPlanFormPage
