export type CustomError = {
  id: string
  message: string
}

const getErrMsg = (t: any, err: any) => {
  let errMsg: any
  if (err.response) {
    if (err.response.status) {
      switch (err.response.status) {
        case 401:
          errMsg = t('common:messages.permissionDenied')
          break
        case 400:
          errMsg = t((err.response.data as CustomError)?.message)
          break
        case 403:
          errMsg = t((err.response.data as CustomError)?.message)
          break
        case 500:
          errMsg = t('common:messages.internalServerErr')
          break
        case 504:
          errMsg = t('common:messages.serverTimeOutErr')
          break
        default:
          errMsg = err.response.data?.message
            ? t((err.response.data as CustomError).message)
            : t('common:messages.unknownError') +
              `${err.status ? t('status code: ') + err.status : ''}`
          break
      }
    } else errMsg = t('common:messages.unknownError')
  } else {
    errMsg = t('common:messages.unknownError')
  }
  return errMsg
}

export default getErrMsg
