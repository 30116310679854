import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import react from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { KeyValPair } from '@models/Common'
import FormMultipleSelect from '@components/form/multipleSelect'
import { QueryStepParser } from '@utils/queryStepParser'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import {
  InitOrganizationForm,
  OrganizationFormModel,
  EnrolmentStatusEnum,
  hasWorkRightsRoles,
} from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import FormBinarySelect from '@components/form/binarySelect'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import getErrMsg from '@utils/getErrMsg'
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import {
  GridCellParams,
  GridColDef,
  GridFilterOperator,
  GridRenderCellParams,
} from '@mui/x-data-grid'

import FormDataGrid from '@components/form/datagrid'
import BaseButton from '@components/button/BaseButton'
import styles from '@styles/baseButton.module.scss'
import Tabs from '@components/Tabs'
import { RolesEnum } from '@services/model/user.model'
import cleanString from '@utils/cleanString'

const UUID = uuid()

export type DialogContextProps = {
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const AdminOrganizationFormPage = () => {
  const { hasRightByCatAndCode } = useContext(GlobalContext)

  // let readonly = hasRightByCatAndCode(RightsCategory.Organization, ['C', 'U']) ? false : true
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<OrganizationFormModel>({
    defaultValues: { ...InitOrganizationForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()

  const userListRef = useRef<KeyValPair[]>([])
  const [organizationMeta, setOrganizationMeta] = useState<any>([])

  const rows: any = []
  const [activeUsersData, setActiveUsersData] = useState<any>(rows)
  const [inactiveUsersData, setInactiveUsersData] = useState<any>(rows)
  const [pendingInvitationUsersData, setPendingInvitationUsersData] = useState<any>(rows)
  const [pendingApprovalUsersData, setPendingApprovalUsersData] = useState<any>(rows)
  const [activeProjectsData, setActiveProjectsData] = useState<any>(rows)
  const [inactiveProjectsData, setInactiveProjectsData] = useState<any>(rows)
  const [activeContractsData, setActiveContractsData] = useState<any>(rows)
  const [inactiveContractsData, setInactiveContractsData] = useState<any>(rows)

  let readonly = userInfo?.canInviteUser || userInfo?.allRights ? false : true

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await AdminService.GetOrganizationForm(formId)
            .then(async (res) => {
              if (res) {
                reset(res)
                const contracts = res?.contracts ?? []
                setActiveContractsData(contracts.filter((x) => x.status === true))
                setInactiveContractsData(contracts.filter((x) => x.status === false))
                const flattenedProjects: any = []
                for (const contract of contracts) {
                  for (const project of contract.projects) {
                    flattenedProjects.push({
                      contractId: contract.id,
                      contractName: contract.contractName,
                      ...project,
                    })
                  }
                }
                setActiveProjectsData(flattenedProjects.filter((x) => x.status === true))
                setInactiveProjectsData(flattenedProjects.filter((x) => x.status === false))

                const users = res?.users ?? []
                // setActiveUsersData(users.filter((x) => x.enrolment === EnrolmentStatusEnum.ACTIVE))
                // setInactiveUsersData(
                //   users.filter(
                //     (x) => x.enrolment === EnrolmentStatusEnum.DISABLED || x.status === false,
                //   ),
                // )
                // setPendingInvitationUsersData(
                //   users.filter((x) => x.enrolment === EnrolmentStatusEnum.PENDING_INVITATION),
                // )
                // setPendingApprovalUsersData(
                //   users.filter((x) => x.enrolment === EnrolmentStatusEnum.PENDING_APPROVAL),
                // )
                // setActiveUsersData(
                //   users.filter(
                //     (x) =>
                //       x.enrolment ===
                //       globalState?.enrolmentStatusList?.find(
                //         (enrolStatus) =>
                //           cleanString(enrolStatus.value) ===
                //           cleanString(EnrolmentStatusEnum.active),
                //       )?.key,
                //   ),
                // )
                setActiveUsersData(users)
                setInactiveUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                        globalState?.enrolmentStatusList?.find(
                          (enrolStatus) =>
                            cleanString(enrolStatus.value) ===
                            cleanString(EnrolmentStatusEnum.disabled)?.key,
                        ) || x.status === false,
                  ),
                )
                setPendingInvitationUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                      globalState?.enrolmentStatusList?.find(
                        (enrolStatus) =>
                          cleanString(enrolStatus.value) ===
                          cleanString(EnrolmentStatusEnum.pendingInvitation)?.key,
                      ),
                  ),
                )
                setPendingApprovalUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                      globalState?.enrolmentStatusList?.find(
                        (enrolStatus) =>
                          cleanString(enrolStatus.value) ===
                          cleanString(EnrolmentStatusEnum.pendingApproval)?.key,
                      ),
                  ),
                )
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
              // NavigateTo(navigate, '/admin')
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (formId == null) {
      reset({ ...InitOrganizationForm })
    }

    reload()
    reloadOthers()
  }, [formId]) //activeUsersData

  useEffect(() => {
    reloadOthers()
  }, [activeUsersData])

  const reloadOthers = async () => {
    const list = await AdminService.GetOrganizationMeta()

    if (list) {
      const [_, ...newList] = list
      let organizationList =
        newList?.map((x) => {
          return {
            key: x.id,
            value: x.name,
          }
        }) || []

      setOrganizationMeta(organizationList)
    }

    isMounted.current = true
  }

  // const matchedContract = eiGeneralOptions?.contractNo?.find(
  //   (contractNo) => contractNo.id === contractNoId,
  // )

  const handleOnSubmit = async (event: any) => {
    const values = getValues()
    if (values.status === undefined) {
      values.status = true
    }
    values.organizationTypeId = 2
    setRequest({
      callback: async () => {
        await AdminService.SaveCreateOrganization(values).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
          })
          NavigateTo(navigate, '/admin/organization/all-records')
        })
      },
    })
  }

  let index = 0
  let title = 'Add New Organization' //TODO: form switcher remove A/ if only one part
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleNext = async (event: any) => {
    NavigateTo(navigate, '/admin') // TODO: submit form
  }
  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin/organization/all-records')
  }

  const handleInviteUser = async (event: any) => {
    const { inviteUser: cicUserProfile } = getValues()
    const { roles, contractId, projectId, organizationId, ...ciamUserProfile } = cicUserProfile!
    if (cicUserProfile) {
      cicUserProfile.organizationId = formId
      await AdminService.InviteUser(cicUserProfile)
        .then(async (res) => {
          if (res) {
            await handeCiamInviteUser(ciamUserProfile, res.userId)
            enqueueSnackbar(t('User Invited'), {
              variant: 'success',
            })
          }
        })
        .catch(async (err) => {
          if (err.response?.data?.code === 450 || err.response?.data?.code === 404) {
            await handeCiamInviteUser(ciamUserProfile, err.response?.data?.userId) // wait for BE fixing useId missing bug for error 450
            enqueueSnackbar(t(err.response?.data?.message), {
              variant: 'error',
            })
          } else {
            enqueueSnackbar(getErrMsg(t, err), {
              // wait for fixing enrolment bug
              variant: 'error',
            })
          }
        })
    }
  }

  const handeCiamInviteUser = async (ciamUserProfile: any, userId: string) => {
    await AdminService.CiamGetUserProfile(ciamUserProfile?.email ?? '')
      .then(async (res) => {
        if (res?.id) {
          await AdminService.CiamAddUserToGroup(res.id).catch(async (err) => {
            if (err) {
              enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                variant: 'success',
              })

              await AdminService.CiamAddUserToGroup(res.id).catch(async (err) => {
                if (err && !err.response) {
                  await handleEditCiamInfo(userId, res.id)
                    .then(async (err) => {
                      enqueueSnackbar(t('Successfully add Ciam User Id to the Database'), {
                        variant: 'success',
                      })
                    })
                    .catch(async (err) => {
                      enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                        variant: 'error',
                      })
                    })
                  enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                    variant: 'success',
                  })
                }
              })
            }
          })
        } else {
          console.log('no res.id but success handeCiamInviteUser')
          ciamUserProfile.login = ciamUserProfile.email
          ciamUserProfile.displayName = ciamUserProfile.firstName + ' ' + ciamUserProfile.lastName
          await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
            .then(async (res) => {
              if (res) {
                const ciamUserId = res.id
                await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                  if (err && !err.response) {
                    await handleEditCiamInfo(userId, ciamUserId)
                      .then(async (err) => {
                        enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                          variant: 'success',
                        })
                      })
                      .catch(async (err) => {
                        enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                          variant: 'error',
                        })
                      })
                    enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                      variant: 'success',
                    })
                  }
                })
              }
            })
            .catch((err) => {
              if (err.response?.data) {
                enqueueSnackbar(
                  err.response?.data?.errorSummary ?? 'Unknown Okta Error Creating User',
                  {
                    variant: 'error',
                  },
                )
              }
            })
        }
      })
      .catch(async (err) => {
        console.log('in catch', err)

        // no user found, create user
        ciamUserProfile.login = ciamUserProfile.email
        ciamUserProfile.displayName = ciamUserProfile.firstName + ' ' + ciamUserProfile.lastName
        await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
          .then(async (res) => {
            if (res) {
              const ciamUserId = res.id
              await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                if (err && !err.response) {
                  await handleEditCiamInfo(userId, ciamUserId)
                    .then(async (err) => {
                      enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                        variant: 'success',
                      })
                    })
                    .catch(async (err) => {
                      enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                        variant: 'error',
                      })
                    })
                  enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                    variant: 'success',
                  })
                }
              })
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              enqueueSnackbar(
                err.response?.data?.errorSummary ?? 'Unknown Okta Error Creating User',
                {
                  variant: 'error',
                },
              )
            }
          })
      })
  }

  const handleEditCiamInfo = async (userId: string, email: string) => {
    await AdminService.EditUserCiamInfo(userId, email)
      .then((res) => {
        enqueueSnackbar('Successfully update Ciam User Id', {
          variant: 'success',
        })
      })
      .catch((err) => {
        enqueueSnackbar(getErrMsg(t, err), {
          variant: 'error',
        })
      })
  }

  function findTreeNode(key: number, tree: any[]): any {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.key === key) {
        return node
      } else if (node.children) {
        const childNode = findTreeNode(key, node.children)
        if (childNode) {
          return childNode
        }
      }
    }
    return null
  }

  function getRoleName(key: number, treeData): string {
    const node = findTreeNode(key, treeData)
    return node ? node.value : ''
  }

  function ArrayRenderer(params: GridCellParams) {
    const arrayValue = params.value as number[]
    const roleNames = arrayValue.map((value) => `${getRoleName(value, globalState.roleMetaList)}`)
    const roleNameString = roleNames.join(', ')
    return <div>{roleNameString}</div>
  }

  const RoleDropdownOperator: GridFilterOperator = {
    label: 'Role Name',
    value: 'role-dropdown',
    getApplyFilterFn: (filterItem) => {
      const roleId = filterItem.value as number

      return (params) => {
        const roleIds = params.value as number[]
        return roleId === 0 || !roleId || roleIds.includes(roleId)
      }
    },
    InputComponent: ({ item, applyValue, ...rest }) => {
      const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

      const handleRoleSelect = (event) => {
        const roleId = event.target.value
        setSelectedRoleId(roleId)
        applyValue({ ...item, value: roleId })
      }

      return (
        <FormControl sx={{ minWidth: 120 }} variant={'standard'}>
          <InputLabel id="role-select-label">Role Name</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRoleId}
            onChange={handleRoleSelect}
            {...rest}>
            <MenuItem value={0}>{t('Select')}</MenuItem>
            {globalState.roleMetaList?.map((node) => (
              <MenuItem key={node.key} value={node.key}>
                {t(node.value as string)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('Full Name'),
      width: 200,
      editable: false,
    },
    {
      field: 'roles',
      headerName: t('Roles'),
      width: 110,
      editable: false,
      renderCell: ArrayRenderer,
      filterOperators: [RoleDropdownOperator],
    },
    {
      field: 'email',
      headerName: t('Email'),
      width: 250,
      editable: false,
    },
    {
      field: 'uuid',
      headerName: t('Link to Edit User'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/user/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to User')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const contractColumns: GridColDef[] = [
    {
      field: 'contractName',
      headerName: t('Contract Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'contractNo',
      headerName: t('Contract No.'),
      width: 150,
      editable: false,
    },

    {
      field: 'id',
      headerName: t('Link to Edit Contract'),

      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/contract/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Contract')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const projectColumns: GridColDef[] = [
    {
      field: 'contractName',
      headerName: t('Contract Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'projectName',
      headerName: t('Project Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'id',
      headerName: t('Link to Edit Project'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/project/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Project')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  type ListComponentProps = {
    status: 'active' | 'inactive' | 'invited'
    dataType: 'User' | 'Project' | 'Organization' | 'Contract'
    control: any
    readonly: boolean
    data: any[]
    columns: GridColDef[]
    rowId: string
  }

  const ListComponent = (props: ListComponentProps) => {
    const { status, control, readonly, data, dataType, columns, rowId } = props

    const controlProps = {
      name: `${status}${dataType}List`,
      control,
    }

    return (
      <FormController controllerProps={controlProps}>
        <FormDataGrid
          getRowId={(row: any) => row[rowId]}
          rows={data}
          columns={columns}
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          uneditable={readonly}
          checkboxSelection={false}
        />
      </FormController>
    )
  }

  const activeUserList = (
    <ListComponent
      status="active"
      dataType="User"
      control={control}
      readonly={readonly}
      data={activeUsersData}
      columns={columns}
      rowId={'uuid'}
    />
  )

  const inactiveUserList = (
    <ListComponent
      status="inactive"
      dataType="User"
      control={control}
      readonly={readonly}
      data={inactiveUsersData}
      columns={columns}
      rowId={'uuid'}
    />
  )

  const pendingInvitationUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingInvitationUsersData}
      columns={columns}
      rowId={'uuid'}
    />
  )

  const pendingApprovalUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingApprovalUsersData}
      columns={columns}
      rowId={'uuid'}
    />
  )

  const activeProjectList = (
    <ListComponent
      status="active"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={activeProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )

  const inactiveProjectList = (
    <ListComponent
      status="inactive"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={inactiveProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )

  const activeContractList = (
    <ListComponent
      status="active"
      dataType="Contract"
      control={control}
      readonly={readonly}
      data={activeContractsData}
      columns={contractColumns}
      rowId={'id'}
    />
  )

  const inactiveContractList = (
    <ListComponent
      status="inactive"
      dataType="Contract"
      control={control}
      readonly={readonly}
      data={inactiveContractsData}
      columns={contractColumns}
      rowId={'id'}
    />
  )

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <StyledBox>
          <Typography
            variant={matchDownMd ? 'h5' : 'h4'}
            sx={{
              fontWeight: 'bold',
              margin: matchDownMd ? theme.spacing(2) : theme.spacing(5),
            }}>
            {!formId ? t('Add New Organization') : t('Edit Organization')}
          </Typography>

          <FormContainer fkey={`${UUID}-ei-a`}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h5'}
              sx={{
                fontWeight: 'bold',
                margin: matchDownMd ? theme.spacing(2) : theme.spacing(5),
                marginLeft: matchDownMd ? theme.spacing(2) : theme.spacing(0),
                marginRight: matchDownMd ? theme.spacing(2) : theme.spacing(0),
              }}>
              {t('Part A / Organization Information')}
            </Typography>
            <FormField fieldName="Company Name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'companyName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Subsidiary name (if applicable)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'subsidiaryName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Organization Name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'companyName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={true} />
              </FormController>
              &nbsp;
              <FormController
                controllerProps={{
                  name: 'subsidiaryName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={true} />
              </FormController>
            </FormField>

            <FormField fieldName="Business registration no." index={index++}>
              <FormController
                controllerProps={{
                  name: 'businessRegistrationNo',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            {!!formId && (
              <>
                {' '}
                <FormField fieldName="Assigned Projects" index={index++}>
                  <Box sx={{ height: 540, width: '100%' }}>
                    {
                      <Tabs
                        data={[
                          { content: activeProjectList, menuTitle: t('Active'), title: 'Active' },
                          {
                            content: inactiveProjectList,
                            menuTitle: t('Inactive'),
                            title: 'Inactive',
                          },
                        ]}
                      />
                    }
                  </Box>
                </FormField>
                <FormField fieldName="Assigned Contracts" index={index++}>
                  <Box sx={{ height: 540, width: '100%' }}>
                    {
                      <Tabs
                        data={[
                          { content: activeContractList, menuTitle: t('Active'), title: 'Active' },
                          {
                            content: inactiveContractList,
                            menuTitle: t('Inactive'),
                            title: 'Inactive',
                          },
                        ]}
                      />
                    }
                  </Box>
                </FormField>
                <FormField fieldName="Assigned Users" index={index++}>
                  <Box sx={{ height: 540, width: '100%' }}>
                    {activeUserList}
                    {/* {
                      <Tabs
                        data={[
                          { content: activeUserList, menuTitle: 'Active', title: 'Active' },
                          { content: inactiveUserList, menuTitle: 'Disabled', title: 'Disabled' },
                          {
                            content: pendingInvitationUserList,
                            menuTitle: 'Pending Invitation',
                            title: 'Pending Invitation',
                          },
                          {
                            content: pendingApprovalUserList,
                            menuTitle: 'Pending Approval',
                            title: 'Pending Approval',
                          },
                        ]}
                      />
                    } */}
                  </Box>
                </FormField>
              </>
            )}
          </FormContainer>

          <FormContainer fkey={`${UUID}-admin-d`}>
            {formId ? (
              <>
                <Typography
                  variant={matchDownMd ? 'h6' : 'h5'}
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(4),
                  }}>
                  {t('Organization Status')}
                </Typography>
                <FormField fieldName="Change Organization Status" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'status',
                      control,
                    }}>
                    <FormBinarySelect
                      binaryOptionNames={{ yes: t('Active'), no: t('Disabled') }}
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
              </>
            ) : (
              <></>
            )}
          </FormContainer>
          {!!formId && (userInfo?.canInviteUser || userInfo?.allRights) && (
            <Box sx={{ minHeight: '600px' }}>
              <FormContainer fkey={`${UUID}-contract-invite-user`}>
                <Typography
                  variant={matchDownMd ? 'h6' : 'h5'}
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(4),
                  }}>
                  {t('Invite User')}
                </Typography>
                <FormField fieldName="Email" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'inviteUser.email',
                      control,
                    }}>
                    <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                  </FormController>
                </FormField>
                <FormField fieldName="First Name" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'inviteUser.firstName',
                      control,
                    }}>
                    <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                  </FormController>
                </FormField>
                <FormField fieldName="Last Name" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'inviteUser.lastName',
                      control,
                    }}>
                    <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                  </FormController>
                </FormField>
                <FormField fieldName="Role" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'inviteUser.roles',
                      control,
                    }}>
                    <FormMultipleSelect
                      options={
                        globalState.roleMetaList?.filter((role) =>
                          hasWorkRightsRoles?.includes(role.value),
                        ) || []
                      }
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="" index={index++} removeColon>
                  <BaseButton
                    displayText="Invite"
                    disabled={false}
                    className={styles.formButton}
                    sx={{ width: '200px', marginLeft: '10px' }}
                    onClickHandler={handleInviteUser}
                  />
                </FormField>
              </FormContainer>
            </Box>
          )}
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!formId ? t('common:buttons.back') : t('common:buttons.back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleOnSubmit}
                disabled={false}>
                {!formId ? t('common:buttons.submit') : t('common:buttons.save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
    </Fragment>
  )
}
export default AdminOrganizationFormPage
