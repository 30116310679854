import FormContainer from '@components/form/container'
import { FormWftModel } from '@services/model/form/form.WFT.model'
import { Control } from 'react-hook-form'
import FormTable from '@components/form/table/Table'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { FormWftGeneralOptionsModel } from '@services/model/form/form.WFT.model'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormTypography from '@components/form/typography'
import testIsMobile from '@utils/testIsMobile'

const WasteForecastTableFormPartB = ({
  globalState,
  getValues,
  useWatch,
  setValue,
  setError,
  clearErrors,
  control,
  formPermission,
  wftGeneralOptions,
  editable,
}: {
  globalState: any
  getValues: any
  setError: any
  clearErrors: any
  useWatch: any
  setValue: any
  control: Control<FormWftModel>
  formPermission: any
  wftGeneralOptions: FormWftGeneralOptionsModel | undefined
  editable?: boolean
}) => {
  const cicFormTableTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: '68px !important',
          },
          icon: {
            width: '40px',
            height: '90%',
            top: 'inherit',
            background: ' #FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '0px 5px 5px 0px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })
  const uneditable = !formPermission?.canUpdate && !editable
  let index = 0
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const defaultWidth = '150px'
  const defaultMinHeight = '80px'
  const { t } = useTranslation()
  const wftGeneralOptionsMemo = useMemo(() => wftGeneralOptions, [wftGeneralOptions])
  const isMobile = testIsMobile()
  const HandleChangeOptions = (event: any, datumIndex: any) => {
    const wasteManagementMethod = getValues(`rows.${datumIndex}.wasteManagementMethod`)
    const material = getValues(`rows.${datumIndex}.material`)
    if (wasteManagementMethod && material) {
      setValue(
        `rows.${datumIndex}.shortTips`,
        wftGeneralOptions?.wasteManagementTipShort?.[material]?.[wasteManagementMethod],
      )
      setValue(
        `rows.${datumIndex}.tips`,
        wftGeneralOptions?.wasteManagementTip?.[material]?.[wasteManagementMethod],
      )
    }
  }

  const handleDuplicateCheck = (event: any, datumIndex: any) => {
    const estimatedQuantityWeight = getValues(`rows.${datumIndex}.estimatedQuantityWeight`)
    const estimatedQuantityVolume = getValues(`rows.${datumIndex}.estimatedQuantityVolume`)
    if (estimatedQuantityWeight && estimatedQuantityVolume) {
      setDuplicateInputErr(datumIndex, true)
    } else {
      setDuplicateInputErr(datumIndex, false)
    }
  }

  const setDuplicateInputErr = (datumIndex: number, error: boolean) => {
    if (error) {
      setError(`rows.${datumIndex}.estimatedQuantityVolume`, {
        type: 'manual',
        message: t('forms:wasteForecastTable.fields.duplicateInputErr'),
      })
      setError(`rows.${datumIndex}.estimatedQuantityWeight`, {
        type: 'manual',
        message: t('forms:wasteForecastTable.fields.duplicateInputErr'),
      })
    } else {
      clearErrors(`rows.${datumIndex}.estimatedQuantityVolume`)
      clearErrors(`rows.${datumIndex}.estimatedQuantityWeight`)
    }
  }
  let formRemarks = (
    <Box>
      <div>{t('forms:wasteForecastTable.fields.referenceMsg')}</div>
      <ol>
        <li>
          {t('forms:wasteForecastTable.fields.referenceLink1')}
          <a
            href="https://susproc.jrc.ec.europa.eu/product-bureau/sites/default/files/inline-files/WasteManagementBEMP.pdf"
            target="_blank">
            https://susproc.jrc.ec.europa.eu/product-bureau/sites/default/files/inline-files/WasteManagementBEMP.pdf
          </a>
        </li>
        <li>
          {t('forms:wasteForecastTable.fields.referenceLink2')}
          <a
            href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.beamsociety.org.hk%2Ffiles%2Fdownload%2FIDCM_8_IDCM-08-1_Form_v01.xlsm&wdOrigin=BROWSELINK"
            target="_blank">
            IDCM_8_IDCM-08-1_Form_v01.xlsm (live.com)
          </a>
        </li>
        <li>
          {t('forms:wasteForecastTable.fields.referenceLink3')}
          <a
            href="https://zerowastescotland.org.uk/sites/default/files/Maximising%20Re-use%20of%20materials%20on-site_0.pdf"
            target="_blank">
            Maximising Re-use of materials on-site_0.pdf (zerowastescotland.org.uk)
          </a>
        </li>
      </ol>
    </Box>
  )

  let defaultVariant = isMobile ? { variant: 'standard' } : {}

  return (
    <Box key={`wft-b`}>
      <ThemeProvider theme={cicFormTableTheme}>
        <FormTable
          isMobile={isMobile}
          useWatch={useWatch}
          getValues={getValues}
          setValue={setValue}
          removeOverflow={true}
          key={`${222}${index}`}
          uneditable={uneditable}
          columns={[
            {
              field: 'constructionStage',
              displayName: t('forms:wasteForecastTable.fields.constructionStage'),
              type: 'select',
              options: wftGeneralOptionsMemo?.constructionStage,
              minHeight: defaultMinHeight,
              width: 200,
            },
            {
              field: 'otherConstructionStage',
              displayName: t('forms:wasteForecastTable.fields.otherConstructionStage'),
              type: 'string',
              minHeight: defaultMinHeight,
              width: 200,
              disableCallback: (datumIndex: any) => {
                return getValues(`rows.${datumIndex}.constructionStage`) !== 4
              },
            },
            {
              field: 'constructionStartDate',
              displayName: t('forms:wasteForecastTable.fields.constructionStartDate'),
              type: 'month',
              minHeight: defaultMinHeight,
              width: 200,
              dateFormat: 'MM/YYYY',
              fieldProps: isMobile
                ? {}
                : {
                    ...defaultVariant,
                    slotProps: {
                      textField: {
                        sx: { backgroundColor: 'white' },
                        inputProps: {
                          sx: {
                            py: '30px',
                            fontSize: 12,
                          },
                        },
                      },
                    },
                  },
            },
            {
              field: 'constructionEndDate',
              displayName: t('forms:wasteForecastTable.fields.constructionEndDate'),
              type: 'month',

              minHeight: defaultMinHeight,
              width: 200,
              dateFormat: 'MM/YYYY',
              fieldProps: isMobile
                ? {}
                : {
                    ...defaultVariant,
                    slotProps: {
                      textField: {
                        sx: { backgroundColor: 'white' },
                        inputProps: {
                          sx: {
                            py: '30px',
                            fontSize: 12,
                          },
                        },
                      },
                    },
                  },
            },
            {
              field: 'material',
              displayName: t('forms:wasteForecastTable.fields.material'),
              type: 'select',
              options: wftGeneralOptionsMemo?.material,
              minHeight: defaultMinHeight,
              width: 350,
              additionalOnChangeAction: HandleChangeOptions,
            },
            {
              field: 'estimatedQuantityVolume',
              displayName: t('forms:wasteForecastTable.fields.estimatedQuantityVolume'),
              type: 'number',
              minHeight: defaultMinHeight,
              width: defaultWidth,
              rows: 2,
              additionalOnChangeAction: handleDuplicateCheck,
            },
            {
              field: 'estimatedQuantityWeight',
              displayName: t('forms:wasteForecastTable.fields.estimatedQuantityWeight'),
              type: 'number',
              minHeight: defaultMinHeight,
              width: defaultWidth,
              rows: 2,
              additionalOnChangeAction: handleDuplicateCheck,
            },
            {
              field: 'wasteManagementMethod',
              displayName: t('forms:wasteForecastTable.fields.wasteManagementMethod'),
              type: 'select',
              options: wftGeneralOptionsMemo?.wasteManagementMethod,
              minHeight: defaultMinHeight,
              width: 400,
              additionalOnChangeAction: HandleChangeOptions,
            },
            {
              field: 'remarks',
              displayName: t('forms:wasteForecastTable.fields.remarks'),
              type: 'string',
              minHeight: defaultMinHeight,
              width: 200,
              rows: 2,
            },
            {
              field: 'shortTips',
              displayName: t('forms:wasteForecastTable.fields.shortTips'),
              type: 'string',
              minHeight: defaultMinHeight,
              width: 200,
              rows: 2,
              uneditable: true,
              tooltip: 'tips',
              getTooltip({ rowData }) {
                return t(rowData?.tips)
              },
            },
          ]}
          height={50}
          fieldArrayProps={{
            name: 'rows',
            control: control,
          }}
        />
      </ThemeProvider>

      <FormContainer fkey={'remarks'}>
        <FormTypography type="custom" value={formRemarks} />
      </FormContainer>
    </Box>
  )
}
export default WasteForecastTableFormPartB
//when all three columns are selected, tips based on the selected options will be shown
