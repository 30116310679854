import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import { Card, Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import SwitchTabs from '@components/dashboard/switchTabs'

const DashboardProjectRecyclingRate = ({
  getValues,
  settings,
  StyledCard,
  StyledBox,
}: {
  getValues: any
  settings: any
  StyledCard: any
  StyledBox: any
}) => {
  const { t } = useTranslation()
  // const values = getValues() // TODO: change to this one

  const values = getValues()
  const resKey = 'recyclingRate'
  const resData = values?.[resKey]?.datasets
  const hideChart = values?.[resKey]?.showChart === false
  const message = values?.[resKey]?.message ?? ''
  const outerData = resData?.find((item) => item.groupName === 'Outer')?.data
  const innerData = resData?.find((item) => item.groupName === 'Inner')?.data //TODO: inner ring
  const outerModifiedData = outerData?.reduce(
    (acc, curr) => ({ ...acc, [curr.itemName]: curr.value }), // for percentage float value
    {},
  )

  let data = [
    {
      title: '',
      menuTitle: t('dashboard:byContract'),
      content: (
        <SingleDatasetChart
          eleBefore={<></>}
          chartWrapperSX={{
            width: '100%',
            margin: 'auto',
          }}
          type={'doughnut'}
          hasSummaryTable={false}
          data={outerModifiedData ?? {}}
          dataConfig={{
            borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
            borderWidth: 2,
            backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true,
                intersect: false,
                mode: 'nearest',
                callbacks: {
                  label: (item) => {
                    return t(item.label) + ': ' + item.parsed * 100 + '%'
                  },
                },
              },
              legend: {
                display: true,
                position: 'bottom',
              },
              datalabels: {
                formatter: (value, ctx) => {
                  let percentage = (value * 100).toFixed(2) + '%'
                  return percentage
                },
                color: '#fff',
              },
            },
          }}></SingleDatasetChart>
      ),
    },
    {
      title: '',
      menuTitle: 'By Average',
      content: (
        <SingleDatasetChart
          eleBefore={<></>}
          chartWrapperSX={{
            width: '100%',
            margin: 'auto',
          }}
          type={'doughnut'}
          hasSummaryTable={false}
          data={outerModifiedData ?? {}}
          dataConfig={{
            borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
            borderWidth: 2,
            backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true,
                intersect: false,
                mode: 'nearest',
                callbacks: {
                  label: (item) => {
                    return t(item.label) + ': ' + item.parsed * 100 + '%'
                  },
                },
              },
              legend: {
                display: true,
                position: 'bottom',
              },
              datalabels: {
                formatter: (value, ctx) => {
                  let percentage = (value * 100).toFixed(2) + '%'
                  return percentage
                },
                color: '#fff',
              },
            },
          }}></SingleDatasetChart>
      ),
    },
  ]
  return (
    <>
      <Grid container md={12} xs={12}>
        <Grid item md={12} xs={12}>
          {/* <h2>{t('dashboard:recyclingRate')}</h2> */}
          {hideChart ? (
            <>{t(message)}</>
          ) : (
            <SingleDatasetChart
              eleBefore={<>{/* <h2>{t('dashboard:recyclingRate')}</h2> */}</>}
              chartWrapperSX={{
                width: '100%',
                margin: 'auto',
              }}
              type={'doughnut'}
              hasSummaryTable={false}
              data={outerModifiedData ?? {}}
              dataConfig={{
                borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
                borderWidth: 2,
                backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
              }}
              options={{
                responsive: true,
                plugins: {
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    callbacks: {
                      label: (item) => {
                        return t(item.label) + ': ' + item.parsed * 100 + '%'
                      },
                    },
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      let percentage = (value * 100).toFixed(2) + '%'
                      return percentage
                    },
                    color: '#fff',
                  },
                },
              }}></SingleDatasetChart>
          )}
        </Grid>
      </Grid>
    </>
    // <SwitchTabs data={data} />
  )
}

export default DashboardProjectRecyclingRate
