import { Fragment, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import FormCheckbox from '@components/form/checkbox'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { FormPartComponents, KeyValPair } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import FormController from '@components/form/controller'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import FormTypography from '@components/form/typography'
import {
  FormMwfModel,
  InitMwfForm,
  WasteCategoryListEnum,
} from '@services/model/form/form.MWF.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormMwfService from '@services/formService/form.MWF.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import MwfPartA from './PartA'
import MwfPartB from './PartB'
import MwfPartC from './PartC'
import MwfPartD from './PartD'
import MwfPartE from './PartE'
import { FormStatusEnum } from '@services/model/form/form.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import { CicGeneralOptionsModel } from '@services/model/general.model'
import useLocalStorage from '@hooks/useLocalStorage'
import _ from 'lodash'
import cleanString from '@utils/cleanString'
import getErrMsg from '@utils/getErrMsg'
import { Box } from '@mui/system'
import { useLocation } from 'react-router-dom'

const MonthlyWasteFlowTableFormPage = () => {
  const UUID = uuid()
  let { id: formId } = useParams<string>()
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const cat = QueryStepParser(query.get('cat'))
  const defaultMsg = t('forms:validate.messages.required')

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()

  const validationSchema = Yup.object().shape({
    hardRockRecycled: Yup.number().typeError(defaultMsg).required(defaultMsg),
    reusedInContract: Yup.number().typeError(defaultMsg).required(defaultMsg),
    reusedInOthers: Yup.number().typeError(defaultMsg).required(defaultMsg),
    disposedOfToPublicFill: Yup.number().typeError(defaultMsg).required(defaultMsg),
    disposedOfToSortingFacility: Yup.number().typeError(defaultMsg).required(defaultMsg),
    metals: Yup.number().typeError(defaultMsg).required(defaultMsg),
    paperPackaging: Yup.number().typeError(defaultMsg).required(defaultMsg),
    timber: Yup.number().typeError(defaultMsg).required(defaultMsg),
    plastic: Yup.number().typeError(defaultMsg).required(defaultMsg),
    chemicalWaste: Yup.number().typeError(defaultMsg).required(defaultMsg),
    otherRecycled: Yup.number().typeError(defaultMsg).required(defaultMsg),
  })

  const { control, setValue, getValues, reset, trigger } = useForm<FormMwfModel>({
    defaultValues: { ...InitMwfForm },
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [contractNoId, contractNo, contractName, formStatus, formPermission] = useWatch({
    name: [
      'baseForm.contractNoId',
      'baseForm.contractNo',
      'baseForm.contractName',
      'baseForm.formStatus',
      'baseForm.formPermission',
    ],
    control: control,
  })

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  // only 7 categories out of 12 categories used in this form
  const displayWasteCategoryList = [
    WasteCategoryListEnum.hardRockRecycled,
    WasteCategoryListEnum.reusedInOthers,
    WasteCategoryListEnum.metals,
    WasteCategoryListEnum.paperPackaging,
    WasteCategoryListEnum.timber,
    WasteCategoryListEnum.plastic,
    WasteCategoryListEnum.otherRecycled,
  ]
  const [anyNumberEmpty, setAnyNumberEmpty] = useState<boolean>(false)

  const unitInVolume = t('common:units.unitInVolume')
  const unitInWeight = t('common:units.unitInWeight')
  const [reportPeriod, wasteQuantityReportingUnit, constructionStageId, documentLock] = useWatch({
    name: ['reportPeriod', 'wasteQuantityReportingUnit', 'constructionStageId', 'documentLock'],
    control: control,
  })

  const [numDocumentList, setNumDocumentList] = useState<any>()
  const [partAInfoMissing, setPartAInfoMissing] = useState<boolean>(false)
  let unit: string = wasteQuantityReportingUnit === 5 ? unitInWeight : unitInVolume
  const mwfGeneralOptions = useRef<CicGeneralOptionsModel>()
  console.debug('[formState]', formState)
  useEffect(() => {
    if (reportPeriod && constructionStageId && wasteQuantityReportingUnit) {
      if (!formId) {
        handleOnSave()
      }
      if (partAInfoMissing) {
        setPartAInfoMissing(false)
      }
    } else {
      if (!partAInfoMissing) {
        setPartAInfoMissing(true)
      }
    }
  }, [reportPeriod, constructionStageId, wasteQuantityReportingUnit])
  const location = useLocation()

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formStatus])

  const reloadGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormMwfService.GetGeneralOptions()
          .then(async (res) => {
            mwfGeneralOptions.current = res
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      reloadGeneralOptions()
    }

    if (formState.form && formState.formType === 'MWF') {
      isMounted.current = false

      formStateAction({ type: 'pop', targetFormType: 'MWF' })
      let prevForm = { ...formState.form }
      reset(prevForm)
      setPartAInfoMissing(false)
    } else if (currentContract?.contractId && currentProject?.projectId && !formId) {
      setValue('baseForm.contractNoId', currentContract.contractId)
      setValue('baseForm.contractNo', currentContract.contractNo)
      setValue('baseForm.contractName', currentContract.contractName)
      setValue('baseForm.projectId', currentProject.projectId)
    } else if (globalState.contractList && formId && !isMounted.current) {
      reload(formId)
      isMounted.current = true
    }
    return () => {}
  }, [formId, globalAction, globalState, step])

  const reload = async (formId: string) => {
    setRequest({
      callback: async () => {
        await FormMwfService.GetMwfForm(formId, formStatus)
          .then(async (res) => {
            if (res) {
              const resContractNo = _.get(
                _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
                'contractNo',
              )
              const resContractName = _.get(
                _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
                'contractName',
              )
              let newRes = {
                ...res,
                baseForm: {
                  ...res.baseForm,
                  contractNo: resContractNo,
                  contractName: resContractName,
                },
              }
              reset(newRes)
              isMounted.current = true
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }
  const uneditable =
    !formPermission?.canUpdate ||
    formStatus === FormStatusEnum.MonthlyWasteFlowTableReview ||
    formStatus === FormStatusEnum.MonthlyWasteFlowTableApproved

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    setValue('submissionComment', submissionComment)
    const values = getValues()

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormMwfService.RejectMwfForm(values).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/monthly-waste-flow-table/all-records')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormMwfService.ApproveMwfForm(values).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/monthly-waste-flow-table/all-records')
          })
        },
      })
    }
  }
  const handleOnSave = async (currentStep?: number) => {
    const submitValue = getValues()
    // setRequest({
    //   callback: async () => {

    //   },
    // })

    await FormMwfService.SaveMwfForm(submitValue)
      .then((resp) => {
        setValue('baseForm.formId', resp.formId)
        setValue('baseForm.formStatus', resp.formStatus)
        if (!formId) {
          NavigateTo(navigate, '/monthly-waste-flow-table/:id', {
            id: resp.formId,
          })
        }

        formId = resp.formId
        if (formId) {
          reload(formId)
        }

        enqueueSnackbar(t('common:messages.recordSaved'), {
          variant: 'success',
        })
      })
      .catch((err) => {
        if (formId) {
          reload(formId)
          enqueueSnackbar(<>{getErrMsg(t, err)}</>, {
            variant: 'error',
          })
        } else {
          const { reportPeriod, ...rest } = submitValue
          reset(rest)

          enqueueSnackbar(<>{getErrMsg(t, err)}</>, {
            variant: 'error',
          })

          throw err
        }
      })
  }
  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormMwfService.DeleteMwfForm(getValues())
          .then(() => {
            enqueueSnackbar(t('common:messages.recordArchived'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/monthly-waste-flow-table/all-records')
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    if (reportPeriod) {
      reloadMwfUploadedDocumentList()
    }
  }, [currentContract, reportPeriod])

  const reloadMwfUploadedDocumentList = async () => {
    const reqData = {
      contractNo: { Operator: '=', Value: [currentContract?.contractId ?? null] },
      reportPeriod: {
        Operator: '=',
        Value: {
          Min: reportPeriod,
        },
      },
    }
    setRequest({
      callback: async () => {
        const list: Array<any> = []
        await FormMwfService.GetMwfUploadedDocumentList(reqData).then(async (res) => {
          if (res)
            displayWasteCategoryList.forEach((categoryName) => {
              const categoryId = mwfGeneralOptions.current?.documentCategoryList?.find(
                (category: KeyValPair) =>
                  cleanString(category.value as string) === cleanString(categoryName),
              )?.key

              list[categoryId] = res?.list?.filter((x) => x.mwfCategory === categoryId)
            })

          setNumDocumentList(list)
        })
      },
    })
  }

  const filteredList =
    mwfGeneralOptions.current?.documentCategoryList?.filter((item: KeyValPair) =>
      displayWasteCategoryList.some((value) => cleanString(value) === cleanString(item.value)),
    ) ?? []

  const hiddenForPreview =
    formStatus === 'FORM_MWF_REJECTED' || formStatus === '' || formStatus === 'FORM_MWF_DRAFT'
      ? false
      : true

  const partA = (): FormPartComponents => {
    let showOtherConstructionStageInput = constructionStageId === 4 ? true : false // 4 is other
    return {
      partTitle: t('forms:monthlyWasteFlowTable.sections.general.title'),
      component: (
        <MwfPartA
          mwfGeneralOptions={mwfGeneralOptions.current}
          uneditable={uneditable}
          control={control}
          showOtherConstructionStageInput={showOtherConstructionStageInput}
          handleOnSave={handleOnSave}
          formId={formId}
          partAInfoMissing={partAInfoMissing}
          useWatch={useWatch}
        />
      ),
      onNextDisabled: partAInfoMissing,
    }
  }

  const partB = (): FormPartComponents => {
    const handleDocumentLock = async () => {
      let ff = getValues()
      let method = 'lock'
      if (ff.documentLock === true) {
        method = 'lock'
      } else {
        method = 'unlock'
      }
      delete ff.documentLock
      if (formId) {
        setRequest({
          callback: async () => {
            await FormMwfService.LockUnlockMWFDocument(ff, method)
              .then(async (res) => {
                if (res) {
                  enqueueSnackbar(t('Document Locked'), {
                    variant: 'success',
                    action: (key) => {
                      return <SnackbarCloseButton id={key} />
                    },
                  })
                }
              })
              .catch((err) => {
                setValue('documentLock', !ff.documentLock)
                if (formId) reload(formId)
              })
          },
        })
      }
    }

    return {
      partTitle: t('forms:monthlyWasteFlowTable.sections.actualInert.title'),
      component: (
        <MwfPartB
          uneditable={uneditable}
          formId={formId}
          control={control}
          unit={unit}
          showNote={wasteQuantityReportingUnit === 6}
          useWatch={useWatch}
          getValues={getValues}
          mwfGeneralOptions={mwfGeneralOptions}
          setValue={setValue}
          reload={reload}
          handleDocumentLock={handleDocumentLock}
          numDocumentList={numDocumentList}
        />
      ),
      disabled: partAInfoMissing,
      onNext: async () => {
        if (!(await trigger())) {
          enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), {
            variant: 'error',
          })
          return 1
        }
      },
      onNextDisabled: !documentLock || anyNumberEmpty,
    }
  }

  const partE = (): FormPartComponents => {
    const handleLinkClick = (index?: number) => {
      let reportPeriod = getValues('reportPeriod')
      if (!reportPeriod) {
        return
      }
      formStateAction({ type: 'push', form: getValues(), formType: 'MWF' })
      if (index) {
        NavigateTo(
          navigate,
          '/monthly-waste-flow-table-upload/:formId',
          { formId },
          {
            cat: index,
            month: reportPeriod.toString(),
            locked: getValues('documentLock')?.toString() || 'false',
          },
        )
      } else {
        NavigateTo(navigate, '/monthly-waste-flow-table-upload/:formId', { formId })
      }
    }
    const handleDocumentLock = async () => {
      let ff = getValues()
      let method = 'lock'
      if (ff.documentLock === true) {
        method = 'lock'
      } else {
        method = 'unlock'
      }
      delete ff.documentLock
      if (formId) {
        setRequest({
          callback: async () => {
            await FormMwfService.LockUnlockMWFDocument(ff, method)
              .then(async (res) => {
                if (res) {
                  enqueueSnackbar(t('Document Locked'), {
                    variant: 'success',
                    action: (key) => {
                      return <SnackbarCloseButton id={key} />
                    },
                  })
                }
              })
              .catch((err) => {
                setValue('documentLock', !ff.documentLock)
                if (formId) reload(formId)
              })
          },
        })
      }
    }

    return {
      partTitle: t('forms:monthlyWasteFlowTable.sections.uploadedDocuments.title'),
      component: (
        <MwfPartE
          uneditable={uneditable}
          globalState={globalState}
          formId={formId}
          control={control}
          numDocumentList={numDocumentList}
          filteredList={filteredList}
          handleLinkClick={handleLinkClick}
          handleDocumentLock={handleDocumentLock}
        />
      ),

      onNextDisabled: !documentLock || anyNumberEmpty,
      disabled: anyNumberEmpty || partAInfoMissing,
    }
  }

  const [acknowledgement] = useWatch({
    name: ['acknowledgement'],
    control: control,
  })
  const MWFPartF = (): FormPartComponents => {
    let index = 0
    return {
      partTitle: t('forms:monthlyWasteFlowTable.sections.acknowledgement.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-f`}>
          <FormField
            fieldName={t('forms:monthlyWasteFlowTable.fields.acknowledgement.label')}
            index={index++}>
            <FormTypography
              type="string"
              value={t('forms:monthlyWasteFlowTable.fields.acknowledgement.value')}
            />
          </FormField>
          <FormField
            alignItems="center"
            fieldName={t('forms:monthlyWasteFlowTable.fields.confirmation')}
            index={index++}>
            <FormController
              controllerProps={{
                name: 'acknowledgement',
                control,
              }}>
              <FormCheckbox label={'Yes'} uneditable={uneditable} />
            </FormController>
          </FormField>
        </FormContainer>
      ),
      onNextDisabled: !acknowledgement || !documentLock || anyNumberEmpty,

      disabled: !documentLock || anyNumberEmpty || partAInfoMissing,
    }
  }

  const MWFPartG = (): FormPartComponents => {
    return {
      partTitle: t('forms:monthlyWasteFlowTable.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
      disabled: !acknowledgement || !documentLock || anyNumberEmpty,
    }
  }
  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'MWF',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete, handleNext, handleBack } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:monthlyWasteFlowTable.title'),
    components: [partA(), partB(), MWFPartF(), MWFPartG()],

    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableComment: getValues('baseForm.formId') ? false : true,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate,
    disableSaveBtn: uneditable,
    disableDelete: !formPermission?.canDelete,
    approveRequired: formPermission?.approveRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
    minAssignUser: formStatus === FormStatusEnum.MonthlyWasteFlowTableApproved ? undefined : 1,
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}
export default MonthlyWasteFlowTableFormPage
