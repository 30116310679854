import { TextField, useMediaQuery, SxProps, Theme, Box, Grid, Stack, Button } from '@mui/material'
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CSSProperties, Fragment, useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from '@styles/reactDatePicker.module.scss'
import { FormHelperText } from '@mui/material'
import { css } from '@emotion/react'
import Typography from '@components/Typography'
import { zhCN, zhHK, enUS } from 'date-fns/locale' // the locale you want
import { LanguageContext } from '@providers/languageProvider'
import { useContext } from 'react'
const dateTimePickerTheme = createTheme({
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 30,
        },
      },
    },
  },
})

interface formDateTimePickerProps {
  value?: Date
  onChange?: (...event: any[]) => void
  onBlur?: (...event: any[]) => void
  minDate?: Date | string
  maxDate?: Date | string
  minTime?: Date | string
  maxTime?: Date | string
  type?: 'month' | 'date' | 'datetime' | 'time'
  additionalChangeAction?: (value: Date | null) => void
  sx?: CSSProperties
  defaultValue?: Date | string
  disabled?: boolean
  uneditable?: boolean
  error?: any
  minHeight?: any
  withPortal?: boolean
  width?: number | string
  datePickerType?: 'desktop-table' | 'mobile-table' | 'form'
  tableClass?: boolean
  cellStyle?: SxProps<Theme>
  withCurrent?: boolean
}

const FormDateTimePicker = (props: formDateTimePickerProps) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const dateOnly = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  )
  const { t } = useTranslation()

  const languageContext = useContext(LanguageContext)

  registerLocale('en-US', enUS) // register it with the name you want
  registerLocale('zh-Hans', zhCN)
  registerLocale('zh-Hant', zhHK)

  const { language } = languageContext

  useEffect(() => {}, [language])

  const DateTextField = styled(TextField)<{ state: { picked?: boolean } }>(({ state }) => ({
    '& .MuiInput-input.MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'rgb(50, 50, 50)',
    },
    color: state.picked ? '#000000' : '#bfbfbf',
  }))

  const [date, setDate] = useState<Date | null>(
    props.value ? new Date(props.value) : props.defaultValue ? new Date(props.defaultValue) : null,
  )
  const [time, setTime] = useState<Date | null>(
    props.value
      ? new Date(props.value)
      : props.defaultValue
      ? new Date(props.defaultValue)
      : dateOnly,
  )
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  if (!props.onChange) {
    return <>missing onChange from props</>
  }
  const handleChange = (value, type?) => {
    let formattedDate = value
    if (type === 'month') {
      formattedDate = new Date(value.getFullYear(), value.getMonth(), 1)
      setDate(value)
    } else if (type === 'time') {
      setTime(value)
    } else {
      setDate(value)
    }
    const _date = value ? formattedDate : undefined
    props.onChange!(_date)
    if (props.additionalChangeAction) {
      props.additionalChangeAction(value)
    }
    props.onBlur
  }

  let datePickerClassName = `${styles.datePicker} 
  } ${props.error ? styles.datePickerError : ''}`

  if (props.datePickerType === 'desktop-table') {
    datePickerClassName = `${styles.datePickerDesktopTable} 
   ${props.error ? styles.datePickerError : ''}`
  } else if (props.datePickerType === 'mobile-table') {
    datePickerClassName = `${styles.datePickerMobileTable} 
   ${props.error ? styles.datePickerError : ''}`
  }
  let picker: JSX.Element = <></>
  switch (props.type) {
    case 'month':
      picker = (
        <Box sx={props.cellStyle}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            sx={{ width: '100%', clear: 'both' }}>
            <DatePicker
              // locale={language}
              className={datePickerClassName}
              helperText={props.error}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              selected={date}
              placeholderText={t('Please pick a month')}
              onChange={(value) => {
                handleChange(value, 'month')
              }}
              showPopperArrow={false}
              showMonthYearPicker
              showFullMonthYearPicker
              dateFormat="MMMM yyyy"
              minDate={
                props.minDate
                  ? new Date(moment(props.minDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              maxDate={
                props.maxDate
                  ? new Date(moment(props.maxDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              withPortal={props.withPortal ?? downMd}
              disabled={props.disabled}
            />
            {props.withCurrent && (
              <Button
                size="small"
                variant="outlined"
                disabled={props.disabled}
                onClick={() => handleChange(new Date(), 'month')}>
                <Typography component="span" sx={{ px: 1 }}>
                  {t('common:buttons.currentTime')}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )
      break
    case 'date':
      picker = (
        <Box sx={props.cellStyle}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            sx={{ width: '100%', clear: 'both' }}>
            <DatePicker
              // locale={language}
              // formatWeekDay={(day: string) => day.substr(0, 1)}
              className={datePickerClassName}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              selected={date}
              placeholderText={t('Please pick a date')}
              onChange={(value) => {
                handleChange(value, 'date')
              }}
              showPopperArrow={false}
              dateFormat="d MMMM yyyy"
              minDate={
                props.minDate
                  ? new Date(moment(props.minDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              maxDate={
                props.maxDate
                  ? new Date(moment(props.maxDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              withPortal={props.withPortal ?? downMd}
              disabled={props.disabled}
            />
            {props.withCurrent && (
              <Button
                size="small"
                variant="outlined"
                disabled={props.disabled}
                onClick={() => handleChange(new Date(), 'date')}>
                <Typography component="span" variant="remark" whiteSpace="nowrap" sx={{ px: 1 }}>
                  {t('common:buttons.currentTime')}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )
      break
    case 'time':
      picker = (
        <Box sx={props.cellStyle}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            sx={{ width: '100%', clear: 'both' }}>
            <DatePicker
              // locale={language}
              // formatWeekDay={(day: string) => day.substr(0, 1)}
              // className={styles.datePicker}
              className={datePickerClassName}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              timeClassName={() => {
                return styles.datePickerTime
              }}
              placeholderText={t('Please pick a time')}
              selected={time}
              onChange={(value: Date | null) => {
                handleChange(value, 'time')
              }}
              onBlur={props.onBlur}
              showTimeSelect={true}
              showTimeSelectOnly
              calendarClassName={styles.disableCalendar}
              showMonthDropdown={false}
              showYearDropdown={false}
              scrollableYearDropdown={false}
              timeIntervals={1}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              minDate={
                props.minDate
                  ? new Date(moment(props.minDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              maxDate={
                props.maxDate
                  ? new Date(moment(props.maxDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              minTime={props.minTime}
              maxTime={props.maxTime}
              tabIndex={99999}
              withPortal={false}
              disabled={props.disabled}
            />
            {props.withCurrent && (
              <Button
                size="small"
                variant="outlined"
                disabled={props.disabled}
                onClick={() => handleChange(new Date(), 'time')}>
                <Typography component="span" variant="remark" whiteSpace="nowrap" sx={{ px: 1 }}>
                  {t('common:buttons.currentTime')}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )
      break
    case 'datetime':
    default:
      picker = (
        <Box sx={props.cellStyle}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            sx={{ width: '100%', clear: 'both' }}>
            <DatePicker
              // locale={language}
              // formatWeekDay={(day: string) => day.substr(0, 1)}
              className={datePickerClassName}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              timeClassName={() => {
                return styles.datePickerTime
              }}
              selected={date}
              placeholderText={t('Please pick a datetime')}
              onChange={(value) => {
                handleChange(value, 'datetime')
              }}
              onBlur={props.onBlur}
              showPopperArrow={false}
              showTimeSelect
              timeIntervals={1}
              timeFormat="HH:mm"
              dateFormat="d MMMM yyyy, HH:mm"
              minDate={
                props.minDate
                  ? new Date(moment(props.minDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              maxDate={
                props.maxDate
                  ? new Date(moment(props.maxDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                  : null
              }
              minTime={props.minTime}
              maxTime={props.maxTime}
              withPortal={props.withPortal ?? downMd}
              disabled={props.disabled}
            />
            {props.withCurrent && (
              <Button
                size="small"
                variant="outlined"
                disabled={props.disabled}
                onClick={() => handleChange(new Date(), 'time')}>
                <Typography component="span" variant="remark" whiteSpace="nowrap" sx={{ px: 1 }}>
                  {t('common:buttons.currentTime')}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )
      break
  }
  const getTypographyDisplay = () => {
    if (!props.value) {
      return ''
    }

    switch (props.type) {
      case 'month': {
        return moment(props.value).format('MMMM YYYY')
      }
      case 'date': {
        return moment(props.value).format('D MMMM YYYY')
      }
      case 'time': {
        return moment(props.value).format('HH:mm')
      }
      case 'datetime':
      default: {
        return moment(props.value).format(moment.defaultFormat)
      }
    }
  }

  return (
    <ThemeProvider theme={dateTimePickerTheme}>
      {props.uneditable ? (
        <Typography sx={{ display: 'flex', alignItems: 'flex-start', ...props.sx }}>
          {getTypographyDisplay()}
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
            ...props.sx,
          }}>
          <Box sx={{ width: '100%' }}>{picker}</Box>
          <Box sx={{ width: '100%' }}>
            <FormHelperText sx={{ width: '100%', textAlign: 'left' }} error={!!props.error}>
              {props.error?.message ?? null}
            </FormHelperText>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  )
}
export default FormDateTimePicker
