import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  Stack,
  Drawer,
} from '@mui/material'

import { useTheme } from '@mui/material/styles'
import '@styles/table.module.scss'
import { useTranslation } from 'react-i18next'
import { Fragment, useState, useRef } from 'react'
import { FieldValues } from 'react-hook-form'
import FormDialog from '@components/form/dialog_v2'
import { DrawerContextProps, InitDrawerContent } from '@components/form/table/model'
import FormTableBox from '@components/form/table/presentation/formTableBox'
import { FormSingleRowTableChildProps } from '@components/form/table/model'

function MobileSingleRowTable<F extends FieldValues>(props: FormSingleRowTableChildProps<F>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  let itemNo = 0
  const [drawerContext, setDrawerContext] = useState<DrawerContextProps>(InitDrawerContent)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>()

  const handleDrawerClose = (event) => {
    setDrawerContext(InitDrawerContent)
  }

  return (
    <Grid xs={12} style={{ overflow: props.removeOverflow ? 'none' : 'auto', width: '100%' }}>
      <FormTableBox>
        <Stack spacing={theme.spacing(1)}>
          <Card>
            <CardContent>
              <Box
                key={`form-table-row`}
                sx={{
                  // height: props.height ?? 200,
                  width: '100%',
                  ':hover': {
                    '#table-row-actions': {
                      display: 'inherit',
                    },
                  },
                  backgroundColor: 'transparent',
                }}>
                <Box sx={{ backgroundColor: 'fff', border: 'none' }}></Box>
                <Box
                  style={{
                    // flex: 1,
                    minWidth: '100px',
                    position: 'relative',
                    textAlign: 'left',
                    padding: theme.spacing(1),
                  }}
                  key={`form-table-cell-item-no`}>
                  {!props.hiddenItemNo ? (
                    <Typography textAlign={props.align ?? 'left'} fontSize={16}>
                      Item No.: {++itemNo}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>
                {props.columns.map((column, columnIndex: number) => {
                  let children: JSX.Element = <Fragment></Fragment>
                  if (column.hidden || (downMd && column.mobileHidden)) {
                    return children
                  }

                  children = <>{column.field}</>

                  return (
                    <Box
                      style={{
                        // flex: 1,
                        minWidth: column.width ?? '100px',
                        position: 'relative',
                        textAlign: 'left',
                        padding: theme.spacing(1),
                      }}
                      key={`form-table-cell-${columnIndex}`}>
                      <Stack spacing={0}>
                        <Box sx={{ color: '#818181', fontSize: '12px' }}>
                          <>
                            {typeof column.displayName === 'string'
                              ? t(column.displayName)
                              : column.displayName}{' '}
                            :
                          </>
                        </Box>
                        <Box>{children}</Box>
                      </Stack>
                    </Box>
                  )
                })}
              </Box>
            </CardContent>
          </Card>

          {props.warningText && (
            <Typography
              sx={{
                color: 'red',
              }}>
              {t(props.warningText)}
            </Typography>
          )}
        </Stack>
      </FormTableBox>

      <props.ImageModal image={props.modalImage} />

      <Drawer
        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        children={drawerContext.children}
        sx={{ height: window.innerHeight, width: window.innerWidth }}
      />
      <FormDialog
        key={'dialog'}
        open={props.dialogContext.isOpen}
        onClose={props.handleDialogClose}
        toolbarStyle={props.dialogContext.toolbarStyle}
        title={props.dialogContext.title}
        buttons={props.dialogContext.buttons}
        children={props.dialogContext.children}
      />
    </Grid>
  )
}

export default MobileSingleRowTable
