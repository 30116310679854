import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormTypography from '@components/form/typography'
import { FormMwfModel, WasteCategoryListEnum } from '@services/model/form/form.MWF.model'
import { Control, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AccordionGroup from '@components/form/accordionGroup'
import useLocalStorage from '@hooks/useLocalStorage'
import { MWFDocRequestModel } from '@services/model/form/form.MWF.upload.model'
import useAPIFetch from '@hooks/useAPIFetch'
import FormMwfService from '@services/formService/form.MWF.service'
import { Ref, useEffect, useState } from 'react'
import FormTableBasic from '@components/form/tableBasic'
import { Tooltip } from '@mui/material'
import { useContext } from 'react'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import { useNavigate, useParams } from 'react-router-dom'
import { CicGeneralOptionsModel } from '@services/model/general.model'
import { KeyValPair } from '@models/Common'
import cleanString from '@utils/cleanString'
import { RiFileEditLine as EditIcon } from 'react-icons/ri'
import { useSnackbar } from 'notistack'
import FormSwitch from '@components/form/switch'

const calculateTotal = (valueList: any[]) => {
  let total = 0
  valueList.forEach((value: string) => {
    if (typeof value === 'number' && !isNaN(value)) {
      total += value
    }
  })
  return total
}

const MonthlyWasteFlowTableFormPartB = ({
  uneditable,
  control,
  unit,
  showNote,
  useWatch,
  getValues,
  formId,
  mwfGeneralOptions,
  setValue,
  reload,
  handleDocumentLock,
  numDocumentList,
}: {
  uneditable: boolean
  control: Control<FormMwfModel>
  unit: string
  showNote: boolean | undefined
  useWatch: any
  getValues: any
  formId: string | undefined
  mwfGeneralOptions?: any
  setValue: any
  reload: any
  handleDocumentLock: any
  numDocumentList: any
}) => {
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  let index = 0
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [anyNumberEmpty, setAnyNumberEmpty] = useState<boolean>(false)

  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  console.debug('numDocumentList', numDocumentList)

  const getCategoryId = (categoryName?: string) => {
    const categoryId = mwfGeneralOptions?.current?.documentCategoryList?.find(
      (category: KeyValPair) => cleanString(category.value as string) === cleanString(categoryName),
    )?.key

    return categoryId
  }

  const handleLinkClick = (categoryName?: string) => {
    let reportPeriod = getValues('reportPeriod')
    if (!reportPeriod) {
      return
    }

    const categoryId = getCategoryId(categoryName)
    formStateAction({ type: 'push', form: getValues(), formType: 'MWF' })
    if (index) {
      NavigateTo(
        navigate,
        '/monthly-waste-flow-table-upload/:formId',
        { formId },
        {
          cat: categoryId,
          month: reportPeriod.toString(),
          locked: getValues('documentLock')?.toString() || 'false',
          backstep: 1,
        },
      )
    } else {
      NavigateTo(navigate, '/monthly-waste-flow-table-upload/:formId', { formId })
    }
  }
  const { setRequest: lockRequest, isLoading } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()

  const actualInertSection = {
    key: t('forms:monthlyWasteFlowTable.sections.actualInert.title'),
    value: (
      <>
        <FormTableBasic
          columns={[
            {
              title: t('forms:monthlyWasteFlowTable.fields.category'),
              key: 'category',
              width: '50%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.quantity', { unit: unit }),
              key: 'quantity',
              width: '10%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.noOfUploads'),
              key: 'noOfUploads',
              width: '10%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.editUploads'),
              key: 'editUploads',
              width: '10%',
            },
          ]}
          rows={[
            {
              category: t('forms:monthlyWasteFlowTable.fields.hardRockRecycled'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'hardRockRecycled',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.hardRockRecycled)]
                        ?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <>
                  <EditIcon
                    size={40}
                    onClick={() => handleLinkClick(WasteCategoryListEnum.hardRockRecycled)}
                  />
                </>
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.reusedInContract'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'reusedInContract',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: <>-</>,
              editUploads: <></>,
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.reusedInOthers'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'reusedInOthers',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.reusedInOthers)]
                        ?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon
                  size={40}
                  onClick={() => handleLinkClick(WasteCategoryListEnum.reusedInOthers)}
                />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.disposedOfToPublicFill'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'disposedOfToPublicFill',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: <>-</>,
              editUploads: <></>,
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.disposedOfToSortingFacility'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'disposedOfToSortingFacility',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: <>-</>,
              editUploads: <></>,
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.importedFill'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'importedFill',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: <>-</>,
              editUploads: <></>,
            },
          ]}
        />
      </>
    ),
  }
  const actualNonInertSection = {
    key: t('forms:monthlyWasteFlowTable.sections.actualNonInert.title'),
    value: (
      <>
        <FormTableBasic
          columns={[
            {
              title: t('forms:monthlyWasteFlowTable.fields.category'),
              key: 'category',
              width: '50%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.quantity', { unit: unit }),
              key: 'quantity',
              width: '10%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.noOfUploads'),
              key: 'noOfUploads',
              width: '10%',
            },
            {
              title: t('forms:monthlyWasteFlowTable.fields.editUploads'),
              key: 'editUploads',
              width: '10%',
            },
          ]}
          rows={[
            {
              category: t('forms:monthlyWasteFlowTable.fields.metals'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'metals',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.metals)]?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon size={40} onClick={() => handleLinkClick(WasteCategoryListEnum.metals)} />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.paperPackaging'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'paperPackaging',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.paperPackaging)]
                        ?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon
                  size={40}
                  onClick={() => handleLinkClick(WasteCategoryListEnum.paperPackaging)}
                />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.timber'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'timber',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.timber)]?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon size={40} onClick={() => handleLinkClick(WasteCategoryListEnum.timber)} />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.plastic'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'plastic',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.plastic)]?.length ?? 0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon
                  size={40}
                  onClick={() => handleLinkClick(WasteCategoryListEnum.plastic)}
                />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.chemicalWaste'),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'chemicalWaste',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: <>-</>,
              editUploads: <></>,
            },
            {
              category: (
                <Tooltip title={t('forms:monthlyWasteFlowTable.fields.otherRecycled.tooltip')}>
                  {t('forms:monthlyWasteFlowTable.fields.otherRecycled.label')}
                </Tooltip>
              ),
              quantity: (
                <FormController
                  controllerProps={{
                    name: 'otherRecycled',
                    control,
                  }}>
                  <FormFreeText
                    inputType="number"
                    textfieldProps={{ fullWidth: true, autoFocus: true }} // autoFocus: true
                    uneditable={uneditable}
                  />
                </FormController>
              ),
              noOfUploads: (
                <>
                  {numDocumentList
                    ? numDocumentList[getCategoryId(WasteCategoryListEnum.otherRecycled)]?.length ??
                      0
                    : 0}
                </>
              ),
              editUploads: (
                <EditIcon
                  size={40}
                  onClick={() => handleLinkClick(WasteCategoryListEnum.otherRecycled)}
                />
              ),
            },
          ]}
        />
      </>
    ),
  }

  const summarySection = {
    key: t('forms:monthlyWasteFlowTable.sections.summary.title'),
    value: (
      <>
        <FormTableBasic
          columns={[
            { title: t('forms:monthlyWasteFlowTable.fields.category'), key: 'category' },
            { title: t('forms:monthlyWasteFlowTable.fields.value'), key: 'value', width: '20%' },
          ]}
          rows={[
            {
              category: t('forms:monthlyWasteFlowTable.fields.totalWasteQuality'),
              value: <TotalWasteQualityField useWatch={useWatch} control={control} unit={unit} />,
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.totalQualityRecycledWithinSite'),
              value: (
                <TotalQualityRecycledWithinSiteField
                  useWatch={useWatch}
                  control={control}
                  unit={unit}
                />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.totalWasteQualityDisposed'),
              value: (
                <TotalWasteQualityDisposedField useWatch={useWatch} control={control} unit={unit} />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.totalQualityRecycledOffSite'),
              value: (
                <TotalQualityRecycledOffSiteField
                  useWatch={useWatch}
                  control={control}
                  unit={unit}
                />
              ),
            },
            {
              category: t('forms:monthlyWasteFlowTable.fields.demolition'),
              value: <DemolitionField useWatch={useWatch} control={control} unit={'%'} />,
            },
          ]}
        />
      </>
    ),
  }
  const items = [actualInertSection, actualNonInertSection, summarySection]

  return (
    <FormContainer fkey={`mwf-b`}>
      <FormField
        alignItems="center"
        fieldName={t('forms:monthlyWasteFlowTable.fields.month')}
        index={index++}>
        <FormController
          controllerProps={{
            name: 'reportPeriod',
            control,
          }}>
          <FormTypography type="time" timeFormat="MMMM YYYY" />
        </FormController>
      </FormField>
      <AccordionGroup items={items} openList={[true, true, true]} />
      {showNote ? (
        <FormField
          fieldName={t('forms:monthlyWasteFlowTable.fields.notePartB.label')}
          index={index++}>
          <FormTypography
            type="string"
            value={t('forms:monthlyWasteFlowTable.fields.notePartB.value')}
          />
        </FormField>
      ) : (
        <></>
      )}
      {formId ? (
        <FormField
          fieldName={t('forms:monthlyWasteFlowTable.fields.confirmDocumentsCorrect')}
          index={index++}
          // sx={{ width: '100px' }}
        >
          <FormController
            controllerProps={{
              name: 'documentLock',
              control,
            }}>
            <FormSwitch
              startAdornment={t('common:buttons.unlock')}
              additionalChangeAction={handleDocumentLock}
              uneditable={uneditable}
              endAdornment={t('common:buttons.lock')}
            />
          </FormController>
        </FormField>
      ) : (
        <></>
      )}
    </FormContainer>
  )
}

const TotalWasteQualityFieldList = [
  'hardRockRecycled',
  'reusedInContract',
  'reusedInOthers',
  'disposedOfToPublicFill',
  'disposedOfToSortingFacility',
  'metals',
  'paperPackaging',
  'timber',
  'plastic',
  'chemicalWaste',
  'otherRecycled',
]

const TotalQualityRecycledWithinSiteFieldList = ['reusedInContract']

const TotalWasteQualityDisposedFieldList = [
  'disposedOfToPublicFill',
  'disposedOfToSortingFacility',
  'chemicalWaste',
  'otherRecycled',
]

const TotalQualityRecycledOffSiteFieldList = [
  'hardRockRecycled',
  'reusedInOthers',
  'metals',
  'paperPackaging',
  'timber',
  'plastic',
]

const TotalWasteQualityField = (props: { useWatch: any; control: any; unit?: string }) => {
  const valueList = props.useWatch({
    name: TotalWasteQualityFieldList,
    control: props.control,
  })

  return (
    <>
      {calculateTotal(valueList)?.toFixed(1)} {props.unit ? `(${props.unit})` : null}
    </>
  )
}

const TotalQualityRecycledWithinSiteField = (props: {
  useWatch: any
  control: any
  unit?: string
}) => {
  const valueList = props.useWatch({
    name: TotalQualityRecycledWithinSiteFieldList,
    control: props.control,
  })

  return (
    <>
      {calculateTotal(valueList)?.toFixed(1)} {props.unit ? `(${props.unit})` : null}
    </>
  )
}

const TotalWasteQualityDisposedField = (props: { useWatch: any; control: any; unit?: string }) => {
  const valueList = props.useWatch({
    name: TotalWasteQualityDisposedFieldList,
    control: props.control,
  })

  return (
    <>
      {calculateTotal(valueList)?.toFixed(1)} {props.unit ? `(${props.unit})` : null}
    </>
  )
}

const TotalQualityRecycledOffSiteField = (props: {
  useWatch: any
  control: any
  unit?: string
}) => {
  const valueList = props.useWatch({
    name: TotalQualityRecycledOffSiteFieldList,
    control: props.control,
  })

  return (
    <>
      {calculateTotal(valueList)?.toFixed(1)} {props.unit ? `(${props.unit})` : null}
    </>
  )
}

const DemolitionField = (props: { useWatch: any; control: any; unit?: string }) => {
  const TotalWasteQualityValueList = props.useWatch({
    name: TotalWasteQualityFieldList,
    control: props.control,
  })

  const TotalQualityRecycledWithinSiteValueList = props.useWatch({
    name: TotalQualityRecycledWithinSiteFieldList,
    control: props.control,
  })

  const TotalQualityRecycledOffSiteValueList = props.useWatch({
    name: TotalQualityRecycledOffSiteFieldList,
    control: props.control,
  })

  const totalWasteQuality = calculateTotal(TotalWasteQualityValueList)
  const totalQualityRecycledWithinSite = calculateTotal(TotalQualityRecycledWithinSiteValueList)
  const totalQualityRecycledOffSite = calculateTotal(TotalQualityRecycledOffSiteValueList)

  return (
    <>
      {totalWasteQuality > 0 ? (
        <>
          {(
            ((totalQualityRecycledWithinSite + totalQualityRecycledOffSite) / totalWasteQuality) *
            100
          ).toFixed(1)}{' '}
          {props.unit ? `(${props.unit})` : null}
        </>
      ) : (
        '-'
      )}
    </>
  )
}
export default MonthlyWasteFlowTableFormPartB
