import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Stack,
  Box,
  CssBaseline,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import cicLogoutLogo from '../../assets/img/cic-logout-logo.png'
import { NavigateTo } from '@utils/navigate'
import { Button } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'

const Logout = () => {
  const navigate = useNavigate()
  const login = () => {
    NavigateTo(navigate, '/login')
  }
  const { oktaAuth } = useOktaAuth()

  // const login = async () => {
  //   oktaAuth.signInWithRedirect({ originalUri: '/' })
  // }

  return (
    <Box sx={{ backgroundColor: '#ebebed', width: '100%', height: '100%', paddingTop: 30 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          width: '400px',
          maxWidth: '100%',
          margin: 'auto',
          backgroundColor: 'white',
        }}>
        <Box
          sx={{ padding: '30px 90px', backgroundColor: 'white', borderBottom: '1px solid #ccc' }}>
          <Box
            component="img"
            alt="CIC Logo"
            src={cicLogoutLogo}
            sx={{ width: '150px', padding: '10px' }}
          />
        </Box>
        <Box>
          <Stack sx={{ margin: '30px' }}>
            <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>Logout successfully</Box>
            <Button
              sx={{
                fontSize: '14px',
                backgroundColor: '#7FB539',
                marginTop: '20px',
                '&:hover': {
                  backgroundColor: '#72a333',
                },
              }}
              onClick={login}>
              Login
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
export default Logout
