import { KeyValPair, KeyValPairSelect } from '@models/Common'
import { MenuItem, Select, FormHelperText } from '@mui/material'

import { useTranslation } from 'react-i18next'

const TableSelect = (props: {
  uneditable?: boolean
  options?: KeyValPair[]
  error?: any
  value?: any
  field?: any
  minHeight?: number | string
  additionalOnChangeAction?: any
  datumIndex: number
  sx?: any
  fieldProps?: any
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Select
        error={!!props.error}
        blurOnSelect
        fullWidth
        sx={{
          fontSize: 12,
          backgroundColor: '#ffffff',
          height: props.minHeight,
          ...props.sx,
        }}
        {...props.field}
        onChange={async (event: any) => {
          props.field.onChange!(event)
          if (props.additionalOnChangeAction) {
            if (props.additionalOnChangeAction.constructor.name === 'AsyncFunction') {
              await props.additionalOnChangeAction(event, props.datumIndex)
            } else {
              props.additionalOnChangeAction(event, props.datumIndex)
            }
          }
        }}
        {...props.fieldProps}>
        {props.options!.map((option: KeyValPairSelect) => {
          console.log("option.value", option.value)
          if (option?.isGroup) {
            return <MenuItem disabled>{t(option.value)}</MenuItem>
          } else {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                value={option.key}
                key={`form-table-select-option-${option.key}`}>
                {t(option.value)}
              </MenuItem>
            )
          }
        })}
      </Select>
      <FormHelperText error={!!props.error}>{props.error?.message}</FormHelperText>
    </>
  )
}

export default TableSelect
