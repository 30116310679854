import { Box, IconButton, Button } from '@mui/material'
import CheckBox from '@mui/material/Checkbox'
import { useRef, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useCallback, useState } from 'react'
import Popover from '@mui/material/Popover'
import '@styles/lightGallery.module.scss'
import 'photoswipe/dist/photoswipe.css'
import DownloadIcon from '@mui/icons-material/Download'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Gallery, Item } from 'react-photoswipe-gallery'
import Checkbox from '@mui/material/Checkbox'
import { MediaModel } from '@services/model/media.model'
import { FileToFileModel, GetFileFromUrl } from '@utils/file'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

const checkDefaultSelected = (image: MediaModel, defaultSelected: any[]) => {
  // api called whenever the gallery is clicked
  // each images have selected property.
  // As user will be able to reselect again,
  // to retent the selected state between each call,
  // there is a gallerySelected list saved separately
  // and passed as defaultSelected to the ImageGallery component
  // so the checkbox will check against the defaultSelected List first
  // then the selected property of the image from the api
  return defaultSelected?.find((s) => s?.fileId === image.fileId && s?.selected === true)
    ? true
    : defaultSelected?.find((s) => s?.fileId === image.fileId && s?.selected === false)
    ? false
    : image.selected === true
    ? true
    : false
}

const handleDownloadFile = async (file) => {
  if (file) {
    const fileBytes = file.bytes
    if (fileBytes) {
      const download = document.createElement('a')
      download.href = window.URL.createObjectURL(new Blob([fileBytes]))
      download.setAttribute('download', `${file.fileName}.${file.ext}`)
      document.body.appendChild(download)
      download.click()
      document.body.removeChild(download)
    } else if (file.fileUrl && file.fileUrl !== '') {
      const blob = await GetFileFromUrl(file)
      if (blob) {
        const download = document.createElement('a')
        download.href = URL.createObjectURL(blob)
        download.setAttribute('download', `${file.fileName}.${file.ext}`)
        document.body.appendChild(download)
        download.click()
        document.body.removeChild(download)
      }
    }
  }
}

export default function useImageGallery(): {
  ImageGallery: ({
    disabled,
    uneditable,
    onChange,
    defaultSelected,
    onUploadClick,
    onRefreshClick,
  }: {
    onChange?: (
      checked?: boolean,
      fileId?: string,
      chitPhotoId?: string,
      gallerySelected?: string,
      ...event: any
    ) => void
    defaultSelected?: any
    onUploadClick?: (...event: any[]) => void
    onRefreshClick?: (...event: any[]) => void
    disabled?: boolean
    uneditable?: boolean
  }) => JSX.Element
  openImageGallery: () => void
  closeImageGallery: () => void
  isImageGalleryOpen: boolean
  setImageGalleryImages: (images: MediaModel[]) => void
  setImageGallerySelected: (gallerySelected: string) => void
  galleryImages: MediaModel[]
} {
  const [isImageGalleryOpen, setOpen] = useState<boolean>(false)
  const [galleryImages, setGalleryImages] = useState<MediaModel[]>([])
  const [gallerySelected, setGalleryTarget] = useState<string>('')
  const openImageGallery = () => setOpen(true)
  const closeImageGallery = () => setOpen(false)
  const setImageGalleryImages = (images: MediaModel[]) => {
    setGalleryImages(images)
  }
  const setImageGallerySelected = (gallerySelected: string) => {
    setGalleryTarget(gallerySelected)
  }
  const placeHolderImage = '/img/file-outlined.png'
  const placeHolderImageRegular = '/img/file-outlined.svg'
  const ImageGallery = useCallback(
    ({
      onChange,
      defaultSelected,
      onUploadClick,
      onRefreshClick,
      disabled,
      uneditable,
    }: {
      onChange?: (
        checked?: boolean,
        fileId?: string,
        chitPhotoId?: string,
        gallerySelected?: string,
        ...event: any
      ) => void
      defaultSelected?: any
      onUploadClick?: (...event: any[]) => void
      onRefreshClick?: (...event: any[]) => void
      disabled?: boolean
      uneditable?: boolean
    }) => {
      return (
        <Popover
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          onClose={closeImageGallery}
          open={isImageGalleryOpen}
          sx={{
            zIndex: '999 !important',
            // position: 'fixed',
            width: '500px',
            // overflowX: 'hidden',
          }}>
          <div id="caption">
            {/* <Button
              onClick={(event) => {
                onUploadClick!()
              }}>
              Upload
            </Button> */}
            <Button
              onClick={(event) => {
                onRefreshClick!()
              }}>
              Refresh
            </Button>
          </div>
          <Box
            className="testc"
            sx={{ width: '400px', height: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Gallery options={{ padding: { top: 5, bottom: 5, left: 5, right: 5 } }}>
              <div
                style={{
                  width: '400px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: 10,
                  marginLeft: '15',
                  marginRight: '15',
                }}>
                {galleryImages?.map((image, index) => {
                  const img = new Image()
                  img.src = image?.regularUrl ?? ''
                  let width = img.width
                  let height = img.height
                  img.onload = () => {
                    width = img.width
                    height = img.height
                  }
                  return (
                    <>
                      <Item
                        original={image.regularUrl ?? placeHolderImageRegular}
                        thumbnail={image.regularUrl ?? placeHolderImage}
                        width={width ?? '1024'}
                        height={height ?? '768'}>
                        {({ ref, open }) => (
                          <Box>
                            <Box
                              className="gContainer"
                              style={{
                                width: '180px',
                                height: '180px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                              }}>
                              <img
                                className="gImage"
                                src={image.regularUrl ?? placeHolderImage}
                                style={{ width: '180px', margin: '0 auto  0 auto' }}
                              />
                              <Box className="gMiddle">
                                <Box
                                  //@ts-ignore
                                  ref={ref}
                                  onClick={open}>
                                  <IconButton>
                                    <OpenInFullIcon />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <CheckBox
                                    defaultChecked={checkDefaultSelected(image, defaultSelected!)}
                                    disabled={disabled || uneditable}
                                    className="gCheckbox"
                                    onChange={(event) => {
                                      onChange!(
                                        event.target.checked,
                                        image.fileId,
                                        image?.id,
                                        gallerySelected,
                                      )
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={(event) => {
                                      handleDownloadFile(image)
                                    }}>
                                    <DownloadIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                            <Box sx={{ width: 180, height: 50, overflow: 'hidden' }}>
                              {image.fileName}
                            </Box>
                          </Box>
                        )}
                      </Item>
                    </>
                  )
                })}

                {!galleryImages || galleryImages.length === 0 ? <>No image</> : <></>}
              </div>
            </Gallery>
          </Box>
        </Popover>
      )
    },
    [isImageGalleryOpen, galleryImages],
  )

  return {
    ImageGallery,
    openImageGallery,
    closeImageGallery,
    isImageGalleryOpen,
    setImageGalleryImages,
    setImageGallerySelected,
    galleryImages,
  }
}
