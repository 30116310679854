import { styled, useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'

const TableImageHolder = styled(Box)(({ theme }) => ({
  margin: 'auto',
  border: '0.1px solid rgb(0 0 0 / 20%)',
  objectFit: 'contain',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 70,
  },
  [theme.breakpoints.up('md')]: {
    width: 300,
    height: 200,
  },
}))

export default TableImageHolder
