import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { CSSProperties } from 'react'
import { get } from 'lodash'
type FormDataGridProps = {
  onChange?: (...event: any[]) => void
  rows: Record<string, any>[]
  columns: GridColDef[]
  initialState?: any
  checkboxSelection?: boolean
  sx?: CSSProperties
  uneditable?: boolean
  disabled?: boolean
  value?: any
  getRowId?: (row: GridRowParams) => string
}
const FormDataGrid = (props: FormDataGridProps) => {
  if (!props.onChange) {
    // return <>missing onChange from props</>
  }
  let initialState = props.initialState
  if (!get(props, 'initialState.pagination.paginationModel.pageSize')) {
    initialState = {
      ...props.initialState,
      pagination: { paginationModel: { pageSize: 5 } },
    }
  }
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        getRowId={props.getRowId}
        rows={props.rows}
        columns={props.columns}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          props.onChange!(newRowSelectionModel)
        }}
        rowSelectionModel={props.value}
        checkboxSelection={props.checkboxSelection ?? true}
        sx={{ backgroundColor: 'transparent', ...props.sx }}
        isRowSelectable={(params: GridRowParams) =>
          props.disabled || props.uneditable ? true : true
        }
        initialState={initialState}
      />
    </Box>
  )
}

export default FormDataGrid
