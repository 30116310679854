import { menuItems } from '@components/sidebar/menuList'

export function getFormListOrder() {
  let formList: {
    module: string
    formCode: string
  }[] = []
  menuItems.forEach((module) => {
    let m = module.id
    module.children.forEach((form) => {
      let shortName = form.acronym ? form.acronym : ''
      formList.push({
        module: m,
        formCode: shortName,
      })
    })
  })
  return formList
}
