import apiGateway from '@utils/axiosInterceptor'
// import { FormMrUploadModel } from '@services/model/form/form.MR.upload.model'
import { BaseFormResponse, FormStatusEnum } from '@services/model/form/form.model'

const SaveMrForm = async (req: any, formStatus?: string) => {
  return await apiGateway.post('api/v1/forms/MR', req).then((rep) => {
    return rep.data
  })
}

const GetMrList = async (req: object, cancelToken?: any, myRecordOnly?: boolean) => {
  return await apiGateway
    .post('/api/v1/forms/MR/list', req, { cancelToken: cancelToken })
    .then((rep) => {
      return rep.data
    })
}

const UpdateMrForm = async (req: any) => {
  if (req?.baseForm?.formId === '' || req?.fileId === '') {
    return
  }
  return await apiGateway
    .put(`api/v1/forms/MR/${req.baseForm.formId}/${req.fileId}`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteMrForm = async (formId: string, documentId?: string) => {}

const GetMrForm = async (formId: string, formStatus?: string) => {
  if (formId === undefined) {
    return
  }
  return await apiGateway.get(`api/v1/forms/MR/${formId}`).then((resp) => {
    return resp.data
  })
}

const FormMrService = {
  GetMrList,
  GetMrForm,
  SaveMrForm,
  UpdateMrForm,
  DeleteMrForm,
}

export default FormMrService
