import { Box } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import styles from '@styles/sidebar.module.scss'
import image from '../../assets/img/logo.png'
import toplogo from '../../assets/img/top-logo.png'
import ciclogo from '../../assets/img/cic.png'
import { width } from '@mui/system'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'

const SideMenu = () => {
  const { state, dispatch } = useContext(GlobalContext)
  // const handleLeftDrawerToggle = () => {
  //   dispatch({ type: 'toggleLeftDrawer' })
  // }
  const navigate = useNavigate()

  const handleClick = (event) => {
    NavigateTo(navigate, '/')
  }
  return (
    <>
      <Box
        component="img"
        className={styles.headerLogoImage}
        alt="Smart Waste Management Logo"
        sx={{ paddingTop: '20px', width: '80%' }}
        src={toplogo}
        onClick={handleClick} //handleLeftDrawerToggle
      />
      {/* <Box
        component="img"
        className={styles.headerLogoImage}
        alt="CIC Carbon Assessment Tool Logo"
        sx={{ paddingTop: '0px', width: '80%' }}
        src={ciclogo}
        onClick={handleClick}
      /> */}
    </>
  )
}
export default SideMenu
