export interface UserInfo {
  uuid: string
  email: string
  canCreateOrgCnt?: boolean
  canInviteUser?: boolean
  firstName: string
  lastName: string
  roles: number[]
  defaultRoleName?: string
  organization?: organizationModel
  position?: string
  allRights: boolean
  districts: number[]
  teams: number[]
  categoryRights?: {
    category: string
    rights: string[]
  }[]
  lastUpdateDt: Date | undefined
}
export interface organizationModel {
  id?: number
  uuid?: string
  organizationType: {
    id?: number
    name?: string
  }
  businessRegistrationNo?: string
  companyName?: string
}
export const InitUserInfo: UserInfo = {
  email: '',
  uuid: '',
  firstName: '',
  lastName: '',
  roles: [],
  allRights: false,
  lastUpdateDt: undefined,
  districts: [],
  teams: [],
}
export enum RolesEnum {
  SA = 1,
  EO,
  COW,
  EM,
  PJ_BEAMP,
  PJ_ADMIN,
  FM,
  GUEST,
  CIC_UA,
  CIC_PA,
}
