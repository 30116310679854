import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { pieChartProps } from './BasicPieChart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const DoughnutChart = (props: pieChartProps) => {
  const { t } = useTranslation()
  const translatedLabels = props.labels?.map((label) => t(label))

  let datasets = props.datasets.map((dataset) => {
    return {
      label: t(dataset.name as string),
      data: dataset.data,
      ...dataset.dataConfig,
    }
  })

  return (
    <Doughnut
      options={props.options}
      data={{
        labels: translatedLabels,
        datasets: [...datasets],
      }}
    />
  )
}
export default DoughnutChart
