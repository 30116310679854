import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  Stack,
  Drawer,
  Button,
  Popover,
} from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { AiOutlineArrowUp } from 'react-icons/ai'
import commonStyles from '@styles/common.module.scss'
import { FaSave } from 'react-icons/fa'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useTheme } from '@mui/material/styles'
import '@styles/table.module.scss'
import { useTranslation } from 'react-i18next'
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import {
  ArrayPath,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  useFieldArray,
} from 'react-hook-form'
import useImageModal from '@hooks/useImageModal'
import FormDialog from '@components/form/dialog_v2'
import {
  DialogContextProps,
  InitDialogContent,
  DrawerContextProps,
  InitDrawerContent,
} from '@components/form/table/model'
import FormTableBox from '@components/form/table/presentation/formTableBox'
import FormTableFooterButton from '@components/form/table/presentation/formTableFooterButton'
import FormTableAddIcon from '@components/form/table/presentation/formTableAddIcon'
import FormWidget from '@components/form/table/FormWidget'
import { RxPencil1 } from 'react-icons/rx'
import { FormTableChildProps } from '@components/form/table/model'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { TbTrashXFilled } from 'react-icons/tb'
import { el } from 'date-fns/locale'
import AddIcon from '@mui/icons-material/Add'
import LinkTableAddButton from '@components/form/table/presentation/LinkTableAddButton'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Fab from '@mui/material/Fab'

function MobileTable<F extends FieldValues>(props: FormTableChildProps<F>) {
  const { t } = useTranslation()
  const theme = useTheme()
  let itemNo = 0
  const [drawerContext, setDrawerContext] = useState<DrawerContextProps>(InitDrawerContent)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>()
  const { fields, append, remove, update } = useFieldArray({
    keyName: '_id',
    ...props.fieldArrayProps,
  })
  const [extraInfo, setExtraInfo] = useState<any>(props.extraInfo)

  const signatureBase64Ref = useRef<string | undefined>()
  const [signatureBase64, setSignatureBase64] = useState<Array<string>>([''])
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const [warningText, setWarningText] = useState<string | undefined>()
  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  useEffect(() => {
    setExtraInfo(props.extraInfo)
  }, [props.extraInfo])

  const appendField = (event: any) => {
    append({ status: true, mobileStatus: 'add' } as FieldArray<F, ArrayPath<F>>)
    if (props.additionalAppendAction) {
      props.additionalAppendAction(event)
    }
  }
  const handleClickHeaderBtn = (event: any) => {
    if (props.onHeaderAddButtonClick) {
      props.onHeaderAddButtonClick(append)
      return
    }
    appendField(event)
  }

  const handleClickFooterBtn = (event: any) => {
    if (props.onFooterButtonClick) {
      props.onFooterButtonClick(append)
      return
    }
    appendField(event)
  }

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }
  const handleDialogConfirm = (index: string) => {
    setDialogContext(InitDialogContent)
    let _signature: Array<any> = [...signatureBase64]
    _signature[index] = signatureBase64Ref[index]
    if (_signature) {
      setSignatureBase64(_signature)
    }
  }
  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  const editField = (event: any, index: number, action: string, extraInfo: any) => {
    switch (action) {
      case 'edit':
        props.onClickEditField!(
          event,
          index,
          action,
          extraInfo,
          fields,
          setDialogContext,
          dialogContext,
          handleDialogClose,
          editField,
        )
        break
      case 'download':
        props.onDownloadFile ? props.onDownloadFile(index, extraInfo) : null
        break
    }
  }

  const enableEditRow = (event: any, index: number, datum: any) => {
    const _field = fields[index]
    _field['mobileStatus'] = 'edit'
    update(index, _field)
  }

  const handleSaveRow = (event: any, index: number, datum: any) => {
    if (props.onClickSaveRowBtn) {
      props.onClickSaveRowBtn!(event, index, datum, updateField)
    } else {
      updateField(index)
    }
  }

  const updateField = (index) => {
    const _field = fields[index]
    _field['mobileStatus'] = 'display'
    update(index, _field)
  }

  const handleDrawerClose = (event) => {
    setDrawerContext(InitDrawerContent)
  }
  const openAddForm = (event: any) => {
    setIsDrawerOpen(true)
    if (!props.onOpenAddForm) return
    props.onOpenAddForm({
      event: event,
      append: append,
      drawerContext: drawerContext,
      setDrawerContext: setDrawerContext,
      InitDrawerContent: InitDrawerContent,
      closeAddForm: closeAddForm,
    })
  }

  const closeAddForm = (event: any) => {
    setIsDrawerOpen(false)
  }

  const checkWidgetDisable = (datum: any, column: any) => {
    if (props.disabled || datum.disable || column.disable) {
      return true
    }
  }
  const checkWidgetUneditable = (datum: any, column: any) => {
    if (
      props.uneditable ||
      datum.uneditable ||
      column.uneditable ||
      props.disabled ||
      column.disable
    ) {
      return true
    } else if (datum['mobileStatus'] === 'add' || datum['mobileStatus'] === 'edit') {
      false
    } else {
      return true
    }
  }

  const checkShowEditRowButton = (datum: any) => {
    if (props.uneditable || datum.uneditable || datum.disable) {
      return false
    } else if (datum['mobileStatus'] === 'add' || datum['mobileStatus'] === 'edit') {
      false
    } else {
      return true
    }
  }

  const checkShowDeleteRowButton = (datum: any) => {
    if (props.uneditable || datum.uneditable || datum.disable) {
      return false
    } else {
      return true
    }
  }

  const checkShowSaveRowButton = (datum: any) => {
    if (props.uneditable || datum.uneditable || datum.disable) {
      return false
    } else if (datum['mobileStatus'] !== 'add' && datum['mobileStatus'] !== 'edit') {
      false
    } else {
      return true
    }
  }

  const checkMobileHidden = (datum: any, column: any) => {
    if (
      column.mobileHidden &&
      datum['mobileStatus'] !== 'add' &&
      datum['mobileStatus'] !== 'edit' &&
      datum['mobileStatus'] !== 'displayDetail'
    ) {
      return true
    } else {
      return false
    }
  }

  const removeField = (event: any, index: number) => {
    // uuid / formId / id / fileId -> set status = false
    //           otherize -> remove record
    if (fields[index]['uuid'] || fields[index]['id'] || fields[index]['fileId']) {
      const _field = fields[index]
      _field['status'] = false
      update(index, _field)
    } else if (fields[index]['baseForm'] && fields[index]['baseForm']['formId']) {
      const _field = fields[index]
      _field['baseForm']['status'] = false
      update(index, _field)
    } else {
      remove(index)
    }
    if (props.additionalRemoveAction) {
      props.additionalRemoveAction(event, index)
    }
    setAnchorEl(null)
    setAnchorId(null)
  }
  const mobileShowRow = (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => {
    // if any other row's mobileStatus edit or add , hide this row
    return true
  }

  const [isEditing, setIsEditing] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [anchorId, setAnchorId] = useState<number | null>(null)

  const handlePopOverClick = (event: React.MouseEvent<HTMLButtonElement>, datumIndex) => {
    setAnchorEl(event.currentTarget)
    setAnchorId(datumIndex)
  }

  const handlePopOverClose = () => {
    setAnchorEl(null)
    setAnchorId(null)
  }
  const open = Boolean(anchorEl)
  const headerAddButtonClick = (event: any) => {
    if (props.onHeaderAddButtonClick) {
      props.onHeaderAddButtonClick(append)
      return
    }
    appendField(event)
  }
  const trigger = useScrollTrigger({
    // Number of pixels needed to scroll to toggle `trigger` to `true`.
    threshold: 100,
  })

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Grid xs={12} style={{ overflow: props.removeOverflow ? 'none' : 'auto', width: '100%' }}>
      <FormTableBox>
        <Box
          sx={{
            display:
              props.title || props.addWithPopupComponent || props.showHeaderAddButton
                ? 'flex'
                : 'none',
            padding: 2,
            alignItems: 'center',
          }}>
          {props.title ? (
            <Box
              sx={{
                maxWidth: 250,
              }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}>
                {props.title ? t(props.title) : ''}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {props.addWithPopupComponent && props.addWithPopupComponent(isPopupOpen, togglePopup)}
          {props.showHeaderAddButton ? (
            <LinkTableAddButton
              state={{
                showHeaderAddButton: props.showHeaderAddButton,
                disableAddButton: props.disableAddButton,
                uneditable: props.uneditable,
                color: props.color,
              }}
              onClick={headerAddButtonClick}>
              <AddIcon
                id="link-table-add-icon"
                sx={{
                  height: '100%',
                  width: '100%',
                  color: '#ffffff',
                }}
              />
              {props.addButtonText ? (
                <Box
                  id="add-button-hover-text"
                  style={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    padding: 10,
                    paddingRight: 70,
                    opacity: 0,
                  }}>
                  <Typography>
                    {props.addButtonText
                      ? t(props.addButtonText)
                      : t('Add a ') + props.fieldArrayProps.name}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </LinkTableAddButton>
          ) : (
            <></>
          )}
        </Box>
        <Stack spacing={theme.spacing(1)}>
          {fields.map((datum: FieldArrayWithId<F, ArrayPath<F>, '_id'>, datumIndex: number) => {
            if (
              props.rowFilter &&
              !props.rowFilter(
                datum as FieldArrayWithId<F, ArrayPath<F>, '_id'>,
                datumIndex,
                mobileShowRow,
              )
            ) {
              return <Fragment key={`form-table-row-${datumIndex}-${datum._id}`} />
            } else if (
              !props.rowFilter &&
              !(
                datum['status'] ||
                (datum['baseForm'] && datum['baseForm']['status']) ||
                !mobileShowRow(datum, datumIndex)
              )
            ) {
              return <Fragment key={`form-table-row-${datumIndex}-${datum._id}`} />
            }
            return (
              <Card key={`form-table-row-${datumIndex}-${datum._id}`}>
                <CardContent>
                  <Box
                    sx={{
                      // height: props.height ?? 200,
                      width: '100%',

                      backgroundColor: 'transparent',
                    }}>
                    <Box
                      style={{
                        flex: 1,
                        width: '100%',
                        position: 'relative',
                        textAlign: 'left',
                        alignItems: 'center',
                        padding: theme.spacing(1),
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={`form-table-cell-${datumIndex}-item-no`}>
                      <Typography textAlign={props.align ?? 'left'} fontSize={16}>
                        Item No.: {++itemNo}
                      </Typography>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {props.showEditDownloadButtons === true ? (
                            <>
                              {props.uneditable ||
                              props.disabled ||
                              datum['baseForm']?.['editable'] === false ? (
                                <></>
                              ) : (
                                <Box id="table-row-actions">
                                  <IconButton
                                    onClick={(event: any) => {
                                      editField(event, datumIndex, 'edit', extraInfo)
                                    }}>
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              )}
                              <Box id="table-row-actions">
                                <IconButton
                                  onClick={(event: any) => {
                                    editField(event, datumIndex, 'download', props.extraInfo)
                                  }}>
                                  <DownloadIcon />
                                </IconButton>
                              </Box>
                            </>
                          ) : (
                            <></>
                          )}
                          {props.showDeleteFileRecordButton === true ? (
                            props.uneditable ||
                            props.disabled ||
                            datum['baseForm']?.['editable'] === false ? (
                              <></>
                            ) : (
                              <Box id="table-row-actions">
                                <IconButton
                                  onClick={(event: any) => {
                                    removeField(event, datumIndex)
                                  }}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Box>
                            )
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: 'fff',
                            border: 'none',
                          }}>
                          <Box>
                            {props.disabled ||
                            props.uneditable ||
                            datum['baseForm']?.['editable'] === false ? (
                              <></>
                            ) : (
                              <Box id="table-row-actions">
                                {checkShowSaveRowButton(datum) ? (
                                  <IconButton
                                    className={commonStyles.svgIcon}
                                    onClick={(event) => handleSaveRow(event, datumIndex, datum)}>
                                    <FaSave />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {checkShowEditRowButton(datum) ? (
                                  <IconButton
                                    onClick={(event: any) => {
                                      enableEditRow(event, datumIndex, datum)
                                    }}>
                                    <RxPencil1 />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {checkShowDeleteRowButton(datum) &&
                                !props.disableDeleteRowButton ? (
                                  <>
                                    <IconButton
                                      onClick={(event) => handlePopOverClick(event, datumIndex)}>
                                      <BiDotsVerticalRounded />
                                    </IconButton>
                                    <Popover
                                      id={`popover-${datumIndex}`}
                                      open={Boolean(anchorEl) && anchorId === datumIndex}
                                      anchorEl={anchorEl}
                                      onClose={handlePopOverClose}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}>
                                      <Stack sx={{ borderRadius: '10px' }}>
                                        <IconButton
                                          onClick={(event: any) => {
                                            removeField(event, datumIndex)
                                          }}>
                                          <TbTrashXFilled />
                                        </IconButton>
                                        <IconButton
                                          onClick={(event: any) => {
                                            setAnchorEl(null)
                                            setAnchorId(null)
                                          }}>
                                          <RiArrowGoBackFill />
                                        </IconButton>
                                      </Stack>
                                    </Popover>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {props.columns.map((column, columnIndex: number) => {
                      let children: JSX.Element = <Fragment></Fragment>
                      if (column.hidden || checkMobileHidden(datum, column)) {
                        return children
                      }
                      if (column.type) {
                        children = (
                          <FormWidget
                            tooltip={column.tooltip}
                            column={column}
                            disabled={checkWidgetDisable(datum, column)}
                            uneditable={checkWidgetUneditable(datum, column)}
                            fieldArrayProps={props.fieldArrayProps}
                            datumIndex={datumIndex}
                            datum={datum}
                            handleImageModalOpen={handleImageModalOpen}
                            useWatch={props.useWatch}
                            setValue={props.setValue}
                            getValues={props.getValues}
                            setWarningText={setWarningText}
                            setDialogContext={setDialogContext}
                            handleDialogClose={handleDialogClose}
                            handleDialogConfirm={handleDialogConfirm}
                            signatureBase64={signatureBase64}
                            setSignatureBase64={setSignatureBase64}
                            signatureBase64Ref={signatureBase64Ref}
                            datePickerType={props.mobileDatePickerType ?? 'mobile-table'}
                          />
                        )
                      } else {
                        children = (
                          <Typography
                            sx={{
                              textAlign: 'left',
                              fontSize: 12,
                              whiteSpace: 'initial',
                              wordBreak: 'break-word',
                            }}>
                            {datum[column.field] ?? ''}
                          </Typography>
                        )
                      }
                      return (
                        <Box
                          style={{
                            // flex: 1,
                            minWidth: column.width ?? '100px',
                            position: 'relative',
                            textAlign: 'left',
                            padding: theme.spacing(1),
                          }}
                          key={`form-table-cell-${columnIndex}-${datumIndex}`}>
                          <Stack spacing={0}>
                            <Box sx={{ color: '#818181', fontSize: '12px' }}>
                              {t(column.displayName)}:
                            </Box>
                            <Box>{children}</Box>
                          </Stack>
                        </Box>
                      )
                    })}
                  </Box>
                </CardContent>
              </Card>
            )
          })}
          {props.additionalRow && props.additionalRow(fields)}

          {props.warningText && (
            <Typography
              sx={{
                color: 'red',
              }}>
              {t(props.warningText)}
            </Typography>
          )}
        </Stack>
      </FormTableBox>

      <props.ImageModal image={props.modalImage} />
      {props.removeAddButton ? (
        <></>
      ) : (
        <FormTableFooterButton
          disabled={props.disableAddButton || props.disabled || props.uneditable}
          startIcon={
            <FormTableAddIcon
              id="form-table-add-icon"
              sx={{
                height: 40,
                width: 40,
              }}
            />
          }
          onClick={(event: any) => {
            handleClickFooterBtn(event)
          }}
        />
      )}
      {props.showAddFilesButton === true ? (
        <>
          <Box
            sx={{
              boxShadow: 2,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              display: 'inherit',
            }}>
            <Button
              variant="contained"
              sx={{
                height: 60,
                width: '100%',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: '#ffffff',
                ':hover': {
                  backgroundColor: 'inherit',
                  '#form-table-add-icon': {
                    color: '#000000',
                  },
                },
                ':disabled': {
                  backgroundColor: '#d9d9d9',
                  '#form-table-add-icon': {
                    color: '#ffffff',
                  },
                },
              }}
              component="label"
              startIcon={
                <PostAddIcon
                  id="form-table-add-icon"
                  sx={{
                    color: 'black',
                    height: 40,
                    width: 40,
                  }}
                />
              }>
              <input
                hidden
                accept={props.acceptFileTypes ?? 'image/jpeg,image/gif,image/png,application/pdf'}
                multiple
                onClick={(event: any) => {
                  event.target.value = null
                }}
                type="file"
                onChange={(event: any) => {
                  props.onClickAddFiles
                    ? props.onClickAddFiles(event, append, props.extraInfo)
                    : null
                }}
              />
            </Button>
          </Box>
        </>
      ) : (
        <></>
      )}
      <Drawer
        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        children={drawerContext.children}
        sx={{ height: window.innerHeight, width: window.innerWidth }}
      />

      <Fab
        onClick={scrollToTop}
        size="small"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 50, right: 32, zIndex: 1 }}>
        <AiOutlineArrowUp />
      </Fab>
    </Grid>
  )
}

export default MobileTable
