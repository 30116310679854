import * as React from 'react'
import { CSSProperties } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: CSSProperties
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3, ...sx }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

interface TabsProps {
  data: { content?: React.ReactNode; menuTitle: string; title: string }[]
  sx?: CSSProperties
  variant?: 'default' | 'menu'
  tabBgColor?: string
}

const SwitchTabs = (props: TabsProps) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const menuStyleTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    color: '#393939', // set your desired color here
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '&.Mui-selected': {
      color: '#000', // set your desired color here
      backgroundColor: props.tabBgColor ?? 'inherit',
      borderRadius: '5px',
    },
  }))

  const defaultStyleTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    color: '#393939', // set your desired color here
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '&.Mui-selected': {
      color: '#000', // set your desired color here
      backgroundColor: 'white',
      borderRadius: '5px',
    },
  }))
  const StyledTab = props.variant === 'menu' ? menuStyleTab : defaultStyleTab

  const defaultStyleTabs = styled(Tabs)(({ theme }) => ({
    color: '#393939', // set your desired color here
    padding: theme.spacing(1),
    backgroundColor: '#cccccc',
    borderRadius: theme.spacing(1),
  }))

  const menuStyleTabs = styled(Tabs)(({ theme }) => ({
    color: '#393939', // set your desired color here
    padding: theme.spacing(1),
    borderBottom: `1px solid #ccc`,
  }))

  const StyledTabs = props.variant === 'menu' ? menuStyleTabs : defaultStyleTabs

  return (
    <Grid
      container
      md={12}
      spacing={matchUpMd ? 2 : 1}
      sx={{
        height: 'auto',
        display: 'flex',
        justifyContent: 'flex-end', // align items to the right
        ...props.sx,
      }}>
      <Grid
        item
        md={12}
        xs={12}
        sx={{ backgroundColor: 'transparent !important', order: { xs: 1, md: 1 }, marginTop: 0 }}>
        <StyledTabs
          // orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Total C&D Waste Generation Menu"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            height: 'auto',
            '& .MuiTab-root': {
              textAlign: 'left',
            },
          }}>
          {props.data?.map((item, index) => {
            return <StyledTab key={index} label={item.menuTitle} {...a11yProps(index)} />
          })}
        </StyledTabs>
      </Grid>
      <Grid item md={12} xs={12} sx={{ order: { xs: 2, md: 2 } }}>
        {props.data?.map((item, index) => {
          return (
            <TabPanel value={value} index={index}>
              {/* <Box>{item.title}</Box> */}
              <Box>{item.content}</Box>
            </TabPanel>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default SwitchTabs
