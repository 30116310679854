import FormContainer from '@components/form/container'
import FormField from '@components/form/field'
import { FormWmpModel } from '@services/model/form/form.WMP.model'
import FormFileUpload from '@components/form/fileUpload'
import FormTypography from '@components/form/typography'
import { Control } from 'react-hook-form'
import { Grid } from '@mui/material'
import FormController from '@components/form/controller'
import { useTranslation } from 'react-i18next'
import { FormMrModel } from '@services/model/form/form.MR.upload.model'
import FormDateTimePicker from '@components/form/dateTimePicker'
const WasteManagementPlanFormPartA = ({
  control,
  formPermission,
  currentContract,
  formId,
}: {
  control: Control<FormMrModel>
  formPermission: any
  currentContract: any
  formId?: string
}) => {
  let index = 0
  const MAX_FILE_SIZE = 5000000
  const { t } = useTranslation()
  const handleFileCheck = (event, file) => {
    let result = file
    if (file.size < MAX_FILE_SIZE) {
      result.validated = true
      return result
    }
    result.validated = false
    result.message = 'File size cannot be more than 5MB'
    return result
  }

  const disabled = !!formId && (!formPermission?.canUpdate || formPermission.endOfFlow)
  const downloadOnly = !formPermission?.canUpdate

  return (
    <Grid sx={{ minHeight: 620 }}>
      <FormContainer fkey={`$wmp-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormTypography value={currentContract?.contractNo} type="string" />
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormTypography value={currentContract?.contractName} type="string" />
        </FormField>
        <FormField fieldName={t('forms:monthlyReport.fields.dateFrom')} index={index++}>
          <FormController
            controllerProps={{
              name: 'dateFrom',
              control,
            }}>
            <FormDateTimePicker disabled={disabled} type="date" />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:monthlyReport.fields.dateTo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'dateTo',
              control,
            }}>
            <FormDateTimePicker disabled={disabled} type="date" />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:monthlyReport.title')} index={index++}>
          <FormController
            controllerProps={{
              name: 'monthlyReportFileList',
              control,
            }}>
            <FormFileUpload
              uneditable={downloadOnly}
              disabled={disabled}
              acceptFiles={['application/pdf']}
              uploadChecker={handleFileCheck}></FormFileUpload>
          </FormController>
        </FormField>
      </FormContainer>
    </Grid>
  )
}

export default WasteManagementPlanFormPartA
