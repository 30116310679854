import { Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Base64StringToImageBase64 } from '@utils/image'
import { FieldError } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useFormState } from 'react-hook-form'
import { useRef } from 'react'
import FormHelperText from '@mui/material/FormHelperText'

interface formSignatureHolderProps {
  value?: string // base64
  newValue?: any
  onChange?: (...event: any[]) => void
  onClick: (...event: any[]) => any
  error?: FieldError
  disabled?: boolean
  control?: any
  uneditable?: boolean
}

export default function FormSignatureHolderForTable(props: formSignatureHolderProps) {
  const [rerender, setRender] = useState(0)
  const [image, setImage] = useState(props.value)
  const handleOnClick = (event) => {
    //@ts-ignore
    if (props.disabled || props.uneditable) return
    if (buttonRef.current) buttonRef.current.focus()
    if (props.onClick) {
      props.onClick()
    }
  }
  const buttonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.newValue && props.value !== props.newValue) {
      props.onChange!(props.newValue)
    }
    if (props.value) {
      setImage(props.value)
    }
  }, [props.value, props.newValue])
  return (
    <Box
      ref={buttonRef}
      tabIndex={-1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        height: 60,
        width: '100%',
        borderBottom: '1px solid rgb(50, 50, 50)',
        borderColor: props.error ? 'red' : '',
        ...(!props.disabled && {
          cursor: 'pointer',
        }),
      }}
      onClick={handleOnClick}>
      {props.value ? (
        <img
          src={Base64StringToImageBase64(image, 'png')}
          alt="signature"
          style={{
            height: 60,
            width: '100%',
            objectFit: 'contain',
          }}
        />
      ) : (
        <Box>
          <EditIcon sx={{ color: props.disabled ? 'gray' : 'red' }} />
        </Box>
      )}
      <FormHelperText error={props.error ? true : false}>{props.error?.message}</FormHelperText>
    </Box>
  )
}
