import SearchPanel from '@components/searchPanel'
import { Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { FormWftListModel } from '@services/model/form/form.WFT.model'
import FormWftService from '@services/formService/form.WFT.service'
import { NavigateTo } from '@utils/navigate'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { excelExporter } from '@utils/excelExporter'
import { FormWftGeneralOptionsModel } from '@services/model/form/form.WFT.model'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import { RiFileTextFill } from 'react-icons/ri'
import Typography from '@mui/material/Typography'
import getErrMsg from '@utils/getErrMsg'
import { KeyValPair } from '@models/Common'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const { setRequest, isLoading } = useAPIFetch()
  const refMounted = useRef(true)
  const { state: globalState, userInfo, hasRightByCatAndCode } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const [extraListInfo, setExtraListInfo] = useState<{
    submittedByList: string[]
    approvedByList: string[]
    formIdList: KeyValPair[]
    parentFormIdList: KeyValPair[]
  }>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
  })
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [wftGeneralOptions, setWftGeneralOptions] = useState<FormWftGeneralOptionsModel>()

  const reloadWFTGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormWftService.GetWftGeneralOptions()
          .then(async (res) => {
            setWftGeneralOptions(res)
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const reloadCallback = async (
    pagination: object,
    sorting: object | undefined,
    cancelToken: any,
  ) => {
    checkRecordDisableCreateBtn()
    let contractNoId = contractId ?? globalState.currentContract?.contractId
    if (!contractNoId) return
    const res = await FormWftService.GetWftList(
      {
        pagination: pagination,
        sorting: sorting,
        contractNo: {
          Operator: '=',
          Value: [contractNoId],
        },
      },

      cancelToken,
      props.showMyRecord || false,
    )

    setExtraListInfo({
      submittedByList: res.submittedByList,
      approvedByList: res.approvedByList,
      formIdList: res.formIdList,
      parentFormIdList: res.parentFormIdList,
    })

    return res
  }
  const [disableCreateBtn, setDisableCreateBtn] = useState<boolean>(false)
  const checkRecordDisableCreateBtn = () => {
    let contractNoId = contractId ?? globalState.currentContract?.contractId
    if (!contractNoId) return
  }

  useEffect(() => {
    reloadWFTGeneralOptions()
    checkRecordDisableCreateBtn()
    document.title = t('forms:wasteForecastTable.title', { ns: common.i18nFormNS })
  }, [])

  const [contractId, setContractId] = useState<number>(0)
  useEffect(() => {
    setContractId(globalState.currentContract?.contractId)
  }, [globalState.currentContract])

  const formatData = (rows, constructionStageList, materialList, wasteManagementMethodList) => {
    const data = rows.map((row) => {
      const constructionStage = constructionStageList.find(
        (item) => item.key === row.constructionStage,
      )
      const material = materialList.find((item) => item.key === row.material)
      const wasteManagementMethod = wasteManagementMethodList.find(
        (item) => item.key === row.wasteManagementMethod,
      )
      row.constructionStartDate = moment(row.constructionStartDate).format('MM/YYYY')
      row.constructionEndDate = moment(row.constructionEndDate).format('MM/YYYY')

      return {
        ...row,
        constructionStage: constructionStage?.value,
        material: material?.value,
        wasteManagementMethod: wasteManagementMethod?.value,
      }
    })

    return data
  }

  const handleClickDownload = (formId: string, formStatus: string, reportNo: string) => {
    let rows: any

    const header = [
      { headerName: 'Item No', dataName: 'itemNo' },
      { headerName: 'Construction Stage', dataName: 'constructionStage' },
      { headerName: 'Construction Start Date', dataName: 'constructionStartDate' },
      { headerName: 'Construction End Date', dataName: 'constructionEndDate' },
      { headerName: 'Material', dataName: 'material' },
      {
        headerName: 'Estimated Quantity (in cubic meter)',
        dataName: 'estimatedQuantityVolume',
      },
      { headerName: 'Estimated Quantity (in tonnes)', dataName: 'estimatedQuantityWeight' },
      { headerName: 'Planned Waste Management Method', dataName: 'wasteManagementMethod' },
      { headerName: 'Remarks', dataName: 'remarks' },
    ]

    const columnWidths = [300, 500, 100, 100, 100, 100, 100, 100]

    if (formId) {
      setRequest({
        callback: async () => {
          if (!formId) return
          const list: Array<any> = []
          await FormWftService.GetWftForm(formId, formStatus).then(async (res) => {
            if (res) {
              let rows = res?.rows
              let formattedData: any
              if (rows) {
                formattedData = formatData(
                  rows,
                  wftGeneralOptions?.constructionStage,
                  wftGeneralOptions?.material,
                  wftGeneralOptions?.wasteManagementMethod,
                )
                formattedData = formattedData?.map((d, i) => {
                  d.itemNo = i + 1
                  return d
                })
                excelExporter({
                  data: formattedData,
                  filename: `Waste Forecast Table ${reportNo}`,
                  header: header,
                  titleStyles: {
                    border: {
                      left: { style: 'thin' },
                      right: { style: 'thin' },
                      top: { style: 'thin' },
                      bottom: { style: 'thin' },
                    },
                  },
                  bodyStyles: {
                    border: {
                      left: { style: 'thin' },
                      right: { style: 'thin' },
                      top: { style: 'thin' },
                      bottom: { style: 'thin' },
                    },
                  },
                })
              }
            }
          })
        },
      })
    }
  }

  const [TableView, reload] = useTableView<FormWftListModel>({
    headers: [
      {
        key: 'short_name',
        desc: t('common:baseForm.generalStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormWftListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'report_no',
        desc: t('common:baseForm.formId', { ns: common.i18nFormNS }),
        renderCell: (record: FormWftListModel) => <Fragment>{record.reportNo}</Fragment>,
      },

      {
        key: 'updated_at',
        desc: t('common:baseForm.submittedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormWftListModel) => (
          <Fragment>
            {GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.updatedAt', { ns: common.i18nFormNS }),
        renderCell: (record: FormWftListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:buttons.download', { ns: common.i18nFormNS }),
        renderCell: (record: FormWftListModel) => (
          <Fragment>
            <IconButton
              className="download-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
              }}
              onClick={() => {
                handleClickDownload!(record.formId, record.formStatus, record.reportNo)
              }}>
              <DownloadIcon />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormWftListModel, event: any) {
      if (
        event.target.matches('.download-button') ||
        event.target.matches('.MuiSvgIcon-root') ||
        event.target.matches('path')
      ) {
        handleClickDownload!(record.formId, record.formStatus, record.reportNo)
      } else {
        NavigateTo(navigate, '/waste-forecast-table/:id', { id: record.formId })
      }
    },
    mountedRef: refMounted,
    reloadCallback: reloadCallback,
  })

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('My Actions') : t('All Records')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            disableCreateBtn || !hasRightByCatAndCode(FormStatusEnum.WasteForecastTable, ['C'])
              ? undefined
              : '/waste-forecast-table'
          }
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
