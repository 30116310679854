import SearchPanel from '@components/searchPanel'
import { Grid, TableCell, useTheme } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { FormDrsUploadListModel } from '@services/model/form/form.DRS.upload.model'
import FormDrsService from '@services/formService/form.DRS.service'
import { NavigateTo } from '@utils/navigate'
import { FormStatusEnum } from '@services/model/form/form.model'
import { RiFileTextFill } from 'react-icons/ri'
import Typography from '@mui/material/Typography'

const DailyRecordSummaryUploadAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const theme = useTheme()
  const { state: globalState, userInfo, hasRightByCatAndCode } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const { t } = useTranslation()
  const [contractId, setContractId] = useState<number>(0)
  useEffect(() => {
    setContractId(globalState.currentContract?.contractId)
  }, [globalState.currentContract])
  const [TableView, reload] = useTableView<FormDrsUploadListModel>({
    headers: [
      {
        key: 'chitNo',
        desc: t('Chit/DDF No', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsUploadListModel) => (
          <Fragment>{record.chitNo ?? 'N/A'}</Fragment>
        ),
      },
      {
        key: 'reportingPeriod',
        desc: t('Date of Disposal', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsUploadListModel) => (
          <Fragment>
            {record.reportPeriod ? moment(record.reportPeriod).format('DD-MM-yyyy') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsUploadListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormDrsUploadListModel) {
      NavigateTo(navigate, '/daily-record-summary-upload/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (contractId > 0) {
        return await FormDrsService.GetDrsUploadedDocumentList(
          {
            pagination: null,
            sorting: null,
            contractNo: {
              Operator: '=',
              Value: [contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
      } else {
        return await FormDrsService.GetDrsUploadedDocumentList(
          {
            pagination: null,
            sorting: null,
            contractNo: {
              Operator: '=',
              Value: [globalState.currentContract?.contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
      }
    },
  })

  useEffect(() => {
    document.title = t('Notification Form of Defect', { ns: common.i18nFormNS })
    // eslint-disable-next-line
  }, [])

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {t('Upload')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryUploadPlatform, ['C'])
              ? '/daily-record-summary-upload'
              : undefined
          }
          // target="_self"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default DailyRecordSummaryUploadAllRecordsPage
