import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

export const downMd = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('md'))
}

export const upMd = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('md'))
}
