import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { MediaModel } from '../media.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'
import { Construction } from '@mui/icons-material'
export interface FormWmptModel {
  baseForm: FormModel
  goodSitePractice?: boolean
  hardRockConcrete?: boolean
  excavatedSoil?: boolean
  otherInertWaste?: boolean
  metals?: boolean
  paperPackaging?: boolean
  plastics?: boolean
  timber?: boolean
  constructionChemicalWaste?: boolean
  otherNonInertWaste?: boolean
  constructionOtherWaste?: boolean
  municipalSolidWaste?: boolean
  operationChemicalWaste?: boolean
  operationOtherWaste?: boolean
  list?: FormWmptList[]
  signatureBase64?: string
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
}

export interface FormWmptList {
  goodSitePractice?: boolean
  hardRockConcrete?: boolean
  excavatedSoil?: boolean
  otherInertWaste?: boolean
  metals?: boolean
  paperPackaging?: boolean
  plastics?: boolean
  timber?: boolean
  constructionChemicalWaste?: boolean
  otherNonInertWaste?: boolean
  constructionOtherWaste?: boolean
  municipalSolidWaste?: boolean
  operationChemicalWaste?: boolean
  operationOtherWaste?: boolean
}

export const InitWmptForm: FormWmptModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.WasteManagementPlanTemplateDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormWmptListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo?: number
  chitNo?: number
  contractNoId: number
  archivedDate: Date
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  createdAt: Date
  reportPeriod?: Date
}

export type FormWmptGeneralOptionsModel = {}
