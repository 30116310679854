import apiGateway from '@utils/axiosInterceptor'
import { FormMwfModel } from '@services/model/form/form.MWF.model'
import { CicGeneralOptionsModel } from '@services/model/general_option.model'
import { FormMwfUploadModel } from '@services/model/form/form.MWF.upload.model'
import { BaseFormResponse, FormStatusEnum } from '@services/model/form/form.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<CicGeneralOptionsModel>(`/api/v1/forms/MWF/options`).then((rep) => {
    return rep.data
  })
}

const GetMwfUploadedDocumentList = async (req: object) => {
  return await apiGateway.post('/api/v1/forms/MWF_UP/list', req).then((rep) => {
    return rep.data
  })
}

const UpdateMWFUploadedDocument = async (req: any) => {
  if (req?.baseForm?.formId === '' || req?.fileId === '') {
    return
  }
  return await apiGateway
    .put(`api/v1/forms/MWF_UP/${req.baseForm.formId}/${req.fileId}`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteMwfUploadedDocument = async (formId: string, documentId: string) => {
  return await apiGateway
    .put<any>(`/api/v1/forms/MWF_UP/${formId}/${documentId}/status/false`)
    .then((rep) => {
      return rep.data
    })
}

const LockUnlockMWFDocument = async (req: any, method: string) => {
  if (!req.baseForm.contractNoId || !req.baseForm.formId) {
    return
  }
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.MonthlyWasteFlowTableDraft
  }
  return await apiGateway
    .put(`api/v1/forms/MWF/${formStatus}/${req.baseForm.formId}/${method}`)
    .then((rep) => {
      return rep.data
    })
}

const GetMwfForm = async (formId: string, formStatus: string) => {
  if (formId === undefined) {
    return
  }
  if (!formStatus || formStatus === '') formStatus = 'FORM_MWF_DRAFT'
  return await apiGateway.get(`api/v1/forms/MWF/${formStatus}/${formId}`).then((rep) => {
    return rep.data
  })
}

const GetMwfList = async (req: any, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post(`/api/v1/forms/MWF/list`, req).then((rep) => {
    return rep.data
  })
}

const GetMwfExportList = async (req: any) => {
  return await apiGateway.post(`/api/v1/forms/MWF/export-list`, req).then((rep) => {
    return rep.data
  })
}

const SaveMwfForm = async (req: FormMwfModel) => {
  console.debug('SaveMwfForm', req)
  if (req.baseForm.formId === '') {
    return await apiGateway.post('api/v1/forms/MWF/', req).then((rep) => {
      return rep.data.baseForm
    })
  } else {
    let formStatus = req.baseForm.formStatus
    if (!formStatus || formStatus === '') {
      formStatus = FormStatusEnum.MonthlyWasteFlowTableDraft
    }
    return await apiGateway
      .put(`api/v1/forms/MWF/${formStatus}/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveMwfForm = async (req: FormMwfModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.MonthlyWasteFlowTableDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/MWF/${formStatus}/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectMwfForm = async (req: FormMwfModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.MonthlyWasteFlowTableDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/MWF/${formStatus}/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteMwfForm = async (req: FormMwfModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.MonthlyWasteFlowTableDraft
  }
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/MWF/${formStatus}/${req.baseForm.formId}`,
  )
}

const SaveMWFDocument = async (req: FormMwfUploadModel) => {
  return await apiGateway.post('api/v1/forms/MWF_UP/', req).then((rep) => {
    return rep.data
  })
}

const FormMwfService = {
  GetMwfForm,
  GetMwfList,
  GetMwfExportList,
  SaveMwfForm,
  ApproveMwfForm,
  RejectMwfForm,
  DeleteMwfForm,
  GetGeneralOptions,
  GetMwfUploadedDocumentList,
  LockUnlockMWFDocument,
  SaveMWFDocument,
  UpdateMWFUploadedDocument,
  DeleteMwfUploadedDocument,
}

export default FormMwfService
