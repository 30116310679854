import { CSSProperties } from '@emotion/serialize'
import { Box, Grid, Tooltip, Typography, useMediaQuery, Stack } from '@mui/material'
import { styled, SxProps, useTheme } from '@mui/material/styles'
import { Theme } from '@mui/system'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
}))

const FormFieldContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '14px 60px',
  },
  minHeight: 67,
  whiteSpace: 'initial',
  wordBreak: 'break-word',
}))

const FormStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const FormFieldNameBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 200,
    width: 200,
    // padding: theme.spacing(1),
  },
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

const FormFieldContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 200,
    width: '100%',
    // padding: theme.spacing(1),
  },
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

interface formFieldProps {
  children: any
  fieldName: ReactElement | string
  index?: number
  fieldTooltip?: string
  fieldTooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
  removeColon?: boolean
  hidden?: boolean
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
}

/**
 * form field wrapping a child jsx element
 * @param props formFieldProps
 * @example
 * ```
 * <FormField fieldName="Weather" index={8}>
 *      <FormController
 *          controllerProps={{
 *              name: 'weatherId',
 *              control: control,
 *          }}>
 *          <FormSelect fullWidth options={masterData.weather} />
 *      </FormController>
 *  </FormField>
 * ```
 */
const FormField = (props: formFieldProps) => {
  const { t } = useTranslation()

  if (props.hidden) {
    return <></>
  } else {
    return (
      <FormFieldContainer item xs={12}>
        <FormStack direction="row" spacing={0} alignItems={props.alignItems ?? 'start'}>
          {/* <FormFieldNameBox sx={{ paddingTop: 1 }}> */}
          <FormFieldNameBox sx={{ paddingTop: 0 }}>
            <Tooltip
              title={props.fieldTooltip ? t(props.fieldTooltip) : ''}
              placement={props.fieldTooltipPlacement ?? 'right'}>
              <StyledTypography>
                {typeof props.fieldName === 'string' ? t(props.fieldName) : props.fieldName}
                {!props.removeColon ? ':' : ''}
              </StyledTypography>
            </Tooltip>
          </FormFieldNameBox>
          <FormFieldContentBox>{props.children}</FormFieldContentBox>
        </FormStack>
      </FormFieldContainer>
    )
  }
}
export default FormField
