import { Nil } from '@models/Common'
import { stringToNumber } from './number'

export const parseLocalStorageValue = <T = any>(key: string, fallback?: T): Nil<T> => {
  const value = window.localStorage.getItem(key) as Nil<string>
  if (value) {
    try {
      switch (value) {
        case 'undefined':
          return undefined
        case 'null':
          return null
        default:
          return JSON.parse(value) as T
      }
    } catch (e) {
      return stringToNumber(value, value || fallback) as Nil<T>
    }
  }

  return (value || fallback) as Nil<T>
}
