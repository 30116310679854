import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormTypography from '@components/form/typography'
import FormRadio from '@components/form/radio'
import { Box } from '@mui/material'
import { FormMwfModel } from '@services/model/form/form.MWF.model'
import { Control } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const MonthlyWasteFlowTableFormPartA = ({
  mwfGeneralOptions,
  uneditable,
  control,
  showOtherConstructionStageInput,
  handleOnSave,
  formId,
  partAInfoMissing,
  useWatch,
}: {
  mwfGeneralOptions: any
  uneditable: boolean
  control: Control<FormMwfModel>
  showOtherConstructionStageInput: boolean
  handleOnSave: any
  formId?: string
  partAInfoMissing?: boolean
  useWatch: any
}) => {
  let index = 0

  const [constructionStageList, setConstructionStageList] = useState<any>([])
  const [wasteQuantityReportingUnitList, setWasteQuantityReportingUnitList] = useState<any>([])
  const { t } = useTranslation()
  useEffect(() => {
    if (constructionStageList.length === 0 && mwfGeneralOptions?.constructionStageList)
      setConstructionStageList(mwfGeneralOptions.constructionStageList)
    if (constructionStageList.length === 0 && mwfGeneralOptions?.wasteQuantityReportingUnitList)
      setWasteQuantityReportingUnitList(mwfGeneralOptions.wasteQuantityReportingUnitList)
  }, [mwfGeneralOptions])
  const [reportPeriod, wasteQuantityReportingUnit, constructionStageId, documentLock] = useWatch({
    name: ['reportPeriod', 'wasteQuantityReportingUnit', 'constructionStageId', 'documentLock'],
    control: control,
  })
  return (
    <>
      <FormContainer fkey={`$mwf-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractName',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>

        <FormField fieldName={t('forms:monthlyWasteFlowTable.fields.reportPeriod')} index={index++}>
          <FormController
            controllerProps={{
              name: 'reportPeriod',
              control,
            }}>
            <FormDateTimePicker type="month" maxDate={new Date()} uneditable={uneditable} />
          </FormController>
        </FormField>

        <FormField
          fieldName={t('forms:monthlyWasteFlowTable.fields.constructionStage')}
          index={index++}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <FormController
              controllerProps={{
                name: 'constructionStageId',
                control: control,
              }}>
              <FormRadio labels={constructionStageList || []} uneditable={uneditable} />
            </FormController>
            {showOtherConstructionStageInput ? (
              <FormController
                controllerProps={{
                  name: 'otherConstructionStage',
                  control: control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
              </FormController>
            ) : null}
          </Box>
        </FormField>

        <FormField
          fieldName={t('forms:monthlyWasteFlowTable.fields.wasteQuantityReportingUnit')}
          index={index++}>
          <FormController
            controllerProps={{
              name: 'wasteQuantityReportingUnit',
              control: control,
            }}>
            <FormRadio
              labels={wasteQuantityReportingUnitList || []}
              uneditable={uneditable}
              additionalOnChangeAction={() => {
                if (!formId && reportPeriod && wasteQuantityReportingUnit && constructionStageId) {
                  handleOnSave()
                }
              }}
            />
          </FormController>
        </FormField>
      </FormContainer>
    </>
  )
}
export default MonthlyWasteFlowTableFormPartA
