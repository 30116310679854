import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { MediaModel } from '../media.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'
export interface FormDrsModel {
  id?: string
  editable?: boolean
  baseForm: FormModel
  approvedDate?: Date
  signatureBase64?: string
  dateOfDisposal?: Date
  designatedDisposalGround?: KeyValPair[]
  approvedAlternativeDisposalGround?: KeyValPair[]
  otherApprovedAlternativeDisposalGround?: string
  drsItems: part1Part2Model[]
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
  currentDrsChit?: part1Part2Model
  mobileRecords?: mobileRecordModel[]
  validatedPassed?: boolean
  meansOfAccess?: string
  chitAccountNo?:string

}

export interface mobileRecordModel {
  type?: 'edit' | 'add' | 'display' | undefined | null
}
export interface drsChitUploadModel {
  baseForm: FormModel
  chitNo: string
  chitReceipts: MediaModel[]
  truckPhotos: MediaModel[]
}
export interface part1Part2Model {
  // chitDdfNo?: number
  id: string
  selected?: boolean
  chitNo?: string
  vehicleRegistrationMark?: string
  approxVol?: number
  cDMaterialType?: any
  disposalGround?: number
  drsChitUpload?: drsChitUploadModel
  drsFormId?: string
  selectedTruckPhotos?: MediaModel[]
  selectedChitReceipts?: MediaModel[]
  contractorSignatureBase64?: string
  reRepresentativeSignatureBase64?: string
  actualDisposalGround?: number
  departureTimeFromSite?: Date
  otherActualDisposalGround?: number
  remarks?: string
  chitReceipts?: MediaModel[]
  truckPhotos?: MediaModel[]
  arrivedTime?: Date
  mobileStatus?: 'edit' | 'add' | 'display' | 'displayDetail' | undefined | null
  wasteDepth?: number
  weightIn?: number
  weightOut?: number
  netWeight?: number
  overweight?: boolean
  zoneWithinSite?: string
  drsLock?: boolean
}

export const InitDrsForm: FormDrsModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.DailyRecordSummaryDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  drsItems: [],
}

export interface FormDrsListModel {
  formId: string
  color: string
  formStatus: string
  formStatusId: number
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo: number
  chitNo: number
  contractNoId: number
  archivedDate?: Date
  submittedBy?: string
  approvedBy?: string
  updatedAt?: Date
  createdAt?: Date
  dateOfDisposal?: Date
  dateOfArchived?: Date
  dateApproved?: Date
}

export type keyValueContent = {
  key: number
  parentKey?: number
  value: string
  title?: string
  titleZh?: string
  titleCn?: string
  cEasyTitle?: string
  cEasyTitleZh?: string
  cEasyTitleCn?: string
  cEasyTitleV2?: string
  cEasyTitleZhV2?: string
  cEasyTitleCnV2?: string
}

export type FormDrsGeneralOptionsModel = {
  disposalGround?: keyValueContent[]
  materialType?: keyValueContent[]
  approximateValue?: KeyValPair[]
  cDMaterialType?: keyValueContent[]
  constructionStageList: KeyValPair[]
}

export enum DisposalGroundEnum {
  'other' = 'Other',
}
