import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import _ from 'lodash'

const DashboardCicTotalCdWasteGenerationWasteFlow = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  const { t } = useTranslation()

  // const values = getValues()

  const values = getValues()
  let resKey = 'cdWasteGenerationByWasteFlow'
  // const resData = values?.[resKey]?.data
  const resData = values?.[resKey]?.datasets
  const chartData: any = {}
  const hideChart = values?.[resKey]?.showChart === false
  const message = values?.[resKey]?.message ?? ''
  const inertByWasteFlowData = resData?.find((item) => item.groupName === 'Inert')?.data
  const noninertByWasteFlowData = resData?.find((item) => item.groupName === 'Non-Inert')?.data

  inertByWasteFlowData?.map((item) => {
    const groupName = 'Inert'
    const itemName = item.itemName
    const itemValue = item.value // for percentage float value
    if (!chartData[groupName]) {
      chartData[groupName] = {}
    }
    chartData[groupName][itemName] = itemValue
  })

  noninertByWasteFlowData?.map((item) => {
    const groupName = 'Non-Inert'
    const itemName = item.itemName
    const itemValue = item.value // for percentage float value
    if (!chartData[groupName]) {
      chartData[groupName] = {}
    }
    chartData[groupName][itemName] = itemValue
  })

  const inertBorderColors = _.get(settings, [resKey, 'Inert'], []).map((item) => item.borderColor)

  const inertBackgroundColors = _.get(settings, [resKey, 'Inert'], []).map(
    (item) => item.backgroundColor,
  )

  const nonInertBorderColors = _.get(settings, [resKey, 'Non-Inert'], []).map(
    (item) => item.borderColor,
  )

  const nonInertBackgroundColors = _.get(settings, [resKey, 'Non-Inert'], []).map(
    (item) => item.backgroundColor,
  )

  return (
    <Grid container md={12} xs={12}>
      {hideChart ? (
        <>{t(message)}</>
      ) : (
        <>
          <Grid item md={6} xs={12}>
            <SingleDatasetChart
              eleBefore={
                <>
                  <h4>{t('dashboard:inert')}</h4>
                </>
              }
              chartWrapperSX={{
                width: '75%',
                margin: 'auto',
              }}
              type={'pie'}
              hasSummaryTable={false}
              data={chartData?.['Inert'] ?? {}}
              dataConfig={{
                borderColor: inertBorderColors,
                borderWidth: 2,
                backgroundColor: inertBackgroundColors,
              }}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    callbacks: {
                      label: (item) => {
                        return t(item.label) + ': ' + item.parsed * 100 + '%'
                      },
                    },
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      let percentage = (value * 100).toFixed(2) + '%'
                      if (percentage === '0.00%') {
                        percentage = ''
                      }
                      return percentage
                    },
                    color: '#fff',
                  },
                },
              }}></SingleDatasetChart>
          </Grid>
          <Grid item md={6} xs={12}>
            {/* <StyledCard variant="outlined"> */}
            <SingleDatasetChart
              eleBefore={
                <>
                  <h4>{t('dashboard:nonInert')}</h4>
                </>
              }
              chartWrapperSX={{
                width: '75%',
                margin: 'auto',
              }}
              type={'pie'}
              hasSummaryTable={false}
              data={chartData?.['Non-Inert'] ?? {}}
              dataConfig={{
                borderColor: nonInertBorderColors,
                borderWidth: 2,
                backgroundColor: nonInertBackgroundColors,
              }}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    callbacks: {
                      label: (item) => {
                        return item.label + ': ' + item.parsed * 100 + '%'
                      },
                    },
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      let percentage = (value * 100).toFixed(2) + '%'
                      if (percentage === '0.00%') {
                        percentage = ''
                      }
                      return percentage
                    },
                    color: '#fff',
                  },
                },
              }}></SingleDatasetChart>
            {/* </StyledCard> */}
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default DashboardCicTotalCdWasteGenerationWasteFlow
