import { formIconProps } from '@components/form/overview/icon'
import { formBriefInfo } from '@components/form/overview/introductionBox'
import { Card, Grid, Box, useMediaQuery } from '@mui/material'
import { getFormDetails } from '@utils/getFormDetails'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import CumulatiiveActualVsForecast from './CumulatiiveActualVsForecast'
import RecyclingRate from './RecyclingRate'
import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import { useForm, useWatch } from 'react-hook-form'
import useAPIFetch from '@hooks/useAPIFetch'
import { ProjectDashboardSettings } from '@models/Dashboard/Project' //ToDO: change to real data
import DashboardService from '@services/dashboard.service'
import VerticalTabs from '@components/dashboard/verticalTabs'
import { ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { projectDashboardDataModel } from '@services/model/dashboard.model'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import TotalCDWasteGeneration from './TotalCdWasteGeneration'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import SearchPanel from '@components/searchPanel'
import getKeyValPairsByList from '@utils/getKeyValPairsByList'
import useLocalStorage from '@hooks/useLocalStorage'
import { useSnackbar } from 'notistack'
import getErrMsg from '@utils/getErrMsg'
import { isEmpty } from 'lodash'

const DashboardProject = ({
  info,
  logs,
  status,
  byApproval1,
  byApproval2,
  byDate,
}: {
  info?: formBriefInfo
  logs?: any
  status?: any
  byApproval1?: any
  byApproval2?: any
  byDate?: any
}) => {
  const theme = useTheme()
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const refMounted = useRef(false)

  const StyledCard = styled(Card)(({ theme }) => ({
    margin: matchUpMd ? theme.spacing(2) : theme.spacing(1),
    padding: matchUpMd ? theme.spacing(3) : theme.spacing(1),
  }))
  const StyledBox = styled(Box)(({ theme }) => ({
    margin: matchUpMd ? theme.spacing(2) : theme.spacing(1),
    padding: matchUpMd ? theme.spacing(3) : theme.spacing(1),
  }))
  const { enqueueSnackbar } = useSnackbar()
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [options, setOptions] = useState<any>({})
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  const { control, getValues, reset, register, trigger, setError } =
    useForm<projectDashboardDataModel>()

  const reload = async () => {
    if (!currentContract?.contractId) return
    let values = getValues()
    let req: any = {}
    //TODO: apply filter, if no filter , use these request :

    req = {
      ...state.filterResult,
      contractId: {
        operator: '=',
        value: [currentContract.contractId],
      },
    }
    console.debug('[isEmpty]', isEmpty(req))
    if (isEmpty(req) || !req?.reportPeriod?.value) {
      req.reportPeriod = options.reportPeriod
    }
    setRequest({
      callback: async () => {
        const list: Array<any> = []
        await DashboardService.GetProjectDashboard(req)
          .then(async (res) => {
            if (res) {
              reset(res)
              refMounted.current = true
              isMounted.current = true
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const isMounted = useRef(false)

  const reloadGeneralOptions = async (projectId: number) => {
    setOptionsRequest({
      callback: async () => {
        const list: Array<any> = []
        await DashboardService.GetDashboardOptions(projectId)
          .then(async (res) => {
            if (res) {
              let constructionStageList = getKeyValPairsByList(res.constructionStageList)
              let projectTypeList = getKeyValPairsByList(res.projectTypeList)
              let options: any = {}
              options.constructionStageList = constructionStageList
              options.projectTypeList = projectTypeList
              options.reportPeriod = res.reportPeriod
              setOptions(options)
              refMounted.current = true
              isMounted.current = true
            }
          })
          .catch((err) => {
            return err
          })
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current && currentProject?.projectId) {
      reloadGeneralOptions(currentProject.projectId)
    }

    if (options && isMounted.current) {
      reload()
    }
    return () => {}
  }, [options, isMounted.current])

  const { t } = useTranslation()
  return (
    <SearchPanelContext.Provider value={{ state, dispatch }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{ margin: '0px 20px 0 20px' }}>
          <h1>{t('Project Dashboard')}</h1>
        </Grid>
        <SearchPanel
          dispatch={dispatch}
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'reportPeriod',
              desc: t('dashboard:reportPeriod'),
              type: 'DateFilter',
              subType: 'month',
              disableOperator: true,
              defaultOperator: 'in',
              defaultFrom: options?.reportPeriod?.value?.min,
              minDate: options?.reportPeriod?.value?.min,
              defaultTo: options?.reportPeriod?.value?.max,
              maxDate: options?.reportPeriod?.value?.max,
            },
            {
              key: 'constructionStage',
              desc: t('dashboard:constructionStage'),
              type: 'NumberSelectionFilter',
              disableOperator: true,
              multiple: true,
              valueList: options?.constructionStageList || [],
            },
          ]}
        />
        <Grid item xs={12} md={12}>
          <StyledCard variant="outlined">
            <TotalCDWasteGeneration
              settings={ProjectDashboardSettings}
              getValues={getValues}
              StyledCard={StyledCard}
              StyledBox={StyledBox}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard variant="outlined">
            <h2>{t('dashboard:recyclingRate')}</h2>
            <RecyclingRate
              settings={ProjectDashboardSettings}
              getValues={getValues}
              StyledCard={StyledCard}
              StyledBox={StyledBox}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledCard variant="outlined">
            <h2>{t('dashboard:cumulativeActualVsForecast')}</h2>
            <CumulatiiveActualVsForecast
              settings={ProjectDashboardSettings}
              getValues={getValues}
              StyledCard={StyledCard}
              StyledBox={StyledBox}
            />
          </StyledCard>
        </Grid>
      </Grid>
    </SearchPanelContext.Provider>
  )
}

export default DashboardProject
