import apiGateway from '@utils/axiosInterceptor'
import { ContractNo } from './model/contract.model'

const GetContractList = async () => {
  return await apiGateway.get<ContractNo[]>('/api/v1/contracts').then((rep) => {
    return rep.data
  })
}
const item = window.localStorage.getItem('usr')

let userId = ''
if (item) {
  const userInfo = JSON.parse(item)
  userId = userInfo.uuid
}

const GetContractListByCurrentUser = async () => {
  if (userId === '') return
  return await apiGateway.get<ContractNo[]>(`/api/v1/contracts?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const GetContractListByUser = async (userId: string) => {
  return await apiGateway.get<ContractNo[]>(`/api/v1/contracts?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const ContractService = {
  GetContractList,
  GetContractListByUser,
  GetContractListByCurrentUser,
}

export default ContractService
