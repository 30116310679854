import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Grid,
  Tooltip,
  Button,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import '@styles/table.module.scss'
import { useTranslation } from 'react-i18next'
import { Fragment, useState, useRef, useLayoutEffect } from 'react'
import {
  ArrayPath,
  Controller,
  ControllerRenderProps,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayProps,
} from 'react-hook-form'
import useImageModal from '@hooks/useImageModal'
import FormDialog from '@components/form/dialog_v2'
import ScrollContainer from 'react-indiana-drag-scroll'
import { DialogContextProps, InitDialogContent } from '@components/form/table/model'
import FormTableBox from '@components/form/table/presentation/formTableBox'
import FormTableFooterButton from '@components/form/table/presentation/formTableFooterButton'
import FormTableAddIcon from '@components/form/table/presentation/formTableAddIcon'
import HeaderTableCell from '@components/form/table/presentation/headerTableCell'
import BodyTableCell from '@components/form/table/presentation/bodyTableCell'
import FormWidget from '@components/form/table/FormWidget'
import { FormSingleRowTableChildProps } from '@components/form/table/model'
import { Card, CardContent, Stack, Drawer } from '@mui/material'
import { DrawerContextProps, InitDrawerContent } from '@components/form/table/model'
import { RxPencil1 } from 'react-icons/rx'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { SxProps, Theme } from '@mui/material/styles'
import FormTableAddButton from '@components/form/table/presentation/FormTableAddButton'
import AddIcon from '@mui/icons-material/Add'

function DesktopSingleRowTable<F extends FieldValues>(props: FormSingleRowTableChildProps<F>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  let itemNo = 0

  type DialogContextProps = {
    isOpen: boolean
    title?: JSX.Element
    toolbarStyle?: SxProps<Theme>
    children?: JSX.Element
    buttons?: JSX.Element
    showConfirmDeleteBtn?: boolean
  }
  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }

  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }

  return (
    <Grid style={{ overflow: props.removeOverflow ? 'none' : 'auto' }}>
      <Box>
        <ScrollContainer
          hideScrollbars={false}
          className="scroll-container"
          ignoreElements={'input,textarea,td'}
          nativeMobileScroll={downMd}>
          <Box flex={1} />
          <Box>
            <FormTableBox sx={{ color: '#707070' }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: '#c5c5c5',
                      color: '#707070',
                      width: '100%',
                    }}>
                    <HeaderTableCell
                      align={props.align ?? 'center'}
                      style={{
                        width: 5,
                        display: props.hiddenItemNo ? 'none' : 'table-cell',
                      }}>
                      {downMd ? '#' : t('Item No.')}
                    </HeaderTableCell>
                    {props.columns.map((column, index: number) => (
                      <Tooltip
                        title={column.tooltip ? t(column.tooltip) : ''}
                        key={`form-table-header-tooltip-${index}`}
                        placement="top">
                        <HeaderTableCell
                          style={{
                            display:
                              column.hidden || (downMd && column.mobileHidden)
                                ? 'none'
                                : 'table-cell',
                            // flex: 1,
                            width: column.width ?? 'auto',
                            minWidth: column.minWidth ?? '100px',
                          }}
                          key={`form-table-header-${index}`}
                          align={props.align ?? 'center'}>
                          {typeof column.displayName === 'string'
                            ? t(column.displayName)
                            : column.displayName}
                        </HeaderTableCell>
                      </Tooltip>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={`form-table-row`}
                    sx={{
                      minHeight: props.height ?? 50,
                      width: '100%',
                      ':hover': {
                        '#table-row-actions': {
                          display: 'inherit',
                        },
                      },
                      backgroundColor: '#edeef0',
                    }}>
                    <BodyTableCell
                      style={{
                        flex: 1,
                        position: 'relative',
                        display: props.hiddenItemNo ? 'none' : 'table-cell',
                      }}
                      key={`form-table-cell-item-no`}>
                      <Typography textAlign={props.align ?? 'center'} fontSize={12}>
                        {++itemNo}
                      </Typography>
                    </BodyTableCell>
                    {props.columns.map((column, columnIndex: number) => {
                      let children: JSX.Element = <Fragment></Fragment>
                      if (column.hidden || (downMd && column.mobileHidden)) {
                        return children
                      }
                      children = <>{column.field}</>
                      return (
                        <BodyTableCell
                          style={{
                            // flex: 1,
                            width: column.width ?? 'auto',
                            minWidth: column.minWidth ?? '100px',
                            minHeight: column.minHeight ?? '20px',
                            position: 'relative',
                            textAlign: 'center',
                          }}
                          key={`form-table-cell-${columnIndex}`}>
                          {children}
                        </BodyTableCell>
                      )
                    })}
                  </TableRow>
                </TableBody>
              </Table>

              {props.warningText && (
                <Typography
                  sx={{
                    color: 'red',
                  }}>
                  {t(props.warningText)}
                </Typography>
              )}
            </FormTableBox>
          </Box>
        </ScrollContainer>
      </Box>
      <props.ImageModal image={props.modalImage} />
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
    </Grid>
  )
}
export default DesktopSingleRowTable
