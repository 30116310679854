import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { FormDrsItemsModel, InitDrsItemsForm } from '@services/model/form/form.DRS.item.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import DailyRecordSummaryItemBatchPartA from './PartA'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import getFormValidationSchema from '@utils/getFormValidationSchema'
import useLocalStorage from '@hooks/useLocalStorage'
import FormDrsItemService from '@services/formService/form.DRS.item.service'
import FormDrsService from '@services/formService/form.DRS.service'

const UUID = uuid()

const DailyRecordSystemItemBatchPage = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'forms'])
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const cat = QueryStepParser(query.get('cat'))

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  Yup.addMethod(Yup.array, 'unique', function (field, message) {
    return this.test('unique', message, function (array) {
      let errors: any = []
      const counts = {}
      array?.forEach((row) => {
        if (row.status && row[field] && !counts[row[field]?.toLowerCase()]) {
          counts[row[field]?.toLowerCase()] = 0
        }
        counts[row[field]?.toLowerCase()]++
      })

      Object.keys(counts).forEach((key) => {
        if (counts[key] > 1) {
          const duplicateValues = array?.filter((row, i) => {
            return row.status && row[field] && row[field]?.toLowerCase() === key
          })
          duplicateValues?.forEach((row, i) => {
            errors.push(
              this.createError({
                path: `${this.path}[${array?.indexOf(row)}].${field}`,
                message,
              }),
            )
          })
        }
      })
      if (errors?.length > 0) {
        throw new Yup.ValidationError(errors)
      }
      return true
    })
  })

  const defaultMsg = t('forms:validate.messages.required')

  const validationSchema = Yup.object().shape({
    drsItems: Yup.array().of(
      Yup.object().shape({
        // chitNo: Yup.string().nullable().required(defaultMsg),
        // dateOfDisposal: Yup.string().nullable().required(defaultMsg),
        // vehicleRegistrationMark: Yup.string().nullable().required(defaultMsg),
        // departureTimeFromSite: Yup.string().nullable().required(defaultMsg),
        // approxVol: Yup.number().nullable().required(defaultMsg),
        // cDMaterialType: Yup.number().nullable().required(defaultMsg),
        // disposalGround: Yup.number().nullable().required(defaultMsg),
      }),
    ), //@ts-ignore
    // .unique('chitNo', t('forms:validate.messages.chitUnique')),
  })

  const { control, setValue, getValues, reset, trigger } = useForm<FormDrsItemsModel>({
    defaultValues: { ...InitDrsItemsForm },
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })

  const [contractNoId, contractNo, contractName, formStatus, formPermission, projectId] = useWatch({
    name: [
      'baseForm.contractNoId',
      'baseForm.contractNo',
      'baseForm.contractName',
      'baseForm.formStatus',
      'baseForm.formPermission',
      'baseForm.projectId',
    ],
    control: control,
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const drsGeneralOptions = useRef<FormDrsGeneralOptionsModel>()

  const part1ReadOnly =
    !formPermission?.canUpdate ||
    formStatus === FormStatusEnum.DailyRecordSummaryItemReview ||
    formStatus === FormStatusEnum.DailyRecordSummaryItemApproved

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    // setValue('submissionComment', submissionComment)
    const values = getValues()
    if (!(await trigger())) {
      enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
      return
    }
    await FormDrsItemService.BatchSaveDrsItems(values)
      .then((resp) => {
        enqueueSnackbar(t('common:messages.recordSubmitted'), {
          variant: 'success',
          action: (key) => {
            return <SnackbarCloseButton id={key} />
          },
        })
        NavigateTo(navigate, '/daily-record-summary-item/all-records')
      })
      .catch((err) => {
        console.debug({ err })
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
          action: (key) => {
            return <SnackbarCloseButton id={key} />
          },
        })
      })
  }

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formStatus])

  useEffect(() => {
    if (!isMounted.current) {
      reloadDrsGeneralOptions()
    }
    if (formStateContext.form && formStateContext.formType === 'DRS') {
      formStateAction({ type: 'pop', targetFormType: 'DRS' })
      // previous editing form (new / existed form)
      let prevform = { ...formStateContext.form }
      isMounted.current = false // if back from another form, reevaluate onNext disable by reset form
      reset(prevform)
      isMounted.current = true
    } else if (currentContract && currentProject && !formId) {
      setValue('baseForm', {
        ...getValues('baseForm'),
        contractNoId: currentContract.contractId,
        contractNo: currentContract.contractNo,
        contractName: currentContract.contractName,
        projectId: currentProject.projectId,
      })
    } else if (globalState?.contractList && formId && !isMounted.current) {
      reload(formId)
      isMounted.current = true
    }
    return () => {}
  }, [formId, currentContract, currentProject, globalState?.contractList])

  const reloadDrsGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormDrsService.GetDrsGeneralOptions().then(async (res) => {
          drsGeneralOptions.current = res
        })
      },
    })
  }

  const reload = async (formId: string) => {
    setRequest({
      callback: async () => {},
    })
  }
  const onClickSaveRowBtn = async (event, index, datum, updateField) => {
    if (!(await trigger())) {
      return
    } else {
      updateField(index)
    }
  }

  // const [failValidation, setFailValidation] = useState(true)

  const partA = (): FormPartComponents => {
    return {
      partTitle: t('forms:drsItems.cEasy.sections.create.title'),
      component: (
        <DailyRecordSummaryItemBatchPartA
          part1ReadOnly={part1ReadOnly}
          getValues={getValues}
          useWatch={useWatch}
          setValue={setValue}
          control={control}
          formStatus={formStatus}
          formPermission={formPermission}
          drsGeneralOptions={drsGeneralOptions.current}
          onClickSaveRowBtn={onClickSaveRowBtn}
          trigger={trigger}
          globalState={globalState}
        />
      ),
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('forms:drsItems.cEasy.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }
  const onLoadNotifyList = async (isReject: boolean) => {
    let defaultNotifyList = await FormService.GetNotifyList(
      'DRS',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    let defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete, activeStep } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:drsItems:title'),
    components: [partA(), partB()],
    formOnLoadCommentList: async () => {
      console.debug('this form dont have submission comment')
      return []
      // return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      console.debug('this form dont have submission comment')
      // await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: async () => {},
    formOnSave: async () => {},
    disableComment: true,
    hideSubmissionComment: true,
    startStep: step,
    isLoading: isLoading,
    disableSaveBtn: true,
    disableDelete: true,
    approveRequired: formPermission?.approveRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })
  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}

export default DailyRecordSystemItemBatchPage
