import { Dialog, DialogContent, Box, CircularProgress } from '@mui/material'

export interface LoadingPopupProps {
  open: boolean
}

const LoadingPopup = (props: LoadingPopupProps) => {
  return (
    <Dialog
      open={props.open}
      sx={{
        '&MuiDialog-paper': {
          backgroundColor: 'transparent !important',
        },
      }}>
      <DialogContent sx={{ width: 'fit-content', backgroundColor: 'transparent !important' }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default LoadingPopup
