import { ImageModel } from '../image.model'
import { FormModel, InitFormModel } from './form.model'
import { FileModel } from '../file.model'
import { KeyValPair } from '@models/Common'
export interface FormDrsCreateDocumentModel {
  baseForm?: FormModel
  reportPeriod?: Date
  materialType?: number
  bytes?: string
  bytesBase64?: string
  fileName?: string
  fileId?: string
}

export interface FormDrsUploadModel {
  baseForm?: FormModel
  editable?: boolean
  chitNo?: string
  reportPeriod?: Date
  disposalGround?: number
  disposalGroundClass?: string | number
  materialType?: number
  list?: FormDrsCreateDocumentModel[]
  lists?: FormDrsCreateDocumentModel[][]
  truckPhotos?: FormDrsCreateDocumentModel[]
  chitReceipts?: FormDrsCreateDocumentModel[]
  newTruckPhotos?: FormDrsCreateDocumentModel[]
  newChitReceipts?: FormDrsCreateDocumentModel[]
  acknowledgement?: boolean
}

export const InitFormDrsUploadModel: FormDrsUploadModel = {
  baseForm: { ...InitFormModel, formStatus: 'Draft' },
}

export type FormDrsUploadOptions = {
  constructionStageList: KeyValPair[]
  wasteQuantityReportingUnitList: KeyValPair[]
  documentsCategoryList: KeyValPair[]
  selectableMonths?: object
  contractNo: {
    key: number
    contractNo: string
    contractName: string
  }[]
}

export interface DRSDocRequestModel {
  contractId: number
  month?: string
  chitNo?: number
  projectId?: number
}

export interface DRSDocResponseModel {
  list: {
    baseForm?: FormModel
    createdBy?: string
    id?: number
    reportPeriod?: Date
    drsCategory?: number
    fileId?: string
    fileName?: string
  }[]
  totalCount?: number
}
export interface FormDrsUploadListModel {
  formId: number
  chitNo: number
  reportPeriod?: Date
  materialType?: number[]
  archivedDate?: Date
  submittedBy?: string
  updatedAt?: Date
  createdAt?: Date
}

export interface FormDrsVerifyDrsChitsReqModel {
  dateOfDisposal: Date | string
  uuid: string
}

export interface FormDrsVerifyDrsChitsResModel {
  missingRecords: string[]
  extraRecords: string[]
  missingCounts: number
  extraCounts: number
  isMatched: boolean
}
export interface FormDrsExtraFormResModel {
  newRecords: string[]
  updatedRecords: string[]
  newRecordCount: number
  updatedRecordsCount: number
}
