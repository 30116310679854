import { AppBar, Box, CssBaseline, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import Header from './header'
import Sidebar, { DrawerWidth } from './sidebar'
import { GlobalContext } from '@providers/globalStore'
import { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import UseLeftSideBar from './LeftSideBar'
import GeneralOptionsService from '@services/general.service'
import UserService from '@services/user.service'
import ContractService from '@services/contract.service'
import AdminService from '@services/admin.service'
import ProjectService from '@services/project.service'
import { ErrorBoundary } from 'react-error-boundary'
import { GetMenuMetaData } from '@utils/getMenuMetaData'
import { useTranslation } from 'react-i18next'
import FormMwfService from '@services/formService/form.MWF.service'
import Fab from '@mui/material/Fab'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useCallback } from 'react'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { parseLocalStorageValue } from '@utils/localStorage'

type mainProps = {
  leftDrawerOpened?: boolean
}

// styles
const Main = styled('main', {
  shouldForwardProp: (prop: string) => prop !== 'leftDrawerOpened',
})<mainProps>(({ theme, leftDrawerOpened: open }) => ({
  ...{
    width: 'inherit !important',
    flexGrow: 1,
    padding: '0px',
    margin: '65px 0px 0px 0px',
    paddingTop: '5px',
    height: '100%',
    minHeight: '900px',
    // background: 'radial-gradient(circle, rgba(157,201,208,1) 0%, rgba(255,255,255,1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    overflow: 'hidden',
  },
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: '100px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: DrawerWidth,
    },
  }),
}))

const WrappedHeader = styled('header', {
  shouldForwardProp: (prop: string) => prop !== 'leftDrawerOpened',
})<mainProps>(({ theme, leftDrawerOpened: open }) => ({
  ...{
    width: '100%',
    padding: '5px',
    margin: '0px 10px 0px 10px',
    zIndex: 999,
    overflowX: 'hidden',
  },
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: DrawerWidth + 10,
    },
  }),
}))

type APIs = {
  retryCnt: number
  isDone: boolean
  dispatchAction:
    | 'reloadGeneralOptionList'
    | 'reloadUserMetaList'
    | 'reloadOrganizationMetaList'
    | 'reloadRoleMetaList'
    | 'reloadContractList'
    | 'reloadProjectList'
    | 'reloadGeneralConfigList'
    | 'reloadGroupTypeList'
    | 'reloadFormActionList'
    | 'reloadProjectOptionList'
  promise: Promise<any>
}

const MainLayout = () => {
  const userId = parseLocalStorageValue('usr')?.uuid
  // const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  const trigger = useScrollTrigger({
    // Number of pixels needed to scroll to toggle `trigger` to `true`.
    threshold: 100,
  })

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const theme = useTheme()
  const globalContext = useContext(GlobalContext)
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const { t } = useTranslation(['dashboard', 'forms', 'common', 'sideMenu'])

  const reload = async () => {
    const promises: APIs[] = [
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadGeneralOptionList',
        promise: FormMwfService.GetGeneralOptions(),
      },

      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadUserMetaList',
        promise: UserService.GetUserMetaList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadOrganizationMetaList',
        promise: UserService.GetOrganizationMetaList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadRoleMetaList',
        promise: UserService.GetRoleMetaList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadContractList',
        promise: ContractService.GetContractList(),
        // promise: ContractService.GetContractListByCurrentUser(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadProjectList',
        promise: ProjectService.GetProjectListByCurrentUser(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadGeneralConfigList',
        promise: GeneralOptionsService.GetGeneralConfigList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadGroupTypeList',
        promise: UserService.GetGroupTypeList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadFormActionList',
        promise: GeneralOptionsService.GetFormActionList(),
      },
      {
        isDone: false,
        retryCnt: 0,
        dispatchAction: 'reloadProjectOptionList',
        promise: AdminService.GetProjectOptionList(),
      },
    ]

    const loadMasterData = (withRetry: boolean) => {
      promises
        .filter((x) => !x.isDone && x.retryCnt < 3)
        .forEach(async (x) => {
          await x.promise
            .then((rs) => {
              x.isDone = true
              globalContext.dispatch({
                type: x.dispatchAction,
                list: rs || [],
              })
            })
            .catch(() => {
              x.retryCnt++
            })
        })

      if (withRetry && promises.filter((x) => !x.isDone && x.retryCnt < 3).length > 0) {
        setTimeout(() => {
          loadMasterData(true)
        }, 5000)
      }
    }
    loadMasterData(false)
    setTimeout(() => {
      loadMasterData(true)
    }, 5000)
  }
  useEffect(() => {
    if (userId && userId !== '') {
      reload()
    }
  }, [userId])

  useEffect(() => {
    const metaData = GetMenuMetaData(window.location.pathname)
    globalContext.dispatch({ type: 'changeHeaderColor', color: metaData?.colorCode })
    globalContext.dispatch({
      type: 'changeTitle',
      title: t(metaData?.title ?? 'Smart Waste Management Tool'),
    })
    if (metaData) {
      globalContext.dispatch({
        type: 'changeSubtitle',
        subtitle: t(metaData.subtitle),
      })
    }
    document.title = t(metaData?.title ?? 'Smart Waste Management Tool')
  }, [window.location.pathname])

  const [LeftSideBar] = UseLeftSideBar()

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden !important' }}>
      <CssBaseline />
      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ zIndex: 999 }}>
        <Toolbar
          sx={{
            backgroundColor: globalContext.state.headerColor,
            boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
            // padding: '14px 20px',
            padding: 'auto',
            minHeight: '75px !important',
          }}>
          <WrappedHeader leftDrawerOpened={globalContext.state.leftDrawerOpened} theme={theme}>
            <Header
              title={globalContext.state.title}
              bgColor={globalContext.state.headerColor}
              subtitle={globalContext.state.subtitle}
              formStatus={globalContext.state.formStatus}
            />
          </WrappedHeader>
        </Toolbar>
      </AppBar>

      {matchUpMd ? (
        globalContext.state.leftDrawerOpened ? (
          <Sidebar />
        ) : (
          <LeftSideBar />
        )
      ) : (
        <Sidebar isMobile={true} />
      )}

      <Main leftDrawerOpened={globalContext.state.leftDrawerOpened} theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}>
          <Outlet />
        </ErrorBoundary>
      </Main>
      <Fab
        onClick={scrollToTop}
        size="small"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 50, right: 32, zIndex: 1 }}>
        <AiOutlineArrowUp />
      </Fab>
    </Box>
  )
}

export default MainLayout
