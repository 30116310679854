import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import debounce from '@utils/debounce'

const TableTextField = (props: {
  type?: any
  error?: any
  rows?: number
  value?: any
  onBlur?: any
  datumIndex?: number
  additionalOnChangeAction?: any
  onChange: any
  displayName?: string
  minHeight?: string | number
  disabled?: boolean
  minNumber?: number
  multiline?: boolean
  extraError?: any
  isDebounce?: boolean
  uneditable?: boolean
  allowNegative?: boolean
  inputType?: 'string' | 'number' | 'float'
  fieldProps?: any
}) => {
  const { t } = useTranslation()
  const [hasBeenFocused, setHasBeenFocused] = useState(false)
  const isNum = props.type === 'float' || props.type === 'number'

  const handleChange = (event: any) => {
    let value = props.type === 'number' ? parseFloat(event.target.value) : event.target.value
    if (isNum && isNaN(value)) {
      value = null
    }
    props.onChange!(value)
    if (props.additionalOnChangeAction) {
      props.additionalOnChangeAction!(value, props.datumIndex)
    }
  }
  const handleFocus = (event) => {
    setHasBeenFocused(true)
    handleChange(event)
  }
  const handleBlur = (event: any) => {
    const value = props.type === 'number' ? parseFloat(event.target.value) : event.target.value
    props.onBlur!(value)
  }
  const handleOnKeyDown = (event) => {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'End' ||
      event.key === 'Home'
    ) {
      return
    }

    if (isNum) {
      const value = event.key
      if (event.key === '.' && event.target.value.includes('.')) {
        event.preventDefault()
      }
      if (!props.allowNegative) {
        if (!/^[0-9.]*$/.test(value)) {
          event.preventDefault()
        }
      } else {
        if (!/^-?[0-9.]*$/.test(value)) {
          event.preventDefault()
        }
      }
    }
  }

  return (
    <TextField
      onKeyDown={handleOnKeyDown}
      onFocus={handleFocus}
      error={!!props.error}
      type={props.type === 'string' ? 'text' : 'text'} // props.type ??
      helperText={props.error ? props.error.message : null}
      fullWidth
      multiline={props.multiline ? true : props.type === 'number'}
      rows={props.rows ?? 1}
      defaultValue={props.value ?? ''}
      onBlur={(event: any) => {
        if (props.isDebounce) {
          debounce(handleBlur, 1000)(event)
        } else {
          handleBlur(event)
        }
      }}
      onChange={(event: any) => {
        if (props.isDebounce) {
          debounce(handleChange, 800)(event)
        } else {
          handleChange(event)
        }
      }}
      placeholder={props.displayName ? t(props.displayName) : ''}
      InputProps={{
        inputProps: { min: props.minNumber ?? 0 },
        style: {
          fontSize: 12,
          backgroundColor: '#ffffff',
          height: props.minHeight ?? 40,
        },
      }}
      disabled={props.disabled}
      {...props.fieldProps}
    />
  )
}

export default TableTextField
