import * as yup from 'yup'

export interface FormValidationSchemaProps {
  formStatus: string
  conditions: {
    formStatusList: string[]
    validationSchema: yup.ObjectSchema<any>
  }[]
}

export default function getFormValidationSchema(formStatus, conditions) {
  let schema: yup.ObjectSchema<any> | undefined
  for (let i = 0; i < conditions.length; i++) {
    if (conditions[i].formStatusList.includes(formStatus)) {
      schema = conditions[i].validationSchema
      break
    }
  }

  if (schema === undefined) {
    schema = yup.object().shape({
      abcdefg: yup.object().required('No Validation schema'),
    })
  }

  return schema
}
