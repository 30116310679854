type settingProps = { borderColor: any; backgroundColor: any; type?: string }
export interface chartSettingsProps {
  userVisitors: Record<string, settingProps>
  onGoingProjects: Record<string, settingProps>
  numberOfEnquiries: Record<string, settingProps>

  cdWasteGenerationByMonthAndCategory: Record<string, settingProps>
  cdWasteGenerationByWasteFlow: Record<string, settingProps[]>
  recyclingRateOfSameProjectTypeAndStage: Record<string, settingProps>
}

export const DashboardCicSettings: chartSettingsProps = {
  userVisitors: {
    'Registered User': { borderColor: 'rgb(177,131,35)', backgroundColor: 'rgb(177,131,35)' },
    Visitor: {
      borderColor: 'rgb(35,93,96)',
      backgroundColor: 'rgb(35,93,96)',
    },
  },
  onGoingProjects: {
    Building: { borderColor: 'rgb(242,180,52)', backgroundColor: 'rgb(242,180,52)' },
    Civil: { borderColor: 'rgb(115,160,208)', backgroundColor: 'rgb(115,160,208)' },
    Infrastructure: { borderColor: 'rgb(228,97,15)', backgroundColor: 'rgb(228,97,15)' },
    Others: { borderColor: 'rgb(241,172,130)', backgroundColor: 'rgb(241,172,130)' },
  },
  numberOfEnquiries: {
    'General Enquiry': { borderColor: 'rgb(228,97,15)', backgroundColor: 'rgb(228,97,15)' },
    'IT Issues': { borderColor: 'rgb(115,160,208)', backgroundColor: 'rgb(115,160,208)' },
    Others: { borderColor: 'rgb(242,180,52)', backgroundColor: 'rgb(242,180,52)' },
  },

  cdWasteGenerationByMonthAndCategory: {
    Inert: {
      borderColor: 'rgb(99,145,79)',
      backgroundColor: 'rgb(99,145,79)',
    },
    'Non-Inert': {
      borderColor: 'rgb(236,207,109)',
      backgroundColor: 'rgb(236,207,109)',
    },
  },

  cdWasteGenerationByWasteFlow: {
    Inert: [
      { borderColor: 'rgb(177,131,35)', backgroundColor: 'rgb(177,131,35)' },
      {
        borderColor: 'rgb(206,154,43)',
        backgroundColor: 'rgb(206,154,43)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(230,172,49)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(242,189,101)',
      },
      {
        borderColor: 'rgb(245,206,154)',
        backgroundColor: 'rgb(245,206,154)',
      },
      {
        borderColor: 'rgb(248,222,190)',
        backgroundColor: 'rgb(248,222,190)',
      },
    ],
    'Non-Inert': [
      {
        borderColor: 'rgb(35,93,96)',
        backgroundColor: 'rgb(35,93,96)',
      },
      {
        borderColor: 'rgb(1,89,92)',
        backgroundColor: 'rgb(1,89,92)',
      },
      {
        borderColor: 'rgb(2,107,110)',
        backgroundColor: 'rgb(2,107,110)',
      },
      {
        borderColor: 'rgb(86,137,139)',
        backgroundColor: 'rgb(86,137,139)',
      },
      {
        borderColor: 'rgb(152,176,177)',
        backgroundColor: 'rgb(152,176,177)',
      },
      {
        borderColor: 'rgb(184,199,200)',
        backgroundColor: 'rgb(184,199,200)',
      },
    ],
  },
  recyclingRateOfSameProjectTypeAndStage: {
    Inert: {
      borderColor: 'rgb(99,145,79)',
      backgroundColor: 'rgb(99,145,79)',
    },
    'Non-Inert': {
      borderColor: 'rgb(236,207,109)',
      backgroundColor: 'rgb(236,207,109)',
    },
  },
}
