import XLSX from 'xlsx-js-style'

export const excelExporter = ({
  data,
  filename,
  header,
  titleStyles,
  bodyStyles,
}: {
  data: any
  filename: string
  header?: any
  titleStyles?: any
  bodyStyles?: any
}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const dataWithHeaders = data.map((d) => {
    const obj = {}
    header?.forEach((h) => {
      obj[h.headerName] = d[h.dataName]
    })
    return obj
  })

  const wb = XLSX.utils.book_new()

  const rowTitles = Object.keys(dataWithHeaders[0])?.map((titleName) => {
    return {
      v: titleName,
      t: 's',
      s: titleStyles,
    }
  })

  const rowsData = dataWithHeaders.map((row) => {
    return Object.values(row).map((cellData) => {
      return {
        v: cellData,
        t: 's',
        s: bodyStyles,
      }
    })
  })

  let rows = [rowTitles, ...rowsData]

  const ws = XLSX.utils.aoa_to_sheet(rows)
  XLSX.utils.book_append_sheet(wb, ws, 'data')

  XLSX.writeFile(wb, `${filename + fileExtension}`)
}
