type settingProps = { borderColor: any; backgroundColor: any; type?: string }
export interface chartSettingsProps {
  cdWasteGenerationByType: Record<string, settingProps[]>
  cdWasteGenerationByWasteFlow: Record<string, settingProps[]>
  cdWasteGenerationByMonthAndCategory: Record<string, settingProps>
  cdWasteGenerationByMonthAndFlow: Record<string, settingProps>
  recyclingRate: Record<string, settingProps>
  cumulativeActualVsForecast: Record<string, settingProps>
}

export const ProjectDashboardSettings: chartSettingsProps = {
  cdWasteGenerationByType: {
    Inert: [
      { borderColor: 'rgb(177,131,35)', backgroundColor: 'rgb(177,131,35)' },
      {
        borderColor: 'rgb(206,154,43)',
        backgroundColor: 'rgb(206,154,43)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(230,172,49)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(242,189,101)',
      },
      {
        borderColor: 'rgb(245,206,154)',
        backgroundColor: 'rgb(245,206,154)',
      },
      {
        borderColor: 'rgb(248,222,190)',
        backgroundColor: 'rgb(248,222,190)',
      },
    ],
    'Non-Inert': [
      {
        borderColor: 'rgb(35,93,96)',
        backgroundColor: 'rgb(35,93,96)',
      },
      {
        borderColor: 'rgb(1,89,92)',
        backgroundColor: 'rgb(1,89,92)',
      },
      {
        borderColor: 'rgb(2,107,110)',
        backgroundColor: 'rgb(2,107,110)',
      },
      {
        borderColor: 'rgb(86,137,139)',
        backgroundColor: 'rgb(86,137,139)',
      },
      {
        borderColor: 'rgb(152,176,177)',
        backgroundColor: 'rgb(152,176,177)',
      },
      {
        borderColor: 'rgb(184,199,200)',
        backgroundColor: 'rgb(184,199,200)',
      },
    ],
  },
  cdWasteGenerationByWasteFlow: {
    Inert: [
      { borderColor: 'rgb(177,131,35)', backgroundColor: 'rgb(177,131,35)' },
      {
        borderColor: 'rgb(206,154,43)',
        backgroundColor: 'rgb(206,154,43)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(230,172,49)',
      },
      {
        borderColor: 'rgb(230,172,49)',
        backgroundColor: 'rgb(242,189,101)',
      },
      {
        borderColor: 'rgb(245,206,154)',
        backgroundColor: 'rgb(245,206,154)',
      },
      {
        borderColor: 'rgb(248,222,190)',
        backgroundColor: 'rgb(248,222,190)',
      },
    ],
    'Non-Inert': [
      {
        borderColor: 'rgb(35,93,96)',
        backgroundColor: 'rgb(35,93,96)',
      },
      {
        borderColor: 'rgb(1,89,92)',
        backgroundColor: 'rgb(1,89,92)',
      },
      {
        borderColor: 'rgb(2,107,110)',
        backgroundColor: 'rgb(2,107,110)',
      },
      {
        borderColor: 'rgb(86,137,139)',
        backgroundColor: 'rgb(86,137,139)',
      },
      {
        borderColor: 'rgb(152,176,177)',
        backgroundColor: 'rgb(152,176,177)',
      },
      {
        borderColor: 'rgb(184,199,200)',
        backgroundColor: 'rgb(184,199,200)',
      },
    ],
  },
  cdWasteGenerationByMonthAndCategory: {
    Inert: { borderColor: 'rgb(242,180,52)', backgroundColor: 'rgb(242,180,52)' },
    'Non-Inert': {
      borderColor: 'rgb(50,99,149)',
      backgroundColor: 'rgb(50,99,149)',
    },
  },
  cdWasteGenerationByMonthAndFlow: {
    'Disposed to Landfill and other facilities': {
      borderColor: 'rgb(233,120,50)',
      backgroundColor: 'rgb(233,120,50)',
    },
    'Disposed to Public Fill': {
      borderColor: 'rgb(242,180,52)',
      backgroundColor: 'rgb(242,180,52)',
    },
    'Reused in the Same Contract': {
      borderColor: 'rgb(0,176,79)',
      backgroundColor: 'rgb(0,176,79)',
    },
    'Disposed to Sorting Facilities': {
      borderColor: 'rgb(137,96,9)',
      backgroundColor: 'rgb(137,96,9)',
    },
    'Reused in Other Projects': {
      borderColor: 'rgb(209,215,95)',
      backgroundColor: 'rgb(209,215,95)',
    },
    'Other Recycled': { borderColor: 'rgb(9,112,192)', backgroundColor: 'rgb(9,112,192)' },
  },

  recyclingRate: {
    'By Contract': {
      borderColor: 'rgb(0,176,79)',
      backgroundColor: 'rgb(0,176,79)',
    },
    'By Average': {
      borderColor: 'rgb(0,176,79)',
      backgroundColor: 'rgb(0,176,79)',
    },
  },
  cumulativeActualVsForecast: {
    'Total Forecast': {
      borderColor: 'rgb(99,145,79)',
      backgroundColor: 'rgb(99,145,79)',
    },
    'Total Actual': {
      borderColor: 'rgb(236,207,109)',
      backgroundColor: 'rgb(236,207,109)',
    },
  },
}
