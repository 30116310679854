import { styled, useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'

const FormTableFooterButton = styled(Button)(() => ({
  height: 60,
  width: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: '#ffffff',
  ':hover': {
    backgroundColor: '#bbbbbb',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
  ':disabled': {
    backgroundColor: '#d9d9d9',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
}))

export default FormTableFooterButton
