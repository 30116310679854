import { Box, IconButton, Typography } from '@mui/material'
import userIcon from '../assets/img/dummyAvatar.png'
import styles from '@styles/userBox.module.scss'

import { InitUserInfo, UserInfo } from '@services/model/user.model'
import useLocalStorage from '@hooks/useLocalStorage'
import { Link, useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import home from '../assets/img/home.svg'

const UserBox = () => {
  // const { keycloak, initialized } = useKeycloak()
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const navigate = useNavigate()

  const UserIcon = () => {
    return (
      <Box
        component="img"
        alt="UserIcon"
        src={userIcon}
        sx={{ width: '80%', padding: '10px', borderRadius: '99px' }}
      />
    )
  }

  const BtnBar = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          height: '70px',
          alignItems: 'center',
        }}>
        <Box className={styles.homeBtn} />
        <Box className={styles.notificationBtn} />
      </Box>
    )
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#f2f2f2',
          boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
        }}></Box>
      <BtnBar></BtnBar>
    </>
  )
}

export default UserBox
