import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import styles from '@styles/var.module.scss'
import '@styles/common.module.scss'
import SnackbarCloseButton from '@components/SnackbarCloseButton'

// import i18n (needs to be bundled ;))
// import './utils/i18n'

import '@i18n/index'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { CssBaseline } from '@mui/material'
import '@styles/globalStyles.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: styles.main,
      light: styles.light,
      dark: styles.dark,
      contrastText: styles.contrasttext,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h6: {
      fontSize: '1rem',
    },
  },

  components: {
    // MuiDivider: {
    //     styleOverrides: {
    //         root: {
    //             marginLeft: 0,
    //             marginRight: 0,

    //             '::before': {
    //                 width: '0%',
    //             },
    //         },
    //     },
    // },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          border: '1px solid #c9c9c9',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 14px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            '& .MuiOutlinedInput-input': {
              padding: '8px 14px',
            },
          },
        },
        multiline: {
          padding: '8px 14px !important',
        },
        inputMultiline: {
          padding: '0px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // color: '#000000 !important',
          // borderBottomColor: '#000000 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px 14px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 8px) scale(1)',
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0px !important',
          '& .MuiAutocomplete-input': {
            padding: '7.5px 14px',
          },
        },
        root: {
          '& .MuiAutocomplete-tag': {
            margin: '0 3px 0 0 !important',
          },
        },
        endAdornment: {
          top: 'auto',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          '&.Mui-selected': {
            // <-- mixing the two classes
            backgroundColor: '#add3eb !important',
          },
        },
      },
    },
  },
})

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider
      maxSnack={5}
      action={(key) => {
        return <SnackbarCloseButton id={key} />
      }}>
      <App />
    </SnackbarProvider>
  </ThemeProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
