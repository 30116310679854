import apiGateway from '@utils/axiosInterceptor'
import { ContractNo } from './model/contract.model'

const GetProjectList = async () => {
  return await apiGateway.get<any[]>('/api/v1/projects').then((rep) => {
    return rep.data
  })
}
const item = window.localStorage.getItem('usr')

let userId = ''
if (item) {
  const userInfo = JSON.parse(item)
  userId = userInfo.uuid
}

const GetProjectListByCurrentUser = async () => {
  return await apiGateway.get<any[]>(`/api/v1/projects?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const GetProjectListByUser = async (userId: string) => {
  if (!userId) return
  return await apiGateway.get<any[]>(`/api/v1/projects?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const ProjectService = {
  GetProjectList,
  GetProjectListByCurrentUser,
  GetProjectListByUser,
}

export default ProjectService
