import { KeyValPair } from '@models/Common'
import _ from 'lodash'

const getUserRightByRole = (userInfo, globalState, allowedRoleNames?: Array<string>) => {
  const roleMetaList = globalState?.roleMetaList ?? []
  const currentUserRoles: Array<string> = userInfo?.roles?.map((role: KeyValPair) => {
    const matchingRole = roleMetaList.find((metaRole) => metaRole.key === role)
    return matchingRole?.value
  })

  function hasMatchingRole(currentUserRoles: string[], rolesToCheck: string[]): boolean {
    return rolesToCheck?.some((role) => currentUserRoles?.includes(role))
  }

  const hasUserRight = hasMatchingRole(currentUserRoles, allowedRoleNames ?? [])

  return {
    hasUserRight,
  }
}

export default getUserRightByRole
