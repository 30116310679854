const blobToBase64 = (blobUrl) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', blobUrl, true)
    xhr.responseType = 'blob'

    xhr.onload = function (e) {
      if (this.status === 200) {
        const reader = new FileReader()
        reader.readAsDataURL(this.response)
        reader.onloadend = function () {
          const base64data = (reader.result as string).split(',')[1]
          resolve(base64data)
        }
      } else {
        reject(new Error(`Failed to load Blob from URL ${blobUrl}. Status code: ${this.status}`))
      }
    }

    xhr.onerror = function () {
      reject(new Error(`Failed to load Blob from URL ${blobUrl}.`))
    }

    xhr.send()
  })
}

export { blobToBase64 }
