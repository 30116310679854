import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { FormDrsItemsModel } from '@services/model/form/form.DRS.item.model'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormTypography from '@components/form/typography'
import cleanString from '@utils/cleanString'
import { DisposalGroundEnum } from '@services/model/form/form.DRS.model'

const DailyRecordSummaryItemBatchPartA = ({
  uneditable,
  control,
  drsGeneralOptions,
}: {
  uneditable: boolean
  control: Control<FormDrsItemsModel>
  drsGeneralOptions: any
}) => {
  let index = 0
  const designatedDisposalGroundOptions = drsGeneralOptions?.disposalGround?.filter(
    (option) => cleanString(option.value) !== cleanString(DisposalGroundEnum.other),
  )

  const { t } = useTranslation(['forms'])
  return (
    <>
      <FormContainer fkey={`drs-a`}>
        <FormField fieldName={t('common:baseForm.contractNo')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractNo',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('common:baseForm.contractName')} index={index++}>
          <FormController
            controllerProps={{
              name: 'baseForm.contractName',
              control: control,
            }}>
            <FormTypography type="string" />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.dateOfDisposal')} index={index++}>
          <FormController
            controllerProps={{
              name: 'dateOfDisposal',
              control,
            }}>
            <FormDateTimePicker
              type="date"
              maxDate={new Date()}
              disabled={uneditable}
              sx={{ width: '100%' }}
              withCurrent={true}
            />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.cDMaterialType')} index={index++}>
          <FormController
            controllerProps={{
              name: 'cDMaterialType',
              control,
            }}>
            <FormSelect
              options={drsGeneralOptions?.cDMaterialType ?? []}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormField fieldName={t('forms:dailyRecordSummary.fields.disposalGround')} index={index++}>
          <FormController
            controllerProps={{
              name: 'disposalGround',
              control,
            }}>
            <FormSelect
              options={designatedDisposalGroundOptions}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
      </FormContainer>
    </>
  )
}
export default DailyRecordSummaryItemBatchPartA
