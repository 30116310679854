import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { FormDrsModel, InitDrsForm } from '@services/model/form/form.DRS.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormDrsService from '@services/formService/form.DRS.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import DailyRecordSummaryPartA from './PartA'
import DailyRecordSummaryPartB from './PartB'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import getFormValidationSchema from '@utils/getFormValidationSchema'
import useLocalStorage from '@hooks/useLocalStorage'
import _ from 'lodash'
import getErrMsg from '@utils/getErrMsg'

const DailyRecordSummaryFormPage = () => {
  const UUID = uuid()
  const [firstSaveSuccess, setFirstSaveSuccess] = useState(false)

  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  // extracting id from params
  let { id: formId } = useParams<string>()
  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const cat = QueryStepParser(query.get('cat'))
  const [missingGeneralInfo, setMissingGeneralInfo] = useState(true)
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  const [failValidation, setFailValidation] = useState(true)

  Yup.addMethod(Yup.array, 'unique', function (field, message) {
    return this.test('unique', message, function (array) {
      let errors: any = []
      const counts = {}
      array?.forEach((row) => {
        if (row.status && row[field] && !counts[row[field]?.toLowerCase()]) {
          counts[row[field]?.toLowerCase()] = 0
        }
        counts[row[field]?.toLowerCase()]++
      })

      Object.keys(counts).forEach((key) => {
        if (counts[key] > 1) {
          const duplicateValues = array?.filter((row, i) => {
            return row.status && row[field] && row[field]?.toLowerCase() === key
          })
          duplicateValues?.forEach((row, i) => {
            errors.push(
              this.createError({
                path: `${this.path}[${array?.indexOf(row)}].${field}`,
                message,
              }),
            )
          })
        }
      })
      if (errors?.length > 0) {
        throw new Yup.ValidationError(errors)
      }
      return true
    })
  })

  const defaultMsg = t('forms:validate.messages.required')

  const validationSchemaByFormStatus = [
    {
      formStatusList: [
        FormStatusEnum.DailyRecordSummaryDraft,
        FormStatusEnum.DailyRecordSummaryFirstRejected,
      ],

      validationSchema: Yup.object().shape({
        drsItems: Yup.array()
          .of(
            Yup.object().shape({
              chitNo: Yup.string().nullable().required(defaultMsg),
              vehicleRegistrationMark: Yup.string().nullable().required(defaultMsg),
              approxVol: Yup.number().nullable().required(defaultMsg),
              cDMaterialType: Yup.number().nullable().required(defaultMsg),
              disposalGround: Yup.number().nullable().required(defaultMsg),
              departureTimeFromSite: Yup.string().nullable().required(defaultMsg),
            }),
          ) //@ts-ignore
          .unique('chitNo', 'Chit Must be Unique'),
      }),
    },
    {
      formStatusList: [FormStatusEnum.DailyRecordSummaryFirstReview],
      validationSchema: Yup.object().shape({
        drsItems: Yup.array().of(Yup.object().shape({})), //@ts-ignore
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.DailyRecordSummaryFirstApproved,
        FormStatusEnum.DailyRecordSummarySecondRejected,
      ],
      validationSchema: Yup.object().shape({
        drsItems: Yup.array()
          .of(
            Yup.object().shape({
              chitNo: Yup.string().nullable().required(defaultMsg),
              vehicleRegistrationMark: Yup.string().nullable().required(defaultMsg),
              approxVol: Yup.number().nullable().required(defaultMsg),
              cDMaterialType: Yup.number().nullable().required(defaultMsg),
              disposalGround: Yup.number().nullable().required(defaultMsg),
              departureTimeFromSite: Yup.string().nullable().required(defaultMsg),
              actualDisposalGround: Yup.number().nullable().required(defaultMsg),
              arrivedTime: Yup.string().nullable().required(defaultMsg),
            }),
          ) //@ts-ignore
          .unique('chitNo', 'Chit Must be Unique'),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.DailyRecordSummarySecondReview,
        FormStatusEnum.DailyRecordSummaryFirstReview,
        FormStatusEnum.DailyRecordSummarySecondApproved,
      ],
      validationSchema: Yup.object(),
    },
  ]

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    register,
    trigger,
    setError,
    clearErrors,
    formState: { errors: formErrors },
  } = useForm<FormDrsModel>({
    defaultValues: { ...InitDrsForm },
    // resolver: yupResolver(validationSchema, { abortEarly: false }),
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaByFormStatus,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })

  const [
    validatedPassed,
    dateOfDisposal,
    designatedDisposalGround,
    approvedAlternativeDisposalGround,
  ] = useWatch({
    name: [
      'validatedPassed',
      'dateOfDisposal',
      'designatedDisposalGround',
      'approvedAlternativeDisposalGround',
    ],
    control: control,
  })

  const isValidatedRef = useRef(validatedPassed)

  useEffect(() => {
    if (missingGeneralInfo === false && !formId) {
      handleOnSave()
    }
  }, [missingGeneralInfo, dateOfDisposal, designatedDisposalGround])

  const [contractNoId, contractNo, contractName, formStatus, formPermission] = useWatch({
    name: [
      'baseForm.contractNoId',
      'baseForm.contractNo',
      'baseForm.contractName',
      'baseForm.formStatus',
      'baseForm.formPermission',
    ],
    control: control,
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const drsGeneralOptions = useRef<FormDrsGeneralOptionsModel>()

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyList', notifyList)
    setValue('assignToList', assignToList)
    setValue('submissionComment', submissionComment)
    const values = getValues()
    if (isReject) {
      console.debug('isRejected')
      setRequest({
        callback: async () => {
          await FormDrsService.RejectDrsForm(values).then((resp) => {
            enqueueSnackbar(t('common:messages.recordSubmitted'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/daily-record-summary/all-records')
          })
        },
      })
    } else if (isReturn) {
      console.debug('isReturn')
      if (!(await trigger())) {
        console.debug('trigger failed isReturn')
        enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
        return
      }
      setRequest({
        callback: async () => {
          await FormDrsService.ReturnDrsForm(values).then((resp) => {
            enqueueSnackbar(t('common:messages.recordSubmitted'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/daily-record-summary/all-records')
          })
        },
      })
    } else {
      console.debug('ApproveDrsForm')

      if (!(await trigger())) {
        console.debug('trigger failed ApproveDrsForm', formErrors)
        enqueueSnackbar(t('forms:validate.messages.checkFormErrors'), { variant: 'error' })
        return
      }
      setRequest({
        callback: async () => {
          await FormDrsService.ApproveDrsForm(values).then((resp) => {
            enqueueSnackbar(t('common:messages.recordSubmitted'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/daily-record-summary/all-records')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    let values = getValues()

    setRequest({
      callback: async () => {
        await FormDrsService.SaveDrsForm(values)
          .then((resp) => {
            if (resp?.formId) {
              if (!formId) {
                setFirstSaveSuccess(true)
              }
              setValue('baseForm.formId', resp.formId)
              setValue('baseForm.formStatus', resp.formStatus)
              NavigateTo(navigate, '/daily-record-summary/:id', {
                id: resp.formId,
              })
              formId = resp.formId
              if (formId) {
                reload(formId)
              }
            }
            enqueueSnackbar(t('common:messages.recordSaved'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
          })
          .catch((err) => {
            if (formId) {
              reload(formId)
            } else {
              const { dateOfDisposal, ...rest } = values
              reset(rest)
            }
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormDrsService.DeleteDrsForm(getValues())
          .then(() => {
            enqueueSnackbar(t('common:messages.recordArchived'), {
              variant: 'success',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
            NavigateTo(navigate, '/daily-record-summary/all-records')
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
    return () => {
      // formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formStatus])

  useEffect(() => {
    if (
      currentContract?.contractId &&
      currentContract?.contractNo &&
      currentContract?.contractName &&
      currentProject?.projectId &&
      !formId
    ) {
      setValue('baseForm.contractNoId', currentContract.contractId)
      setValue('baseForm.contractNo', currentContract.contractNo)
      setValue('baseForm.contractName', currentContract.contractName)
      setValue('baseForm.projectId', currentProject?.projectId)
      setValue('chitAccountNo', currentProject?.chitAccountNo)

    }

    if (!isMounted.current) {
      reloadDrsGeneralOptions()
    }
    if (formStateContext.form && formStateContext.formType === 'DRS') {
      formStateAction({ type: 'pop', targetFormType: 'DRS' })
      let prevform = { ...formStateContext.form }
      isMounted.current = false // if back from another form, reevaluate onNext disable by reset form
      reset(prevform)
      isMounted.current = true
    } else if (globalState?.contractList && formId && !isMounted.current) {
      reload(formId)
      isMounted.current = true
    }
    return () => {}
  }, [formId, currentContract, globalState?.contractList])

  const reloadDrsGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormDrsService.GetDrsGeneralOptions().then(async (res) => {
          drsGeneralOptions.current = res
        })
      },
    })
  }

  const reload = async (formId: string) => {
    setRequest({
      callback: async () => {
        const list: Array<any> = []
        await FormDrsService.GetDrsForm(formId, formStatus)
          .then(async (res) => {
            if (res) {
              const resContractNo = _.get(
                _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
                'contractNo',
              )
              const resContractName = _.get(
                _.find(globalState?.contractList, { id: res.baseForm.contractNoId }),
                'contractName',
              )


              const resChitAccountNo = _.get(
                _.find(globalState?.projectList, { id: res.baseForm.projectId }),
                'chitAccountNo',
              )

              let newRes = {
                ...res,
                chitAccountNo:resChitAccountNo,
                baseForm: {
                  ...res.baseForm,
                  contractNo: resContractNo,
                  contractName: resContractName,
                },
              }
              reset(newRes)
              if (res.baseForm.formStatus !== formStatus) {
                globalAction({
                  type: 'changeFormStatus',
                  formStatus: res.baseForm.formStatusName,
                })
              }
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }
  const onClickSaveRowBtn = async (event, index, datum, updateField) => {
    if (!(await trigger())) {
      return
    } else {
      updateField(index)
    }
  }

  const partA = (): FormPartComponents => {
    const handleOnNext = (disabled: boolean) => {
      if (disabled) setMissingGeneralInfo(true)
      else setMissingGeneralInfo(false)
    }
    return {
      partTitle: t('forms:dailyRecordSummary.sections.general.title'),
      component: (
        <DailyRecordSummaryPartA
          formId={formId}
          formStatus={formStatus}
          onNextDisabled={handleOnNext}
          useWatch={useWatch}
          control={control}
          formPermission={formPermission}
          drsGeneralOptions={drsGeneralOptions.current}
          handleOnSave={handleOnSave}
        />
      ),
      onNextDisabled: missingGeneralInfo || !formId,
      onNext: async () => {
        if (firstSaveSuccess) {
          let values = getValues()
          FormDrsService.LinkDrsItems(values).then((resp) => {
            reload(resp.formId)
          })
        }
      },
    }
  }

  const partB = (): FormPartComponents => {
    const handleOnNext = (disabled: boolean) => {
      if (disabled) setFailValidation(true)
      else setFailValidation(false)
    }
    return {
      partTitle: t('forms:dailyRecordSummary.sections.part1part2.title'),
      component: (
        <DailyRecordSummaryPartB
          onNextDisabled={handleOnNext}
          getValues={getValues}
          control={control}
          useWatch={useWatch}
          setValue={setValue}
          setError={setError}
          formPermission={formPermission}
          formStatus={formStatus}
          drsGeneralOptions={drsGeneralOptions.current}
          globalState={globalState}
          onClickSaveRowBtn={onClickSaveRowBtn}
          trigger={trigger}
          reload={reload}
          formId={formId}
          isValidatedRef={isValidatedRef}
        />
      ),
      onNextDisabled: failValidation || missingGeneralInfo || !formId,
      disabled: missingGeneralInfo || !formId,
    }
  }

  const partC = (): FormPartComponents => {
    return {
      partTitle: t('forms:dailyRecordSummary.sections.confirmation.title'),
      component: (
        <FormContainer fkey={`${UUID}-mwf-g`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission.endOfFlow}
            userMetaList={globalState.userMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
      onNextDisabled: failValidation || missingGeneralInfo || !formId,
      disabled: failValidation || missingGeneralInfo || !formId,
    }
  }
  const onLoadNotifyList = async (isReject: boolean) => {
    let defaultNotifyList = await FormService.GetNotifyList(
      'DRS',
      getValues('baseForm.formId'),
      isReject,
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    let defaultHistoryList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultHistoryList
  }

  const { FormSwitcher, handleOnComplete, activeStep } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:dailyRecordSummary.title'),
    components: [partA(), partB(), partC()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate,
    disableSaveBtn:
      formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
      formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
      formStatus === FormStatusEnum.DailyRecordSummarySecondApproved,
    disableComment: getValues('baseForm.formId') ? false : true,
    disableDelete: !formPermission?.canDelete,
    approveRequired: formPermission?.approveRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
    minAssignUser: formStatus === FormStatusEnum.DailyRecordSummarySecondApproved ? undefined : 1,
  })
  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}
export default DailyRecordSummaryFormPage
