import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import react from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import FormMultipleSelect from '@components/form/multipleSelect'
import { QueryStepParser } from '@utils/queryStepParser'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import {
  UserFormModel,
  InitUserForm,
  RoleNameEnum,
  cicAdminRoles,
} from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material'
import getErrMsg from '@utils/getErrMsg'
import FormDataGrid from '@components/form/datagrid'
import Tabs from '@components/Tabs'
import {
  GridCellParams,
  GridColDef,
  GridFilterOperator,
  GridRenderCellParams,
} from '@mui/x-data-grid'

import getUserRightByRole from '@utils/getUserRightByRole'
import LoadingPopup from '@components/form/LoadingDialog'
import FormDialog from '@components/form/dialog_v2'

const UUID = uuid()
let readonly = false

export type DialogContextProps = {
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const AdminUserFormPage = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  // extracting id from params
  let { id: userId } = useParams<string>()
  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const profile = query.get('profile')

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { touchedFields, errors },
  } = useForm<UserFormModel>({
    defaultValues: { ...InitUserForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestContracts } = useAPIFetch()
  const { setRequest: setRequestProjects } = useAPIFetch()
  const { setRequest: setRequestOrganizations } = useAPIFetch()
  let index = 0
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const rows: any = []

  const [organizationMeta, setOrganizationMeta] = useState<any>([])
  const [activeProjectsData, setActiveProjectsData] = useState<any>(rows)
  const [inactiveProjectsData, setInactiveProjectsData] = useState<any>(rows)
  const [activeContractsData, setActiveContractsData] = useState<any>(rows)
  const [inactiveContractsData, setInactiveContractsData] = useState<any>(rows)
  const [activeOrganizationsData, setActiveOrganizationsData] = useState<any>(rows)
  const [inactiveOrganizationsData, setInactiveOrganizationsData] = useState<any>(rows)
  const { hasUserRight: showAllUsers } = getUserRightByRole(userInfo, globalState, cicAdminRoles)

  readonly = !showAllUsers || profile === 'true'

  const [ciamUserId] = useWatch({
    name: ['ciamUserId'],
    control: control,
  })

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (userId) {
          await AdminService.GetUserForm(userId)
            .then(async (f) => {
              if (f) reset(f)
              setActiveOrganizationsData(f?.organization ? [f.organization] : [])
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        }
        isMounted.current = true
      },
    })
    setRequestContracts({
      callback: async () => {
        if (userId) {
          await AdminService.GetContractListByUserId(userId)
            .then((res) => {
              let list = res ?? []
              setActiveContractsData(list?.filter((x) => x.status === true))
              setInactiveContractsData(list?.filter((x) => x.status === false))
            })
            .catch(() => {})
        }
      },
    })
    setRequestProjects({
      callback: async () => {
        if (userId) {
          await AdminService.GetProjectListByUserId(userId)
            .then((res) => {
              let list = res ?? []
              setActiveProjectsData(list?.filter((x) => x.status === true))
              setInactiveProjectsData(list?.filter((x) => x.status === false))
            })
            .catch(() => {})
        }
      },
    })
  }

  useEffect(() => {
    reloadOthers()

    if (userId == null) {
      reset({ ...InitUserForm })
    }
    if (userId) {
      reload()
    }
  }, [userId])

  const reloadOthers = async () => {
    let list = await AdminService.GetOrganizationMeta()

    if (list) {
      const [_, ...newList] = list
      let organizationList =
        newList?.map((x) => {
          return {
            key: x.identifier,
            value: x.name,
          }
        }) || []

      setOrganizationMeta(organizationList)
    }

    isMounted.current = true
  }

  const handleOnSubmit = async (data: any) => {
    const values = data
    if (values.status === undefined) {
      values.status = true
    }
    setRequest({
      callback: async () => {
        const ciamUserProfile = {
          firstName: values.firstName,
          lastName: values.lastName,
          displayName: values.firstName + ' ' + values.lastName,
          email: values.email,
          login: values.email,
          mobilePhone: values.phone,
          customRedirectURL: process.env.REACT_APP_API_BASE,
        }

        console.log('userPage user value', values)
        if (!values.ciamUserId || values.ciamUserId === '' || !userId) {
          await AdminService.SaveCreateUser(values).then(async (resp) => {
            if (resp) {
              await handeCiamInviteUser(ciamUserProfile, resp.userId)
              navigate(`/admin/user/${resp.userId}`)
            }
          })
        } else if (values.ciamUserId && userId) {
          await AdminService.SaveCreateUser(values).then(async (resp) => {
            if (resp) {
              const { login, email, ...ciamUserUpdateProfile } = ciamUserProfile
              await AdminService.CiamUpdateUserProfile(
                { profile: ciamUserUpdateProfile },
                values.ciamUserId,
              )
                .then(async (resp) => {
                  if (resp) {
                    enqueueSnackbar(t('common:messages.recordSaved'), {
                      variant: 'success',
                    })
                    NavigateTo(navigate, '/admin/user/all-records')
                  }
                })
                .catch(async (err) => {
                  if (err) {
                    if (err && !err.response) {
                      enqueueSnackbar('Successfully Okta user profile', {
                        variant: 'success',
                      })
                      NavigateTo(navigate, '/admin/user/all-records')
                    } else {
                      enqueueSnackbar(
                        err.response?.data?.errSummary ??
                          'Unknown error when updating Okta user profile',
                        {
                          variant: 'error',
                        },
                      )
                    }
                  }
                })
            }
          })
        } else if (userId) {
          await AdminService.SaveCreateUser(values).then(async (resp) => {
            if (resp) {
              enqueueSnackbar(
                t(
                  'Record Saved successfully in Database, No ciamUserId found, please try to invite user again',
                ),
                {
                  variant: 'success',
                },
              )
              NavigateTo(navigate, '/admin/user/all-records')
            }
          })
        }
      },
    })
  }

  const handeCiamInviteUser = async (ciamUserProfile: any, userId: string) => {
    await AdminService.CiamGetUserProfile(ciamUserProfile?.email ?? '')
      .then(async (res) => {
        if (res?.id) {
          await AdminService.CiamAddUserToGroup(res.id).catch(async (err) => {
            if (err && !err.response) {
              await handleEditCiamInfo(userId, res.id)
                .then(async (err) => {
                  enqueueSnackbar(t('Successfully add Ciam User Id to the Database'), {
                    variant: 'success',
                  })
                })
                .catch(async (err) => {
                  enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                    variant: 'error',
                  })
                })
              enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                variant: 'success',
              })
            }
          })
        } else {
          console.log('no res.id but success handeCiamInviteUser')
          ciamUserProfile.login = ciamUserProfile.email
          ciamUserProfile.displayName = ciamUserProfile.firstName + ' ' + ciamUserProfile.lastName
          await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
            .then(async (res) => {
              if (res) {
                const ciamUserId = res.id
                await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                  if (err && !err.response) {
                    await handleEditCiamInfo(userId, ciamUserId)
                      .then(async (err) => {
                        enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                          variant: 'success',
                        })
                      })
                      .catch(async (err) => {
                        enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                          variant: 'error',
                        })
                      })
                    enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                      variant: 'success',
                    })
                  }
                })
              }
            })
            .catch((err) => {
              if (err.response?.data) {
                enqueueSnackbar(
                  err.response?.data?.errorSummary ?? 'Unknown Okta Error Creating User',
                  {
                    variant: 'error',
                  },
                )
              }
            })
        }
      })
      .catch(async (err) => {
        console.log('in catch', err)

        // no user found, create user
        ciamUserProfile.login = ciamUserProfile.email
        await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
          .then(async (res) => {
            if (res) {
              const ciamUserId = res.id
              await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                if (err && !err.response) {
                  await handleEditCiamInfo(userId, ciamUserId)
                    .then(async (err) => {
                      enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                        variant: 'success',
                      })
                    })
                    .catch(async (err) => {
                      enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                        variant: 'error',
                      })
                    })
                  enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                    variant: 'success',
                  })
                }
              })
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              enqueueSnackbar(
                err.response?.data?.errorSummary ?? 'Unknown Okta Error Creating User',
                {
                  variant: 'error',
                },
              )
            }
          })
      })
  }

  const handleEditCiamInfo = async (userId: string, email: string) => {
    await AdminService.EditUserCiamInfo(userId, email)
      .then((res) => {
        enqueueSnackbar('Successfully update Ciam User Id', {
          variant: 'success',
        })
        navigate(`/admin/user/all-records`)
      })
      .catch((err) => {
        enqueueSnackbar(getErrMsg(t, err), {
          variant: 'error',
        })
      })
  }

  const checkCiamEmail = async () => {
    const email = getValues('email')
    await AdminService.CiamGetUserProfile(email)
      .then(async (resp) => {})
      .catch((err) => {})
  }

  const findTreeNode = (key: number, tree: any[]): any => {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.key === key) {
        return node
      } else if (node.children) {
        const childNode = findTreeNode(key, node.children)
        if (childNode) {
          return childNode
        }
      }
    }
    return null
  }

  const getRoleName = (key: number, treeData): string => {
    const node = findTreeNode(key, treeData)
    return node ? node.value : ''
  }
  const ArrayRenderer = (params: GridCellParams) => {
    const arrayValue = params.value as number[]
    const roleNames = arrayValue.map((value) => `${getRoleName(value, globalState.roleMetaList)}`)
    const roleNameString = roleNames.join(', ')
    return <div>{roleNameString}</div>
  }

  const RoleDropdownOperator: GridFilterOperator = {
    label: 'Role Name',
    value: 'role-dropdown',
    getApplyFilterFn: (filterItem) => {
      const roleId = filterItem.value as number

      return (params) => {
        const roleIds = params.value as number[]
        return roleId === 0 || !roleId || roleIds.includes(roleId)
      }
    },
    InputComponent: ({ item, applyValue, ...rest }) => {
      const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

      const handleRoleSelect = (event) => {
        const roleId = event.target.value
        setSelectedRoleId(roleId)
        applyValue({ ...item, value: roleId })
      }
      return (
        <FormControl sx={{ minWidth: 120 }} variant={'standard'}>
          <InputLabel id="role-select-label">Role Name</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRoleId}
            onChange={handleRoleSelect}
            {...rest}>
            <MenuItem value={0}>{t('Select')}</MenuItem>
            {globalState.roleMetaList
              ?.filter(
                (x) =>
                  x.value === RoleNameEnum.EnvironmentalOfficer ||
                  x.value === RoleNameEnum.EnvironmentalManager,
              )
              ?.map((node) => (
                <MenuItem key={node.key} value={node.key}>
                  {t(node.value as string)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    },
  }

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin/user/all-records')
  }

  type ListComponentProps = {
    status: 'active' | 'inactive' | 'invited'
    dataType: 'Contract' | 'Project' | 'Organization'
    control: any
    readonly: boolean
    data: any[]
    columns: GridColDef[]
    rowId: string
  }

  const ListComponent = (props: ListComponentProps) => {
    const { status, control, readonly, data, dataType, columns, rowId } = props

    const controlProps = {
      name: `${status}${dataType}List`,
      control,
    }

    return (
      <FormController controllerProps={controlProps}>
        <FormDataGrid
          getRowId={(row: any) => row[rowId]}
          rows={data}
          columns={columns}
          uneditable={readonly}
          checkboxSelection={false}
        />
      </FormController>
    )
  }

  const organizationColumns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: t('Company Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'subsidiaryName',
      headerName: t('Subsidiary Name'),
      width: 150,
      editable: false,
    },

    {
      field: 'uuid',
      headerName: t('Link to Edit Organization'),

      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/organization/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Organization')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const projectColumns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: t('Project Name'),
      width: 150,
      editable: false,
    },

    {
      field: 'id',
      headerName: t('Link to Edit Project'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/project/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Project')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const contractColumns: GridColDef[] = [
    {
      field: 'contractName',
      headerName: t('Contract Name'),
      width: 150,
      editable: false,
    },

    {
      field: 'id',
      headerName: t('Link to Edit Contract'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/contract/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Contract')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const activeProjectList = (
    <ListComponent
      status="active"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={activeProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )

  const inactiveProjectList = (
    <ListComponent
      status="inactive"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={inactiveProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )
  const inactiveContractList = (
    <ListComponent
      status="inactive"
      dataType="Contract"
      control={control}
      readonly={readonly}
      data={inactiveContractsData}
      columns={contractColumns}
      rowId={'id'}
    />
  )

  const activeContractList = (
    <ListComponent
      status="active"
      dataType="Contract"
      control={control}
      readonly={readonly}
      data={activeContractsData}
      columns={contractColumns}
      rowId={'id'}
    />
  )

  const activeOrganizationList = (
    <ListComponent
      status="active"
      dataType="Organization"
      control={control}
      readonly={readonly}
      data={activeOrganizationsData}
      columns={organizationColumns}
      rowId={'uuid'}
    />
  )

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}>
              {!userId ? t('Add User') : t('Edit User')}
            </Typography>
          </Box>
          <FormContainer fkey={`${UUID}-ei-a`}>
            <FormField fieldName="First Name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'firstName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Last Name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'lastName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Organization" index={index++}>
              <FormController
                controllerProps={{
                  name: 'organizationId',
                  control,
                }}>
                <FormSelect options={organizationMeta || []} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Position" index={index++}>
              <FormController
                controllerProps={{
                  name: 'position',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Role" index={index++}>
              <FormController
                controllerProps={{
                  name: 'roles',
                  control,
                }}>
                <FormMultipleSelect
                  key={2}
                  options={globalState?.roleMetaList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            {/* <FormField fieldName="Enrolment" index={index++}>
              <FormController
                controllerProps={{
                  name: 'enrolment',
                  control,
                }}>
                <FormSelect
                  options={globalState?.enrolmentStatusList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField> */}
            <FormField fieldName="Email Address" index={index++}>
              <FormController
                controllerProps={{
                  name: 'email',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={readonly || !!userId}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Phone Number" index={index++}>
              <FormController
                controllerProps={{
                  name: 'phone',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Assigned Organizations" index={index++}>
              {activeOrganizationList}
            </FormField>
            <FormField fieldName="Assigned Projects" index={index++}>
              <Box sx={{ height: 540, width: '100%' }}>
                {
                  <Tabs
                    data={[
                      { content: activeProjectList, menuTitle: t('Active'), title: 'Active' },
                      { content: inactiveProjectList, menuTitle: t('Inactive'), title: 'Inactive' },
                    ]}
                  />
                }
              </Box>
            </FormField>
            <FormField fieldName="Assigned Contracts" index={index++}>
              <Box sx={{ height: 540, width: '100%' }}>
                {
                  <Tabs
                    data={[
                      { content: activeContractList, menuTitle: t('Active'), title: 'Active' },
                      {
                        content: inactiveContractList,
                        menuTitle: t('Inactive'),
                        title: 'Inactive',
                      },
                    ]}
                  />
                }
              </Box>
            </FormField>
            {ciamUserId && userInfo?.allRights ? (
              <FormField fieldName="Remove User From Okta" index={index++}>
                <Button
                  onClick={async () => {
                    const ciamUserId = getValues()?.ciamUserId
                    if (ciamUserId) {
                      await AdminService.CiamRemoveUserGroup(ciamUserId)
                    }
                  }}
                  sx={{
                    padding: 1,
                    backgroundColor: '#ccc',
                    '&hover': {
                      backgroundColor: '#000',
                    },
                  }}>
                  Remove User From Okta
                </Button>
              </FormField>
            ) : (
              <></>
            )}
          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!userId ? t('common:buttons.back') : t('common:buttons.back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmit(handleOnSubmit)}
                disabled={false}>
                {!userId ? t('Submit') : t('Save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>

          <LoadingPopup open={isLoading} />

          <DevTool control={control} />
        </StyledBox>
      </Box>
    </Fragment>
  )
}
export default AdminUserFormPage
