import { Fragment, useContext, useEffect, useInsertionEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents, KeyValPair } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import {
  FormDrsUploadModel,
  InitFormDrsUploadModel,
  DRSDocRequestModel,
} from '@services/model/form/form.DRS.upload.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormDrsService from '@services/formService/form.DRS.service'
import useAPIFetch from '@hooks/useAPIFetch'
const UUID = uuid()
import DailyRecordSummaryUploadPartA from './PartA'
import DailyRecordSummaryUploadPartB from './PartB'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ValidationError } from 'yup'
import { Button, Grid, Stack } from '@mui/material'
import { FormStatusEnum } from '@services/model/form/form.model'
import useLocalStorage from '@hooks/useLocalStorage'
import getErrMsg from '@utils/getErrMsg'
import FormField from '@components/form/field'
import DialogButton from '@components/button/DialogButton'

const DailyRecordSummaryUploadFormPage = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { state: globalState, userInfo, hasRightByCatAndCode } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  // extracting id from params
  let { id: formId } = useParams<string>()
  // extracting categoryId from url query and parsing it as Int
  const cat = QueryStepParser(query.get('cat'))
  // extracting step from query
  const month = query.get('month')
  const chitNoParam = query.get('chitNo')
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestOptions } = useAPIFetch()

  const defaultMsg = t('Required')
  const validationSchema = Yup.object().shape({
    chitNo: Yup.string().typeError(defaultMsg).required(defaultMsg),
    reportPeriod: Yup.string().typeError(defaultMsg).required(defaultMsg),
    // disposalGround: Yup.number().typeError(defaultMsg).required(defaultMsg),
    // typeOfMaterial: Yup.number().typeError(defaultMsg).required(defaultMsg),
  })

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )

  const { control, watch, setValue, getValues, handleSubmit, reset, trigger, setError } =
    useForm<FormDrsUploadModel>({
      defaultValues: { ...InitFormDrsUploadModel },
      resolver: yupResolver(validationSchema, { abortEarly: false }),
      mode: 'all',
      reValidateMode: 'onBlur',
      criteriaMode: 'all',
    })

  const [drsGeneralOptions, setDrsGeneralOptions] = useState<FormDrsGeneralOptionsModel>()
  const formPermission = getValues('baseForm.formPermission')
  const fileEditable = getValues('baseForm.editable')

  const formStatus = getValues('baseForm.formStatus')
  const isMounted = useRef(false)
  const acknowledgement = t(
    'I confirm that the uploaded documents and inputted information are correct, and I acknowledge that I will not be able to delete documents or change document details after I confirm changes.',
  )
  const uneditable = formPermission?.canUpdate === false || fileEditable === false

  const [dataList, setDataList] = useState<any>([])
  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    // setValue('signatureBase64', signatureBase64)
    // Save function override submit function
  }
  const handleOnDelete = async () => {
    // This Form do not have a delete function
  }

  const getErrorMessage = (error, chitNo) => {
    if (error.item) {
      return (
        <>
          {t('Already exsit in the system. Please go to the form of chitNo:')}
          <Button
            onClick={() => {
              NavigateTo(navigate, '/daily-record-summary-upload/:id', { id: error.item })
            }}>
            {chitNo}
          </Button>
        </>
      )
    } else {
      return error.message
    }
  }
  const handleOnSave = async () => {
    if (!(await trigger())) {
      return
    }

    if (currentContract?.contractId) {
      setValue('baseForm.contractNoId', currentContract?.contractId)
    }

    if (currentProject?.projectId) {
      setValue('baseForm.projectId', currentProject?.projectId)
    }
    const _values = getValues()
    delete _values.lists

    setRequest({
      callback: async () => {
        await FormDrsService.SaveDrsDocument(_values)
          .then((resp) => {
            if (resp) {
              setValue('truckPhotos', undefined)
              setValue('chitReceipts', undefined)
              formId = resp.formId
              if (formId) {
                reload(formId)
                NavigateTo(navigate, '/daily-record-summary-upload/:id', { id: formId })
                handleBack(undefined)
              }
            }
          })
          .catch((err) => {
            if (err.response.data.code === 409 && err.response.data.item) {
              setError('chitNo', {
                type: 'manual',
                message: getErrorMessage(err.response.data, _values.chitNo),
              })
            } else {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            }
          })
      },
    })
  }
  const handleDownloadFile = async (index: number, galleryType: string) => {
    let targetData = getValues()?.[galleryType]?.[index]
    let downloadURL = targetData?.fileUrl || ''
    fetch(downloadURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${targetData?.fileName}`)
        // Append to html link element page
        document.body.appendChild(link)
        // Start download
        link.click()
        // Clean up and remove the link
        link?.parentNode?.removeChild(link)
      })
  }

  useEffect(() => {
    if (!isMounted.current) {
      reloadDrsGeneralOptions()
      if (!formId && chitNoParam) {
        setValue('chitNo', chitNoParam)
      }
      if (formId && currentContract && globalState.userMetaList) {
        reload(formId)
        isMounted.current = true
      }
    }
    return () => {
      // isMounted.current = false
    }
  }, [formId, currentContract, globalState.userMetaList, chitNoParam])

  const reloadDrsGeneralOptions = async () => {
    setRequestOptions({
      callback: async () => {
        await FormDrsService.GetDrsGeneralOptions().then(async (res) => {
          setDrsGeneralOptions(res)
        })
      },
    })
  }

  const reload = async (formId: string) => {
    let reqData: DRSDocRequestModel = { contractId: -1 }
    reqData.contractId = currentContract?.contractId
    reqData.projectId = currentProject?.projectId

    // Get list of uploaded documents
    setRequest({
      callback: async () => {
        await FormDrsService.GetDrsUploadForm(formId).then(async (res) => {
          if (res) {
            let modifiedResponse = res
            modifiedResponse.truckPhotos?.forEach((item) => {
              if (globalState.userMetaList) {
                item.uploadedBy =
                  globalState.userMetaList?.find((y) => y.key === item.createdBy)?.value || ''
              }
            })
            modifiedResponse.chitReceipts?.forEach((item) => {
              if (globalState.userMetaList) {
                item.uploadedBy =
                  globalState.userMetaList?.find((y) => y.key === item.wwwwwwwwww)?.value || ''
              }
            })
            reset(modifiedResponse)
          }
          isMounted.current = true
        })
      },
    })
  }

  const partA = (): FormPartComponents => {
    const overrideAddButton = (event) => {
      handleNext(event)
    }

    return {
      partTitle: t('Uploaded Documents'),
      component: (
        <DailyRecordSummaryUploadPartA
          t={t}
          setValue={setValue}
          getValues={getValues}
          uneditable={uneditable}
          userInfo={userInfo}
          globalState={globalState}
          control={control}
          formPermission={formPermission}
          handleDownloadFile={handleDownloadFile}
          overrideAddButton={overrideAddButton}
          drsGeneralOptions={drsGeneralOptions}
          formId={formId}
          reload={reload}
        />
      ),
      onNextDisabled:
        uneditable ||
        !hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryUploadPlatformDraft, ['U', 'D']), // TODO: remove this
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('Upload Documents'),
      component: (
        <DailyRecordSummaryUploadPartB
          uneditable={uneditable}
          formId={formId}
          t={t}
          userInfo={userInfo}
          currentContract={currentContract}
          control={control}
          drsGeneralOptions={drsGeneralOptions}
        />
      ),
      disabled:
        uneditable ||
        !hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryUploadPlatformDraft, ['U', 'D']),
    }
  }

  const { FormSwitcher, handleNext, handleBack } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('Upload Platform for Monthly Waste Flow Table'),
    components: [partA(), partB()],
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading,
    disableSave: uneditable, // TODO change back to canUpdate
    disableSaveBtn: false,
    disableSaveOnStep: 1,
    disableDelete: !formPermission?.canDelete,
    approveRequired: false,
    endOfFlow: formPermission?.endOfFlow,
    customEndDialog: {
      control: control,
      getValues: getValues,
      handleConfirm: handleOnSave,
      content: (
        <Grid>
          <FormField fieldName="Submitted By">{userInfo.firstName}</FormField>
          <FormField fieldName="Acknowledgement">{acknowledgement}</FormField>
        </Grid>
      ),
    },
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}

export default DailyRecordSummaryUploadFormPage
