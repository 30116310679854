import { ReactElement, cloneElement } from 'react'

interface Props {
	active: boolean
	children: ReactElement
}

/* Debug Component in local development */
const Debugger = ({ active, children }: Props) =>
	active ? cloneElement(children, { 'aria-label': 'debugger' }) : null

export default Debugger
