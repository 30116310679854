import { KeyValPair } from '@models/Common'
import { GlobalStateProps } from '@providers/globalStore'
import { ContractNo } from '@services/model/contract.model'
import { FormStatus } from '@services/model/form/form.model'
import {
  GeneralConfig,
  GeneralOptionGroup,
  CicGeneralOptionsModel,
} from '@services/model/general.model'
import { ProjectOptionsModel } from '@services/model/admin.model'
import { GroupType } from '@services/model/group.model'

export type ActionType =
  | {
      type: 'login' | 'refreshLogin'
    }
  | {
      type: 'logout'
    }
  | {
      type: 'toggleLeftDrawer'
      opened?: boolean
    }
  | {
      type: 'changeHeaderColor'
      color?: string
    }
  | {
      type: 'changeTitle'
      title?: string
    }
  | {
      type: 'changeContract'
      contract?: {
        contractNo?: string
        contractId?: number
        contractName?: string
        id: number
        isDemolition: boolean
        isFoundation: boolean
        isOthers: boolean
        isSuperstruct: boolean
        projType: string
        status: boolean
      }
    }
  | {
      type: 'changeProject'
      project?: any
    }
  | {
      type: 'changeSubtitle'
      subtitle?: string
    }
  | {
      type: 'changeFormStatus'
      formStatus?: string
    }
  | {
      type: 'openMenu'
      menuId: string | undefined
    }
  | {
      type: 'reloadUserMetaList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadOrganizationMetaList'
      list: any[]
    }
  | {
      type: 'reloadRoleMetaList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadGeneralOptionList'
      list: CicGeneralOptionsModel
    }
  | {
      type: 'reloadContractList'
      list: ContractNo[]
    }
  | {
      type: 'reloadProjectList'
      list: any[]
    }
  | {
      type: 'reloadGeneralConfigList'
      list: GeneralConfig[]
    }
  | {
      type: 'reloadGroupTypeList'
      list: GroupType[]
    }
  | {
      type: 'reloadFormActionList'
      list: FormStatus[]
    }
  | {
      type: 'reloadProjectOptionList'
      list: ProjectOptionsModel
    }

function GlobalReducer(state: GlobalStateProps, action: ActionType): GlobalStateProps {
  switch (action.type) {
    case 'login':
    case 'refreshLogin':
      return {
        ...state,
      }

    case 'logout':
      return {
        ...state,
      }

    case 'toggleLeftDrawer':
      if (action.opened)
        return {
          ...state,
          leftDrawerOpened: action.opened,
        }
      else
        return {
          ...state,
          leftDrawerOpened: !state.leftDrawerOpened,
        }

    case 'changeHeaderColor':
      return {
        ...state,
        headerColor: action.color ?? 'rgb(255, 202, 97)',
      }
    case 'changeTitle':
      return {
        ...state,
        title: action.title ?? 'Smart Waste Management Tool',
      }
    case 'changeSubtitle':
      return {
        ...state,
        subtitle: action.subtitle,
      }
    case 'changeFormStatus':
      return {
        ...state,
        formStatus: action.formStatus,
      }
    case 'openMenu':
      return {
        ...state,
        openedMenuId: action.menuId,
      }

    case 'reloadUserMetaList':
      return {
        ...state,
        userMetaList: action.list,
      }

    case 'reloadOrganizationMetaList':
      return {
        ...state,
        organizationMetaList: action.list,
      }

    case 'reloadRoleMetaList':
      return {
        ...state,
        roleMetaList: action.list,
      }

    case 'reloadContractList':
      return {
        ...state,
        contractList: action.list,
      }
    case 'reloadProjectList':
      return {
        ...state,
        projectList: action.list,
      }
    case 'reloadGeneralOptionList':
      return {
        ...state,
        generalOptionList: action.list,
        constructionStageList: action.list['constructionStageList'],
        wasteQuantityReportingUnitList: action.list['wasteQuantityReportingUnitList'],
        selectableMonths: action.list['selectableMonths'],
        documentCategoryList: action.list['documentCategoryList'],
      }
    case 'reloadGeneralConfigList':
      return {
        ...state,
        generalConfigList: action.list,
      }
    case 'reloadGroupTypeList':
      return {
        ...state,
        teamList:
          action.list
            ?.find((x) => x.name?.toLowerCase() === 'team')
            ?.groups.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        districtList:
          action.list
            ?.find((x) => x.name?.toLowerCase() === 'district')
            ?.groups.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        enrolmentStatusList:
          action.list
            ?.find((x) => x.name?.toLowerCase() === 'enrolment status')
            ?.groups.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
      }
    case 'reloadFormActionList':
      return {
        ...state,
        formStatusList: action.list,
      }
    case 'changeContract':
      return {
        ...state,
        currentContract: action.contract,
      }
    case 'changeProject':
      return {
        ...state,
        currentProject: action.project,
      }
    case 'reloadProjectOptionList':
      return {
        ...state,
        projectLocationList: action.list['projectLocationList'],
        projectDistrictList: action.list['projectDistrictList'],
        projectTypeList: action.list['projectTypeList'],
        projectClassificationB1List: action.list['projectClassificationB1List'],
        projectClassificationB2List: action.list['projectClassificationB2List'],
      }
    default:
      return state
  }
}

export default GlobalReducer
