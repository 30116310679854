import { styled, useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'

const FormTableBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '16px 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px 0px',
  },
  // overflowX: 'auto',
}))

export default FormTableBox
