import apiGateway from '@utils/axiosInterceptor'
import { NotifyList, Comment, FormHistory } from './model/form/form.model'

const GetNotifyList = async (
  formCategory: string,
  formId: string,
  isReject: boolean,
  extraFields?: {
    key: string
    value?: string | number | boolean
  }[],
) => {
  let baseUrl = `api/v1/forms/notify-list-for-${
    isReject ? 'rejection' : 'approval'
  }?form_category=${formCategory}&form_id=${formId}`
  if (extraFields && extraFields.length > 0) {
    extraFields.map((x) => {
      if (x.value !== undefined && x.value !== null) {
        baseUrl += `&${x.key}=${x.value}`
      }
    })
  }

  return await apiGateway.get<NotifyList>(baseUrl).then((rep) => rep.data)
}

const GetCommentList = async (formId: string) => {
  return await apiGateway.get<Comment[]>(`api/v1/forms/${formId}/comments`).then((rep) => rep.data)
}

const SubmitComment = async (formId: string, comment: string) => {
  return await apiGateway.post(`api/v1/forms/${formId}/comments`, {
    comment: comment,
  })
}

const GetHistoryList = async (formId: string) => {
  return await apiGateway
    .get<FormHistory[]>(`api/v1/forms/${formId}/form-status-list`)
    .then((rep) => rep.data)
}

const FormService = {
  GetNotifyList,
  GetCommentList,
  SubmitComment,
  GetHistoryList,
}

export default FormService
