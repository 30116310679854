import {
  BaseFormPermission,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from './form.model'

export interface FormMrCreateModel {
  baseForm?: FormModel
  dateFrom?: Date
  dateTo?: Date
  bytesBase64?: string
  fileName?: string
  size?: number
  ext?: string
  file: {
    bytesBase64: string
    fileName: string
    size: number
    ext: string
  }
}

export interface FormMrModel {
  baseForm: FormModel
  dateFrom?: Date
  dateTo?: Date
  monthlyReportFileList?: {
    bytes: string
    bytesBase64: string
    fileName: string
    fileId: string
    fileUrl: string
    size: number
    ext: string
    status: boolean
  }[]
  signatureBase64?: string
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
  monthlyReport?: any
}

export const InitMrForm: FormMrModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.MonthlyReportUploadDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface MrRequestModel {
  contractNoId: {
    operator: string
    value: number
  }
  projectId: {
    operator: string
    value: number
  }
}
export const InitMrRequestModel: MrRequestModel = {
  contractNoId: {
    operator: '=',
    value: 0,
  },
  projectId: {
    operator: '=',
    value: 0,
  },
}

export interface FormMrListRespModel {
  list: FormMrListModel[]
  totalCount?: number
}
export interface FormMrListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  createdAt: Date
  reportPeriod?: Date
  file: {
    bytes: string
    bytesBase64: string
    fileName: string
    fileId: string
    fileUrl: string
    size: number
    ext: string
    status: boolean
  }
}
