import SearchPanel from '@components/searchPanel'
import { Grid, TableCell, useTheme } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { FormWmpListModel } from '@services/model/form/form.WMP.model'
import FormWmpService from '@services/formService/form.WMP.service'
import { NavigateTo } from '@utils/navigate'
import { RiFileTextFill } from 'react-icons/ri'
import Typography from '@mui/material/Typography'

const WasteManagementPlanAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const theme = useTheme()
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [isTableView, setIsTableView] = useState<boolean>(true)

  const { t } = useTranslation()

  const [contractId, setContractId] = useState<number>(0)
  useEffect(() => {
    setContractId(globalState.currentContract?.contractId)
  }, [globalState.currentContract])
  const [TableView, reload] = useTableView<FormWmpListModel>({
    headers: [
      {
        key: 'updated_at',
        desc: t('common:baseForm.generalStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormWmpListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.formId', { ns: common.i18nFormNS }),
        renderCell: (record: FormWmpListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm:submittedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormWmpListModel) => (
          <Fragment>
            {GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm:updatedAt', { ns: common.i18nFormNS }),
        renderCell: (record: FormWmpListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormWmpListModel) {
      NavigateTo(navigate, '/waste-management-plan/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (contractId > 0) {
        return await FormWmpService.GetWmpList(
          {
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
      } else {
        return await FormWmpService.GetWmpList(
          {
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [globalState.currentContract?.contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
      }
    },
  })

  useEffect(() => {
    document.title = t('forms:wasteManagementPlan.title', { ns: common.i18nFormNS })
    // eslint-disable-next-line
  }, [])

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('My Actions') : t('All Records')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/waste-management-plan"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default WasteManagementPlanAllRecordsPage
