import FormContainer from '@components/form/container'
import { FormMwfUploadModel } from '@services/model/form/form.MWF.upload.model'
import { Control } from 'react-hook-form'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormTable from '@components/form/table/Table'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { FileToImageModel } from '@utils/image'
import { ConvertBase64 } from '@utils/file'
import { useSnackbar } from 'notistack'
import { KeyValPair } from '@models/Common'

const MonthlyWasteFlowTableUploadFormPartB = ({
  globalState,
  month,
  control,
  isMobile,
  userInfo,
  filteredList,
  selectableMonths,
}: {
  globalState: any
  month: any
  control: Control<FormMwfUploadModel>
  isMobile: boolean
  userInfo: any
  filteredList: KeyValPair[]
  selectableMonths: any
}) => {
  const { t } = useTranslation()

  const [selectableMonthList, setSelectableMonthList] = useState<any>([])

  const [reloadOptionCount, setReloadOptionCount] = useState(0)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (
      selectableMonths &&
      globalState?.currentContract?.contractId &&
      selectableMonthList.length === 0
    ) {
      let fullMonthList: Array<{ key: string; value: string }> = selectableMonths?.[
        globalState.currentContract.contractId
      ]?.map((item) => {
        return { key: item, value: moment(item).format('MMMM YYYY') }
      })
      let matchedMonth: { key: string; value: string } | undefined // if redirected from another form with month in url, limit the selection to that month
      if (month) {
        matchedMonth = fullMonthList?.find(
          (item) => item.value === moment(month).format('MMMM YYYY'),
        )
      }
      let selectableList: any
      if (matchedMonth) {
        selectableList = [matchedMonth]
      } else {
        selectableList = fullMonthList
      }

      if (selectableList) {
        setSelectableMonthList(selectableList)
      }
    }
  }, [globalState, reloadOptionCount])

  const handleClickAddFiles = async (event, append, extraInfo) => {
    try {
      //  Image files to be compressed to 1MB.
      // PDF files to be limited to 10 nos. and no more than 2MB per file

      for (let i = 0; i < event.target.files?.length; i++) {
        let file = event.target.files?.[i]

        console.debug('file', file)
        let _type = file?.type
        if (_type?.includes('image')) {
          let FileToImageModelRes = await FileToImageModel({
            file: file,
            targetFileSize: 2000000,
          }).then((res) => {
            if (res) {
              append({
                status: true,
                fileName: file?.name,
                ext: res?.ext,
                size: file?.size,
                createdBy: extraInfo?.createdBy,
                bytesBase64: res.bytesBase64,
                baseForm: extraInfo?.baseForm,
                thumbnail: file, // appear in frontend when upload only
                mobileStatus: 'add',
              })
            }
          })
        } else {
          const MAX_FILE_SIZE = 2000000
          if (file.size > MAX_FILE_SIZE) {
            enqueueSnackbar(
              t('forms:monthlyWasteFlowTable.fields.invalidFileSize', { fileName: file.name }),
              {
                variant: 'error',
                autoHideDuration: null,
              },
            )
            return
          }
          await ConvertBase64(file).then((res) => {
            if (res) {
              append({
                status: true,
                fileName: file?.name,
                ext: file?.name?.split('.').pop() || '',
                size: file?.size,
                createdBy: extraInfo?.createdBy,
                bytesBase64: res,
                baseForm: extraInfo?.baseForm,
                thumbnail: file, // appear in frontend when upload only
                mobileStatus: 'add',
              })
            }
          })
        }
      }
    } catch (e) {}
  }

  return (
    <FormContainer fkey={`$drs-a`} removeOverflow={true}>
      <FormTable
        isMobile={isMobile}
        removeOverflow
        disableDeleteRowButton={true}
        key={'mwfTable'}
        columns={[
          {
            field: 'mwfMonth',
            displayName: t('forms:monthlyWasteFlowTable.fields.mwfMonth'),
            type: 'month',
            icon: <DateRangeIcon sx={{ paddingRight: '5px' }} />,
            cellStyle: { minWidth: '150px' },
            withPortal: true,
            uneditable: false,
          },
          {
            field: 'mwfCategory',
            displayName: t('forms:monthlyWasteFlowTable.fields.mwfCategory'),
            type: 'select',
            options: filteredList,
            cellStyle: { minWidth: '350px' },
            uneditable: false,
          },
          {
            field: 'fileName',
            displayName: t('forms:monthlyWasteFlowTable.fields.fileName'),
            type: 'string',
            uneditable: true,
          },
          {
            field: 'createdBy',
            displayName: t('forms:monthlyWasteFlowTable.fields.createdBy'),
            type: 'string',
            uneditable: true,
          },
        ]}
        showAddFilesButton
        showDeleteFileRecordButton
        hiddenItemNo
        removeAddButton
        height={50}
        fieldArrayProps={{
          name: 'list',
          control: control,
        }}
        extraInfo={{
          createdBy: userInfo.firstName,
          baseForm: { contractNoId: globalState.currentContract?.contractId ?? 0 },
        }}
        onClickAddFiles={handleClickAddFiles}
      />
    </FormContainer>
  )
}
export default MonthlyWasteFlowTableUploadFormPartB
