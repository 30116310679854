import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse from '@services/model/base.model'
import {
  FormWftListModel,
  FormWftModel,
  FormWftGeneralOptionsModel,
} from '@services/model/form/form.WFT.model'
import { CicGeneralOptionsModel } from '@services/model/general_option.model'
import { BaseFormResponse } from '@services/model/form/form.model'

const GetWftGeneralOptions = async () => {
  return await apiGateway
    .get<FormWftGeneralOptionsModel>(`/api/v1/forms/WFT/options`)
    .then((resp) => {
      return resp.data
    })
}

// not yet implemented
const GetWftMediaByChitNo = async (contractId: number, chitNo: string) => {
  return await apiGateway
    .get(`/api/v1/forms/WFT_UP?contractId=${contractId}&chitNo=${chitNo}`)
    .then((resp) => {
      return resp.data
    })
}

const DeleteWftUploadedDocument = async (formId: string, documentId: string) => {
  return await apiGateway
    .put<any>(`/api/v1/forms/WFT_UP/Delete/${formId}/${documentId}`)
    .then((resp) => {
      return resp.data
    })
}

const GetWftForm = async (formId: string, formStatus: string) => {
  if (formId === undefined) {
    return
  }
  if (!formStatus || formStatus === '') formStatus = 'FORM_WFT_DRAFT'
  return await apiGateway
    .get<FormWftModel>(`api/v1/forms/WFT/${formStatus}/${formId}`)
    .then((resp) => {
      return resp.data
    })
}

const GetWftUploadForm = async (formId: string) => {
  if (formId === undefined || formId === '') {
    return
  }
  return await apiGateway.get(`api/v1/forms/WFT_UP/${formId}`).then((resp) => {
    return resp.data
  })
}

const GetWftList = async (req: any, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post(`/api/v1/forms/WFT/list`, req).then((resp) => {
    return resp.data
  })
}

const GetWftUploadedDocumentList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post('/api/v1/forms/WFT_UP/list', req).then((resp) => {
    return resp.data
  })
}

const SaveWftForm = async (req: FormWftModel) => {
  let formStatus = req?.baseForm?.formStatus
  let formId = req?.baseForm?.formId
  if (formId) {
    if (!formStatus || formStatus === '') {
      formStatus = 'FORM_WFT_DRAFT'
    }

    return await apiGateway
      .put(`api/v1/forms/WFT/${formStatus}/${req.baseForm.formId}`, req)
      .then((resp) => {
        return resp.data
      })
  }

  return await apiGateway.post('api/v1/forms/WFT', req).then((resp) => {
    return resp.data
  })
}

const ApproveWftForm = async (req: FormWftModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = 'FORM_WFT_DRAFT'
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WFT/${formStatus}/${req.baseForm.formId}/approve`, req)
    .then((resp) => {
      return resp.data
    })
}

const RejectWftForm = async (req: FormWftModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = 'FORM_WFT_DRAFT'
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WFT/${formStatus}/${req.baseForm.formId}/reject`, req)
    .then((resp) => {
      return resp.data
    })
}
const DeleteWftForm = async (req: FormWftModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = 'FORM_WFT_DRAFT'
  }
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/WFT/${formStatus}/${req.baseForm.formId}`,
  )
}

const FormWftService = {
  GetWftForm,
  GetWftList,
  SaveWftForm,
  ApproveWftForm,
  RejectWftForm,
  DeleteWftForm,
  GetWftGeneralOptions,
  GetWftUploadForm,
  GetWftUploadedDocumentList,
  DeleteWftUploadedDocument,
  GetWftMediaByChitNo,
}

export default FormWftService
