import { Box, useMediaQuery } from '@mui/material'
import styles from '@styles/leftSidebar.module.scss'
import { ListItem, ListItemButton, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SideMenu from './sidebar/siderMenu'
import { styled, useTheme } from '@mui/material/styles'
import UserBox from './Userbox'
import { useContext } from 'react'
import { GlobalContext } from '@providers/globalStore'
import { menuItems } from './sidebar/menuList'

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '5px',
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60px',
  height: '60px',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
}))

const UseLeftSideBar = (): [LeftSideBar: () => JSX.Element] => {
  const { dispatch, hasRightByCatAndCode } = useContext(GlobalContext)

  const navigate = useNavigate()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const menu = [...menuItems]
  const LeftSideBar = () => {
    return (
      <Box className={styles.leftSidebar}>
        <SideMenu />
        <Divider sx={{ width: '50%', marginBottom: '10px' }} />
        <Box sx={{ overflow: 'auto' }}>
          {menu.map((item) =>
            item.children.map((child, childIndex) => {
              if (child.hidden) {
                return <></>
              }
              return (
                <Box key={`left-side-bar-item-${item.id}-${child.id}`}>
                  <StyledListItem sx={{ backgroundColor: child.colorCode }}>
                    <StyledListItemButton
                      onClick={() => {
                        navigate(child.url ?? '/')
                      }}>
                      <Box
                        className={styles.sideBarIcon}
                        sx={{ backgroundColor: child.colorCode }}
                      />
                      <Box className={styles.text}>{child.acronym}</Box>
                    </StyledListItemButton>
                  </StyledListItem>
                  {item.children.length === childIndex + 1 ? (
                    <Divider sx={{ width: '100%', marginBottom: '10px' }} />
                  ) : (
                    <></>
                  )}
                </Box>
              )
            }),
          )}
        </Box>
        <UserBox />
      </Box>
    )
  }
  return [LeftSideBar]
}

export default UseLeftSideBar
