import { FormStatusEnum } from '@services/model/form/form.model'

export const part1ReadOnlyStatus = (formStatus: string) =>
  formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
  formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
  formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

export const remarksReadOnlyStatus = (formStatus: string) =>
  formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
  formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
  formStatus === FormStatusEnum.DailyRecordSummarySecondRejected ||
  formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

export const part2HiddenStatus = (formStatus: string) =>
  formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
  formStatus === FormStatusEnum.DailyRecordSummaryFirstRejected ||
  formStatus === FormStatusEnum.DailyRecordSummaryDraft
