import { Button } from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
export type BaseButtonProps = {
  className?: string
  hidden?: boolean
  disabled: boolean
  displayText: string
  onClickHandler?: Function
  sx?: CSSProperties
}

const BaseButton = (props: BaseButtonProps) => {
  const { t } = useTranslation()
  const { state } = useContext(GlobalContext)

  const handleOnClick = (onClickHandler) => {
    if (onClickHandler === undefined || typeof onClickHandler !== 'function') {
      return
    } else {
      return onClickHandler
    }
  }

  return (
    <Button
      className={clsx(props.className)}
      disabled={props.disabled}
      onClick={handleOnClick(props.onClickHandler)}
      sx={{ display: props.hidden === true ? 'none' : props.sx?.display, ...props.sx }}>
      {t(props.displayText)}
    </Button>
  )
}

export default BaseButton
