import apiGateway from '@utils/axiosInterceptor'

const GetProjectDashboard = async (req?: any) => {
  return await apiGateway.post('/api/v1/forms/dashboard/project', req).then((rep) => {
    return rep.data
  })
}

const GetCicDashboard = async (req?: any) => {
  return await apiGateway.post('/api/v1/forms/dashboard/cic', req).then((rep) => {
    return rep.data
  })
}

const GetDashboardOptions = async (projectNoId?: number) => {
  if (projectNoId) {
    return await apiGateway
      .get(`/api/v1/forms/dashboard/options?project_no_id=${projectNoId}`)
      .then((rep) => {
        return rep.data
      })
  }
  return await apiGateway.get('/api/v1/forms/dashboard/options').then((rep) => {
    return rep.data
  })
}

const DashboardService = {
  GetProjectDashboard,
  GetCicDashboard,
  GetDashboardOptions,
}
export default DashboardService
