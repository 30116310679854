import apiGateway from '@utils/axiosInterceptor'
import { FormDrsModel, FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import {
  FormDrsVerifyDrsChitsReqModel,
  FormDrsVerifyDrsChitsResModel,
  FormDrsUploadModel,
} from '@services/model/form/form.DRS.upload.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { BaseFormResponse } from '@services/model/form/form.model'

const GetDrsGeneralOptions = async () => {
  return await apiGateway
    .get<FormDrsGeneralOptionsModel>(`/api/v1/forms/DRS/options`)
    .then((resp) => {
      return resp.data
    })
}

// not yet implemented
const GetDrsMediaByChitNo = async (contractId: number, chitNo: string) => {
  return await apiGateway
    .get(`/api/v1/forms/DRS_UP?contractId=${contractId}&chitNo=${chitNo}`)
    .then((resp) => {
      return resp.data
    })
}

const UpdateDRSUploadedDocument = async (req: any) => {
  if (req?.baseForm?.formId === '' || req?.fileId === '') {
    return
  }
  return await apiGateway.put(`api/v1/forms/DRS_UP/${req.baseForm.formId}`, req).then((resp) => {
    return resp.data
  })
}

const DeleteDrsUploadedDocument = async (formId: string, documentId: string) => {
  return await apiGateway
    .put<any>(`/api/v1/forms/DRS_UP/${formId}/${documentId}/status/false`)
    .then((resp) => {
      return resp.data
    })
}

const GetDrsForm = async (formId: string, formStatus: string) => {
  if (formId === undefined) {
    return
  }
  if (!formStatus || formStatus === '') formStatus = FormStatusEnum.DailyRecordSummaryDraft
  return await apiGateway.get(`api/v1/forms/DRS/${formStatus}/${formId}`).then((resp) => {
    return resp.data
  })
}

const GetDrsUploadForm = async (formId: string) => {
  if (formId === undefined) {
    return
  }
  return await apiGateway.get(`api/v1/forms/DRS_UP/${formId}`).then((resp) => {
    return resp.data
  })
}

const GetDrsList = async (req: any, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post(`/api/v1/forms/DRS/list`, req).then((resp) => {
    return resp.data
  })
}

const GetDrsExportList = async (req: any) => {
  return await apiGateway.post(`/api/v1/forms/DRS/export-list`, req).then((resp) => {
    return resp.data
  })
}

const GetDrsUploadedDocumentList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post('/api/v1/forms/DRS_UP/list', req).then((resp) => {
    return resp.data
  })
}

const SaveDrsForm = async (req: FormDrsModel) => {
  let formStatus = req.baseForm.formStatus
  let formId = req?.baseForm?.formId
  if (formId) {
    if (!formStatus || formStatus === '') {
      formStatus = FormStatusEnum.DailyRecordSummaryDraft
    }
    return await apiGateway
      .put(`api/v1/forms/DRS/${formStatus}/${req.baseForm.formId}`, req)
      .then((resp) => {
        return resp.data
      })
  }
  return await apiGateway.post('api/v1/forms/DRS', req).then((resp) => {
    return resp.data
  })
}

const ApproveDrsForm = async (req: FormDrsModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DRS/${formStatus}/${req.baseForm.formId}/approve`, req)
    .then((resp) => {
      return resp.data
    })
}

const RejectDrsForm = async (req: FormDrsModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DRS/${formStatus}/${req.baseForm.formId}/reject`, req)
    .then((resp) => {
      return resp.data
    })
}

const ReturnDrsForm = async (req: FormDrsModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DRS/${formStatus}/${req.baseForm.formId}/return`, req)
    .then((resp) => {
      return resp.data
    })
}

const DeleteDrsForm = async (req: FormDrsModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/DRS/${formStatus}/${req.baseForm.formId}`,
  )
}

const SaveDrsDocument = async (req: FormDrsUploadModel) => {
  let formId = req?.baseForm?.formId
  if (!formId || formId === '') {
    return await apiGateway.post('api/v1/forms/DRS_UP', req).then((resp) => {
      return resp.data
    })
  }
  return await apiGateway.put(`api/v1/forms/DRS_UP/${formId}`, req).then((resp) => {
    return resp.data
  })
}

const AutoFillDrsChits = async (req: FormDrsUploadModel) => {
  return await apiGateway.post('api/v1/forms/DRS/validate', req).then((resp) => {
    return resp.data
  })
}

const VerifyDrsChits = async (req: FormDrsVerifyDrsChitsReqModel) => {
  return await apiGateway
    .post<FormDrsVerifyDrsChitsResModel>('api/v1/forms/DRS_ITEM/epd-validate', req)
    .then((resp) => {
      return resp.data
    })
}

const LinkDrsItems = async (req: FormDrsModel) => {
  const formId = req?.baseForm?.formId
  let formStatus = req?.baseForm?.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  if (formId === '' || !formId) {
    return
  }
  return await apiGateway
    .put(`api/v1/forms/DRS/${formStatus}/${formId}/linkDrsItem`, req)
    .then((resp) => {
      return resp.data
    })
}
type GetDrsItemUnlinkRequest = {
  drsItemsId: number[]
  drsId: number
}

const UnlinkDrsItems = async (
  formId: string | undefined,
  formStatus: string | undefined,
  req: GetDrsItemUnlinkRequest,
) => {
  if (formId === '' || !formId) {
    return
  }
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.DailyRecordSummaryDraft
  }
  return await apiGateway
    .put(`api/v1/forms/DRS/${formStatus}/${formId}/unlinkDrsItem`, req)
    .then((resp) => {
      return resp.data
    })
}

const FormDrsService = {
  GetDrsForm,
  GetDrsExportList,
  GetDrsList,
  SaveDrsForm,
  ReturnDrsForm,
  ApproveDrsForm,
  RejectDrsForm,
  DeleteDrsForm,
  GetDrsGeneralOptions,
  GetDrsUploadForm,
  GetDrsUploadedDocumentList,
  SaveDrsDocument,
  UpdateDRSUploadedDocument,
  DeleteDrsUploadedDocument,
  GetDrsMediaByChitNo,
  AutoFillDrsChits,
  LinkDrsItems,
  UnlinkDrsItems,
  VerifyDrsChits,
}

export default FormDrsService
