import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Grid,
  Tooltip,
  Button,
  Stack,
  TableCell,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import '@styles/table.module.scss'
import { useTranslation } from 'react-i18next'
import { Fragment, useRef, useState, useEffect } from 'react'
import FormDialog from '@components/form/dialog_v2'
import ScrollContainer from 'react-indiana-drag-scroll'
import FormTableBox from '@components/form/table/presentation/formTableBox'
import FormTableFooterButton from '@components/form/table/presentation/formTableFooterButton'
import FormTableAddIcon from '@components/form/table/presentation/formTableAddIcon'
import HeaderTableCell from '@components/form/table/presentation/headerTableCell'
import BodyTableCell from '@components/form/table/presentation/bodyTableCell'
import FormWidget from '@components/form/table/FormWidget'
import { FormTableChildProps } from '@components/form/table/model'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { SxProps, Theme } from '@mui/material/styles'
import FormTableAddButton from '@components/form/table/presentation/FormTableAddButton'
import AddIcon from '@mui/icons-material/Add'
import { InitDialogContent } from '@components/form/table/model'
import useImageModal from '@hooks/useImageModal'
import LinkTableAddButton from '@components/form/table/presentation/LinkTableAddButton'
import {
  ArrayPath,
  FieldArrayWithId,
  FieldArray,
  FieldValues,
  useFieldArray,
} from 'react-hook-form'

function DesktopTable<F extends FieldValues>(props: FormTableChildProps<F>) {
  const [extraInfo, setExtraInfo] = useState<any>(props.extraInfo)
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  let itemNo = 0
  const { fields, append, remove, update } = useFieldArray({
    keyName: '_id',
    ...props.fieldArrayProps,
  })
  const signatureBase64Ref = useRef<string | undefined>()
  const [signatureBase64, setSignatureBase64] = useState<Array<string>>([''])
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const [warningText, setWarningText] = useState<string | undefined>()

  useEffect(() => {
    setExtraInfo(props.extraInfo)
  }, [props.extraInfo])

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const appendField = (event: any) => {
    append({ status: true } as FieldArray<F, ArrayPath<F>>)
    if (props.additionalAppendAction) {
      props.additionalAppendAction(append)
    }
  }

  const headerAddButtonClick = (event: any) => {
    if (props.onHeaderAddButtonClick) {
      props.onHeaderAddButtonClick(append)
      return
    }
    appendField(event)
  }
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }
  const handleDialogConfirm = (index: string) => {
    setDialogContext(InitDialogContent)
    let _signature: Array<any> = [...signatureBase64]
    _signature[index] = signatureBase64Ref[index]
    if (_signature) {
      setSignatureBase64(_signature)
    }
  }
  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  const editField = (event: any, index: number, action: string, extraInfo: any) => {
    switch (action) {
      case 'edit':
        props.onClickEditField!(
          event,
          index,
          action,
          extraInfo,
          fields,
          setDialogContext,
          dialogContext,
          handleDialogClose,
          editField,
        )
        break
      case 'download':
        props.onDownloadFile ? props.onDownloadFile(index, extraInfo) : null
        break
    }
  }

  const removeField = (event: any, index: number) => {
    // uuid / formId / id / fileId -> set status = false
    //           otherize -> remove record

    if (fields[index]['uuid'] || fields[index]['id'] || fields[index]['fileId']) {
      const _field = fields[index]
      _field['status'] = false
      update(index, _field)
    } else if (fields[index]['baseForm'] && fields[index]['baseForm']['formId']) {
      const _field = fields[index]
      _field['baseForm']['status'] = false
      update(index, _field)
    } else {
      remove(index)
    }
    if (props.additionalRemoveAction) {
      props.additionalRemoveAction(event, index)
    }
  }

  type DialogContextProps = {
    isOpen: boolean
    title?: JSX.Element
    toolbarStyle?: SxProps<Theme>
    children?: JSX.Element
    buttons?: JSX.Element
    showConfirmDeleteBtn?: boolean
  }

  const getEditableRow = (datum, fieldName?: string) => {
    if (!fieldName) return true
    return datum[fieldName]
  }

  return (
    <Grid
      xs={12}
      sx={{
        overflow: props.removeOverflow ? 'none' : 'auto',
        marginRight:
          props.showEditDownloadButtons || props.showDeleteFileRecordButton ? '30px' : null,
        marginLeft:
          props.showEditDownloadButtons || props.showDeleteFileRecordButton ? '30px' : null,
        ...props.sx,
      }}>
      <FormTableBox>
        <Stack sx={{ padding: 0 }}>
          <Box
            sx={{
              display:
                props.title || props.addWithPopupComponent || props.showHeaderAddButton
                  ? 'flex'
                  : 'none',
              padding: 2,
              alignItems: 'center',
            }}>
            {props.title ? (
              <Box
                sx={{
                  maxWidth: 250,
                }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold',
                  }}>
                  {props.title ? t(props.title) : ''}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {props.addWithPopupComponent && props.addWithPopupComponent(isPopupOpen, togglePopup)}
            {props.showHeaderAddButton ? (
              <LinkTableAddButton
                state={{
                  showHeaderAddButton: props.showHeaderAddButton,
                  disableAddButton: props.disableAddButton,
                  uneditable: props.uneditable,
                  color: props.color,
                }}
                onClick={headerAddButtonClick}>
                <AddIcon
                  id="link-table-add-icon"
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
                {props.addButtonText ? (
                  <Box
                    id="add-button-hover-text"
                    style={{
                      position: 'absolute',
                      right: 0,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      padding: 10,
                      paddingRight: 70,
                      opacity: 0,
                    }}>
                    <Typography>
                      {props.addButtonText
                        ? t(props.addButtonText)
                        : t('common:components.table.addBtnText', {
                            recordName: props.fieldArrayProps.name,
                          })}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </LinkTableAddButton>
            ) : (
              <></>
            )}
          </Box>
          <ScrollContainer
            hideScrollbars={false}
            className="scroll-container"
            ignoreElements={'input,textarea,td'}
            nativeMobileScroll={downMd}>
            {props.addWithPopupComponent && props.addWithPopupComponent(isPopupOpen, togglePopup)}
            <FormTableAddButton
              state={{
                showHeaderAddButton: props.showHeaderAddButton,
                disableAddButton: props.disableAddButton,
                uneditable: props.uneditable,
                color: props.color,
              }}
              onClick={headerAddButtonClick}>
              <AddIcon
                id="link-table-add-icon"
                sx={{
                  height: '100%',
                  width: '100%',
                  color: '#ffffff',
                }}
              />
              <Box
                id="add-button-hover-text"
                style={{
                  position: 'absolute',
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  padding: 10,
                  paddingRight: 70,
                  opacity: 0,
                }}>
                <Typography>
                  {props.addButtonText
                    ? t(props.addButtonText)
                    : t('common:components.table.addBtnText', {
                        recordName: props.fieldArrayProps.name,
                      })}
                </Typography>
              </Box>
            </FormTableAddButton>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#c5c5c5',
                  }}>
                  {!props.hiddenItemNo && (
                    <HeaderTableCell
                      align={props.align ?? 'center'}
                      style={{
                        width: 5,
                      }}>
                      {downMd ? '#' : t('common:components.table.itemNo')}
                    </HeaderTableCell>
                  )}

                  {props.columns.map((column, index: number) => (
                    <Tooltip
                      title={column.tooltip ? t(column.tooltip) : ''}
                      key={`form-table-header-tooltip-${index}`}
                      placement="top">
                      <HeaderTableCell
                        style={{
                          display:
                            // column.hidden || (downMd && column.mobileHidden) // handle this in mobile form already
                            column.hidden ? 'none' : 'table-cell',
                          // flex: 1,
                          width: column.width ?? 'inherit',
                          minWidth: column.minWidth ?? '100px',
                        }}
                        key={`form-table-header-${index}`}
                        align={props.align ?? 'center'}>
                        {typeof column.displayName === 'string'
                          ? t(column.displayName)
                          : column.displayName}
                      </HeaderTableCell>
                    </Tooltip>
                  ))}
                  {!props.disabled && !props.uneditable && !props.disableDeleteRowButton ? (
                    <HeaderTableCell
                      align={props.align ?? 'center'}
                      style={{
                        display: props.disableDeleteRowButton ? 'none' : 'table-cell',
                      }}>
                      <Box sx={{ width: '15px' }}></Box>
                    </HeaderTableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map(
                  (datum: FieldArrayWithId<F, ArrayPath<F>, '_id'>, datumIndex: number) => {
                    if (
                      props.rowFilter &&
                      !props.rowFilter(
                        datum as FieldArrayWithId<F, ArrayPath<F>, '_id'>,
                        datumIndex,
                      )
                    ) {
                      return <Fragment key={`form-table-row-${datumIndex}-${datum._id}`} />
                    } else if (
                      !props.rowFilter &&
                      !(datum['status'] || (datum['baseForm'] && datum['baseForm']['status']))
                    ) {
                      return <Fragment key={`form-table-row-${datumIndex}-${datum._id}`} />
                    }
                    return (
                      <TableRow
                        key={`form-table-row-${datumIndex}-${datum._id}`}
                        sx={{
                          height: props.height ?? 200,
                          ':hover': {
                            '#table-row-actions': {
                              display: 'inherit',
                            },
                          },
                          backgroundColor: '#edeef0',
                        }}>
                        {!props.hiddenItemNo && (
                          <BodyTableCell
                            style={{
                              flex: 1,
                              position: 'relative',
                              display: props.hiddenItemNo ? 'none' : 'table-cell',
                            }}
                            key={`form-table-cell-${datumIndex}-item-no`}>
                            <Typography textAlign={props.align ?? 'center'} fontSize={12}>
                              {++itemNo}
                            </Typography>
                          </BodyTableCell>
                        )}

                        {props.columns.map((column, columnIndex: number) => {
                          let children: JSX.Element = <Fragment></Fragment>
                          if (column.hidden || (downMd && column.mobileHidden)) {
                            return children
                          }

                          if (column.type) {
                            children = (
                              <FormWidget
                                tooltip={column.tooltip}
                                column={column}
                                disabled={props.disabled || column.disable}
                                uneditable={props.uneditable || props.disabled || column.disable}
                                fieldArrayProps={props.fieldArrayProps}
                                datumIndex={datumIndex}
                                datum={datum}
                                handleImageModalOpen={handleImageModalOpen}
                                useWatch={props.useWatch}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                setWarningText={setWarningText}
                                setDialogContext={setDialogContext}
                                handleDialogClose={handleDialogClose}
                                handleDialogConfirm={handleDialogConfirm}
                                signatureBase64={signatureBase64}
                                setSignatureBase64={setSignatureBase64}
                                signatureBase64Ref={signatureBase64Ref}
                                datePickerType={props.desktopDatePickerType ?? 'desktop-table'}
                              />
                            )
                          } else {
                            children = (
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  fontSize: 14,
                                  whiteSpace: 'initial',
                                  wordBreak: 'break-word',
                                }}>
                                {datum[column.field] ?? ''}
                              </Typography>
                            )
                          }
                          return (
                            <BodyTableCell
                              style={{
                                // flex: 1,
                                minWidth: column.width ?? '100px',
                                position: 'relative',
                                textAlign: 'center',
                              }}
                              key={`form-table-cell-${columnIndex}-${datumIndex}`}>
                              {children}
                            </BodyTableCell>
                          )
                        })}
                        {!props.disabled && !props.uneditable && !props.disableDeleteRowButton ? (
                          <BodyTableCell>
                            <Box
                              id="table-row-actions"
                              sx={{
                                transition: 'transform',
                                ':hover': {
                                  display: 'inherit',
                                },
                              }}>
                              <IconButton
                                sx={{
                                  ':hover': {
                                    transition: 'transform 1000ms ease-in-out',
                                  },
                                }}
                                onClick={(event: any) => {
                                  removeField(event, datumIndex)
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </Box>{' '}
                          </BodyTableCell>
                        ) : (
                          <></>
                        )}
                        {props.showEditDownloadButtons === true ? (
                          <>
                            {props.uneditable ||
                            props.disabled ||
                            datum['baseForm']?.['editable'] === false ? (
                              <></>
                            ) : (
                              <Box
                                id="table-row-actions"
                                sx={{
                                  position: 'absolute',
                                  transition: 'transform',
                                  transform: 'translateX(0)',
                                  display: 'none',
                                  ':hover': {
                                    display: 'inherit',
                                  },
                                }}>
                                <IconButton
                                  sx={{
                                    ':hover': {
                                      backgroundColor: 'transparent',
                                      transition: 'transform 1000ms ease-in-out',
                                    },
                                  }}
                                  onClick={(event: any) => {
                                    editField(event, datumIndex, 'edit', extraInfo)
                                  }}>
                                  <EditIcon />
                                </IconButton>
                              </Box>
                            )}
                            <Box
                              id="table-row-actions"
                              sx={{
                                position: 'absolute',
                                transition: 'transform',
                                transform:
                                  props.uneditable ||
                                  props.disabled ||
                                  datum['baseForm']?.['editable'] === false
                                    ? ''
                                    : 'translateY(25px)',
                                display: 'none',
                                ':hover': {
                                  display: 'inherit',
                                },
                              }}>
                              <IconButton
                                sx={{
                                  ':hover': {
                                    backgroundColor: 'transparent',
                                    transition: 'transform 1000ms ease-in-out',
                                  },
                                }}
                                onClick={(event: any) => {
                                  editField(event, datumIndex, 'download', props.extraInfo)
                                }}>
                                <DownloadIcon />
                              </IconButton>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.showDeleteFileRecordButton === true ? (
                          props.uneditable ||
                          props.disabled ||
                          datum['baseForm']?.['editable'] === false ? (
                            <></>
                          ) : (
                            <Box
                              id="table-row-actions"
                              sx={{
                                position: 'absolute',
                                transition: 'transform',
                                transform: 'translateY(0)',
                                display: 'none',
                                ':hover': {
                                  display: 'inherit',
                                },
                              }}>
                              <IconButton
                                sx={{
                                  ':hover': {
                                    backgroundColor: 'transparent',
                                    transition: 'transform 1000ms ease-in-out',
                                    // transform: 'rotate(360deg)',
                                  },
                                }}
                                onClick={(event: any) => {
                                  removeField(event, datumIndex)
                                }}>
                                <DeleteForeverIcon />
                              </IconButton>
                            </Box>
                          )
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    )
                  },
                )}
                {props.additionalRow && props.additionalRow(fields)}
              </TableBody>
            </Table>
            {warningText && (
              <Typography
                sx={{
                  color: 'red',
                }}>
                {t(warningText)}
              </Typography>
            )}
            {/* </FormTableBox> */}
          </ScrollContainer>
        </Stack>
        <ImageModal image={modalImage} />
        {props.removeAddButton ? (
          <></>
        ) : (
          <FormTableFooterButton
            disabled={props.disableAddButton || props.disabled || props.uneditable}
            startIcon={
              <FormTableAddIcon
                id="form-table-add-icon"
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            }
            onClick={(event: any) => {
              if (props.onFooterButtonClick) {
                props.onFooterButtonClick(append)
                return
              }
              appendField(event)
            }}
          />
        )}
        {props.showAddFilesButton === true ? (
          <>
            <Box
              sx={{
                boxShadow: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'inherit',
              }}>
              <Button
                variant="contained"
                sx={{
                  height: 60,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  backgroundColor: '#ffffff',
                  ':hover': {
                    backgroundColor: '#aaaaaa',
                    '#form-table-add-icon': {
                      color: '#ffffff',
                    },
                  },
                  ':disabled': {
                    backgroundColor: '#d9d9d9',
                    '#form-table-add-icon': {
                      color: '#ffffff',
                    },
                  },
                }}
                component="label"
                startIcon={
                  <PostAddIcon
                    id="form-table-add-icon"
                    sx={{
                      color: 'black',
                      height: 40,
                      width: 40,
                    }}
                  />
                }>
                <input
                  hidden
                  accept={props.acceptFileTypes ?? 'image/jpeg,image/gif,image/png,application/pdf'}
                  multiple
                  onClick={(event: any) => {
                    event.target.value = null
                  }}
                  type="file"
                  onChange={(event: any) => {
                    props.onClickAddFiles
                      ? props.onClickAddFiles(event, append, props.extraInfo)
                      : null
                  }}
                />
              </Button>
            </Box>
          </>
        ) : (
          <></>
        )}
        <FormDialog
          key={'dialog'}
          open={dialogContext.isOpen}
          onClose={handleDialogClose}
          toolbarStyle={dialogContext.toolbarStyle}
          title={dialogContext.title}
          buttons={dialogContext.buttons}
          children={dialogContext.children}
        />
      </FormTableBox>
    </Grid>
  )
}
export default DesktopTable
