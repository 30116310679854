import { FormDrsItemsModel } from '@services/model/form/form.DRS.item.model'
import { Control, useFieldArray } from 'react-hook-form'
import FormTable from '@components/form/table/Table'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box, Button, Stack } from '@mui/material'
import FormContainer from '@components/form/container'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import { TableColumnProps } from '@components/form/table/model'
import testIsMobile from '@utils/testIsMobile'
import { FormStatusEnum } from '@services/model/form/form.model'
import React, { useState } from 'react'
import JsonView from '@uiw/react-json-view'
import { lightTheme } from '@uiw/react-json-view/light'
import BaseButton from '@components/button/BaseButton'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import styles from '@styles/baseButton.module.scss'
import FormFileUpload from '@components/form/fileUpload'
import CSVReader from 'react-csv-reader'
import { isArray } from 'lodash'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
const DailyRecordSummaryItemBatchPartB = ({
  part1ReadOnly,
  getValues,
  useWatch,
  setValue,
  control,
  formPermission,
  formStatus,
  drsGeneralOptions,
  onClickSaveRowBtn,
  trigger,
  globalState,
}: {
  part1ReadOnly: boolean
  getValues: any
  useWatch: any
  setValue: any
  onClick?: (...event: any[]) => void
  control: Control<FormDrsItemsModel>
  formPermission: any
  formStatus: string
  drsGeneralOptions: FormDrsGeneralOptionsModel | undefined
  onClickSaveRowBtn?: (...event: any[]) => void
  trigger: any
  globalState: any
}) => {
  const theme = useTheme()
  let index = 0
  const { t } = useTranslation()
  const cEasyData = useWatch({
    name: 'cEasyData',
    control,
  })
  const displayData = useWatch({
    name: 'displayData',
    control,
  })
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  }
  return (
    <>
      <FormContainer fkey={`$drs-a`}>
        <FormField fieldName="Upload C·Easy File" index={index++}>
          <FormController
            controllerProps={{
              name: 'cEasyFile',
              control,
            }}>
            <Button variant="contained" component="label">
              {t('Upload C·Easy File')}
              <CSVReader
                fileEncoding="UTF-8"
                cssClass="csv-reader-input"
                label=""
                onFileLoaded={(data, fileInfo) => {
                  const fieldMappings = {
                    chitNo: ['Chit No.', '入帐票号码', '入帳票號碼'],
                    vehicleRegistrationMark: ['VRM', '车牌号码', '車牌號碼'],
                    dateOfDisposal: ['Txn Date', '交易日期', '交易日期'],
                    disposalGround: ['Facility', '设施', '設施'],
                    remarks: ['Remark', '备注', '備註'],
                    cDMaterialType: ['Waste Type', '废物类別', '廢物類別'],
                    materialType: ['Waste Type', '废物类別', '廢物類別'],
                  }
                  const cEasyData: any = data.map((item) => {
                    const mappedItem = {}
                    Object.entries(fieldMappings).forEach(([fieldName, fieldAliases]) => {
                      const fieldValue = fieldAliases
                        .map((alias) => item[alias])
                        .find((value) => value !== undefined)
                      mappedItem[fieldName] = fieldValue
                    })
                    return mappedItem
                  })
                  console.debug('drsGeneralOptions?.materialType', drsGeneralOptions?.materialType)
                  setValue('cEasyData', cEasyData)
                  const displayData = cEasyData.map((item) => {
                    const cDMaterialTypeId = drsGeneralOptions?.materialType?.find(
                      (type) =>
                        !!item.materialType &&
                        item.materialType !== '' &&
                        (type.cEasyTitle == item.materialType ||
                          type.cEasyTitleZh == item.materialType ||
                          type.cEasyTitleCn == item.materialType ||
                          type.cEasyTitleV2 == item.materialType ||
                          type.cEasyTitleZhV2 == item.materialType ||
                          type.cEasyTitleCnV2 == item.materialType),
                    )?.parentKey

                    const cDMaterialType = drsGeneralOptions?.cDMaterialType?.find(
                      (type) => type.key == cDMaterialTypeId,
                    )?.title
                    return {
                      chitNo: item.chitNo.toString(),
                      vehicleRegistrationMark: item.vehicleRegistrationMark,
                      dateOfDisposal: item.dateOfDisposal,
                      disposalGround: item.disposalGround,
                      materialType: item.materialType,
                      cDMaterialType: cDMaterialType,
                      remarks: item.remarks,
                    }
                  })
                  setValue('displayData', displayData)

                  const drsItems = cEasyData.map((item) => {
                    const parsedDateOfDisposal = moment(item.dateOfDisposal, 'DD/MM/YYYY')
                    const outputDateOfDisposal = parsedDateOfDisposal.toISOString()

                    const disposalGroundId = drsGeneralOptions?.disposalGround?.find(
                      (ground) =>
                        !!item.disposalGround &&
                        item.disposalGround !== '' &&
                        (ground.cEasyTitle == item.disposalGround ||
                          ground.cEasyTitleZh == item.disposalGround ||
                          ground.cEasyTitleCn == item.disposalGround),
                    )?.key

                    const cDMaterialTypeId = drsGeneralOptions?.materialType?.find(
                      (type) =>
                        !!item.materialType &&
                        item.materialType !== '' &&
                        (type.cEasyTitle == item.materialType ||
                          type.cEasyTitleZh == item.materialType ||
                          type.cEasyTitleCn == item.materialType ||
                          type.cEasyTitleV2 == item.materialType ||
                          type.cEasyTitleZhV2 == item.materialType ||
                          type.cEasyTitleCnV2 == item.materialType),
                    )?.parentKey

                    return {
                      chitNo: item.chitNo.toString(),
                      vehicleRegistrationMark: item.vehicleRegistrationMark,
                      dateOfDisposal: outputDateOfDisposal,
                      disposalGround: disposalGroundId,
                      materialType: item.materialType,
                      cDMaterialType: cDMaterialTypeId,
                      remarks: item.remarks,
                    }
                  })

                  setValue('drsItems', drsItems)
                }}
                onError={() => {}}
                parserOptions={papaparseOptions}
                inputId="ObiWan"
                inputName="ObiWan"
                inputStyle={{ color: 'red' }}
              />
            </Button>
          </FormController>
        </FormField>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#ccc' }}>
              <TableRow>
                <TableCell>{t('forms:dailyRecordSummary.fields.chitNo')}</TableCell>
                <TableCell>
                  {t('forms:dailyRecordSummary.fields.vehicleRegistrationMark')}
                </TableCell>
                <TableCell>{t('forms:dailyRecordSummary.fields.dateOfDisposal')}</TableCell>
                <TableCell>{t('forms:dailyRecordSummary.fields.disposalGround')}</TableCell>
                <TableCell>{t('forms:dailyRecordSummary.fields.materialType')}</TableCell>
                <TableCell>{t('forms:dailyRecordSummary.fields.cDMaterialType')}</TableCell>
                <TableCell>{t('forms:dailyRecordSummary.fields.remarks.label')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArray(displayData) ? (
                displayData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.chitNo}</TableCell>
                    <TableCell>{item.vehicleRegistrationMark}</TableCell>
                    <TableCell>{item.dateOfDisposal}</TableCell>
                    <TableCell>{item.disposalGround}</TableCell>
                    <TableCell>{item.materialType}</TableCell>
                    <TableCell>{t(item.cDMaterialType)}</TableCell>
                    {/* Only this should be translated, other directly get from Data */}
                    <TableCell>{item.remarks}</TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </FormContainer>
      <Box height={100}></Box>
    </>
  )
}
export default DailyRecordSummaryItemBatchPartB
