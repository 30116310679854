import { styled } from '@mui/material/styles'
import { TableCell } from '@mui/material'

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  padding: theme.spacing(1),
}))

export default HeaderTableCell
