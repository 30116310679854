import { MapModel } from '../map.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { KeyValPair } from '@models/Common'
export interface FormMwfModel {
  baseForm: FormModel
  approvedDate?: Date
  signatureBase64?: string
  mwfMonth?: string
  mwfCategory?: number
  contractNoId?: string
  contractName?: string
  reportingPeriod?: Date
  constructionStage?: string
  wasteQuantityReportingUnit?: number

  //F
  reportNo?: string
  reportPeriod?: Date
  constructionStageId?: number
  otherConstructionStage?: string
  materialDensity?: number

  hardRockRecycled?: number
  reusedInContract?: number
  reusedInOthers?: number
  disposedOfToPublicFill?: number
  disposedOfToSortingFacility?: number // typo
  importedFill?: number

  metals?: number
  paperPackaging?: number
  timber?: number
  plastic?: number
  chemicalWaste?: number
  otherRecycled?: number

  acknowledgement?: boolean
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
  showLock?: boolean
  documentLock?: boolean
  inertTable?: inertTableModel[]
  nonInertTable?: nonInertTableModel[]
  summaryTable?: summaryTableModel[]
}

export interface inertTableModel {
  status?: boolean
  reportPeriod?: Date
  hardRockRecycled?: number
  reusedInContract?: number
  reusedInOthers?: number
  disposedOfToPublicFill?: number
  disposedOfToSortingFacility?: number // typo
  importedFill?: number
}
export interface nonInertTableModel {
  reportPeriod?: Date
  metals?: number
  paperPackaging?: number
  timber?: number
  plastic?: number
  chemicalWaste?: number
  otherRecycled?: number
}

export interface summaryTableModel {
  reportPeriod?: Date
  totalWasteQuality?: number
  totalQualityRecycledWithinSite?: number
  totalWasteQualityDisposed?: number
  totalQualityRecycledOffSite?: number
  percentageConstructionWasteRecycled?: number
}

export const InitMwfForm: FormMwfModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.MonthlyWasteFlowTableDraft,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormMwfListModel {
  formId: string
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string
  reportNo: string
  chitDffNo: number
  contractNoId: number
  // archivedDate: Date
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  createdAt: Date
  reportPeriod: Date
  dateOfArchived: Date
}

export enum WasteCategoryListEnum {
  hardRockRecycled = 'Hard Rock and Large Broken Concrete', //
  reusedInContract = 'Reused in Contract',
  reusedInOthers = 'Reused in Other Project', //
  disposedOfToPublicFill = 'Disposed of to Public Fill',
  disposedOfToSortingFacility = 'Disposed of to Sorting Facility',
  importedFill = 'Imported Fill',
  metals = 'Metals Recycled', //
  paperPackaging = 'Paper/ Cardboard Packaging Recycled', //
  timber = 'Timber/ Wood Pallet Recycled', //
  plastic = 'Plastics Recycled', //
  chemicalWaste = 'Chemical Waste Recycled',
  otherRecycled = 'Others e.g. General Refuse Disposed of to Landfill', //
}
