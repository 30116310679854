import apiGateway from '@utils/axiosInterceptor'
import { FormWmpModel } from '@services/model/form/form.WMP.model'
import { FormWmpGeneralOptionsModel } from '@services/model/form/form.WMP.model'
import { BaseFormResponse } from '@services/model/form/form.model'
import { FormStatusEnum } from '@services/model/form/form.model'

const GetWmpGeneralOptions = async () => {
  return await apiGateway
    .get<FormWmpGeneralOptionsModel>(`/api/v1/forms/WMP/options`)
    .then((resp) => {
      return resp.data
    })
    .catch((err) => {
      return err
    })
}

// not yet implemented
const GetWmpMediaByChitNo = async (contractId: number, chitNo: string) => {
  return await apiGateway
    .get(`/api/v1/forms/WMP_UP?contractId=${contractId}&chitNo=${chitNo}`)
    .then((resp) => {
      return resp.data
    })
}

const UpdateWMPUploadedDocument = async (req: any) => {
  if (req?.baseForm?.formId === '' || req?.fileId === '') {
    return
  }
  return await apiGateway.put(`api/v1/forms/WMP_UP/${req.baseForm.formId}`, req).then((resp) => {
    return resp.data
  })
}

const DeleteWmpUploadedDocument = async (formId: string, documentId: string) => {
  return await apiGateway
    .put<any>(`/api/v1/forms/WMP_UP/Delete/${formId}/${documentId}`)
    .then((resp) => {
      return resp.data
    })
}

const GetWmpForm = async (formId: string, formStatus: string) => {
  if (formId === undefined) {
    return
  }
  if (!formStatus || formStatus === '')
    formStatus = FormStatusEnum.WasteManagementPlanUploadPlatformDraft
  return await apiGateway.get(`api/v1/forms/WMPU/${formStatus}/${formId}`).then((resp) => {
    return resp.data
  })
}

const GetWmpUploadForm = async (formId: string) => {
  if (formId === undefined) {
    return
  }
  return await apiGateway.get(`api/v1/forms/WMP_UP/${formId}`).then((resp) => {
    return resp.data
  })
}

const GetWmpList = async (req: any, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post(`/api/v1/forms/WMPU/list`, req).then((resp) => {
    return resp.data
  })
}

const GetWmpUploadedDocumentList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway.post('/api/v1/forms/WMP_UP/list', req).then((resp) => {
    return resp.data
  })
}

const SaveWmpForm = async (req: FormWmpModel) => {
  let formStatus = req.baseForm.formStatus

  let formId = req?.baseForm?.formId
  if (formId) {
    if (!formStatus || formStatus === '') {
      formStatus = FormStatusEnum.WasteManagementPlanUploadPlatformDraft
    }

    return await apiGateway
      .put(`api/v1/forms/WMPU/${formStatus}/${req.baseForm.formId}`, req)
      .then((resp) => {
        return resp.data
      })
  }

  return await apiGateway.post('api/v1/forms/WMPU', req).then((resp) => {
    return resp.data
  })
}

const ApproveWmpForm = async (req: FormWmpModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.WasteManagementPlanUploadPlatformDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WMPU/${formStatus}/${req.baseForm.formId}/approve`, req)
    .then((resp) => {
      return resp.data
    })
}

const RejectWmpForm = async (req: FormWmpModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.WasteManagementPlanUploadPlatformDraft
  }
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WMPU/${formStatus}/${req.baseForm.formId}/reject`, req)
    .then((resp) => {
      return resp.data
    })
}
const DeleteWmpForm = async (req: FormWmpModel) => {
  let formStatus = req.baseForm.formStatus
  if (!formStatus || formStatus === '') {
    formStatus = FormStatusEnum.WasteManagementPlanUploadPlatformDraft
  }
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/WMPU/${formStatus}/${req.baseForm.formId}`,
  )
}

const FormWmpService = {
  GetWmpForm,
  GetWmpList,
  SaveWmpForm,
  ApproveWmpForm,
  RejectWmpForm,
  DeleteWmpForm,
  GetWmpGeneralOptions,
  GetWmpUploadForm,
  GetWmpUploadedDocumentList,
  UpdateWMPUploadedDocument,
  DeleteWmpUploadedDocument,
  GetWmpMediaByChitNo,
}

export default FormWmpService
