import { ImageModel } from '@services/model/image.model'
import Resizer from 'react-image-file-resizer'
import { ConvertBase64, ReadFile } from './file'
import { LocationModel } from '@services/model/location.model'
import exifr from 'exifr'
import { FileToFileModel, GetFileFromUrl } from '@utils/file'

// import imagemin from 'imagemin'
// import imageminJpegtran from 'imagemin-jpegtran'
// import imageminPngquant from 'imagemin-pngquant'
const ResizeImage = (file: File, sizes: [number, number], fileType: string): Promise<string> =>
  new Promise((resolve, _) => {
    Resizer.imageFileResizer(
      file,
      sizes[0],
      sizes[1],
      fileType,
      100,
      0,
      (uri) => resolve(uri as string),
      'base64',
    )
  })

/**
 * image sizes for resizing
 *
 * medium?: [width, height]
 *
 * thumbnail?: [width, height]
 */
export interface ImageSizes {
  medium?: [number, number]
  thumbnail?: [number, number]
}

const createImageModel = async (
  file: File,
  fileName: string[],
  fileType: string,
  sizes?: ImageSizes,
  location?: LocationModel,
): Promise<ImageModel | undefined> => {
  const image: ImageModel = {
    fileId: '',
    status: true,
    fileName: fileName[0],
    ext: fileName[fileName.length - 1],
    fileUrl: await ConvertBase64(file),
    regularUrl: await ResizeImage(
      file,
      sizes && sizes.medium ? sizes.medium : [300, 180],
      fileType,
    ),
    thumbnailUrl: await ResizeImage(
      file,
      sizes && sizes.thumbnail ? sizes.thumbnail : [50, 30],
      fileType,
    ),
    size: file.size,
    bytes: await ReadFile(file),
    bytesBase64: await ConvertBase64(file),
    location: location,
  }

  return image
}

const ResizeImageReturnBlob = (
  file: File,
  sizes: [number, number],
  fileType: string,
  fileQuality: number,
): Promise<File> =>
  new Promise((resolve, _) => {
    Resizer.imageFileResizer(
      file,
      sizes[0],
      sizes[1],
      fileType,
      fileQuality,
      0,
      (uri) => resolve(uri as File),
      'file',
    )
  })

const CompressImageReturnBlob = async (
  file: File,
  fileType: string,
  fileQuality: number,
  sizes?: [number, number],
): Promise<File> =>
  new Promise((resolve, _) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      return Resizer.imageFileResizer(
        file,
        (img.width * fileQuality) / 100,
        (img.height * fileQuality) / 100,
        fileType,
        fileQuality,
        0,
        (uri) => resolve(uri as File),
        'file',
      )
    }
  })

const FileToImageModel = async ({
  file,
  sizes,
  targetFileSize,
}: {
  file: any
  sizes?: ImageSizes
  targetFileSize?: number
}): Promise<ImageModel | undefined> => {
  try {
    let fileType = 'JPEG'
    switch (file.type) {
      case 'image/png':
        fileType = 'PNG'
        break
      case 'image/webp':
        fileType = 'WEBP'
        break
      case 'image/jpeg':
      case 'image/jpg':
        break
      default:
        return undefined
    }

    let location: LocationModel | undefined
    try {
      let exifData = await exifr.parse(file)
      if (exifData.latitude && exifData.longitude) {
        location = {
          locationLatitude: exifData.latitude,
          locationLongitude: exifData.longitude,
          locationAddress: '',
        }
      }
    } catch (e) {
      console.error('No GPS Meta Data')
    }

    const fileName = file.name.split('.')
    let fileModelFile = file

    if (targetFileSize && file.size > targetFileSize) {
      //try to compress the file here
      let fileQuality = (targetFileSize * 100) / file.size
      let compressedFile = await CompressImageReturnBlob(file, fileType, fileQuality)
      fileModelFile = compressedFile
    } else if (file.size > 5242880) {
      //try to compress the file here
      let fileQuality = (5242880 * 100) / file.size
      let resizedFile = await ResizeImageReturnBlob(
        file,
        sizes && sizes.medium ? sizes.medium : [2000, 2000],
        fileType,
        fileQuality,
      )
      fileModelFile = resizedFile
    }
    return createImageModel(fileModelFile, fileName, fileType, sizes, location)
  } catch (err) {
    console.error('Error converting image to image model')
  }
}

const ImageModelToUrl = (image: ImageModel, size: 'regular' | 'thumbnail' | 'original'): string => {
  switch (size) {
    case 'regular':
      return image.regularUrl
    case 'thumbnail':
      return image.thumbnailUrl
    case 'original':
      return image.fileUrl
  }
}

/**
 * add header to base64 string to display as image in html `img` tag
 * @param base64 base64 string
 * @param format format of the base64 string, 'png' | 'jpeg' | 'jpg' | 'webp'
 * @returns base64 string with header
 * @example
 * ```
 * const imageBase64 = Base64StringToImageBase64(base64, 'png')
 * ```
 */
const Base64StringToImageBase64 = (
  base64: string | undefined,
  format: 'png' | 'jpeg' | 'jpg' | 'webp',
): string => {
  return base64 !== undefined ? `data:image/${format};base64,${base64}` : ''
}

const DownloadFile = async (file) => {
  if (file) {
    const fileBytes = file.bytes
    if (fileBytes) {
      const download = document.createElement('a')
      download.href = window.URL.createObjectURL(new Blob([fileBytes]))
      download.setAttribute('download', `${file.fileName}.${file.ext}`)
      document.body.appendChild(download)
      download.click()
      document.body.removeChild(download)
    } else if (file.fileUrl && file.fileUrl !== '') {
      const blob = await GetFileFromUrl(file)
      if (blob) {
        const download = document.createElement('a')
        download.href = URL.createObjectURL(blob)
        download.setAttribute('download', `${file.fileName}.${file.ext}`)
        document.body.appendChild(download)
        download.click()
        document.body.removeChild(download)
      }
    }
  }
}

const GetImageDimensions = (
  url: string,
  isBase64?: boolean,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }
    img.onerror = reject
    if (isBase64) {
      img.src = `data:image;base64,${url}`
    } else {
      img.src = url
    }
  })
}

const GetBase64FromUrl = async (url): Promise<string | ArrayBuffer | null | undefined> => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export {
  FileToImageModel,
  ImageModelToUrl,
  Base64StringToImageBase64,
  ResizeImageReturnBlob,
  CompressImageReturnBlob,
  DownloadFile,
  GetImageDimensions,
  GetBase64FromUrl,
}
