import { FileModel } from '@services/model/file.model'
import useImageGallery from '@hooks/useImageGallery'
import useAPIFetch from '@hooks/useAPIFetch'
import { useState, useRef } from 'react'
import { MediaModel } from '@services/model/media.model'
import { Box, Button, Paper } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'

const TableImageUploadGallery = (props: {
  error?: any
  value?: FileModel[] | undefined
  callback?: any
  onChange?: (...event: any[]) => void
  onClick?: (...event: any[]) => void
  minHeight?: string | number
  width?: string | number
  disabled?: boolean
  uneditable?: boolean
  setValue?: any
  getValues?: any
  // defaultSelected?: any
  useWatch?: any
  gallerySource?: string
  control?: any
  gallerySelected?: string
  arrayFieldName?: string
  onRefreshClick?: (...event: any[]) => void
  onUploadClick?: (...event: any[]) => void
  galleryName?: string
}) => {
  const {
    ImageGallery,
    openImageGallery,
    setImageGalleryImages,
    setImageGallerySelected,
    galleryImages,
    closeImageGallery,
  } = useImageGallery()
  const { setRequest, isLoading } = useAPIFetch()
  const [defaultSelected, setDefaultSelected] = useState<any>([{ fileId: '', selected: false }])
  let currentGallerySource: any
  if (props?.useWatch && props?.gallerySource && props?.control) {
    currentGallerySource = props.useWatch!({ control: props.control, name: props?.gallerySource })
  }
  let currentGallerySelected: any = []
  if (props?.useWatch && props?.gallerySelected && props?.control) {
    currentGallerySelected = props.useWatch!({
      control: props.control,
      name: props?.gallerySelected,
    })
  }

  const buttonRef = useRef(null)

  const selectedList = () => {
    // api called whenever the gallery is clicked
    // each images have selected property.
    // As user will be able to reselect again,
    // to retent the selected state between each call,
    // there is a gallerySelected list saved the selected list separately
    // and passed as defaultSelected to the ImageGallery component
    // so the checkbox will check against the defaultSelected List first
    // then the selected property of the image from the api
    // combine images and gallerySelectedList by fileId

    let images: MediaModel[]
    let gallerySelectedList: any[] = [{}]
    if (props.getValues && props.gallerySelected && props.galleryName) {
      images = props.getValues!(props.galleryName)
      gallerySelectedList = currentGallerySelected
    } else {
      return []
    }

    const combinedList = images?.map((image) => {
      const matchingElement = gallerySelectedList?.find(
        (gallerySelected) => image.fileId === gallerySelected?.fileId,
      )

      if (matchingElement) {
        return { ...image, selected: matchingElement.selected }
      }
      return image
    })
    // return number of records that are selected
    return combinedList
  }

  return (
    <>
      <Button
        color={props.error ? 'error' : 'primary'}
        sx={{ marginRight: '-6px', marginLeft: '-6px' }}
        ref={buttonRef}
        tabIndex={-1}
        onClick={() => {
          //@ts-ignore
          if (buttonRef.current) buttonRef.current.focus()
          props.onClick!(
            setRequest,
            openImageGallery,
            setImageGalleryImages,
            currentGallerySource,
            setImageGallerySelected,
          )
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Paper
            sx={{
              width: props.width ?? '95%',
              minWidth: '150',
              minHeight: props.minHeight ?? '50',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <UploadFileIcon />
            <Box sx={props.error ? { color: 'red' } : {}}>
              {selectedList()?.filter((image) => image.selected === true)?.length > 0
                ? selectedList()?.filter((image) => image.selected === true)?.length
                : 'Please Select'}
            </Box>
          </Paper>
        </Box>
      </Button>
      <ImageGallery
        disabled={props.disabled}
        uneditable={props.uneditable}
        onRefreshClick={() => {
          props.onRefreshClick!(
            setRequest,
            openImageGallery,
            setImageGalleryImages,
            currentGallerySource,
            setImageGallerySelected,
            closeImageGallery,
          )
        }}
        onUploadClick={() => {
          props.onUploadClick!(currentGallerySource)
        }}
        onChange={(isChecked, fileId, chitPhotoId, gallerySelected) => {
          let currentGallery = currentGallerySelected
          if (currentGallery) {
            const found = currentGallery?.find((item) => item.fileId === fileId)
            if (found) {
              props.setValue!(
                gallerySelected,
                (currentGallery = currentGallery?.map((item) => {
                  if (item.fileId === fileId) {
                    return { ...item, selected: isChecked }
                  }
                  return item
                })),
              )
            } else {
              props.setValue!(gallerySelected, [
                ...currentGallery,
                { fileId, chitPhotoId, selected: isChecked },
              ])
            }
          } else {
            props.setValue!(gallerySelected, [{ fileId, chitPhotoId, selected: isChecked }])
          }
          setDefaultSelected(currentGallerySelected)
        }}
        defaultSelected={selectedList()}
      />
    </>
  )
}

// end image upload gallery

export default TableImageUploadGallery
