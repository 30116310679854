import SnackbarCloseButton from '@components/SnackbarCloseButton'
import axios, { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export type CustomError = {
  id: string
  message: string
}

export interface useAPIFetchProps {
  // A callback being invoking after api fetch
  callback: (cancelToken: any) => Promise<any>

  // error will be prompted with use of useSnackbar by default, setting hideError = true to disable showing the error
  hideError?: Boolean
}

export default function useAPIFetch(): {
  // trigger a api fetch request by setting the setRequest prop
  setRequest: Dispatch<SetStateAction<useAPIFetchProps | undefined>>

  // loading indicator when performing api fetch
  isLoading: boolean

  // error occur during api fetch
  error: Error | null
} {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [request, setRequest] = useState<useAPIFetchProps | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (!request) return

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)

      await request
        .callback(source.token)
        .catch((ex) => {
          let errMsg: any
          if (axios.isCancel(ex)) {
            return
          } else if (axios.isAxiosError(ex)) {
            if (ex.response) {
              if (ex.response.status) {
                switch (ex.response.status) {
                  case 401:
                    errMsg = t('common:messages.permissionDenied')
                    break
                  case 400:
                    errMsg = (ex.response.data as CustomError).message
                    break
                  case 403:
                    errMsg = (ex.response.data as CustomError).message
                    navigate('/authorized')
                    break
                  case 500:
                    errMsg = t('common:messages.internalServerErr')
                    break
                  case 504:
                    errMsg = t('common:messages.serverTimeOutErr')
                    break
                  default:
                    errMsg = ex.response.data
                    break
                }
              } else errMsg = ex.response.data
            } else {
              errMsg = t('common:messages.unknownError')
            }
          } else {
            errMsg = ex.message
          }

          setError(ex)

          if (typeof errMsg === 'object') {
            enqueueSnackbar(errMsg.title, {
              style: { zIndex: 10 },
              variant: 'error',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
          } else
            enqueueSnackbar(errMsg, {
              style: { zIndex: 10 },
              variant: 'error',
              action: (key) => {
                return <SnackbarCloseButton id={key} />
              },
            })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchData()

    return () => {
      // Cancel request when unmounted
      source.cancel()
    }
  }, [request])

  return { setRequest, isLoading, error }
}
