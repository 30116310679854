export const i18nCommonNS = 'common'
export const i18nAdminNS = 'admin'

export const i18nFormsNS = 'forms'
export const i18nValidationsNS = 'validations'
export const i18nExternalNS = 'external'
export const i18nErrorsNS = 'errors'
export const i18nAlphabetNS = 'alphabet'

export default [
  i18nCommonNS,
  i18nFormsNS,
  i18nExternalNS,
  i18nValidationsNS,
  i18nErrorsNS,
  i18nAlphabetNS,
]
