import { BaseModel } from '../base.model'

export interface FormModel extends BaseModel {
  formId: string
  categoryCode: string
  name?: string
  contractNoId: number
  projectId?: number
  contractNo?: string
  contractName?: string
  districtId: number
  teamId: number
  parentFormId?: string
  formStatus: string
  formStatusName: string
  formStatusShortName: string
  formStatusHistories: FormStatusHistory[]
  editable?: boolean
  formPermission: BaseFormPermission
  notifyList?: string[]
  assignToList?: string[]
  submissionComment?: string
  projectType?: string
  
}

export interface FormCountByUser {
  formCode: string
  totalCount: number
}
;[]

export interface FormStatusHistory {
  id: number
  formStatusId: number
  actionName: string
  actionCode: string
  actionBy?: number
  actionAt?: Date
  actionSignatureBase64?: string
  userName?: string // todo: integrate with backend user name
  displayName?: string // todo: integrate with backend display name
  position?: string // todo: integrate with backend user position
}

export const InitFormStatusHistory: FormStatusHistory = {
  id: 0,
  formStatusId: 0,
  actionName: '',
  actionCode: '',
}

export interface BaseFormPermission {
  canUpdate: boolean
  canDelete: boolean
  approveRequired: boolean
  returnRequired: boolean
  endOfFlow: boolean
}

export const InitBaseFormPermission: BaseFormPermission = {
  canUpdate: true,
  canDelete: false,
  approveRequired: false,
  returnRequired: false,
  endOfFlow: false,
}

export const InitFormModel: FormModel = {
  formId: '',
  categoryCode: '',
  contractNoId: -1,
  districtId: 0,
  teamId: 0,
  formStatus: 'Draft',
  formStatusHistories: [],
  status: true,
  createdBy: '0000-0000-0000-0000',
  createdAt: new Date(),
  formPermission: InitBaseFormPermission,
  formStatusShortName: '',
  formStatusName: 'Draft',
}

export interface FormStatus {
  id: number
  actionName: string
  actionCode: string
  signatureRequired: boolean
}

export interface BaseFormResponse {
  formId: string
  formStatus: string
}
export interface NotifyList {
  roles: number[]
  users: any[]
}

export interface Comment {
  formStatus: any
  commentType: string
  id: number
  formId: string
  status: boolean
  comment: string
  createdBy: number
  createdAt: Date
}

export interface FormHistory {
  statusName: string
  createdBy: string
  createdAt: any
  id: number
  status: boolean
  actionBy: number
  actionAt: Date
  formStatus: string
}

export interface BaseFormStatusDtoForList {
  formStatusId: number
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string
}

export interface formOnSubmitModel {
  event: any
  isReject: boolean
  isReturn: boolean
  notifyList?: string[]
  assignToList?: string[]
  signatureBase64?: string
  submissionComment?: string
}

export enum RightsCategory {
  'DailyRecordSummary' = 'FORM_DRS',
  'ChitRecord' = 'FORM_DRS_ITEM',
  'DailyRecordSummaryUploadPlatform' = 'FORM_DRS_UP',
  'User' = 'FORM_USER',
  'MonthlyWasteFlowTable' = 'FORM_MWF',
  'MonthlyWasteFlowTableUploadPlatform' = 'FORM_MWF_UP',
  'WasteForecastTable' = 'FORM_WFT',
  'WasteManagementPlan' = 'FORM_WMP',
  'WasteManagementPlanUploadPlatform' = 'FORM_WMPU',
  'WasteManagementPlanTemplate' = 'FORM_WMPT',
  'Project' = 'FORM_PROJECT',
  'Contract' = 'FORM_CONTRACT',
  'EpdSubmission' = 'FORM_EPD_SUBMIT',
  'Organization' = 'FORM_ORGANIZATION',
}

export enum FormStatusEnum {
  'draft' = 'Draft',
  //admin
  'userCreate' = 'FORM_USER_CREATE',
  'projectCreate' = 'FORM_PROJECT_CREATE',

  // MWF
  'MonthlyWasteFlowTable' = 'FORM_MWF',
  'MonthlyWasteFlowTableDraft' = 'FORM_MWF_DRAFT',
  'MonthlyWasteFlowTableReview' = 'FORM_MWF_REVIEW',
  'MonthlyWasteFlowTableRejected' = 'FORM_MWF_REJECTED',
  'MonthlyWasteFlowTableApproved' = 'FORM_MWF_APPROVED',

  //MWF_UP
  'MonthlyWasteFlowTableUploadPlatform' = 'FORM_MWF_UP',
  'MonthlyWasteFlowTableUploadPlatformDraft' = 'FORM_MWF_UP_DRAFT',

  // DRS Item
  'DailyRecordSummaryItem' = 'FORM_DRS_ITEM',
  'DailyRecordSummaryItemDraft' = 'FORM_DRS_ITEM_DRAFT',
  'DailyRecordSummaryItemSubmitted' = 'FORM_DRS_ITEM_SUBMITTED',
  'DailyRecordSummaryItemReview' = 'FORM_DRS_ITEM_REVIEW',
  'DailyRecordSummaryItemCompleted' = 'FORM_DRS_ITEM_COMPLETE',

  'DailyRecordSummaryItemRejected' = 'FORM_DRS_ITEM_REJECTED',
  'DailyRecordSummaryItemApproved' = 'FORM_DRS_ITEM_APPROVED',

  // DRS
  'DailyRecordSummary' = 'FORM_DRS',
  'DailyRecordSummaryDraft' = 'FORM_DRS_DRAFT',
  'DailyRecordSummaryFirstReview' = 'FORM_DRS_FIRST_REVIEW',
  'DailyRecordSummaryFirstApproved' = 'FORM_DRS_FIRST_APPROVED',
  'DailyRecordSummaryFirstRejected' = 'FORM_DRS_FIRST_REJECTED',
  'DailyRecordSummarySecondReview' = 'FORM_DRS_SECOND_REVIEW',
  'DailyRecordSummarySecondRejected' = 'FORM_DRS_SECOND_REJECTED',
  'DailyRecordSummarySecondApproved' = 'FORM_DRS_SECOND_APPROVED',

  // DRS UP
  'DailyRecordSummaryUploadPlatform' = 'FORM_DRS_UP',
  'DailyRecordSummaryUploadPlatformDraft' = 'FORM_DRS_UP_DRAFT',
  'DailyRecordSummaryUploadPlatformReview' = 'FORM_DRS_UP_REVIEW',

  // WFT

  'WasteForecastTable' = 'FORM_WFT',
  'WasteForecastTableDraft' = 'FORM_WFT_DRAFT',
  'WasteForecastTableReview' = 'FORM_WFT_REVIEW',
  'WasteForecastTableRejected' = 'FORM_WFT_REJECTED',
  'WasteForecastTableApproved' = 'FORM_WFT_APPROVED',

  // WMP
  'WasteManagementPlan' = 'FORM_WMP',
  'WasteManagementPlanUploadPlatform' = 'FORM_WMPU',
  'WasteManagementPlanUploadPlatformDraft' = 'FORM_WMPU_DRAFT',
  'WasteManagementPlanUploadPlatformReview' = 'FORM_WMPU_REVIEW',
  'WasteManagementPlanUploadPlatformRejected' = 'FORM_WMPU_REJECTED',
  'WasteManagementPlanUploadPlatformApproved' = 'FORM_WMPU_APPROVED',

  // WMPT
  'WasteManagementPlanTemplate' = 'FORM_WMPT',
  'WasteManagementPlanTemplateDraft' = 'FORM_WMPT_DRAFT',
  'WasteManagementPlanTemplateReview' = 'FORM_WMPT_REVIEW',
  'WasteManagementPlanTemplateRejected' = 'FORM_WMPT_REJECTED',
  'WasteManagementPlanTemplateApproved' = 'FORM_WMPT_APPROVED',
  // MR UP
  'MonthlyReport' = 'FORM_MONTHLY_REPORT',
  'MonthlyReportDraft' = 'FORM_MONTHLY_REPORT_DRAFT',
  'MonthlyReportUpload' = 'FORM_MWF_UP_DRAFT',
  'MonthlyReportUploadDraft' = 'FORM_MWF_UP_DRAFT',
}
