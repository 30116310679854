import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import styles from '@styles/baseButton.module.scss'
import useQuery from '@hooks/useQuery'
import { KeyValPair } from '@models/Common'
import { QueryStepParser } from '@utils/queryStepParser'
import FormController from '@components/form/controller'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { SubmitFormModel, InitSubmitForm } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Typography, Divider, Button, Grid, useMediaQuery } from '@mui/material'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import { GridColDef } from '@mui/x-data-grid'
import BaseButton from '@components/button/BaseButton'

const UUID = uuid()

export type DialogContextProps = {
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const AdminSubmitFormPage = () => {
  const { hasRightByCatAndCode } = useContext(GlobalContext)

  let readonly = false
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SubmitFormModel>({
    defaultValues: { ...InitSubmitForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestUsers } = useAPIFetch()
  const userListRef = useRef<KeyValPair[]>([])
  const [organisationMeta, setOrganisationMeta] = useState<any>([])

  const [dialogContext, setDialogContext] = useState<DialogContextProps>({
    title: <></>,
  })
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleOnComplete = () => {}
  const rows: any = []
  const [usersData, setUsersData] = useState<any>(rows)
  const reload = async () => {
    // setRequest({
    //   callback: async () => {
    //     if (formId) {
    //       await AdminService.GetContractForm(formId)
    //         .then(async (f) => {
    //           if (f) reset(f)
    //         })
    //         .catch((err) => {
    //           enqueueSnackbar(err.response?.data?.message, {
    //             variant: 'error',
    //             autoHideDuration: null,
    //             action: (key) => {
    //               return <SnackbarCloseButton id={key} />
    //             },
    //           })
    //           // NavigateTo(navigate, '/admin')
    //         })
    //     }
    //     isMounted.current = true
    //   },
    // })
    setRequestUsers({
      callback: async () => {
        await AdminService.GetUserList({ skipPagination: true }, null, false).then((res) => {
          setUsersData(res?.list)
        })
      },
    })
  }

  useEffect(() => {
    if (formId == null) {
      reset({ ...InitSubmitForm })
    }

    reload()
    reloadOthers()
  }, [formId])

  const reloadOthers = async () => {
    let list = await AdminService.GetOrganizationMeta()

    if (list) {
      // list = list.ToList().RemoveAt(0).ToArray()
      const [_, ...newList] = list
      let organizationList =
        newList?.map((x) => {
          return {
            key: x.id,
            value: x.name,
          }
        }) || []

      setOrganisationMeta(organizationList)
    }

    isMounted.current = true
  }

  // const matchedContract = eiGeneralOptions?.contractNo?.find(
  //   (contractNo) => contractNo.id === contractNoId,
  // )

  const handleSubmitEpdUrl = async (event: any) => {
    let req = getValues()
    let url = req?.epdRecordsUrl ?? ''
    setRequest({
      callback: async () => {
        await AdminService.SubmitEdpUrl(url).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
          // NavigateTo(navigate, '/admin/project/all-records')
        })
      },
    })
  }

  const handleSubmitWeightUrl = async (event: any) => {
    let req = getValues()
    let url = req?.weightRecordsUrl ?? ''
    setRequest({
      callback: async () => {
        await AdminService.SubmitWeightUrl(url).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
          // NavigateTo(navigate, '/admin/project/all-records')
        })
      },
    })
  }

  let index = 0
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/')
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h5' : 'h4'}
              sx={{
                fontWeight: 'bold',
                margin: matchDownMd ? theme.spacing(2) : theme.spacing(5),
              }}>
              {!formId ? t('Data Submission') : t('Data Submission')}
            </Typography>
          </Box>
          {/* <FormSwitcher /> */}
          <Typography
            variant={matchDownMd ? 'h6' : 'h5'}
            sx={{
              fontWeight: 'bold',
              marginTop: matchDownMd ? theme.spacing(2) : theme.spacing(2),
              marginBottom: matchDownMd ? theme.spacing(2) : theme.spacing(4),
              marginLeft: matchDownMd ? theme.spacing(2) : theme.spacing(6),
              marginRight: matchDownMd ? theme.spacing(2) : theme.spacing(6),
            }}>
            {t('Epd Records Submission')}
          </Typography>
          <FormContainer fkey={`${UUID}-submit-a`}>
            <FormField fieldName="Epd Construction waste transaction records" index={index++}>
              <FormController
                controllerProps={{
                  name: 'epdRecordsUrl',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{
                    fullWidth: true,
                    type: 'url',
                    placeholder:
                      'e.g. https://www.epd.gov.hk/epd/misc/cdm/chit/CWDCS20230306-20230319.xls',
                  }}
                  uneditable={readonly}
                />
              </FormController>
              <BaseButton
                displayText={t('common:buttons.submit')}
                disabled={false}
                className={styles.formButton}
                sx={{ width: '200px', marginLeft: '10px' }}
                onClickHandler={handleSubmitEpdUrl}
              />
            </FormField>
          </FormContainer>
          <Typography
            variant={matchDownMd ? 'h6' : 'h5'}
            sx={{
              fontWeight: 'bold',
              marginTop: matchDownMd ? theme.spacing(2) : theme.spacing(2),
              marginBottom: matchDownMd ? theme.spacing(2) : theme.spacing(4),
              marginLeft: matchDownMd ? theme.spacing(2) : theme.spacing(6),
              marginRight: matchDownMd ? theme.spacing(2) : theme.spacing(6),
            }}>
            {t('Weight Records Submission')}
          </Typography>
          <FormContainer fkey={`${UUID}-submit-a`}>
            <FormField fieldName="Permitted gross vehicle weight (Excel table)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'weightRecordsUrl',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{
                    fullWidth: true,
                    type: 'url',
                    placeholder: 'e.g. https://www.epd.gov.hk/epd/misc/cdm/PGVW/PGVW20230417.xlsx',
                  }}
                  uneditable={readonly}
                />
              </FormController>
              <BaseButton
                displayText={t('common:buttons.submit')}
                disabled={false}
                className={styles.formButton}
                sx={{ width: '200px', marginLeft: '10px' }}
                onClickHandler={handleSubmitWeightUrl}
              />
            </FormField>
          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!formId ? t('Back to Home') : t('Back to Home')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleOnSubmit}
                disabled={false}>
                {!formId ? t('Submit') : t('Save')}
              </SwitcherButton> */}
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
    </Fragment>
  )
}
export default AdminSubmitFormPage
