import { KeyValPair } from '@models/Common'

import {
  BaseFormStatusDtoForList,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from '../model/form/form.model'

export enum RoleIdEnum {
  ROLE_SA = 1, //Super Admin
  ROLE_EO = 2, //EO
  ROLE_EO_COW = 3, //CoW
  ROLE_EM = 4, //EM
  ROLE_PJ_BEAMP = 5, //Pj-BEAMP
  ROLE_PJ_ADMIN = 6, //Pj-Admin
  ROLE_FM = 7, //FM
  ROLE_GUEST = 8, //Guest
  ROLE_CIC_UA = 9, //User Admin
  ROLE_CIC_PA = 10, // Project Admin
}

export enum RoleNameEnum {
  'SuperAdmin' = 'SA', //Super Admin
  'EnvironmentalOfficer' = 'EO', //EO
  'ClerkOfWork' = 'CoW', //CoW
  'EnvironmentalManager' = 'EM', //EM
  'ProjectBeamp' = 'Pj-BEAMP', //Pj-BEAMP
  'ProjectAdmin' = 'Pj-Admin', //Pj-Admin
  'Foreman' = 'FM', //FM
  'Guest' = 'Guest', //Guest
  'CicUserAdmin' = 'CIC-UA', //User Admin
  'CicProjectAdmin' = 'CIC-PA', // Project Admin
  'OrganizationAdmin' = 'Org-Admin',
}
export const cicAdminRoles = [
  RoleNameEnum.SuperAdmin,
  RoleNameEnum.CicProjectAdmin,
  RoleNameEnum.CicUserAdmin,

]

export const canCreateOrgUserRoles = [RoleNameEnum.OrganizationAdmin]

export const canCreateProjAssignOrgUsersRoles = [
  RoleNameEnum.EnvironmentalOfficer,
  RoleNameEnum.EnvironmentalManager,
]

export const hasWorkRightsRoles = [
  RoleNameEnum.EnvironmentalOfficer,
  RoleNameEnum.EnvironmentalManager,
  RoleNameEnum.Foreman,
  RoleNameEnum.ClerkOfWork,
]

export const hasUserAdminRightRoles = [RoleNameEnum.SuperAdmin, RoleNameEnum.CicUserAdmin]

export const hasSuperAdminRightsRoles = [RoleNameEnum.SuperAdmin]

export interface UserListModel {
  roleId: number[]
  status?: boolean
  email?: string
  displayName?: string
  position?: string
  organization?: string
  userId?: string
  organizationName?: string
  organizationId?: string
}

export interface UserFormModel {
  baseForm: FormModel
  uuid?: string
  userId?: string
  ciamUserId?: string
  status?: boolean
  email: string
  displayName?: string
  firstName?: string
  lastName?: string
  position?: string
  team?: string
  organizationId?: number
  assignedGroup?: any
  role?: any
  districtId?: number
  teams?: number[] | KeyValPair[]
  organizationName?: string
  roles?: number[] | KeyValPair[]
  districts?: number[] | KeyValPair[]
  phone?: string
  enrolment?: number
  organization?: OrganizationModel
}

export interface OrganizationModel {
  uuid?: string
}

export const InitProjectForm: ProjectFormModel = {}
export const InitContractForm: ContractFormModel = {}
export const InitSubmitForm: SubmitFormModel = {}
export const InitOrganizationForm: OrganizationFormModel = {}

export interface OrganizationListModel {
  status?: boolean
  organizationName?: string
  contractName?: string
  contractNo?: string
  chitAccountNo?: string
  publicOwner?: string
  id?: string | number
  assignedProjects?: any
  companyName?: string
  subsidiaryName?: string
  organizationId?: string
  businessRegistrationNo?: string
  uuid?: string
}

export interface OrganizationFormModel {
  status?: boolean
  id?: number
  //part a
  contracts?: any[]
  users?: any[]
  companyName?: string
  subsidiaryName?: string
  organizationName?: string
  businessRegistrationNo?: string
  contractUsers?: Record<string, any>[]
  organizationTypeId?: number
  organizationId?: string
  uuid?: string
  email?: string
  role?: number
  inviteUser?: inviteUserModel
}

export interface inviteUserModel {
  login?: string
  email?: string
  roles?: number[]
  firstName?: string
  lastName?: string
  organizationId?: string
  contractId?: number
  projectId?: number
}
export interface ProjectListModel {
  status?: boolean
  projectName?: string
  projectNo?: string
  contractPhase?: string
  chitAccountNo?: string
  publicOwner?: string
  id?: string | number
  assignedProjects?: any
  contractId?: number
  contractName?: string
  contractNo?: string
  projectDescription?: string
  ibeamProjectNo?: string
}

export interface ContractListModel {
  status?: boolean
  contractName?: string
  contractNo?: string
  contractPhase?: string
  chitAccountNo?: string
  publicOwner?: string
  id?: string | number
  assignedProjects?: any
}
export interface ProjectOptionsModel {
  projectLocationList?: KeyValPair[]
  projectDistrictList?: KeyValPair[]
  projectTypeList?: KeyValPair[]
  projectClassificationB1List?: KeyValPair[]
  projectClassificationB2List?: KeyValPair[]
}

export interface ProjectFormModel {
  status?: boolean
  id?: number
  //part a
  projectName?: string
  projectNo?: string
  contractId?: number
  assignedContract?: string
  organizationId?: string
  contractName?: string
  contractNo?: string
  contractPhase?: string
  chitAccountNo?: string
  ibeamProjectNo?: string
  projectSiteName?: string
  projectDescription?: string
  projectAddress?: string
  projectLocationId?: number // single selectInitProjectForm
  projectDistrictId?: number // single select
  constructionStartDate?: Date
  expectedConstructionDate?: Date
  contractValue?: number
  projectType?: number // single select
  projectTypeId?: number // single select
  //B1 building
  projectClassificationB1Id?: number // single select
  constructionFloorArea?: number
  grossFloorArea?: number
  constructionStages?: number[] // multiple select
  isSiteFormation?: boolean
  isDemolition?: boolean
  isFoundation?: boolean
  isSuperstruct?: boolean
  isOthers?: boolean
  //B2 civil engineering
  projectClassificationB2Id?: number // single select
  civilArea?: number
  civilConstructionStages?: string // user input
  civilLength?: number
  contractUsers?: Record<string, any>[]
  inviteUser?: inviteUserModel
  addUserId?: string
  projectPermission?: { canUpdate?: boolean; canCreateProject?: boolean }
}
export interface SubmitFormModel {
  epdRecordsUrl?: string
  weightRecordsUrl?: string
}

export interface ContractFormModel {
  projects?: any[]
  status?: boolean
  id?: number
  //part a
  addUserId?: string
  addUserOrganizationId?: string
  contractPermission?: { canUpdate?: boolean; canCreateProject?: boolean }
  organizations?: any[]
  contractName?: string
  contractNo?: string
  chitAccountNo?: string
  ibeamProjectNo?: string
  publicOwner?: string
  contractor?: string
  contractAddress?: string
  contractLocation?: string
  contractLocationId?: number // single selectInitProjectForm
  contractDistrictId?: number // single select
  constructionStartDate?: Date
  expectedConstructionDate?: Date
  contractValue?: number
  projectType?: number // single select
  projectTypeId?: number // single select
  //B1 building
  projectClassificationB1Id?: number // single select
  constructionFloorArea?: number
  grossFloorArea?: number
  constructionStages?: number[] // multiple select
  isDemolition?: boolean
  isFoundation?: boolean
  isSuperstruct?: boolean
  isOthers?: boolean
  //B2 civil engineering
  projectClassificationB2Id?: number // single select
  civilArea?: number
  civilConstructionStages?: string // user input
  civilLength?: number
  contractUsers?: Record<string, any>[]
  assignedOrganizationList?: string[]
  firstName?: string
  activeUserList?: UserListModel[]
  inactiveUserList?: UserListModel[]
  invitedUserList?: UserListModel[]
  inviteUser?: inviteUserModel
}

export const InitUserForm: UserFormModel = {
  baseForm: { ...InitFormModel, formStatus: FormStatusEnum.userCreate },
  email: '',
}

export interface DataSubmissionResponse {
  conf: {
    download_link: string
  }
  dag_id?: string
  dag_run_id?: string
  data_interval_end?: string
  data_interval_start?: string
  end_date?: string
  execution_date?: string
  external_trigger?: boolean
  last_scheduling_decision?: string
  logical_date?: string
  run_type?: string
  start_date?: string
  state?: string
}

export enum ProjectTypeEnum {
  building = 'Building',
  civilEngineering = 'Civil Engineering',
}

export enum EnrolmentStatusEnum {
  pendingInvitation = 'Pending Invitation',
  pendingApproval = 'Pending Approval',
  active = 'Active',
  disabled = 'Disabled',
}

export enum OrganizationTypeEnum {
  internal = 'Internal',
  external = 'External',
}

export enum OrganizationNameEnum {
  cic = 'CIC',
  varadise = 'Varadise',
  system = 'System',
  contractor = 'Contractor',
}
