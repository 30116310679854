import SearchPanel from '@components/searchPanel'
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import { FormDrsItemsListModel } from '@services/model/form/form.DRS.item.model'
import FormDrsItemService from '@services/formService/form.DRS.item.service'
import { NavigateTo } from '@utils/navigate'
import { KeyValPair } from '@models/Common'
import { RiFileTextFill } from 'react-icons/ri'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '@hooks/useLocalStorage'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'

const DailyRecordSystemItemAllRecordsPage = (props: { showMyRecord?: boolean }) => {
  const navigate = useNavigate()
  const refMounted = useRef(true)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [contractId, setContractId] = useState<number | undefined>(undefined)

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )

  useEffect(() => {
    setContractId(currentContract?.contractId)
  }, [currentContract])
  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<{
    submittedByList: string[]
    approvedByList: string[]
    formIdList: KeyValPair[]
    parentFormIdList: KeyValPair[]
  }>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
  })

  const [TableView, reload] = useTableView<FormDrsItemsListModel>({
    headers: [
      {
        key: 'short_name',
        desc: t('common:baseForm:generalStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsItemsListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'chit_no',
        desc: t('forms:dailyRecordSummary.fields.chitNo', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsItemsListModel) => <Fragment>{record.chitNo}</Fragment>,
      },
      {
        key: 'vehicle_registration_mark',
        desc: t('forms:dailyRecordSummary.fields.vehicleRegistrationMark', {
          ns: common.i18nFormNS,
        }),
        renderCell: (record: FormDrsItemsListModel) => (
          <Fragment>{record.vehicleRegistrationMark}</Fragment>
        ),
      },
      {
        key: 'date_of_disposal',
        desc: t('forms:dailyRecordSummary.fields.dateOfDisposal', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsItemsListModel) => (
          <Fragment>
            {record.dateOfDisposal ? moment(record.dateOfDisposal).format('DD-MM-yyyy') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm:updatedAt', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsItemsListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormDrsItemsListModel) {
      NavigateTo(navigate, '/daily-record-summary-item/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (contractId) {
        let resp = await FormDrsItemService.GetDrsItemsList(
          {
            ...state.filterResult,
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
        setExtraListInfo({
          submittedByList: resp.submittedByList,
          approvedByList: resp.approvedByList,
          formIdList: resp.formIdList,
          parentFormIdList: resp.parentFormIdList,
        })
        return resp
      } else {
        let resp = await FormDrsItemService.GetDrsItemsList(
          {
            ...state.filterResult,
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [globalState.currentContract?.contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
        setExtraListInfo({
          submittedByList: resp.submittedByList,
          approvedByList: resp.approvedByList,
          formIdList: resp.formIdList,
          parentFormIdList: resp.parentFormIdList,
        })
        return resp
      }
    },
  })

  useEffect(() => {
    document.title = t('forms:drsItems.title', { ns: common.i18nFormNS })
    // eslint-disable-next-line
  }, [])

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleCreateBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const { hasRightByCatAndCode } = useContext(GlobalContext)

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('My Actions') : t('All Records')}
        </Typography>
        <SearchPanel
          extraButtons={
            hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryItem, ['C']) ? (
              <>
                <IconButton
                  aria-describedby={id}
                  onClick={handleCreateBtnClick}
                  sx={{
                    height: 40,
                    width: 90,
                    margin: '0 5px 0 5px',
                    backgroundColor: globalState.headerColor,
                    color: 'white',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: globalState.headerColor,
                    },
                  }}>
                  <AddBoxIcon sx={{ color: 'white' }} />
                  <Box
                    sx={{
                      fontSize: '16px',
                      paddingLeft: '4px',
                      color: 'white',
                    }}>
                    {t('common:buttons.create')}
                  </Box>
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <Typography sx={{ p: 2 }}>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="vertical button group"
                      variant="text">
                      <Tooltip title={t('forms:drsItems.batch.tooltip')} placement="left">
                        <Button
                          sx={{
                            color: '#000000 !important',
                            borderBottomColor: '#000000 !important',
                          }}
                          onClick={() => {
                            navigate('/daily-record-summary-item/batch')
                          }}>
                          {t('forms:drsItems.batch.button')}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('forms:drsItems.cEasy.tooltip')} placement="left">
                        <Button
                          sx={{
                            color: '#000000 !important',
                            borderBottomColor: '#000000 !important',
                          }}
                          onClick={() => {
                            navigate('/daily-record-summary-item/c-easy')
                          }}>
                          {t('forms:drsItems.cEasy.button')}
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Typography>
                </Popover>
              </>
            ) : (
              <></>
            )
          }
          dispatch={dispatch}
          addUrl={undefined}
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'dateOfDisposal',
              desc: t('forms:drsItems.fields.dateOfDisposal'),
              type: 'DateFilter',
              subType: 'date',
              disableOperator: true,
              defaultOperator: 'in',
            },
            {
              key: 'chitNo',
              desc: t('forms:dailyRecordSummary.fields.chitNo'),
              type: 'StringFilter',
              disableOperator: true,
            },
            {
              key: 'formStatus',
              desc: t('common:baseForm.formStatus'),
              type: 'StringSelectionFilter',
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.ChitRecord))
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
            },
            {
              key: 'submittedBy',
              desc: t('common:baseForm.submittedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.submittedByList?.includes(x.key),
              ),
            },
            {
              key: 'approvedBy',
              desc: t('common:baseForm.approvedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.approvedByList?.includes(x.key),
              ),
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default DailyRecordSystemItemAllRecordsPage
