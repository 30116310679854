import { KeyValPair } from '@models/Common'
import apiGateway from '@utils/axiosInterceptor'
import { GroupType } from './model/group.model'
import { UserInfo } from './model/user.model'

const GetUserInfo = async (email: string) => {
  return await apiGateway.get<UserInfo>(`/api/v1/users/info?email=${email}`).then((rep) => {
    return rep.data
  })
}

const GetRoleMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/roles/meta').then((rep) => {
    return rep.data
  })
}

const GetUserMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/meta').then((rep) => {
    return rep.data
  })
}

const GetGroupTypeList = async () => {
  return await apiGateway.get<GroupType[]>('/api/v1/groups/meta').then((rep) => {
    return rep.data
  })
}

const GetUserFormRights = async (userId: string, cat?: string) => {
  return await apiGateway
    .get<UserInfo>(`/api/v1/users/${userId}/form-rights?form_cat=${cat || ''}`)
    .then((rep) => {
      return rep.data
    })
}
const GetOrganizationMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/organizations/meta').then((rep) => {
    return rep.data
  })
}

const UserService = {
  GetUserInfo,
  GetRoleMetaList,
  GetUserMetaList,
  GetOrganizationMetaList,
  GetGroupTypeList,
  GetUserFormRights,
}

export default UserService
