import FormTable from '@components/form/table/Table'
import { GlobalStateProps } from '@providers/globalStore'
import { FormDrsUploadModel } from '@services/model/form/form.DRS.upload.model'
import { Control } from 'react-hook-form'
import { ConvertBase64 } from '@utils/file'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import moment from 'moment'
import FormContainer from '@components/form/container'
import { FileToImageModel } from '@utils/image'
import { useSnackbar } from 'notistack'
import testIsMobile from '@utils/testIsMobile'
const DailyRecordSummaryUploadPartB = ({
  uneditable,
  formId,
  t,
  userInfo,
  currentContract,
  control,
  drsGeneralOptions,
}: {
  uneditable: boolean
  formId?: string
  t: any
  userInfo: any
  currentContract: any
  control: Control<FormDrsUploadModel>
  drsGeneralOptions: any
}) => {
  const isMobile = testIsMobile()
  let index = 0
  const { enqueueSnackbar } = useSnackbar()
  const onClickAddFiles = async (event, append, extraInfo) => {
    try {
      for (let i = 0; i < event.target.files?.length; i++) {
        let file = event.target.files?.[i]
        let _type = file?.type
        if (_type?.includes('image')) {
          await FileToImageModel({
            file: file,
            targetFileSize: 1000000,
          }).then((res) => {
            if (res) {
              append({
                status: true,
                fileName: file?.name,
                createdBy: extraInfo?.createdBy,
                bytesBase64: res.bytesBase64,
                baseForm: extraInfo?.baseForm,
                thumbnail: file, // appear in ui in the upload stage only
              })
            }
          })
        } else {
          const MAX_FILE_SIZE = 2000000
          if (file.size > MAX_FILE_SIZE) {
            enqueueSnackbar(t(`Invalid file size for ${file.name}, maximum file size: 2mb`), {
              variant: 'error',
              autoHideDuration: null,
            })
            return
          }
          await ConvertBase64(file).then((res) => {
            append({
              status: true,
              fileName: file?.name,
              createdBy: extraInfo?.createdBy,
              bytesBase64: res,
              baseForm: extraInfo?.baseForm,
              thumbnail: file, // appear in ui in the upload stage only
            })
          })
        }
      }
    } catch (e) {
      enqueueSnackbar('error converting image', { variant: 'error' })
    }
  }
  return (
    <>
      <FormContainer fkey={`drs-b`}>
        <FormField fieldName="Chit Number" index={index++}>
          <FormController
            controllerProps={{
              name: 'chitNo',
              control,
            }}>
            <FormFreeText textfieldProps={{ type: 'string' }} uneditable={uneditable || !!formId} />
          </FormController>
        </FormField>
        <FormField fieldName="Reporting Period" index={index++}>
          <FormController
            controllerProps={{
              name: 'reportPeriod',
              control,
            }}>
            <FormDateTimePicker
              type="date"
              maxDate={new Date()}
              // minDate={new Date(moment(new Date()).subtract(3, 'months').format('YYYY MM'))}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormTable
          acceptFileTypes="image/jpeg,image/gif,image/png"
          isMobile={isMobile}
          disableDeleteRowButton
          title={t('Truck Photos')}
          key={t('truckPhotoTable')}
          columns={[
            {
              field: 'fileName',
              displayName: 'Document Name',
              type: 'string',
              uneditable: true,
            },
            {
              field: 'thumbnail',
              displayName: 'Image',
              type: 'image',
              uneditable: true,
            },
            {
              field: 'createdBy',
              displayName: 'Uploaded By',
              type: 'string',
              uneditable: true,
            },
          ]}
          showAddFilesButton
          showDeleteFileRecordButton
          hiddenItemNo
          removeAddButton
          height={50}
          fieldArrayProps={{
            name: 'newTruckPhotos',
            control: control,
          }}
          extraInfo={{
            createdBy: userInfo.firstName,
            baseForm: { contractNoId: currentContract?.contractId ?? 0 },
          }}
          onClickAddFiles={onClickAddFiles}
        />
        <FormTable
          acceptFileTypes="application/pdf"
          isMobile={isMobile}
          disableDeleteRowButton
          title={t('Chit Receipts')}
          key={'chitReceiptTable'}
          columns={[
            {
              field: 'fileName',
              displayName: 'Document Name',
              type: 'string',
              uneditable: true,
            },
            {
              field: 'materialType',
              displayName: 'Type Of Material',
              type: 'select',
              options: drsGeneralOptions?.materialType,
              cellStyle: { width: '350px' },
            },
            {
              field: 'createdBy',
              displayName: 'Uploaded By',
              type: 'string',
              uneditable: true,
            },
          ]}
          showAddFilesButton
          showDeleteFileRecordButton
          hiddenItemNo
          removeAddButton
          height={50}
          fieldArrayProps={{
            name: 'newChitReceipts',
            control: control,
          }}
          extraInfo={{
            createdBy: userInfo.firstName,
            baseForm: { contractNoId: currentContract?.contractId ?? 0 },
          }}
          onClickAddFiles={onClickAddFiles}
        />
      </FormContainer>
    </>
  )
}
export default DailyRecordSummaryUploadPartB
