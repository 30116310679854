import { NavigateFunction } from 'react-router-dom'

const NavigateTo = (
  navigate: NavigateFunction,
  path: string,
  params?: Record<string, string | number | undefined>,
  query?: Record<string, string | number>,
  target?: string,
): void => {
  let q = ''
  if (query) {
    q += '?'
    let i = 0
    for (const [key, value] of Object.entries(query)) {
      q += `${key}=${value}`
      i++
      if (i < Object.keys(query).length) {
        q += '&'
      }
    }
  }
  let _path = path
  if (params) {
    for (const [param, value] of Object.entries(params)) {
      if (value) {
        _path = _path.replace(`:${param}`, value.toString())
      } else if (param === 'id') {
        _path = _path.replace('/:id', '')
      } else {
        console.warn(`error navigating, value [${param}] is undefined`)
        _path = _path.replace(`/:${param}`, '')
      }
    }
  }
  if (target) {
    window.open(`${_path}${q}`, '_blank')
  } else {
    navigate(`${_path}${q}`)
  }
}
export { NavigateTo }
