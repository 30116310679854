import { useOktaAuth } from '@okta/okta-react'
import React, { useState, useEffect } from 'react'
import { Button, Header } from 'semantic-ui-react'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import UserService from '@services/user.service'
import useLocalStorage from '@hooks/useLocalStorage'

const Login = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<any>(null)

  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' })
  }

  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  if (!authState || !authState.isAuthenticated) {
    login()
  }

  // useEffect(() => {
  //   console.debug('useEffect - redirect login page')
  //   if (!authState || !authState.isAuthenticated) {
  //     login()
  //   }
  //   //
  // }, [authState])

  // useEffect(() => {
  //   if (!authState || !authState.isAuthenticated) {
  //     // When user isn't authenticated, forget any user info
  //     setUserInfo(null)
  //   } else {
  //     oktaAuth
  //       .getUser()
  //       .then((info) => {
  //         // window.alert(JSON.stringify(info))
  //         if (info.email) {
  //           UserService.GetUserInfo(info.email).then((userInformation: UserInfo) => {
  //             setLoginUser(userInformation)
  //             setUserInfo(info)
  //           })
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err)
  //       })
  //   }
  // }, [authState, oktaAuth]) // Update if authState changes

  const resourceServerExamples = [
    {
      label: 'Node/Express Resource Server Example',
      url: 'https://github.com/okta/samples-nodejs-express-4/tree/master/resource-server',
    },
    {
      label: 'Java/Spring MVC Resource Server Example',
      url: 'https://github.com/okta/samples-java-spring/tree/master/resource-server',
    },
    {
      label: 'ASP.NET Core Resource Server Example',
      url: 'https://github.com/okta/samples-aspnetcore/tree/master/samples-aspnetcore-3x/resource-server',
    },
  ]

  if (!authState || !authState.isAuthenticated) {
    return <div>Loading...</div>
  }
  return <div id="Login"></div>
}
export default Login
