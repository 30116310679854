import {
  Box,
  Divider,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Stack,
  Link,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigateTo } from '@utils/navigate'
import getUserRightByRole from '@utils/getUserRightByRole'
import {
  canCreateProjAssignOrgUsersRoles,
  cicAdminRoles,
  hasUserAdminRightRoles,
  canCreateOrgUserRoles,
} from '@services/model/admin.model'

const HeaderTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  marginTop: '0px',
}))

const AdminPage = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const reload = async () => {}
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '2px solid',
    borderRadius: '10%',
    '& > *': {
      textDecoration: 'none',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    cursor: 'pointer',
  }))

  const StyledGrid = styled(Grid)(({ theme }) => ({
    '&:hover': {
      pointer: 'cursor',
    },
  }))

  const { hasUserRight: hasViewContProjRight } = getUserRightByRole(userInfo, globalState, [
    ...cicAdminRoles,
    ...canCreateProjAssignOrgUsersRoles,
    ...canCreateOrgUserRoles,
  ])

  const { hasUserRight: hasViewOwnOrgConProjRight } = getUserRightByRole(userInfo, globalState, [
    ...canCreateProjAssignOrgUsersRoles,
    ...canCreateOrgUserRoles,
  ])

  const { hasUserRight: cicAdminRights } = getUserRightByRole(userInfo, globalState, cicAdminRoles)
  const isCicUser = userInfo?.organization?.companyName === 'Construction Industry Council'
  const isSystemUser = userInfo?.organization?.companyName === 'System'
  const { hasUserRight: hasCreateOrgUserRight } = getUserRightByRole(
    userInfo,
    globalState,
    canCreateOrgUserRoles,
  )

  const { hasUserRight: hasUserAdminRight } = getUserRightByRole(
    userInfo,
    globalState,
    hasUserAdminRightRoles,
  )

  const { hasUserRight: hasViewAdminPageRight } = getUserRightByRole(userInfo, globalState, [
    ...cicAdminRoles,
    ...canCreateOrgUserRoles,
    ...canCreateProjAssignOrgUsersRoles,
    ...hasUserAdminRightRoles,
  ])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        padding: matchDownMd ? '10px 10px' : '50px 50px',
      }}>
      <Box>
        <HeaderTitle hidden={matchDownMd}>{t('Settings')}</HeaderTitle>
        <Divider sx={{ mb: '20px' }} />
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          hidden={!(cicAdminRights || (hasViewAdminPageRight && (isSystemUser || isCicUser)))}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, '/admin/submit')
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/epd.png" alt="EPD Records" width={'100%'} />
              </Box>
              <Box>{t('EPD Data Update')}</Box>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={6} sm={4} md={2} hidden={!(cicAdminRights || isSystemUser)}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, '/admin/organization/all-records')
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/organization.svg" alt="organizations" width={'100%'} />
              </Box>
              <Box>{t('Organizations')}</Box>
            </Stack>
          </Item>
        </Grid>

        <Grid item xs={6} sm={4} md={2} hidden={!hasViewOwnOrgConProjRight}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, `/admin/organization/${userInfo?.organization?.uuid}`)
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/organization.svg" alt="organizations" width={'100%'} />
              </Box>
              <Box>{t('My Organization')}</Box>
            </Stack>
          </Item>
        </Grid>

        <Grid item xs={6} sm={4} md={2} hidden={!hasViewContProjRight}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, '/admin/contract/all-records')
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/contract.svg" alt="contracts" width={'100%'} />
              </Box>
              <Box>{t('Contracts')}</Box>
            </Stack>
          </Item>
        </Grid>

        <Grid item xs={6} sm={4} md={2} hidden={!hasViewContProjRight}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, '/admin/project/all-records')
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/project.svg" alt="projects" width={'100%'} />
              </Box>
              <Box>{t('Projects')}</Box>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={6} sm={4} md={2} hidden={!hasUserAdminRight}>
          <Item
            onClick={(e) => {
              NavigateTo(navigate, '/admin/user/all-records')
            }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ pr: '10%', pl: '10%' }}>
                <img src="/img/user.svg" alt="User" width={'100%'} />
              </Box>
              <Box>{t('Users')}</Box>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}
export default AdminPage
