import { KeyValPair } from '@models/Common'
import { useState, useTransition } from 'react'
import {
  ClickAwayListener,
  Autocomplete,
  Typography,
  TextField,
  FilterOptionsState,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTranslation } from 'react-i18next'
const TableSelectWithSearch = (props: {
  uneditable?: boolean
  options?: KeyValPair[]
  value?: any
  onChange?: any
  disabled?: boolean
  fieldProps?: any
}) => {
  const [open, setOpen] = useState(false)

  const blurHandler = (e) => {
    if (open) {
      setOpen(false)
    }
  }

  const clickHandler = (e) => {
    if (!e.target.disabled) {
      setOpen(true)
    }
  }

  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
      }}>
      <Box flex={1}>
        <ClickAwayListener onClickAway={() => undefined}>
          <Autocomplete
            open={open}
            value={{
              key: props.value || 0,
              value: props.options!.find((option) => option.key === props.value)?.value || '',
            }}
            getOptionLabel={(option: KeyValPair) => {
              return t(option.value)
            }}
            isOptionEqualToValue={(option: KeyValPair, value: KeyValPair) =>
              option.key === value.key
            }
            options={[{ key: 0, value: '' }, ...props.options!]}
            // onChange={handleChange}
            onChange={(event: any, newValue: KeyValPair | null) => {
              props.onChange!(newValue ? newValue.key : 0)
              blurHandler(event)
            }}
            disabled={props.disabled ?? false}
            fullWidth
            forcePopupIcon={false}
            renderOption={(props, option) => {
              return (
                <li {...props} key={`${option.key}`}>
                  <Tooltip title={option.value}>
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {option.value}
                    </Typography>
                  </Tooltip>
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                variant={'standard'}
                {...params}
                onClick={(e) => {
                  clickHandler(e)
                }}
                onBlur={(e) => {
                  blurHandler(e)
                }}
                multiline
                {...props.fieldProps}
              />
            )}
            filterOptions={(options: KeyValPair[], state: FilterOptionsState<KeyValPair>) =>
              options.filter(
                (opt: KeyValPair) =>
                  opt.value !== '' &&
                  opt.value?.toLowerCase()?.includes(state.inputValue.toLowerCase()),
              )
            }
          />
        </ClickAwayListener>
      </Box>
      {!props.uneditable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <IconButton
            disabled={props.disabled}
            {...(!props.disabled && {
              onClick: () => {
                setOpen(!open)
              },
            })}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default TableSelectWithSearch
