import { KeyValPair } from '@models/Common'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import React, { createContext, useCallback, useReducer } from 'react'
import useLocalStorage from '@hooks/useLocalStorage'
import GlobalReducer, { ActionType } from '../reducers/globalReducer'
import {
  GeneralConfig,
  GeneralOptionGroup,
  CicGeneralOptionsModel,
} from '@services/model/general.model'
import { ContractNo } from '@services/model/contract.model'
import { FormStatus } from '@services/model/form/form.model'
// import { useKeycloak } from '@react-keycloak/web'
import { ProjectFormModel } from '@services/model/admin.model'
import { useOktaAuth } from '@okta/okta-react'

export interface GlobalStateProps {
  userMetaList?: KeyValPair[]
  roleMetaList?: KeyValPair[]
  enrolmentStatusList?: KeyValPair[]
  contractList?: ContractNo[]
  generalConfigList?: GeneralConfig[]
  generalOptionGroupList?: GeneralOptionGroup[]
  generalOptionList?: CicGeneralOptionsModel
  teamList?: KeyValPair[]
  districtList?: KeyValPair[]
  constructionStageList?: KeyValPair[]
  documentCategoryList?: KeyValPair[]
  selectableMonths?: any
  organizationMetaList?: any
  wasteQuantityReportingUnitList?: KeyValPair[]
  proposedActivityList?: KeyValPair[]
  weatherList?: KeyValPair[]
  essentialWorkList?: KeyValPair[]

  formStatusList?: FormStatus[]

  leftDrawerOpened: boolean
  headerColor: string
  openedMenuId: string | undefined // passing a menu id
  title: string
  subtitle?: string
  formStatus?: string
  currentContract?: any
  currentProject?: any
  projectList?: ProjectFormModel[]

  //admin
  projectLocationList?: KeyValPair[]
  projectDistrictList?: KeyValPair[]
  projectTypeList?: KeyValPair[]
  projectClassificationB1List?: KeyValPair[]
  projectClassificationB2List?: KeyValPair[]
}

const initialGlobalState: GlobalStateProps = {
  leftDrawerOpened: true,
  openedMenuId: undefined,
  title: 'Smart Waste Management Tool',
  headerColor: 'rgb(255, 202, 97)',
}
type organizationMetaListType = {
  businessRegistrationNo?: string
  companyName?: string
  subsidiaryName?: string
  organizationTypeId?: number
  identifier?: string
  name?: string
  status?: boolean
  createdAt?: string
  createdBy?: string
  id: number
}

export const GlobalContext = createContext<{
  userInfo: UserInfo
  state: GlobalStateProps
  dispatch: React.Dispatch<ActionType>
  isAuthenticated: () => boolean
  hasRightByCatAndCode: (
    category: string,
    rightsCode: string[],
    matchWholeWord?: boolean,
  ) => boolean
}>({
  userInfo: InitUserInfo,
  state: initialGlobalState,
  dispatch: () => undefined,
  isAuthenticated: () => false,
  hasRightByCatAndCode: (category: string, rightsCode: string[], matchWholeWord?: boolean) => false,
})

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialGlobalState)
  const [loginUser] = useLocalStorage<UserInfo>('usr', InitUserInfo)
  const { authState, oktaAuth } = useOktaAuth()
  // const { keycloak, initialized } = useKeycloak()

  // const isAuthenticated = useCallback(() => {
  //   return keycloak.authenticated || false
  // }, [keycloak])

  const isAuthenticated = useCallback(() => {
    return authState?.isAuthenticated || false
  }, [authState])

  const hasRightByCatAndCode = useCallback(
    (category: string, rightsCode: string[], matchWholeWord?: boolean) => {
      if (loginUser.allRights) {
        return true
      }

      let found: string | undefined

      if (matchWholeWord === false) {

        found = loginUser.categoryRights
          ?.find((x) => x.category?.toLowerCase().includes(category.toLowerCase()))
          ?.rights?.find((x) => rightsCode.includes(x))
      } else {
        found = loginUser.categoryRights
          ?.find((x) => x.category?.toLowerCase() === category.toLowerCase())
          ?.rights?.find((x) => rightsCode.includes(x))
      }

      return found !== undefined
    },
    [loginUser],
  )
  return (
    <GlobalContext.Provider
      value={{ userInfo: loginUser, state, dispatch, isAuthenticated, hasRightByCatAndCode }}>
      {children}
    </GlobalContext.Provider>
  )
}
