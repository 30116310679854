import DialogButton from '@components/button/DialogButton'
import CategoryBox from '@components/notification/CategoryBox'
import useAPIFetch from '@hooks/useAPIFetch'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Box, Grid, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import NotificationService from '@services/notification.service'
import { NotificationModelByType, getNotificationByModule } from '@utils/notification'
import { useEffect, useState } from 'react'
import { Header, NormalSwitch, Wrapper } from './styled'

export default function Notification() {
  const { setRequest, isLoading } = useAPIFetch()

  const [notifications, setNotifications] = useState<NotificationModelByType>({})
  const [notificationsGroupByModule, setNotificationsGroupByModule] = useState<
    {
      module: string
      notifications: NotificationModelByType
    }[]
  >([])

  const [currentTabs, setCurrentTabs] = useState<string>('All')
  const [days, setDays] = useState<number>(1)
  const [isRead, setIsRead] = useState<boolean>(false)

  const reload = async () => {
    setRequest({
      callback: async () => {
        const _max = new Date()
        const _min = new Date(Date.now() - 3600 * 1000 * 24 * days)
        const req = {
          isRead: {
            operator: '=',
            value: isRead,
          },
          readableTime: {
            operator: 'in',
            value: {
              min: _min,
              max: _max,
            },
          },
        }
        const list = await NotificationService.GetNotificationList(req)

        const notificationGroupByModule = getNotificationByModule(list)
        setNotificationsGroupByModule(notificationGroupByModule)
      },
    })
  }

  const handleSetNotification = () => {
    setNotifications(
      notificationsGroupByModule.find(({ module }) => module === currentTabs)?.notifications ?? {},
    )
  }

  const handleReadAll = async () => {
    const allNotificationsId = Object.keys(notifications)
      ?.map((noti) => notifications[noti])
      .flat()
    setRequest({
      callback: async (cancelToken: any) => {
        await NotificationService.BatchMarkNotificationRead(
          allNotificationsId.map((noti) => noti.id),
        )
        await reload()
      },
    })
  }

  useEffect(() => {
    if (currentTabs) {
      handleSetNotification()
    }
  }, [currentTabs, notifications, notificationsGroupByModule])

  useEffect(() => {
    reload()
  }, [isRead, days])

  return (
    <Wrapper>
      <Header>
        <NotificationsNoneIcon sx={{ color: '#707070', fontSize: 40 }} />
        <Typography sx={{ fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' }}>
          Notification
        </Typography>
      </Header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTabs}
          onChange={(event, newValue) => {
            setCurrentTabs(newValue)
          }}
          aria-label="basic tabs example"
          textColor="primary"
          indicatorColor="primary">
          {notificationsGroupByModule.map(({ module }) => {
            return <Tab label={module} value={module} />
          })}
        </Tabs>
      </Box>
      <Box>
        <Box display="flex" justifyContent="flex-end" gap="8px" sx={{ alignItems: 'center ' }}>
          <NormalSwitch checked={isRead} onChange={(event) => setIsRead(event.target.checked)} />
          <Typography>Show All</Typography>
          <DialogButton
            sx={{ backgroundColor: '#ffffff' }}
            onClick={handleReadAll}
            disabled={isRead}>
            Mark all as read
          </DialogButton>
          <Select
            value={days}
            onChange={(event) => setDays(event.target.value as number)}
            sx={{ width: '120px', height: '44.5px', backgroundColor: 'white' }}>
            <MenuItem value={1}>Today</MenuItem>
            <MenuItem value={3}>3 Days</MenuItem>
            <MenuItem value={7}>7 Days</MenuItem>
            <MenuItem value={14}>14 Days</MenuItem>
            <MenuItem value={30}>30 Days</MenuItem>
          </Select>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <CategoryBox
                type="Form Progression"
                msgList={notifications.FORM_PROGRESSION ?? []}
                reload={reload}
                isRead={isRead}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CategoryBox
                type="Form Comment"
                msgList={notifications.FORM_COMMENT ?? []}
                reload={reload}
                isRead={isRead}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CategoryBox
                type="Progress Due"
                msgList={notifications.PROGRESS_DUE ?? []}
                reload={reload}
                isRead={isRead}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CategoryBox
                type="Reminder"
                msgList={notifications.REMINDER ?? []}
                reload={reload}
                isRead={isRead}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Wrapper>
  )
}
