import { KeyValPair } from '@models/Common'
const getKeyValPairsByList = (list: Record<string, any>) => {
  if (list) {
    return (
      list.map((x) => {
        if (x.status !== false) return { key: x.id, value: x.name, status: x.status }
      }) || []
    )
  }
  return []
}

export default getKeyValPairsByList
