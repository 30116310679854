import { KeyValPair } from '@models/Common'
import { ConstructionOutlined, CropLandscapeOutlined } from '@mui/icons-material'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
  Autocomplete,
  TextFieldProps,
} from '@mui/material'
import { kMaxLength } from 'buffer'
import { Fragment, useState, useRef } from 'react'
import { Tooltip, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

//To support Tooltip in the option, KeyValPair is extended to include title field, which is be set as the tooltip
interface keyValTitle extends KeyValPair {
  title?: string
}
interface multipleSelect {
  value?: number[]
  onChange?: (...event: any[]) => void
  additionalChangeAction?: (event: any) => void
  options: keyValTitle[]
  disabled?: boolean
  uneditable?: boolean
  defaultValue?: any
  textfieldProps?: TextFieldProps
}

const FormMultipleSelect = (props: multipleSelect) => {
  const { t } = useTranslation()

  const textfieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t('common:components.select.placeholder'),
    disabled: props.disabled,
    ...props.textfieldProps,
  }

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any, newValue: keyValTitle[]) => {
    const _value = newValue.map((item) => {
      return item.key
    })
    props.onChange!(_value)
    if (props.additionalChangeAction) {
      props.additionalChangeAction(event)
    }
  }

  const list =
    props.options?.filter((option) => {
      if (Array.isArray(props.value)) return props.value?.includes(option.key)
    }) || []

  return (
    <Fragment>
      <Autocomplete
        readOnly={props.uneditable}
        defaultValue={list}
        fullWidth
        disablePortal
        multiple
        getOptionLabel={(option: keyValTitle) => {
          return t(option.value) ?? ''
        }}
        options={props.options ?? []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} {...textfieldProps} />}
        renderOption={(props, option: keyValTitle) => (
          <Tooltip title={option.title ?? ''}>
            <li {...props}>
              <>{t(option.value)}</>
            </li>
          </Tooltip>
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Fragment>
              {value.map((option, index) => {
                return (
                  <Tooltip title={option?.title ? t(option.title) : ''} key={index}>
                    <Chip label={t(option.value)} {...getTagProps({ index })} />
                  </Tooltip>
                )
              })}
            </Fragment>
          )
        }}
      />
    </Fragment>
  )
}

export default FormMultipleSelect
