import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormTable from '@components/form/table/Table'
import FormTypography from '@components/form/typography'
import FormFreeText from '@components/form/freeText'
import { GlobalStateProps } from '@providers/globalStore'
import { FormDrsUploadModel } from '@services/model/form/form.DRS.upload.model'
import { Control } from 'react-hook-form'
import moment from 'moment'
import FormDrsService from '@services/formService/form.DRS.service'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import DialogButton from '@components/button/DialogButton'
import { KeyValPair } from '@models/Common'
import { Fragment } from 'react'
import { useSnackbar } from 'notistack'
import useAPIFetch from '@hooks/useAPIFetch'
import { useRef } from 'react'
import testIsMobile from '@utils/testIsMobile'
const DailyRecordSummaryUploadPartA = ({
  t,
  setValue,
  getValues,
  uneditable,
  userInfo,
  globalState,
  control,
  formPermission,
  handleDownloadFile,
  overrideAddButton,
  drsGeneralOptions,
  formId,
  reload,
}: {
  t: any
  setValue: any
  getValues: any
  uneditable: any
  userInfo: any
  globalState: GlobalStateProps
  control: Control<FormDrsUploadModel>
  formPermission: any
  handleDownloadFile: any
  overrideAddButton: any
  drsGeneralOptions: any
  formId: any
  reload: any
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { setRequest, isLoading } = useAPIFetch()
  const currentMaterialType = useRef()
  const isMobile = testIsMobile()
  const handleChangeMaterialType = (event) => {
    currentMaterialType.current = event.target.value
  }
  const handleDialogConfirmDelete = (
    event: any,
    index: number,
    drsCategory: number,
    currentCat?: number,
  ) => {
    const _values = getValues()
    let req: any = _values?.[drsCategory]?.[index]
    let _formId: string = _values?.baseForm?.formId
    let _documentId: string = req?.fileId

    if (_formId === undefined || _documentId === undefined) return
    setRequest({
      callback: async () => {
        await FormDrsService.DeleteDrsUploadedDocument(_formId, _documentId).then((res) => {
          reload(formId)
          enqueueSnackbar(t('common:messages.fileDeleted'), { variant: 'success' })
        })
      },
    })
  }
  const handleDialogConfirm = (event: any, index: number, currentMaterialType: any) => {
    let needUpdate = false
    if (
      currentMaterialType?.current &&
      currentMaterialType?.current !== getValues(`truckPhotos.${index}.materialType`)
    ) {
      setValue(`truckPhotos.${index}.materialType`, currentMaterialType.current)
      needUpdate = true
    }

    const _values = getValues()

    let req = {}
    if (_values) {
      req = _values
    }
    // only send request if current field is modified
    if (needUpdate) {
      setRequest({
        callback: async () => {
          await FormDrsService.UpdateDRSUploadedDocument(req)
            .then((res) => {
              enqueueSnackbar(t('common:messages.recordSaved'), { variant: 'success' })
              reload()
            })
            .catch((err) => {
              enqueueSnackbar(err.resp.data.message, { variant: 'error' })
            })
        },
      })
    }
  }
  const handleDialogDeleteClick = (
    event: any,
    index: number,
    action: string,
    extraInfo: any,
    setDialogContext: any,
    dialogContext: any,
    editField: any,
  ) => {
    setDialogContext({
      ...dialogContext,
      title: <Typography variant="h6">{t('common:messages.confirmDeletion')}</Typography>,
      showConfirmDeleteBtn: true,
      isOpen: true,
      buttons: (
        <>
          <DialogButton
            onClick={(event) => handleDialogConfirmDelete(event, index, extraInfo?.drsCategory)}
            sx={{ color: 'red' }}>
            {t('common:buttons.confirm')}
          </DialogButton>
          <DialogButton onClick={(event) => editField(event, index, action, extraInfo)}>
            {t('common:buttons.cancel')}
          </DialogButton>
        </>
      ),
    })
  }
  const handleClickEditFieldTruckPhotos = (
    event,
    index,
    action,
    extraInfo,
    fields,
    setDialogContext,
    dialogContext,
    handleDialogClose,
    editField,
  ) => {
    setDialogContext({
      ...dialogContext,
      title: <Typography variant="h6">{t('File Details')}</Typography>,
      buttons: (
        <Fragment>
          <DialogButton
            onClick={(event) => {
              handleDialogConfirm(event, index, currentMaterialType)
              handleDialogClose()
            }}>
            {t('common:buttons.confirm')}
          </DialogButton>
          <DialogButton
            onClick={(event) =>
              handleDialogDeleteClick(
                event,
                index,
                action,
                extraInfo,
                setDialogContext,
                dialogContext,
                editField,
              )
            }
            sx={{ color: 'red' }}>
            {t('common:buttons.delete')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('common:buttons.cancel')}</DialogButton>
        </Fragment>
      ),
      children: (
        <>
          <FormField fieldName={'Document Name'}>{fields?.[index]?.['fileName']}</FormField>
        </>
      ) ?? <></>,
      isOpen: true,
    })
  }
  const handleClickEditFieldChitReceipts = (
    event,
    index,
    action,
    extraInfo,
    fields,
    setDialogContext,
    dialogContext,
    handleDialogClose,
    editField,
  ) => {
    setDialogContext({
      ...dialogContext,
      title: <Typography variant="h6">{t('File Details')}</Typography>,
      buttons: (
        <Fragment>
          <DialogButton
            onClick={(event) => {
              handleDialogConfirm(event, index, currentMaterialType)
              handleDialogClose()
            }}>
            {t('common:buttons.confirm')}
          </DialogButton>
          <DialogButton
            onClick={(event) =>
              handleDialogDeleteClick(
                event,
                index,
                action,
                extraInfo,
                setDialogContext,
                dialogContext,
                editField,
              )
            }
            sx={{ color: 'red' }}>
            {t('Delete')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
        </Fragment>
      ),
      children: (
        <>
          <FormField fieldName={'Material Type'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* {column.icon} */}
              <Select
                fullWidth
                sx={{
                  minWidth: '300px',
                  fontSize: 12,
                  backgroundColor: '#ffffff',
                }}
                // {...field}
                onChange={handleChangeMaterialType}
                defaultValue={fields?.[index]?.['materialType']}>
                {extraInfo?.materialTypeOptions!.map((option: KeyValPair) => (
                  <MenuItem
                    sx={{
                      fontSize: 12,
                    }}
                    value={option.key}
                    key={`form-table-select-option-${option.key}`}>
                    {t(option.value)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormField>
          <FormField fieldName={'Document Name'}>{fields?.[index]?.['fileName']}</FormField>
        </>
      ) ?? <></>,
      isOpen: true,
    })
  }
  let index = 0

  return (
    <>
      <FormContainer fkey={`drs-a`}>
        <FormField fieldName="Chit Number" index={index++}>
          <FormController
            controllerProps={{
              name: 'chitNo',
              control,
            }}>
            <FormFreeText textfieldProps={{ type: 'string' }} uneditable={uneditable || formId} />
          </FormController>
        </FormField>
        <FormField fieldName="Date of Disposal" index={index++}>
          <FormController
            controllerProps={{
              name: 'reportPeriod',
              control,
            }}>
            <FormDateTimePicker type="date" maxDate={new Date()} uneditable={uneditable} />
          </FormController>
        </FormField>
        {/* <FormField fieldName="Disposal Ground" index={index++}>
          <FormController
            controllerProps={{
              name: 'disposalGround',
              control,
            }}>
            <FormSelect
              options={designatedDisposalGroundOptions}
              tooltipFieldName={'title'}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Type of Material" index={index++}>
          <FormController
            controllerProps={{
              name: 'materialType',
              control,
            }}>
            <FormSelect options={[]} tooltipFieldName={'title'} uneditable={uneditable} />
          </FormController>
        </FormField> */}
      </FormContainer>
      <FormTable
        isMobile={isMobile}
        title={t('Truck Photos')}
        color={globalState?.headerColor}
        columns={[
          {
            field: 'fileName',
            displayName: 'Document Name',
            type: 'string',
            uneditable: true,
          },
          {
            field: 'regularUrl',
            displayName: 'Image',
            type: 'thumbnail',
            uneditable: true,
          },
          {
            field: 'uploadedBy',
            displayName: 'Uploaded By',
            type: 'string',
            uneditable: true,
          },
        ]}
        hiddenItemNo
        disableDeleteRowButton
        onHeaderAddButtonClick={overrideAddButton}
        onFooterButtonClick={overrideAddButton}
        showEditDownloadButtons
        height={50}
        uneditable={uneditable}
        fieldArrayProps={{
          name: `truckPhotos`,
          control: control,
        }}
        extraInfo={{
          createdBy: userInfo.firstName,
          materialTypeOptions: drsGeneralOptions?.materialType ?? [],
          drsCategory: 'truckPhotos',
        }}
        onDownloadFile={(index: number, extraInfo: any) => handleDownloadFile(index, 'truckPhotos')}
        onClickEditField={handleClickEditFieldTruckPhotos}
      />
      <FormTable
        isMobile={isMobile}
        title={t('Chit Receipts')}
        color={globalState?.headerColor}
        columns={[
          {
            field: 'fileName',
            displayName: 'Document Name',
            type: 'string',
            uneditable: true,
          },
          {
            field: 'uploadedBy',
            displayName: 'Uploaded By',
            type: 'string',
            uneditable: true,
          },
          {
            field: 'materialType',
            displayName: 'Type of Material',
            type: 'select',
            options: drsGeneralOptions?.materialType,
            cellStyle: { width: '350px' },
            uneditable: true,
          },
        ]}
        hiddenItemNo
        disableDeleteRowButton
        onFooterButtonClick={overrideAddButton}
        onHeaderAddButtonClick={overrideAddButton}
        showEditDownloadButtons
        height={50}
        uneditable={uneditable}
        fieldArrayProps={{
          name: `chitReceipts`,
          control: control,
        }}
        extraInfo={{
          createdBy: userInfo.firstName,
          materialTypeOptions: drsGeneralOptions?.materialType ?? [],
          drsCategory: 'chitReceipts',
        }}
        onDownloadFile={(index: number, extraInfo: any) =>
          handleDownloadFile(index, 'chitReceipts')
        }
        onClickEditField={handleClickEditFieldChitReceipts}
      />
    </>
  )
}
export default DailyRecordSummaryUploadPartA
