import { format, parseISO } from 'date-fns'

import { Nil } from '@models/Common'

export const toUnixTimestamp = (dateStr?: string | null, inMs = false) => {
  const date = dateStr ? new Date(dateStr) : new Date()

  if (inMs) {
    return date.getTime()
  } else {
    return Math.floor(date.getTime() / 1000)
  }
}

export const getDateFormatByType = (type: string | undefined): string => {
  switch (type) {
    case 'time':
      return 'HH:mm'
    case 'datetime':
      return 'd MMMM yyyy, HH:mm'
    case 'month':
      return 'MMMM yyyy'
    case 'year':
      return 'yyyy'
    case 'date':
    default:
      return 'd MMMM yyyy'
  }
}

const formatDateToDateString = (type: string, date: Nil<Date>) =>
  date instanceof Date ? format(date, getDateFormatByType(type)) : ''

const formatDateStringToFormatedDateString = (type: string, dateString: Nil<string>) => {
  if (!dateString) return ''
  try {
    const date = parseISO(dateString)
    return format(date, getDateFormatByType(type))
  } catch (_: any) {
    return ''
  }
}

export const dateTimeTransformer =
  (type: string) =>
  (value): string => {
    return typeof value === 'string'
      ? formatDateStringToFormatedDateString(type, value)
      : formatDateToDateString(type, value)
  }
