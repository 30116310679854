import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import { Card, Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DashboardCicAverageRecyclingRate = ({ getValues }: { getValues: any }) => {
  const { t } = useTranslation()

  const values = getValues()
  const resKey = 'recyclingRateOfSameProjectTypeAndStage'
  const hideChart = values?.[resKey]?.showChart === false
  const message = values?.[resKey]?.message ?? ''
  const resData = values?.[resKey]?.datasets
  const outerData = resData?.find((item) => item.groupName === 'Outer')?.data

  const outerModifiedData = outerData?.reduce(
    (acc, curr) => ({ ...acc, [curr.itemName]: curr.value }), // for percentage float value
    {},
  )

  return (
    <>
      {hideChart ? (
        <>{t(message)}</>
      ) : (
        <SingleDatasetChart
          eleBefore={<></>}
          chartWrapperSX={{
            width: '100%',
            margin: 'auto',
          }}
          type={'doughnut'}
          hasSummaryTable={false}
          data={outerModifiedData ?? {}}
          dataConfig={{
            borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
            borderWidth: 2,
            backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true,
                intersect: false,
                mode: 'nearest',
                callbacks: {
                  label: (item) => {
                    return t(item.label) + ': ' + item.parsed * 100 + '%'
                  },
                },
              },
              legend: {
                display: true,
                position: 'bottom',
              },
              datalabels: {
                formatter: (value, ctx) => {
                  let percentage = (value * 100).toFixed(2) + '%'
                  return percentage
                },
                color: '#fff',
              },
            },
          }}></SingleDatasetChart>
      )}
    </>
  )
}

export default DashboardCicAverageRecyclingRate
