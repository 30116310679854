const testIsMobile = () => {
  const isMobileDevice = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(
    window.navigator.userAgent,
  )
  const isIPad = /iPad/i.test(window.navigator.userAgent)
  const isDesktopDevice = !isMobileDevice || (isIPad && window.innerWidth >= 768)
  return !isDesktopDevice
}

export default testIsMobile
