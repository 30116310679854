import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import FormService from '@services/form.service'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents } from '@models/Common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import { FormWmptModel, InitWmptForm } from '@services/model/form/form.WMPT.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormWmpService from '@services/formService/form.WMP.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import WmptPartA from './PartA'
import WmptPartB from './PartB'
import WmptPartC from './PartC'
import WmptPartD from './PartD'
import { FormWmpGeneralOptionsModel } from '@services/model/form/form.WMP.model'
import useLocalStorage from '@hooks/useLocalStorage'
import getErrMsg from '@utils/getErrMsg'

const WasteManagementPlanTemplatePage = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formStateContext, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { control, setValue, getValues, handleSubmit, reset, register, trigger } =
    useForm<FormWmptModel>({
      defaultValues: { ...InitWmptForm },
    })

  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const [contractNoId, contractNo, contractName, formStatus, formPermission, projectType] =
    useWatch({
      name: [
        'baseForm.contractNoId',
        'baseForm.contractNo',
        'baseForm.contractName',
        'baseForm.formStatus',
        'baseForm.formPermission',
        'baseForm.projectType',
      ],
      control: control,
    })
  const isMounted = useRef(false)

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [wftGeneralOptions, setWmpGeneralOptions] = useState<FormWmpGeneralOptionsModel>()

  const handleOnSubmit = async () => {} // no need to implement
  const handleOnSave = async () => {} // no need to implement
  const handleOnDelete = async () => {} // no need to implement

  const reloadContract = () => {
    setValue('baseForm.contractNoId', currentContract?.contractId)
    setValue('baseForm.contractName', currentContract?.contractName)
    setValue('baseForm.contractNo', currentContract?.contractNo)
    setValue('baseForm.projectType', currentContract?.projectType)
  }

  useEffect(() => {
    if (!isMounted.current && currentContract && formStateAction && globalAction) {
      reloadContract()
      reloadWMPGeneralOptions()
      if (formStateContext.form && formStateContext.formType === 'WMP') {
        formStateAction({ type: 'pop', targetFormType: 'WMP' })
        reset({ ...formStateContext.form })
      }
      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }
    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })

      isMounted.current = false
    }
  }, [formId, currentContract])

  const reloadWMPGeneralOptions = async () => {
    setOptionsRequest({
      callback: async () => {
        await FormWmpService.GetWmpGeneralOptions()
          .then(async (res) => {
            setWmpGeneralOptions(res)
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const partA = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteManagementPlan.template.sections.general.title'),
      component: <WmptPartA control={control} />,
    }
  }

  const partB = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteManagementPlan.template.sections.selectMeasuresConstruction'),
      component: <WmptPartB control={control} formPermission={formPermission} />,
      disabled: formPermission?.canUpdate === false,
    }
  }
  const partC = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteManagementPlan.template.sections.selectMeasuresOperation'),
      component: <WmptPartC control={control} formPermission={formPermission} />,
      disabled: formPermission?.canUpdate === false,
    }
  }
  const partD = (): FormPartComponents => {
    return {
      partTitle: t('forms:wasteManagementPlan.sections.confirmation.title'),
      component: <WmptPartD getValues={getValues} currentContract={currentContract} />,
      disabled: formPermission?.canUpdate === false,
    }
  }

  const { FormSwitcher } = useFormSwitcher({
    control: control,
    trigger: trigger,
    title: t('forms:wasteManagementPlan.template.title'),
    components: [partA(), partB(), partC(), partD()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    navigateBack: () => {
      NavigateTo(navigate, '/waste-management-plan/all-records')
    },
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableComment: getValues('baseForm.formId') ? false : true,
    startStep: step,
    isLoading: isLoading,
    disableSave: true,
    disableDelete: true,
    approveRequired: false,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
    </Fragment>
  )
}

export default WasteManagementPlanTemplatePage
