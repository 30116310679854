import FormContainer from '@components/form/container'
import { FormWmptModel } from '@services/model/form/form.WMPT.model'
import { Control } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import FormController from '@components/form/controller'
import FormSingleRowTable from '@components/form/table/SingleRowTable'
import FormCheckbox from '@components/form/checkbox'
import { useTranslation } from 'react-i18next'

const WasteManagementPlanTemplatePartB = ({
  control,
  formPermission,
}: {
  control: Control<FormWmptModel>
  formPermission: any
}) => {
  const cicFormTableTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: '68px !important',
          },
          icon: {
            width: '40px',
            height: '90%',
            top: 'inherit',
            background: ' #FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '0px 5px 5px 0px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })
  const uneditable = !formPermission?.canUpdate
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const defaultWidth = '150px'
  const defaultMinHeight = '80px'
  const Construction1Columns = [
    {
      displayName: t('forms:wasteManagementPlan.template.fields.goodSitePractice'),
      field: (
        <FormController
          controllerProps={{
            name: 'goodSitePractice',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.hardRockConcrete'),
      field: (
        <FormController
          controllerProps={{
            name: 'hardRockConcrete',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.excavatedSoil'),
      field: (
        <FormController
          controllerProps={{
            name: 'excavatedSoil',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.otherInertWaste'),
      field: (
        <FormController
          controllerProps={{
            name: 'otherInertWaste',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.metals'),
      field: (
        <FormController
          controllerProps={{
            name: 'metals',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.paperPackaging'),
      field: (
        <FormController
          controllerProps={{
            name: 'paperPackaging',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
  ]

  const Construction2Columns = [
    {
      displayName: t('forms:wasteManagementPlan.template.fields.plastics'),
      field: (
        <FormController
          controllerProps={{
            name: 'plastics',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.timber'),
      field: (
        <FormController
          controllerProps={{
            name: 'timber',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.constructionChemicalWaste'),
      field: (
        <FormController
          controllerProps={{
            name: 'constructionChemicalWaste',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.otherNonInertWaste'),
      field: (
        <FormController
          controllerProps={{
            name: 'otherNonInertWaste',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
    {
      displayName: t('forms:wasteManagementPlan.template.fields.constructionOtherWaste'),
      field: (
        <FormController
          controllerProps={{
            name: 'constructionOtherWaste',
            control,
          }}>
          <FormCheckbox />
        </FormController>
      ),
    },
  ]

  return (
    <FormContainer fkey={`$wmp-b`}>
      <ThemeProvider theme={cicFormTableTheme}>
        <FormSingleRowTable columns={Construction1Columns} hiddenItemNo />
        <FormSingleRowTable columns={Construction2Columns} hiddenItemNo />
      </ThemeProvider>
    </FormContainer>
  )
}
export default WasteManagementPlanTemplatePartB
