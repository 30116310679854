import { styled } from '@mui/material/styles'
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Box } from '@mui/material'

export const FormTableBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  color: '#707070',
}))

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  padding: theme.spacing(1),
  color: '#707070',
}))

export const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: theme.spacing(1),
  color: '#707070',
}))

export const ViewIcon = styled(Box)(({ theme }) => ({
  borderRadius: '5px',
  boxShadow: '0px 3px 6px #00000029',
  backgroundColor: '#FFFFFF',
  padding: theme.spacing(1),
}))
