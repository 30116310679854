import { FormDrsItemsModel } from '@services/model/form/form.DRS.item.model'
import { Control } from 'react-hook-form'
import FormTable from '@components/form/table/Table'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box, Button, Stack } from '@mui/material'
import FormContainer from '@components/form/container'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { DisposalGroundEnum, FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import { TableColumnProps } from '@components/form/table/model'
import testIsMobile from '@utils/testIsMobile'
import { FormStatusEnum } from '@services/model/form/form.model'
import cleanString from '@utils/cleanString'

const DailyRecordSummaryItemBatchPartB = ({
  part1ReadOnly,
  getValues,
  useWatch,
  setValue,
  control,
  formPermission,
  formStatus,
  drsGeneralOptions,
  onClickSaveRowBtn,
  trigger,
}: {
  part1ReadOnly: boolean
  getValues: any
  useWatch: any
  setValue: any
  onClick?: (...event: any[]) => void
  control: Control<FormDrsItemsModel>
  formPermission: any
  formStatus: string
  drsGeneralOptions: FormDrsGeneralOptionsModel | undefined
  onClickSaveRowBtn?: (...event: any[]) => void
  trigger: any
}) => {
  const cicFormTableTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: '68px !important',
          },
          icon: {
            width: '40px',
            height: '90%',
            top: 'inherit',
            background: ' #FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '0px 5px 5px 0px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })

  const cicFormTableThemeMobile = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {},
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })

  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = testIsMobile()
  let index = 0
  const defaultWidth = isMobile ? '100%' : '170px'
  const defaultMinHeight = isMobile ? '40px' : '80px'
  const defaultVariant = isMobile ? { variant: 'standard' } : {}
  const designatedDisposalGroundOptions = drsGeneralOptions?.disposalGround?.filter(
    (option) => cleanString(option.value) !== cleanString(DisposalGroundEnum.other),
  )
  const { t } = useTranslation(['common', 'forms'])

  const disableAddButton = false

  const columnData: TableColumnProps<FormDrsItemsModel>[] = [
    {
      field: 'chitNo',
      displayName: t('forms:dailyRecordSummary.fields.chitNo'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      isDebounce: true,
      fieldProps: defaultVariant,
      additionalOnChangeAction: async (value, datumIndex) => {},
      disableCallback: (datumIndex) => {
        if (getValues(`drsItems.${datumIndex}.drsChitUpload.baseForm`)) {
          return true
        }
      },
      disable: part1ReadOnly,
    },
    {
      field: 'vehicleRegistrationMark',
      displayName: t('forms:dailyRecordSummary.fields.vehicleRegistrationMark'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
    },
    {
      field: 'dateOfDisposal',
      displayName: t('forms:dailyRecordSummary.fields.dateOfDisposal'),
      type: 'date',
      minHeight: defaultMinHeight,
      width: '200px',
      maxDate: new Date(),
      fieldProps: isMobile
        ? {}
        : {
            ...defaultVariant,
            slotProps: {
              textField: {
                sx: { backgroundColor: 'white' },
                inputProps: {
                  sx: {
                    py: '30px',
                    fontSize: 12,
                  },
                },
              },
            },
          },
      defaultValue: getValues('dateOfDisposal'),

      disable: part1ReadOnly,
    },
    {
      field: 'departureTimeFromSite',
      displayName: t('forms:dailyRecordSummary.fields.departureTimeFromSite'),
      type: 'time',
      minHeight: defaultMinHeight,
      width: '200px',
      fieldProps: isMobile
        ? {}
        : {
            ...defaultVariant,
            slotProps: {
              textField: {
                sx: { backgroundColor: 'white' },
                inputProps: {
                  sx: {
                    py: '30px',
                    fontSize: 12,
                  },
                },
              },
            },
          },
      disable: part1ReadOnly,
      additionalChangeAction: (value, datumIndex) => {},
    },
    {
      field: 'approxVol',
      displayName: t('forms:dailyRecordSummary.fields.approxVol'),
      type: 'select',
      options: drsGeneralOptions?.approximateValue,
      minHeight: defaultMinHeight,
      width: defaultWidth,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
    },
    {
      field: 'cDMaterialType',
      displayName: t('forms:dailyRecordSummary.fields.cDMaterialType'),
      type: 'select',
      options: drsGeneralOptions?.cDMaterialType,
      minHeight: defaultMinHeight,
      width: defaultWidth,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      defaultValue: getValues('cDMaterialType'),
    },
    {
      field: 'disposalGround',
      displayName: t('forms:dailyRecordSummary.fields.disposalGround'),
      type: 'select',
      options: designatedDisposalGroundOptions,
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      defaultValue: getValues('disposalGround'),
    },
    {
      field: 'zoneWithinSite',
      displayName: t('forms:dailyRecordSummary.fields.zoneWithinSite'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
    },
    {
      field: 'remarks',
      tooltip: t('forms:dailyRecordSummary.fields.remarks.tooltip'),
      displayName: t('forms:dailyRecordSummary.fields.remarks.label'),
      type: 'string',
      options: designatedDisposalGroundOptions,
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
    },
  ]

  return (
    <ThemeProvider key={'table'} theme={matchUpMd ? cicFormTableTheme : cicFormTableThemeMobile}>
      <FormContainer fkey={`$drs-a`}>
        <FormTable
          onClickSaveRowBtn={onClickSaveRowBtn}
          isMobile={isMobile}
          uneditable={!formPermission?.canUpdate}
          disableAddButton={disableAddButton}
          useWatch={useWatch}
          getValues={getValues}
          setValue={setValue}
          removeOverflow={true}
          key={`table-b-${index}`}
          columns={columnData}
          height={50}
          additionalAppendAction={(event) => {
            setValue('validatedPassed', false)
          }}
          fieldArrayProps={{
            name: 'drsItems',
            control: control,
          }}
        />
      </FormContainer>
      <Box height={100}></Box>
    </ThemeProvider>
  )
}
export default DailyRecordSummaryItemBatchPartB
